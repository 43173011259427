import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  required,
  ArrayInput,
  ReferenceInput,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';

import { positiveNumberValidation } from '../../utils/validationErrors';
import renderReference from '../../utils/renderReference';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';

import { useConstantContext } from '../ConstantsContext';

import validateAssignedCreditLimits from '../utils/validateAssignedCreditLimit';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const validateRequired = [required()];
// TODO global validation for data consistecy -> sum or reserved <= total limit

const UpdateCreditSettingsButton = ({ startingEntityDetailsId }) => {
  const { hasFactoring } = useUserPermissionsAndRoles();
  const record = useRecordContext();
  const { constants, choices } = useConstantContext();
  const { t } = useTranslation();

  const { currencies: currenciesChoices } = choices;
  const { entityRoles, userActions } = constants;
  const { entityDetailsId } = record;

  const entityDetailsToUpdate = entityDetailsId || startingEntityDetailsId;

  const {
    mutate: updateCreditSettings,
    isLoading,
  } = useCustomRpc({
    path: `entity-v2/${entityDetailsToUpdate}/credit-settings`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the credit settings'),
  });

  const canEditCreditInfo = useHasUserPermissions(
    constants,
    userActions.MANAGE_CUSTOMER_CREDIT_LIMIT_FOR_MERCHANTS,
  );

  const onSubmit = useCallback((data) => {
    const {
      creditLimit,
      creditLimitCurrency,
      creditInsuranceLimit,
      creditInsuranceLimitCurrency,
      assignedCreditLimits,
    } = data;
    updateCreditSettings({
      creditLimit,
      creditLimitCurrency,
      creditInsuranceLimit,
      creditInsuranceLimitCurrency,
      assignedCreditLimits: assignedCreditLimits || [],
    });

    return true;
  }, [updateCreditSettings]);

  if (!hasFactoring || !canEditCreditInfo) return null;

  const {
    creditLimit,
    creditLimitCurrency,
    creditInsuranceLimit,
    creditInsuranceLimitCurrency,
    assignedCreditLimits,
  } = record;

  return (
    <EditModalButton
      modalTitle={t('Update credit settings')}
      onClick={onSubmit}
      disabled={isLoading}
      width="47.5rem"
      formDefaultValue={{
        creditLimit,
        creditLimitCurrency,
        creditInsuranceLimit,
        creditInsuranceLimitCurrency,
        assignedCreditLimits,
      }}
    >
      <InputsGroup layout="column">
        <NumberInput
          label={t('Credit limit')}
          source="creditLimit"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <SelectInput
          margin="none"
          label={t('Credit currency')}
          source="creditLimitCurrency"
          choices={currenciesChoices}
          validate={validateRequired}
        />
        <NumberInput
          label={t('Insurance limit')}
          source="creditInsuranceLimit"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <SelectInput
          margin="none"
          label={t('Insurance currency')}
          source="creditInsuranceLimitCurrency"
          choices={currenciesChoices}
          validate={validateRequired}
        />
      </InputsGroup>
      <InputsGroup title={t('Reserved credit limit')}>
        <ArrayInput
          source="assignedCreditLimits"
          label={false}
          validate={validateAssignedCreditLimits}
        >
          <SimpleFormIterator addButtonLabel={t('Reserve new credit limit')} itemLabel={t('Credit limit')}>
            <InputsGroup layout="column">
              <ReferenceInput
                source="merchantId"
                reference="entity-v2/reference"
                filter={{ entityRoles: entityRoles.MERCHANT }}
              >
                <AutocompleteInput
                  label={t('Merchant')}
                  validate={validateRequired}
                  optionText={renderReference}
                  suggestionLimit={20}
                />
              </ReferenceInput>
              <NumberInput
                label={t('Assigned credit limit')}
                source="assignedCreditLimit"
                validate={[positiveNumberValidation, ...validateRequired]}
              />
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
    </EditModalButton>
  );
};

UpdateCreditSettingsButton.propTypes = {
  startingEntityDetailsId: PropTypes.string.isRequired,
};

export default UpdateCreditSettingsButton;
