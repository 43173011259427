import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import { ChildrenProp } from '../../../../utils/helpers/propTypes';

export const getLabelStyle = (theme) => ({
  default: {
    opacity: 1,
    color: theme.palette.headers,
    fontWeight: 400,
    fontSize: '15px',
  },
  filter: {
    opacity: 1,
    color: theme.palette.headers,
    fontWeight: 600,
    fontSize: '12px',
  },
});

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fimentoVariant',
})(({ theme, fimentoVariant }) => ({
  '&:focus-within': {
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.main,
    },
  },
  '& .Mui-error': {
    color: `${theme.palette.error.main}!important`,
  },
  '& .MuiFormControl-root': {
    width: '100%',
    minWidth: 'unset',
    marginTop: '0!important', // R-A apply a constant margin for filter inputs

    '& .MuiInputLabel-root': {
      ...getLabelStyle(theme)[fimentoVariant],
      transform: 'translate(0.75rem, 0.6rem) scale(1)',
    },
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  position: 'relative',
  transformOrigin: 'left',
  transform: 'translate(0, -1.5px) scale(0.75)',
  minHeight: '1.6rem',
}));

const InputWrapper = ({
  children,
  inputId,
  isRequired,
  label,
  error,
  InputLabelProps,
  fimentoVariant,
  handleHide,
}) => {
  const { t } = useTranslation();
  const onClick = useCallback((e) => {
    e.preventDefault();
    handleHide();
  }, [handleHide]);
  return (
    <StyledBox fimentoVariant={fimentoVariant}>
      {label && (
        <StyledInputLabel
          shrink
          htmlFor={inputId}
          error={error}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...InputLabelProps}
        >
          {handleHide && (
            <IconButton
              onClick={onClick}
              title={t('Remove')}
              size="small"
              color={error ? 'error' : 'secondary'}
              sx={{ padding: 0, marginRight: '0.3rem' }}
            >
              <Delete />
            </IconButton>
          )}
          {isRequired ? `${label} *` : label}
        </StyledInputLabel>
      )}
      {children}
    </StyledBox>
  );
};

InputWrapper.propTypes = {
  children: ChildrenProp.isRequired,
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  InputLabelProps: PropTypes.shape(),
  fimentoVariant: PropTypes.string,
  handleHide: PropTypes.func,
};

InputWrapper.defaultProps = {
  isRequired: false,
  error: false,
  InputLabelProps: {},
  label: undefined,
  fimentoVariant: 'default',
  handleHide: undefined,
};

export default InputWrapper;
