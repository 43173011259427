import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { useRecordContext } from 'react-admin';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';

const ResendButton = ({ isCreditor }) => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const {
    mutate: resend,
    isLoading,
  } = useCustomRpc({
    path: `invoice/${record.id}/resend`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not resend email'),
  });

  const handleClick = useCallback(() => {
    resend();
    return true;
  }, [resend]);

  if (!record.pdfGeneratedAt || !isCreditor) return null;

  return (
    <ModalButton
      modalTitle={t('Resend')}
      openButtonLabel={t('Resend')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Send')}
      variant="subAction"
      width="30rem"
    >
      <Typography paragraph>{t('The invoice will be sent via email.')}</Typography>
      <Typography>{t('This action cannot be reverted.')}</Typography>
    </ModalButton>
  );
};

ResendButton.propTypes = {
  isCreditor: PropTypes.bool.isRequired,
};

export default ResendButton;
