import { styled } from '@mui/material';
import { MenuItemLink as RaMenuItemLink } from 'react-admin';

export const menuItemLinkStyle = ({ theme }) => ({
  fontSize: '15px',
  color: theme.palette.switch,
  paddingLeft: 0,
  paddingBottom: 0,
  paddingTop: '0.5rem',
  '& .RaMenuItemLink-icon, .MuiListItemIcon-root': {
    minWidth: 'unset',
    marginRight: '0.3rem',
  },
  '& .MuiTypography-root': {
    fontSize: '15px',
    color: theme.palette.switch,
  },
});

const StyledMenuItemLink = styled(RaMenuItemLink)(menuItemLinkStyle);

const MenuItemLink = StyledMenuItemLink;

export default MenuItemLink;
