import React from 'react';
import {
  Toolbar,
  SaveButton,
} from 'react-admin';

import UpdateUserStatusButton from './UpdateUserStatusButton';

const SaveAndActivateToolbar = () => (
  <Toolbar>
    <SaveButton style={{ marginRight: 'auto' }} />
    <UpdateUserStatusButton />
  </Toolbar>
);

export default SaveAndActivateToolbar;
