import { t } from 'i18next';
import moment from 'moment';

const validateKycField = () => (fieldValue, formValues, fieldInfo) => {
  const {
    creditScoreProvider,
    creditScore,
    paymentScoreProvider,
    paymentScore,
    ratingProvider,
    rating,
    insuranceStartDate,
  } = formValues;
  const { source: fullFieldName } = fieldInfo;

  switch (fullFieldName) {
    case 'ratingProvider':
      if (!fieldValue && rating) return t('Rating provider is required when rating is set');
      break;
    case 'rating':
      if (!fieldValue && ratingProvider) return t('Rating is required when rating provider is set');
      break;
    case 'creditScoreProvider':
      if (!fieldValue && creditScore) return t('Credit score provider is required when credit score is set');
      break;
    case 'creditScore':
      if (!fieldValue && creditScoreProvider) return t('Credit score is required when credit score provider is set');
      break;
    case 'paymentScoreProvider':
      if (!fieldValue && paymentScore) return t('Payment score provider is required when payment score is set');
      break;
    case 'paymentScore':
      if (!fieldValue && paymentScoreProvider) return t('Payment score is required when payment score provider is set');
      break;
    case 'insuranceEndDate':
      if (insuranceStartDate && moment(insuranceStartDate).isAfter(moment(fieldValue))) return t('Insurance end date should be set after insurance start date');
      break;
    default:
      return null;
  }
  return null;
};

export default validateKycField;
