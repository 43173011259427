import React from 'react';

import {
  FunctionField,
  ReferenceField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import CurrencyField from '../../utils/CurrencyField';
import { useConstantContext } from '../../ConstantsContext';
import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import renderReference from '../../../utils/renderReference';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const RequestedCreditSettings = () => {
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );

  return (
    <FieldsGroup title={t('Requested credit settings')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField
              label={t('Credit limit')}
              source="requestedTerms.creditLimit"
              currencySource="requestedTerms.creditLimitCurrency"
            />
          </SimpleShowLabeled>
        </Grid>
        {canViewMerchant && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Merchant')}
                source="merchantId"
                reference="entity-v2/reference"
                link={false}
              >
                <ReferenceField
                  label={t('Merchant')}
                  source="entityDetailsId"
                  reference="entity-v2/merchant"
                  link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
                >
                  <FunctionField render={renderReference} />
                </ReferenceField>
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
        )}
        {isMerchant && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Financer')}
                source="financerId"
                reference="entity-v2/reference"
                link={false}
              >
                <FunctionField render={renderReference} />
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
        )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Requesting user')}
              source="applicant"
              reference="user/public"
              link={false}
            >
              <FunctionField render={(user) => `${user.firstName} ${user.lastName}`} />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default RequestedCreditSettings;
