import React from 'react';

import {
  FormTab,
  ReferenceField,
  ReferenceOneField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import MerchantDetailsGroup from '../MerchantDetailsGroup';
import CustomerDetailsGroup from '../CustomerDetailsGroup';
import ApplicationDetailsGroup from '../ApplicationDetailsGroup';
import FinancingApplicationRulesResultsGroup from '../FinancingApplicationRulesResultsGroup';
import FinancingApplicationTermsGroup from '../FinancingApplicationTermsGroup';

import DeliveryConfirmationGroup from '../../../order/DeliveryConfirmationGroup';

const SummaryTabNew = (props) => {
  const { t } = useTranslation();

  const record = useRecordContext() || {};
  const terms = record.terms || {};

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <ApplicationDetailsGroup />
      <Grid container>
        <Grid item lg={6} md={12}>
          <ReferenceField
            source="merchantId"
            reference="entity-v2/reference"
            link={false}
          >
            <ReferenceOneField
              reference="entity-v2/merchant"
              source="entityDetailsId"
              target="id"
              link={false}
              filter={{ custom_kpiReferenceCurrency: terms.financingCurrency }}
            >
              <MerchantDetailsGroup />
            </ReferenceOneField>
          </ReferenceField>
        </Grid>
        <Grid item lg={6} md={12}>
          <ReferenceField
            source="debtorId"
            reference="entity-v2/reference"
            link={false}
          >
            <ReferenceOneField
              reference="entity-v2/customer"
              source="entityDetailsId"
              target="id"
              link={false}
              filter={{ custom_kpiReferenceCurrency: terms.financingCurrency }}
            >
              <CustomerDetailsGroup />
            </ReferenceOneField>
          </ReferenceField>
        </Grid>
      </Grid>
      <FinancingApplicationRulesResultsGroup />
      <DeliveryConfirmationGroup />
      <FinancingApplicationTermsGroup />
    </FormTab>
  );
};

export default SummaryTabNew;
