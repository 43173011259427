import { useCallback, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useConstantContext } from '../ConstantsContext';
import useCustomRpc from './useCustomRpc';

const uploadRpcDocument = async (dataProvider, document) => dataProvider.uploadDocument([{ file: document, fileName: 'files' }]);

const timeout = (miliseconds) => new Promise((resolve) => {
  setTimeout(() => resolve(), miliseconds);
});

const useUploadFileCustomRpc = ({
  withPolling,
  ...options
}) => {
  const customRpc = useCustomRpc(options);
  const dataProvider = useDataProvider();
  const { constants } = useConstantContext();
  const { fileStatuses } = constants;
  const [fileSummary, setFileSummary] = useState();
  const [isPollingLoading, setIsPollingLoading] = useState(false);

  const isFinalStatus = useCallback(
    (status) => status === fileStatuses.PROCESSED || status === fileStatuses.ERROR,
    [fileStatuses],
  );

  const extendedMutate = useCallback(async (document, data, callbacks) => {
    const { data: fileData } = await uploadRpcDocument(dataProvider, document);
    if (withPolling) {
      return customRpc.mutateAsync({
        ...data,
        ...fileData,
      }, {
        ...callbacks,
        onSuccess: async (rpcResult) => {
          setIsPollingLoading(true);
          const { fileId } = rpcResult.data;
          let currentStatus;
          let currentSummary;
          while (!isFinalStatus(currentStatus)) {
            const {
              data: {
                status,
                summary,
              },
            // eslint-disable-next-line no-await-in-loop
            } = await dataProvider.customRpc({
              httpMethod: 'GET',
              path: `file/${fileId}/status`,
            });
            currentStatus = status;
            currentSummary = summary;

            // eslint-disable-next-line no-await-in-loop
            if (!isFinalStatus(currentStatus)) await timeout(2000);
          }
          setFileSummary(currentSummary);
          setIsPollingLoading(false);
        },
      });
    }

    return customRpc.mutateAsync({
      ...data,
      ...fileData,
    }, callbacks);
  }, [customRpc, dataProvider, isFinalStatus, withPolling]);

  return {
    ...customRpc,
    mutate: extendedMutate,
    mutateAsync: extendedMutate,
    ...(withPolling ? {
      status: isPollingLoading ? 'loading' : customRpc.status,
      isLoading: customRpc.isLoading || isPollingLoading,
      isIdle: customRpc.isIdle && !isPollingLoading,
      isSuccess: customRpc.isSuccess && !isPollingLoading,
      isError: customRpc.isError && !isPollingLoading,
      data: fileSummary,
      reset: () => {
        setFileSummary(undefined);
        customRpc.reset();
      },
    } : {
      data: customRpc.data?.data,
    }),
  };
};

export default useUploadFileCustomRpc;
