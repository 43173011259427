import { styled } from '@mui/material';
import { FilterForm as RaFilterForm } from 'react-admin';

const FILTER_INPUT_GAP = '1.25rem';

const StyledFilterForm = styled(RaFilterForm)`
  display: flex;
  gap: ${FILTER_INPUT_GAP};
  padding: 0;

  & .RaFilterFormInput-spacer, .RaFilterForm-clearFix {
    display: none;
  }
`;

const FilterForm = StyledFilterForm;

const StyledMainFilterForm = styled(StyledFilterForm)`
  & .RaFilterForm-filterFormInput {
    min-width: 16.5rem;
    & > * {
      width: 100%;
    }
  }
`;
export const MainFilterForm = StyledMainFilterForm;

const StyledSubFilterForm = styled(StyledFilterForm)`
  & .RaFilterForm-filterFormInput {
    width: calc(25% - ${FILTER_INPUT_GAP} + ${FILTER_INPUT_GAP} / 4);
    min-width: 15rem;
    & > *:not(.RaFilterFormInput-hideButton) {
      width: 100%;
    };
    position: relative;
  }
  & .RaFilterFormInput-hideButton {
    position: absolute;
    top: -0.3rem;
    left: -0.4rem;
    & svg {
      height: 1.2rem;
      width: 1.2rem;
    };
    z-index: 1;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
export const SubFilterForm = StyledSubFilterForm;

export default FilterForm;
