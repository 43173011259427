import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const TitleH2 = ({ title, color, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...rest} fontSize="20px" fontWeight="600" color={color}>{title}</Typography>
);

TitleH2.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

TitleH2.defaultProps = {
  color: 'switch',
};

export default TitleH2;
