import React from 'react';
import {
  TabbedShowLayout,
  Tab,
  NumberField,
  FunctionField,
  ArrayField,
  DateField,
  TextField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import FieldsGroup from '../utils/OldFieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import { getEntityNameFromToken } from '../../utils/getValueFromToken';
import OrderDraftTitle from './OrderDraftTitle';
import { useConstantContext } from '../ConstantsContext';

import Show from '../designSystem/react-admin/Show';
import Datagrid from '../designSystem/react-admin/Datagrid';

const OrderDraftShow = () => {
  const { choices } = useConstantContext();
  const { t } = useTranslation();

  return (
    <Show appLocation="home.order-draft.show" title={<OrderDraftTitle />}>
      <TabbedShowLayout>
        <Tab label={t('summary')}>
          <FieldsGroup column={2}>
            <FieldsGroup name={t('Order Draft Details')} column={1} withBorder>
              <FieldsGroup column={2}>
                <FieldsGroup>
                  <SimpleShowLabeled>
                    <DateField source="activationDate" label={t('Activation Date')} emptyText="-" />
                  </SimpleShowLabeled>
                  <SimpleShowLabeled>
                    <TextField source="orderReferenceNumber" label={t('Order Reference Number')} emptyText="-" />
                  </SimpleShowLabeled>
                  <SimpleShowLabeled>
                    <TextField source="paymentReference" label={t('Payment Reference')} emptyText="-" />
                  </SimpleShowLabeled>
                  <SimpleShowLabeled>
                    <TextField source="currency" label={t('Currency')} />
                  </SimpleShowLabeled>
                </FieldsGroup>
                <FieldsGroup>
                  <SimpleShowLabeled>
                    <FunctionField label={t('Merchant')} render={getEntityNameFromToken} />
                  </SimpleShowLabeled>
                  <SimpleShowLabeled>
                    <TextField
                      label={t('Customer')}
                      source="debtorId"
                      emptyText="-"
                    />
                  </SimpleShowLabeled>
                  <SimpleShowLabeled>
                    <DateField source="issueDate" label={t('Issue Date')} />
                  </SimpleShowLabeled>
                  <SimpleShowLabeled>
                    <NumberField source="termsOfPayment" label={t('Terms of Payment')} emptyText="-" />
                  </SimpleShowLabeled>
                </FieldsGroup>
              </FieldsGroup>
            </FieldsGroup>
            <FieldsGroup name={t('Items')} column={1} withBorder>
              <ArrayField source="items" style={{ width: '100%' }}>
                <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount']}>
                  <TextField source="feeType" choices={choices.feeTypes} label={t('Type')} emptyText="-" />
                  <TextField source="description" label={t('Description')} emptyText="-" />
                  <NumberField source="amount" label={t('Order Amount')} emptyText="-" />
                </Datagrid>
              </ArrayField>
            </FieldsGroup>
            <FieldsGroup name={t('Customer Details')} column={2} withBorder>
              <SimpleShowLabeled>
                <TextField source="submitted.marketplaceReferenceCustomerId" label={t('Customer Alias')} emptyText="-" />
              </SimpleShowLabeled>
              <SimpleShowLabeled>
                <TextField source="submitted.customerName" label={t('Customer Name')} emptyText="-" />
              </SimpleShowLabeled>
            </FieldsGroup>
          </FieldsGroup>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrderDraftShow;
