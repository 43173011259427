/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';
import MuiButton from '@mui/material/Button';

const Button = ({
  disabledTooltip,
  disabled,
  ...rest
}) => {
  if (disabled && disabledTooltip) {
    return (
      <Tooltip title={disabledTooltip}>
        <span>
          <MuiButton {...rest} disabled />
        </span>
      </Tooltip>
    );
  }

  return <MuiButton {...rest} disabled={disabled} />;
};

Button.propTypes = {
  disabledTooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabledTooltip: undefined,
  disabled: false,
};

export default Button;
