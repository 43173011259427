import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';

const usePaymentsReportFilters = () => {
  const { t } = useTranslation();
  return useState([
    <DateInput
      source="date"
      label={t('Snapshot date')}
    />,
  ]);
};

export default usePaymentsReportFilters;
