import React, { createElement } from 'react';

import PropTypes from 'prop-types';

import {
  useGetResourceLabel,
  useCreatePath,
  useResourceDefinitions,
  useSidebarState,
} from 'react-admin';

import DefaultIcon from '@mui/icons-material/ViewList';
import Box from '@mui/material/Box';

import { useNotifications } from '../NotificationContext';
import { useUnidentified } from '../UnidentifiedContext';

import resources from '../../constants/resources';

import MenuItemLink from '../designSystem/react-admin/menu/MenuItemLink';
import Badge from '../designSystem/Badge';

import { ReactComponent as RectangleIcon } from '../../../assets/rectangle_blue.svg';

const CustomResourceMenuItem = ({
  name,
  dense,
  hideResourceName,
  iconStyle,
  sx,
}) => {
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const notifications = useNotifications();
  const unidentifiedAccounts = useUnidentified();
  const resourcesDefinitions = useResourceDefinitions();
  const [open] = useSidebarState();

  if (!resourcesDefinitions[name] || !resourcesDefinitions[name].hasList) return null;
  const resourceIcon = resourcesDefinitions[name].icon;
  const notificationCount = notifications[name];
  const unidentifiedCount = unidentifiedAccounts ?? 0;

  const badgeContent = name === resources.UNIDENTIFIED_ACCOUNT
    ? unidentifiedCount
    : notificationCount; // use a map when conflicts with notification solved

  const icon = resourceIcon ? createElement(resourceIcon, iconStyle) : <DefaultIcon />;

  return (
    <MenuItemLink
      key={name}
      to={createPath({
        resource: name,
        type: 'list',
      })}
      primaryText={open && !hideResourceName ? (
        <Badge badgeContent={badgeContent} color="error" sx={{ '& .MuiBadge-badge:not(.MuiBadge-invisible)': { transform: 'scale(1) translate(80%, -50%)' } }}>
          {getResourceLabel(name, 2)}
        </Badge>

      ) : ''}
      sx={sx}
      leftIcon={(
        <Box display="flex" alignItems="center" gap="1rem">
          {!hideResourceName && <RectangleIcon />}
          {hideResourceName
            ? (
              <Badge badgeContent={badgeContent} color="error">
                {icon}
              </Badge>
            )
            : icon}
        </Box>
      )}
      dense={dense}
      notification={notificationCount}
    />
  );
};

CustomResourceMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  dense: PropTypes.bool,
  hideResourceName: PropTypes.bool,
  iconStyle: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

CustomResourceMenuItem.defaultProps = {
  dense: null,
  hideResourceName: false,
  iconStyle: {},
  sx: undefined,
};

export default CustomResourceMenuItem;
