import React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  required,
  BooleanInput,
  ReferenceField,
  FunctionField,
  useRecordContext,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import SendResetPasswordLinkButton from './SendResetPasswordLinkButton';
import SaveAndActivateToolbar from './SaveAndActivateToolbar';

import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import { emailValidation } from '../../utils/validationErrors';
import renderReference from '../../utils/renderReference';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import { useConstantContext } from '../ConstantsContext';
import Edit from '../designSystem/react-admin/Edit';
import UserTitle from './UserTitle';
import TitleH1 from '../designSystem/TitleH1';

const validateRequired = [required()];

const UserEditForm = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const { userActions } = constants;

  const { isOwner } = useUserPermissionsAndRoles();
  const canCreateUsers = useHasUserPermissions(
    constants,
    userActions.CREATE_USERS,
  );

  if (!record || !canCreateUsers) return null;

  const { active, id, entityId } = record;

  return (
    <SimpleForm
      sx={{ position: 'relative' }}
      sanitizeEmptyValues
      toolbar={<SaveAndActivateToolbar active={active} userId={id} />}
    >
      {active && <SendResetPasswordLinkButton />}
      <SimpleShowLabeled label="Id">
        <TextField source="id" />
      </SimpleShowLabeled>
      {isOwner && (
        <SimpleShowLabeled label={t('Company')}>
          <ReferenceField
            source="entityId"
            reference="entity-v2/reference"
            link={false}
          >
            <FunctionField render={renderReference} />
          </ReferenceField>
        </SimpleShowLabeled>
      )}
      <TextInput disabled={!active} source="email" type="email" validate={[emailValidation, ...validateRequired]} />
      <TextInput disabled={!active} source="firstName" validate={validateRequired} label={t('First Name')} />
      <TextInput disabled={!active} source="lastName" validate={validateRequired} label={t('Last Name')} />
      <ReferenceArrayInput
        source="roleIds"
        reference="role"
        filter={{ entityId }}
      >
        <AutocompleteArrayInput
          variant="filled"
          optionText="name"
          label={t('Role')}
          disabled={!active}
        />
      </ReferenceArrayInput>
      <BooleanInput
        source="settings.notificationEmailAllowed"
        label={t('Allow Notification Emails')}
        helperText={t('When active, the user will receive emails related to the notifications')}
        disabled={!active}
      />
    </SimpleForm>
  );
};

const UserEdit = () => (
  <Edit
    redirect={false}
    title={
      <TitleH1 title={<UserTitle />} />
    }
    mutationMode="optimistic"
    appLocation="home.manage-users.edit/user"
  >
    <UserEditForm />
  </Edit>
);

export default UserEdit;
