import React from 'react';

import {
  FormTab,
  ReferenceField,
  ReferenceOneField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import EntityExposureGroup from '../../../entityv2/EntityExposureGroup';
import OnboardingStage from '../../../entityv2/OnboardingStage';
import EntityCommentsGroup from '../../../entityv2/EntityCommentsGroup';
import CreditDataGroup from '../../../entityv2/CreditDataGroup';
import OverviewGroup from '../../../entityv2/OverviewGroup';

import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';

import { CreditDataContextProvider } from '../../../CreditDataContext';

const MerchantTab = (props) => {
  const { t } = useTranslation();
  const [featureFlags] = useFeatureFlags();
  const { isFinancer } = useUserPermissionsAndRoles();

  const record = useRecordContext() || {};
  const terms = record.terms || {};

  if (!featureFlags.enableAnalyticsAndRedesign || !isFinancer) return null;

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Merchant')} {...props}>
      <ReferenceField
        label={false}
        source="merchantId"
        reference="entity-v2/reference"
        link={false}
      >
        <ReferenceOneField
          reference="entity-v2/merchant"
          source="entityDetailsId"
          target="id"
          link={false}
          filter={{ custom_kpiReferenceCurrency: terms.financingCurrency }}
        >
          <OnboardingStage />
          <OverviewGroup route="merchant" />
          <EntityCommentsGroup />
          <EntityExposureGroup route="merchant" />
          <CreditDataContextProvider>
            <CreditDataGroup entityId="merchantId" />
          </CreditDataContextProvider>
        </ReferenceOneField>
      </ReferenceField>
    </FormTab>
  );
};

export default MerchantTab;
