import React from 'react';
import PropTypes from 'prop-types';
import { useConstantContext } from '../ConstantsContext';
import CustomerDecisionPayload from './CustomerDecisionPayload';
import FinancingDecisionPayload from './FinancingDecisionPayload';
import LoanDecisionPayload from './LoanDecisionPayload';

const PeerReviewPayload = ({ action }) => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  switch (action) {
    case userActions.TAKE_FINANCING_DECISION:
      return <FinancingDecisionPayload />;
    case userActions.TAKE_DECISION_ON_CUSTOMER_APPLICATIONS:
      return <CustomerDecisionPayload />;
    case userActions.TAKE_LOAN_DECISION:
      return <LoanDecisionPayload />;
    default:
      return null;
  }
};

PeerReviewPayload.propTypes = {
  action: PropTypes.string,
};

PeerReviewPayload.defaultProps = {
  action: '',
};

export default PeerReviewPayload;
