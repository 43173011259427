import React, { useCallback, useState } from 'react';
import {
  ArrayInput,
  required,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import renderReference from '../../../utils/renderReference';
import { isTodayOrFutureDate, positiveNumberValidation, strictlyPositiveNumberValidation } from '../../../utils/validationErrors';

import { useConstantContext } from '../../ConstantsContext';

import InputsGroup from '../../designSystem/InputsGroup';
import SelectInput from '../../designSystem/react-admin/inputs/SelectInput';
import AutocompleteInput from '../../designSystem/react-admin/inputs/AutocompleteInput';
import TextInput from '../../designSystem/react-admin/inputs/TextInput';
import NumberInput from '../../designSystem/react-admin/inputs/NumberInput';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import ReferenceInput from '../../designSystem/react-admin/inputs/ReferenceInput';
import SimpleForm from '../../designSystem/react-admin/SimpleForm';
import PercentageInput from '../../designSystem/react-admin/inputs/PercentageInput';
import SimpleFormIterator from '../../designSystem/react-admin/SimpleFormIterator';
import Create from '../../designSystem/react-admin/Create';
import BooleanInput from '../../designSystem/react-admin/inputs/BooleanInput';
import TitleH1 from '../../designSystem/TitleH1';

import validatePayoutPlan from '../../utils/validatePayoutPlan';
import FieldsGroup from '../../utils/FieldsGroup';

const validateRequired = [required()];

export const getLoanApplicationCreateTitle = (t) => t('Create loan application');

const LoanApplicationFormFields = () => {
  const { constants, choices } = useConstantContext();
  const { entityRoles } = constants;
  const {
    currencies,
    repaymentTypes,
  } = choices;
  const {
    repaymentTypes: repaymentTypesConstants,
  } = constants;
  const [isFixedAmortisation, setIsFixedAmortisation] = useState(false);
  const [shouldShowPayoutPlan, setShouldShowPayoutPlan] = useState(false);
  const [showAmortisationSetting, setShowAmortisationSetting] = useState(false);
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const updateVisibleFields = useCallback((e) => {
    const input = e.target.value;
    const isFixedAmortisationInput = input === repaymentTypesConstants.FIXED_AMORTISATION;
    const isAnnuityInput = input === repaymentTypesConstants.ANNUITY;
    const hasPayoutPlanInput = input === repaymentTypesConstants.INTEREST_ONLY;
    setValue('requestedTerms.payoutPlan', undefined);
    setValue('requestedTerms.fixedAmortisation', undefined);
    setValue('requestedTerms.amortisementFree', undefined);
    setIsFixedAmortisation(isFixedAmortisationInput);
    setShouldShowPayoutPlan(hasPayoutPlanInput);
    setShowAmortisationSetting(isAnnuityInput);
  }, [setValue, setShouldShowPayoutPlan, setIsFixedAmortisation, repaymentTypesConstants]);

  return (
    <FieldsGroup title={t('Loan details')}>
      <InputsGroup layout="column">
        <SelectInput
          label={t('Repayment type')}
          source="requestedTerms.repaymentType"
          choices={repaymentTypes}
          validate={validateRequired}
          onChange={updateVisibleFields}
        />
        <ReferenceInput
          source="customerId"
          reference="entity-v2/reference"
          filter={{ entityRoles: entityRoles.CUSTOMER }}
        >
          <AutocompleteInput
            label={t('Customer')}
            validate={validateRequired}
            optionText={renderReference}
            suggestionLimit={20}
          />
        </ReferenceInput>
        <TextInput
          label={t('Payment reference')}
          source="paymentReference"
          validate={validateRequired}
        />
        <NumberInput
          label={t('Loan amount')}
          source="requestedTerms.financeAmount"
          validate={[strictlyPositiveNumberValidation, ...validateRequired]}
        />
        <SelectInput
          label={t('Currency')}
          source="requestedTerms.financingCurrency"
          choices={currencies}
          validate={validateRequired}
        />
        <PercentageInput
          label={`${t('Nominal rate')} (%)`}
          source="requestedTerms.nominalRate"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <NumberInput
          label={t('Startup fee')}
          source="requestedTerms.startupFee"
          validate={[positiveNumberValidation]}
        />
        <NumberInput
          label={t('Repayment period (months)')}
          source="requestedTerms.repaymentTime"
          validate={[strictlyPositiveNumberValidation, ...validateRequired]}
        />
        <DateInput
          label={t('Payout date')}
          source="requestedTerms.payoutDate"
          validate={[isTodayOrFutureDate, ...validateRequired]}
        />
        {isFixedAmortisation && (
          <NumberInput
            label={t('Fixed amortisation')}
            source="requestedTerms.fixedAmortisation"
            validate={[positiveNumberValidation, ...validateRequired]}
          />
        )}
      </InputsGroup>
      {shouldShowPayoutPlan && (
        <InputsGroup title={t('Payout plan')}>
          <ArrayInput
            label=""
            source="requestedTerms.payoutPlan"
            validate={validatePayoutPlan(
              'financeAmount',
              'payoutDate',
              'requestedTerms',
            )}
          >
            <SimpleFormIterator itemLabel={t('Payout plan')} addButtonLabel={t('Add new payout')}>
              <InputsGroup layout="column">
                <NumberInput
                  label={t('Amount')}
                  source="amount"
                  validate={[...validateRequired, strictlyPositiveNumberValidation]}
                />
                <DateInput
                  label={t('Payout date')}
                  source="date"
                  validate={validateRequired}
                />
              </InputsGroup>
            </SimpleFormIterator>
          </ArrayInput>
        </InputsGroup>
      )}
      {showAmortisationSetting && (
        <InputsGroup title={t('Amortisation')}>
          <BooleanInput
            source="requestedTerms.amortisementFree"
            label={t('Skip amortisation on the next invoice')}
            helperText={t('This is only applied once and will reset after')}
          />
        </InputsGroup>
      )}
    </FieldsGroup>
  );
};

const LoanApplicationCreate = () => {
  const { t } = useTranslation();

  return (
    <Create title={<TitleH1 title={getLoanApplicationCreateTitle(t)} />} appLocation="home.loan-application.create">
      <SimpleForm
        sanitizeEmptyValues
      >
        <LoanApplicationFormFields />
      </SimpleForm>
    </Create>
  );
};

export default LoanApplicationCreate;
