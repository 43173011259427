import React from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from '../designSystem/react-admin/inputs/TextInput';
import DateTimeInput from '../designSystem/react-admin/inputs/DateTimeInput';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

const useLogFilters = (resourceId) => {
  const { t } = useTranslation();

  return [
    <DateTimeInput
      alwaysOn
      label={t('From')}
      source="dateRange_minute_from_createdAt"
    />,
    <DateTimeInput
      alwaysOn
      label={t('To')}
      source="dateRange_minute_to_createdAt"
    />,
    !resourceId && (
      <TextInput
        source="resourceType"
        alwaysOn
        label={t('Origin')}
      />
    ),
    <TextInput
      source="action"
      alwaysOn
      label={t('Action')}
    />,
    <ReferenceInput
      source="userId"
      reference="user/public"
      alwaysOn
    >
      <AutocompleteInput
        label={t('User email')}
        optionText="email"
        suggestionLimit={10}
      />
    </ReferenceInput>,
  ].filter((input) => !!input);
};

export default useLogFilters;
