const getFormattingOptions = (currency) => (currency !== '-' ? {
  style: 'currency',
  currency,
  currencyDisplay: 'code',
} : undefined);

const currencyFormat = (amount, currency) => amount.toLocaleString(
  undefined,
  currency ? getFormattingOptions(currency) : undefined,
);

export default currencyFormat;
