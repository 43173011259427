import React from 'react';
import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import EntityOrdersTab from '../sharedTabs/EntityOrdersTab';
import HistoryTab from '../sharedTabs/EntityHistoryTab';

import useFeatureFlags from '../../hooks/useFeatureFlags';

import BillingsTab from './tabs/BillingsTab';
import SettlementsTab from './tabs/SettlementsTab';
import DetailsTab from './tabs/DetailsTab';
import SummaryTab from './tabs/SummaryTab';
import SummaryTabNew from './tabs/SummaryTabNew';
import FinancingKycTab from './tabs/FinancingKycTab';

import TabbedForm from '../../designSystem/react-admin/TabbedForm';
import Edit from '../../designSystem/react-admin/Edit';
import TitleH1 from '../../designSystem/TitleH1';

import DecisionRuleTab from '../../decisionRule/DecisionRuleTab';

import resources from '../../../constants/resources';

export const getMerchantEditTitle = (record) => (record && record.mainAddress ? record.mainAddress.name : '');

const Title = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Merchant')}: ${getMerchantEditTitle(record)}`;
  return <TitleH1 title={title} />;
};

const MerchantEdit = () => {
  const [featureFlags] = useFeatureFlags();

  return (
    <Edit
      title={<Title />}
      redirect={false}
      mutationMode="optimistic"
      appLocation="home.entity-v2/merchant.edit"
      sx={{
        '& .MuiPaper-root': {
          overflow: 'visible',
        },
      }}
    >
      <TabbedForm
        sanitizeEmptyValues
        toolbar={null}
      >
        { featureFlags.enableAnalyticsAndRedesign
        && <FinancingKycTab />}
        { featureFlags.enableAnalyticsAndRedesign
          ? <SummaryTabNew />
          : <SummaryTab />}
        <DetailsTab />
        <EntityOrdersTab queryTarget="sellerId" isMerchantView />
        <BillingsTab />
        <SettlementsTab />
        <DecisionRuleTab location={resources.MERCHANT} />
        <HistoryTab />
      </TabbedForm>
    </Edit>
  );
};

export default MerchantEdit;
