import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ListContextProvider, usePaginationState, useRecordContext } from 'react-admin';
import SafePagination from '../../utils/SafePagination';

const PaginatedArrayField = (props) => {
  const {
    children,
    resource,
    source,
    initialPage,
    initialPerPage,
  } = props;
  const record = useRecordContext(props);
  const data = get(record, source, []) || [];

  const {
    page,
    setPage,
    perPage,
    setPerPage,
  } = usePaginationState({
    page: initialPage,
    perPage: initialPerPage,
  });

  return (
    <ListContextProvider
      value={{
        data: data.slice((page - 1) * perPage, page * perPage),
        sort: { field: null, order: null },
        page,
        perPage,
        resource,
        setPage,
        setPerPage,
        total: data.length,
      }}
    >
      {children}
      <SafePagination />
    </ListContextProvider>
  );
};

PaginatedArrayField.propTypes = {
  initialPage: PropTypes.number,
  initialPerPage: PropTypes.number,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

PaginatedArrayField.defaultProps = {
  initialPerPage: 100,
  initialPage: 1,
  resource: undefined,
};

export default PaginatedArrayField;
