import React from 'react';
import PropTypes from 'prop-types';
import CompanyDetailsBusinessContent from './CompanyDetailsBusinessContent';
import CompanyDetailsConsumerContent from './CompanyDetailsConsumerContent';
import CompanyDetailsBusinessContentWithAlias from './CompanyDetailsBusinessContentWithAlias';
import CompanyDetailsConsumerContentWithAlias from './CompanyDetailsConsumerContentWithAlias';

const CompanyDetailsForCustomerDetails = ({ isBusiness, enableAliasInput }) => {
  if (isBusiness && enableAliasInput) return <CompanyDetailsBusinessContentWithAlias />;
  if (isBusiness && !enableAliasInput) return <CompanyDetailsBusinessContent />;
  if (!isBusiness && enableAliasInput) return <CompanyDetailsConsumerContentWithAlias />;
  return <CompanyDetailsConsumerContent />;
};

CompanyDetailsForCustomerDetails.propTypes = {
  isBusiness: PropTypes.bool,
  enableAliasInput: PropTypes.bool,
};

CompanyDetailsForCustomerDetails.defaultProps = {
  isBusiness: true,
  enableAliasInput: false,
};

export default CompanyDetailsForCustomerDetails;
