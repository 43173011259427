import React from 'react';
import {
  TextField,
  FunctionField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useConstantContext } from '../ConstantsContext';

import List from '../designSystem/react-admin/list/List';
import DateField from '../designSystem/react-admin/DateField';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import TitleH1 from '../designSystem/TitleH1';

export const getExchangeRateListTitle = (t) => t('Exchange rates');

const ExchangeRateList = () => {
  const { constants } = useConstantContext();
  const { exchangeRateCoefficient } = constants;
  const { t } = useTranslation();

  return (
    <List
      title={<TitleH1 title={getExchangeRateListTitle(t)} />}
      appLocation="home.exchangeRate"
      sort={{ field: 'date', order: 'DESC' }}
    >
      <DatagridConfigurable bulkActionButtons={false} rightAlignedColumns={['exchangeRate']}>
        <DateField source="date" label={t('Last update date')} showTime />
        <TextField source="fromCurrency" label={t('From currency')} />
        <TextField source="toCurrency" label={t('To currency')} />
        <FunctionField source="exchangeRate" label={t('Exchange rate')} render={({ exchangeRate }) => (exchangeRate / exchangeRateCoefficient).toFixed(6)} sortable={false} />
      </DatagridConfigurable>
    </List>
  );
};

export default ExchangeRateList;
