import React from 'react';

import { Show as RaShow } from 'react-admin';
import PropTypes from 'prop-types';

import { useDefineLocation } from '../../AppLocationContext';

const DefineLocation = ({ appLocation }) => {
  useDefineLocation(appLocation);
  return null;
};
DefineLocation.propTypes = {
  appLocation: PropTypes.string,
};
DefineLocation.defaultProps = {
  appLocation: undefined,
};

const Show = ({ appLocation, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RaShow actions={false} {...rest}>
    <>
      <DefineLocation appLocation={appLocation} />
      {children}
    </>
  </RaShow>
);

Show.propTypes = {
  ...DefineLocation.propTypes,
  children: PropTypes.node.isRequired,
};
Show.defaultProps = DefineLocation.defaultProps;

export default Show;
