import React from 'react';

import AutocompleteInput from './AutocompleteInput';

const AutocompleteArrayInput = (props) => (
  <AutocompleteInput
    multiple
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default AutocompleteArrayInput;
