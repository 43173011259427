import React from 'react';
import { styled } from '@mui/material';
import { DashboardMenuItem as RaDashboardMenuItem, useSidebarState } from 'react-admin';
import { menuItemLinkStyle } from './MenuItemLink';

const StyledDashboardMenuItem = styled(RaDashboardMenuItem)(({ theme }) => ({ ...menuItemLinkStyle({ theme }), paddingTop: '0.25rem' }));

const DashboardMenuItem = (props) => {
  const [open] = useSidebarState();

  return (
    <StyledDashboardMenuItem
      primaryText={open
        ? undefined // Use react admin default text
        : ''} // Force it to be an empty string
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default DashboardMenuItem;
