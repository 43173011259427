import { useMemo } from 'react';

import { useConstantContext } from '../../ConstantsContext';

import useArrearsFilters from './useArrearsFilters';
import useInputCLFilters from './useInputCLFilters';
import useInputCLLiquidityFilters from './useInputCLLiquidityFilters';
import useInvoicingFilters from './useInvoicingFilters';
import useCustomerFilters from './useCustomerFilters';
import useDSOFilters from './useDSOFilters';
import useFACSFilters from './useFACSFilters';
import useACCSFilters from './useACCSFilters';
import useCLTSFilters from './useCLTSFilters';
import useEOPBFilters from './useEOPBFilters';
import useAccountingFilters from './useAccountingFilters';
import useBillingAndSettlementReportFilters from './useBillingAndSettlemetReportFilters';
import usePaymentsReportFilters from './usePaymentsReportFilters';
import useLoansReportFilters from './useLoanReportFilters';

const useMapReportsToFilters = () => {
  const { constants: { reports } } = useConstantContext();

  const [arrearsFilters] = useArrearsFilters();
  const [inputCLFilters] = useInputCLFilters();
  const [inputCLLiquidityFilters] = useInputCLLiquidityFilters();
  const [invoicingFilters] = useInvoicingFilters();
  const [customerFilters] = useCustomerFilters();
  const [dsoFilters] = useDSOFilters();
  const [facsFilters] = useFACSFilters();
  const [accsFilters] = useACCSFilters();
  const [cltsFilters] = useCLTSFilters();
  const [eopbFilters] = useEOPBFilters();
  const [accountingFilters] = useAccountingFilters();
  const [billingAndSettlementReportFilters] = useBillingAndSettlementReportFilters();
  const [paymentsReportFilters] = usePaymentsReportFilters();
  const [loansReportFilters] = useLoansReportFilters();

  const mapReportsToFilters = useMemo(() => ({
    [reports.ARREARS]: arrearsFilters,
    [reports.INPUT_CL]: inputCLFilters,
    [reports.INPUT_CL_LIQUIDITY]: inputCLLiquidityFilters,
    [reports.INVOICING_REPORT]: invoicingFilters,
    [reports.CUSTOMER_REPORT]: customerFilters,
    [reports.CUSTOMER_REPORT_V2]: customerFilters,
    [reports.DSO_REPORT]: dsoFilters,
    [reports.DSO_REPORT_EXT]: dsoFilters,
    [reports.FACS]: facsFilters,
    [reports.FACS_EXT]: facsFilters,
    [reports.ACCS]: accsFilters,
    [reports.ACCS_EXT]: accsFilters,
    [reports.CLTS]: cltsFilters,
    [reports.CLTS_EXT]: cltsFilters,
    [reports.EOPB]: eopbFilters,
    [reports.EOPB_EXT]: eopbFilters,
    [reports.ACCOUNTING]: accountingFilters,
    [reports.ACCOUNTING_EXT]: accountingFilters,
    [reports.ACCOUNTING_SIE]: accountingFilters,
    [reports.ACCOUNTING_SIE_EXT]: accountingFilters,
    [reports.BILLING_AND_SETTLEMENT_REPORT]: billingAndSettlementReportFilters,
    [reports.INCOMING_AND_OUTGOING_PAYMENTS]: paymentsReportFilters,
    [reports.INVOICING_REPORT_V2]: invoicingFilters,
    [reports.INVOICING_REPORT_EXT]: invoicingFilters,
    [reports.LOANS]: loansReportFilters,
  }), [reports,
    arrearsFilters,
    inputCLFilters,
    inputCLLiquidityFilters,
    invoicingFilters,
    customerFilters,
    dsoFilters,
    facsFilters,
    accsFilters,
    cltsFilters,
    eopbFilters,
    accountingFilters,
    billingAndSettlementReportFilters,
    paymentsReportFilters,
    loansReportFilters,
  ]);

  return mapReportsToFilters;
};

export default useMapReportsToFilters;
