import React from 'react';

import Box from '@mui/material/Box';

import CurrentExposureKpis from './CurrentExposureKpis';
import MerchantCharts from './MerchantCharts';
import EntityExposureGroup from '../../entityv2/EntityExposureGroup';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const FactoringMerchantContentExposureKpis = () => {
  const [featureFlag] = useFeatureFlags();

  return (
    <Box display="flex" flexDirection="column" marginBottom="2rem">
      {featureFlag.enableAnalyticsAndRedesign
        ? <EntityExposureGroup route="merchantDashboard" />
        : <CurrentExposureKpis />}
      <MerchantCharts />
    </Box>
  );
};

export default FactoringMerchantContentExposureKpis;
