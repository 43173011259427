import React from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import TitleH1 from '../designSystem/TitleH1';

export const getUserTitle = (record) => (record ? `${record.firstName} ${record.lastName}` : '');

const UserTitle = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  return <TitleH1 title={`${t('User')}: ${getUserTitle(record)}`} />;
};

export default UserTitle;
