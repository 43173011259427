import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormTab } from 'react-admin';
import LoanOverviewGroup from '../LoanOverviewGroup';
import LoanFinancingGroup from '../LoanFinancingGroup';
import LoanDetailsGroup from '../LoanDetailsGroup';

const SummaryTab = (props) => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <LoanOverviewGroup />
      <LoanFinancingGroup />
      <LoanDetailsGroup />
    </FormTab>
  );
};

export default SummaryTab;
