import React from 'react';
import { useTranslation } from 'react-i18next';

import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import renderReference from '../../utils/renderReference';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';

const useTransactionFilters = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants, choices } = useConstantContext();
  const { t } = useTranslation();
  const { entityRoles } = constants;
  const { paymentTransactionTypes, paymentAccountTypes } = choices;

  const availablePayerEntityRoles = isFinancer
    ? { entityRoles: [entityRoles.MERCHANT, entityRoles.FINANCER] }
    : { entityRoles: [entityRoles.MERCHANT, entityRoles.CUSTOMER] };

  return [
    <AutocompleteInput
      label={t('Transaction Type')}
      source="custom_transactionType"
      choices={paymentTransactionTypes}
      alwaysOn
    />,
    <AutocompleteInput
      label={t('Account Type')}
      source="custom_accountType"
      choices={paymentAccountTypes}
      alwaysOn
    />,
    <ReferenceInput
      source="custom_payer"
      reference="entity-v2/reference"
      filter={availablePayerEntityRoles}
      alwaysOn
    >
      <AutocompleteInput
        label={t('Payer')}
        optionText={renderReference}
        suggestionLimit={20}
      />
    </ReferenceInput>,
    ...(isFinancer ? [(
      <ReferenceInput
        source="custom_payee"
        reference="entity-v2/reference"
        filter={{ entityRoles: [entityRoles.MERCHANT, entityRoles.FINANCER] }}
        alwaysOn
      >
        <AutocompleteInput
          label={t('Payee')}
          optionText={renderReference}
          suggestionLimit={20}
        />
      </ReferenceInput>
    )] : []
    ),
  ];
};
export default useTransactionFilters;
