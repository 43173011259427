import React from 'react';

import { Create as RaCreate } from 'react-admin';
import PropTypes from 'prop-types';

import { useDefineLocation } from '../../AppLocationContext';

const DefineLocation = ({ appLocation }) => {
  useDefineLocation(appLocation);
  return null;
};
DefineLocation.propTypes = {
  appLocation: PropTypes.string,
};
DefineLocation.defaultProps = {
  appLocation: undefined,
};

const Create = ({ appLocation, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RaCreate {...rest}>
    <>
      <DefineLocation appLocation={appLocation} />
      {children}
    </>
  </RaCreate>
);

Create.propTypes = {
  ...DefineLocation.propTypes,
  children: PropTypes.node.isRequired,
};
Create.defaultProps = DefineLocation.defaultProps;

export default Create;
