import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  required,
} from 'react-admin';

import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';

import { useConstantContext } from '../ConstantsContext';

import { getUserIdFromToken } from '../../utils/getValueFromToken';

import ModalButton from '../designSystem/ModalButton';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';

const validateRequired = [required()];

const ChangeLanguageButton = () => {
  const userId = getUserIdFromToken();
  const { t } = useTranslation();
  const { constants, choices } = useConstantContext();
  const { languageCodeMap } = constants;
  const { languages } = choices;
  const [featureFlags] = useFeatureFlags();

  const {
    mutate: changeLanguage,
    isLoading,
  } = useCustomRpc({
    path: `user/${userId}/change-language`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update language'),
  });

  const onSubmit = useCallback(({ language }) => {
    changeLanguage({ language });
    i18n.changeLanguage(languageCodeMap[language]);
    return true;
  }, [changeLanguage, languageCodeMap]);

  if (!featureFlags.enableLanguage) return null;

  return (
    <ModalButton
      modalTitle={t('Change language')}
      openButtonLabel={t('Language')}
      actionLabel={t('Save')}
      variant="userMenuItem"
      onClick={onSubmit}
      formDefaultValue={{}}
      disabled={isLoading}
      startIcon={<LanguageIcon />}
      width="25rem"
    >
      <Typography marginBottom="0.5rem" fontSize="15px" maxWidth="30rem">
        {t('Your default language is default set to match your company\'s. To modify this setting, select your language preference here')}
      </Typography>
      <AutocompleteInput
        source="language"
        label={t('Select language')}
        choices={languages}
        validate={validateRequired}
      />
    </ModalButton>
  );
};

export default ChangeLanguageButton;
