import React from 'react';

import Box from '@mui/material/Box';

import useFeatureFlags from '../../hooks/useFeatureFlags';

import CurrentExposureKpis from './CurrentExposureKpis';
import OverviewKpis from './OverviewKpis';
import PendingTasks from './PendingTasks';
import EntityExposureGroup from '../../entityv2/EntityExposureGroup';

const FactoringFinancerContentExposureKpis = () => {
  const [featureFlag] = useFeatureFlags();

  return (
    <Box display="flex" flexDirection="column" marginBottom="2rem">
      <PendingTasks />
      {featureFlag.enableAnalyticsAndRedesign
        ? <EntityExposureGroup route="financerDashboard" />
        : <CurrentExposureKpis />}
      <OverviewKpis />
    </Box>
  );
};

export default FactoringFinancerContentExposureKpis;
