import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import Typography from '@mui/material/Typography';

import { isDefined } from '../../utils/validators';

const OverdueDaysField = ({ label, source }) => {
  const { t } = useTranslation();
  return (
    <FunctionField
      label={label}
      source={source}
      render={(record) => {
        if (!isDefined(record.overdueDays)) return '-';
        if (record.isAccountPaid) {
          return <Typography fontSize="14px" fontWeight="bold" color="#17B944">{t('Paid')}</Typography>;
        } if (record.overdueDays < 0) {
          return <Typography fontSize="14px"><strong>{-record.overdueDays}</strong>&nbsp;{record.overdueDays > 1 ? t('days') : t('day')}&nbsp;{t('left')}</Typography>;
        }
        return <Typography fontSize="14px"><strong style={{ color: '#F95738' }}>{record.overdueDays}</strong>&nbsp;{record.overdueDays < 1 ? t('days') : t('day')}&nbsp;{t('overdue')}</Typography>;
      }}
    />
  );
};

OverdueDaysField.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default OverdueDaysField;
