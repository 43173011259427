import React, { useCallback } from 'react';
import {
  SimpleForm,
  NumberInput,
  SelectInput,
  required,
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  SaveButton,
  SimpleFormIterator,
} from 'react-admin';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CoreInfoGroup from '../CoreInfoGroup';

import FieldsGroup from '../../utils/OldFieldsGroup';
import { useMerchantDropdownFilter } from '../../utils/entityDropdownFilter';

import { positiveNumberValidation } from '../../../utils/validationErrors';
import renderReference from '../../../utils/renderReference';

import { useConstantContext } from '../../ConstantsContext';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import Create from '../../designSystem/react-admin/Create';
import TitleH1 from '../../designSystem/TitleH1';

const validateRequired = [required()];

export const getCreditInfoCreateTitle = (t) => t('Create credit info');

const CreditInfoCreate = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { record } = state || {};
  const { id: entityId } = record || {};

  const { choices, constants } = useConstantContext();
  const { userActions } = constants;

  const canCreateCreditInfo = useHasUserPermissions(
    constants,
    userActions.MANAGE_CUSTOMER_CREDIT_LIMIT_FOR_MERCHANTS,
  );

  const merchantDropdown = useMerchantDropdownFilter(false);
  const { currencies: currenciesChoices } = choices;

  const onSuccess = useCallback(() => {
    notify(t('Credit Settings Updated'));
    redirect(`/entity-v2/customer/${entityId}`);
    refresh();
  }, [notify, redirect, refresh, entityId, t]);

  if (!canCreateCreditInfo || !entityId) return <Navigate to="/entity-v2/customer" />;

  return (
    <Create
      mutationOptions={{ onSuccess }}
      appLocation="home.entity-v2/customer.create-credit-info"
      title={<TitleH1 title={getCreditInfoCreateTitle(t)} />}
    >
      <SimpleForm
        toolbar={<Toolbar><SaveButton /></Toolbar>}
        sanitizeEmptyValues
      // warnWhenUnsavedChanges
      >
        <CoreInfoGroup />
        <FieldsGroup
          name={t('Credit Settings')}
          withBorder
          fillAvailable
        >
          <FieldsGroup column={4}>
            <NumberInput
              label={t('Credit Limit')}
              source="creditLimit"
              validate={[positiveNumberValidation, ...validateRequired]}
            />
            <SelectInput
              margin="none"
              label={t('Credit Currency')}
              source="creditLimitCurrency"
              choices={currenciesChoices}
              validate={validateRequired}
            />
            <NumberInput
              label={t('Insurance Limit')}
              source="creditInsuranceLimit"
              validate={[positiveNumberValidation, ...validateRequired]}
            />
            <SelectInput
              margin="none"
              label={t('Insurance Currency')}
              source="creditInsuranceLimitCurrency"
              choices={currenciesChoices}
              validate={validateRequired}
            />
          </FieldsGroup>
          <FieldsGroup fillAvailable>
            <ArrayInput
              source="assignedCreditLimits"
              label={t('Reserved Credit Limit')}
              defaultValue={[]}
              validate={validateRequired}
              style={{ width: 'auto', minWidth: '12rem' }}
            >
              <SimpleFormIterator inline disableReordering>
                <ReferenceInput
                  source="merchantId"
                  reference="entity-v2/reference"
                  filter={merchantDropdown()}
                >
                  <AutocompleteInput
                    label={t('Merchant')}
                    validate={validateRequired}
                    optionText={renderReference}
                    suggestionLimit={20}
                    options={{
                      suggestionsContainerProps: {
                        style: { zIndex: 1500 },
                      },
                    }}
                  />
                </ReferenceInput>
                <NumberInput
                  label={t('Assigned Credit Limit')}
                  source="assignedCreditLimit"
                  validate={[positiveNumberValidation, ...validateRequired]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FieldsGroup>
        </FieldsGroup>
      </SimpleForm>
    </Create>
  );
};

export default CreditInfoCreate;
