import React, { useCallback } from 'react';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import config from '../../../config';
import useFeatureFlags from '../hooks/useFeatureFlags';

const FeatureFlagPage = () => {
  const { canEditFeatureFlags } = config;
  const [featureFlags, setFeatureFlags] = useFeatureFlags();

  const onChange = useCallback((e) => {
    // Create shallow copy to trigger re-render
    const updatedFeatureFlags = {
      ...featureFlags,
      [e.target.id]: e.target.checked,
    };
    setFeatureFlags(updatedFeatureFlags);
  }, [setFeatureFlags, featureFlags]);

  if (!canEditFeatureFlags) return null;

  return (
    <FormGroup>
      {Object.entries(featureFlags).map(([featureFlagName, featureFlagValue]) => (
        <FormControlLabel
          key={featureFlagName}
          control={<Switch id={featureFlagName} checked={featureFlagValue} onChange={onChange} />}
          label={featureFlagName}
        />
      ))}
    </FormGroup>
  );
};

export default FeatureFlagPage;
