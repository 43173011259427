import React, { useState, useCallback } from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import UserList from '../user/UserList';
import RoleList from '../role/RoleList';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const ManagerShow = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const tab = state && state.tab ? state.tab : 0;

  const [tabValue, setTabValue] = useState(tab);

  const { constants } = useConstantContext();

  const {
    userActions,
  } = constants;

  const canManageUserPermission = useHasUserPermissions(
    constants,
    userActions.MANAGE_USER_PERMISSIONS,
  );

  const handleChange = useCallback((_, newValue) => {
    setTabValue(newValue);
  }, [setTabValue]);

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white', marginTop: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label={t('Users')} id="0" />
          {canManageUserPermission && <Tab label={t('Roles')} id="1" />}
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <UserList />
      )}
      {canManageUserPermission && tabValue === 1 && (
        <RoleList />
      )}
    </Box>
  );
};

export default ManagerShow;
