import React from 'react';

import {
  FunctionField,
  SelectField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';
import CustomSuffixTextField from '../utils/CustomSuffixTextField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import DateField from '../designSystem/react-admin/DateField';
import { useConstantContext } from '../ConstantsContext';
import LoanPayoutPlanGrid from './LoanPayoutPlanGrid';
import UpdateLoanDetailsButton from './UpdateLoanDetailsButton';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const LoanDetailsGroup = () => {
  const { choices, constants } = useConstantContext();
  const { repaymentTypes } = constants;
  const { currency, terms: termsRecord } = useRecordContext();
  const { repaymentType } = termsRecord;
  const isFixedAmortisation = repaymentType === repaymentTypes.FIXED_AMORTISATION;
  const shouldShowAmortisementFree = repaymentType === repaymentTypes.ANNUITY;
  const { t } = useTranslation();

  const { isFinancer } = useUserPermissionsAndRoles();
  const { userActions } = constants;

  const canEditLoan = useHasUserPermissions(
    constants,
    userActions.EDIT_LOANS,
  );

  return (
    <FieldsGroup
      title={t('Loan details')}
      actions={isFinancer
        && canEditLoan
        && (<UpdateLoanDetailsButton />)}
    >
      <Grid container sx={{ marginBottom: '2.5rem' }}>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="terms.amount" currency={currency} label={t('Loan amount')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CustomSuffixTextField source="terms.repaymentTime" label={t('Repayment period')} customSuffix="months" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="terms.payoutDate" label={t('Payout date')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="terms.startupFee" currency={currency} label={t('Startup fees')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField source="terms.repaymentType" label={t('Repayment type')} emptyText="-" choices={choices.repaymentTypes} />
          </SimpleShowLabeled>
        </Grid>
        {isFixedAmortisation && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="terms.fixedAmortisation" currency={currency} label={t('Fixed amortisation')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        )}
        {shouldShowAmortisementFree && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField
                label="Next invoice amortisation"
                render={({ terms }) => `${terms.amortisementFree ? 'Amortisement free' : 'Regular'}`}
              />
            </SimpleShowLabeled>
          </Grid>
        )}
      </Grid>
      <LoanPayoutPlanGrid currency={currency} shouldShowPayoutPlan />
    </FieldsGroup>
  );
};

export default LoanDetailsGroup;
