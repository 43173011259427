import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { ChildrenProp } from '../../utils/helpers/propTypes';

const FinancingContext = React.createContext({});

export const PartnerFinancingContextProvider = ({ children, context }) => {
  const [records, setRecords] = useState([{}]);
  const contextRecord = useContext(context);
  const inList = !!(contextRecord && contextRecord.selectedIds);

  useEffect(() => {
    setRecords(inList
      ? contextRecord.data.filter((record) => contextRecord.selectedIds.includes(record.id))
      : [contextRecord]);
  }, [inList, contextRecord]);

  return (
    <FinancingContext.Provider
      value={{
        records,
        orderCurrencies: records.map((record) => record.orderCurrency),
        inList,
      }}
    >
      {children}
    </FinancingContext.Provider>
  );
};

PartnerFinancingContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
  context: PropTypes.shape({}).isRequired,
};

export const usePartnerFinancing = () => {
  const data = useContext(FinancingContext);
  return data;
};
