import React from 'react';
import { useRecordContext } from 'react-admin';

import LogsList from '../logs/LogsList';

const HistoryGroup = () => {
  const record = useRecordContext();
  return <LogsList resourceId={record.id} />;
};

export default HistoryGroup;
