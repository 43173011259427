import React from 'react';

import {
  CreateButton,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import ImportCustomerButton from './ImportCustomerButton';
import ImportMerchantButton from './ImportMerchantButton';
import MergeCustomerButton from './MergeCustomerButton';

import useHasUserPermissions from '../hooks/useHasUserPermissions';

import resources from '../../constants/resources';

import { useConstantContext } from '../ConstantsContext';

const EntityListActions = () => {
  const resource = useResourceContext();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canCreateCustomer = useHasUserPermissions(
    constants,
    userActions.CREATE_CUSTOMERS,
  )
  && resource.endsWith(resources.CUSTOMER);
  const canCreateMerchant = useHasUserPermissions(
    constants,
    userActions.CREATE_MERCHANTS,
  )
  && resource.endsWith(resources.MERCHANT);
  const canMergeCustomer = useHasUserPermissions(
    constants,
    userActions.MERGE_CUSTOMERS,
  )
  && resource.endsWith(resources.CUSTOMER);

  let uploadFile;
  let createLabel;
  if (canCreateCustomer) {
    createLabel = t('Add customer');
    uploadFile = <ImportCustomerButton />;
  }
  if (canCreateMerchant) {
    createLabel = t('Add merchant');
    uploadFile = <ImportMerchantButton />;
  }

  if (!uploadFile) return null;

  return (
    <>
      {uploadFile}
      {canMergeCustomer && <MergeCustomerButton />}
      <CreateButton label={createLabel} />
    </>
  );
};

export default EntityListActions;
