import React from 'react';

import PropTypes from 'prop-types';

import { styled } from '@mui/material';
import {
  useInput,
  SelectInput as RaSelectInput,
} from 'react-admin';

import InputWrapper from './InputWrapper';
import { mapFimentoVariantToStyle } from './TextInput';

import { ReactComponent as ArrowDown } from '../../../../../assets/arrow_down.svg';

export const getStyledInputStyle = ({ theme, error, fimentoVariant }) => ({
  ...mapFimentoVariantToStyle[fimentoVariant || 'default']({ theme, error }),

  '& .MuiSelect-icon': {
    right: '1rem',
    top: '1.1rem',
    '& path': { fill: theme.palette.secondary.main },
  },
});

const StyledSelectInput = styled(RaSelectInput, {
  shouldForwardProp: (prop) => prop !== 'fimentoVariant',
})(getStyledInputStyle);

const mapFimentoVariantToMenuListBackground = {
  default: (theme) => theme.palette.listActive,
  filter: (theme) => theme.palette.grey.input,
};

export const baseSelectProps = (fimentoVariant) => ({
  IconComponent: ArrowDown,
  MenuProps: {
    MenuListProps: {
      sx: {
        backgroundColor: mapFimentoVariantToMenuListBackground[fimentoVariant || 'default'],
      },
    },
  },
});

const SelectInput = (props) => {
  const {
    label,
    InputLabelProps,
    placeholder,
    handleHide,
    ...rest
  } = props;
  const {
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(rest);

  const inputId = `${rest.source}-fimento-select-input`;
  return (
    <InputWrapper
      inputId={inputId}
      error={invalid}
      label={label}
      isRequired={isRequired}
      InputLabelProps={InputLabelProps}
      fimentoVariant={rest.fimentoVariant}
      handleHide={handleHide(rest.source)}
    >
      <StyledSelectInput
        id={inputId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        SelectProps={baseSelectProps(rest.fimentoVariant)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        label={placeholder || label}
        variant="outlined"
        margin="none"
        helperText={(isTouched || isSubmitted) && invalid ? error?.message : ''} // Override react admin that reserves space
        error={(isTouched || isSubmitted) && invalid} // Necessary for our Styled component.
      />
    </InputWrapper>
  );
};

SelectInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.shape(),
  handleHide: PropTypes.func,
};

SelectInput.defaultProps = {
  placeholder: '',
  label: undefined,
  InputLabelProps: {},
  handleHide: () => undefined,
};

export default SelectInput;
