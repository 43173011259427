import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

import useCustomRpc from '../hooks/useCustomRpc';

const RemoveUserRoleButton = ({ roleId }) => {
  const { t } = useTranslation();
  const { id: userId } = useRecordContext();

  const {
    mutate: removeUserRole,
  } = useCustomRpc({
    path: `user/${userId}/remove-user-role`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not remove role from user'),
  });

  const onClick = useCallback(() => {
    removeUserRole({ roleId });
    return true;
  }, [removeUserRole, roleId]);

  return (
    <Button
      label={t('Remove')}
      onClick={onClick}
      size="small"
    >
      <RemoveIcon />
    </Button>
  );
};

RemoveUserRoleButton.propTypes = {
  roleId: PropTypes.string.isRequired,
};

export default RemoveUserRoleButton;
