import React, { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { required } from 'react-admin';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { useConstantContext } from '../ConstantsContext';

import { ProcessableFileUploadModalButton } from '../designSystem/ModalButton';

import useDownloadDocument from '../hooks/useDownloadDocument';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';

const DownloadPaymentFileTemplate = (props) => {
  const { trigger } = useFormContext();
  const { t } = useTranslation();

  const {
    mutate: downloadPaymentFileTemplate,
  } = useDownloadDocument({
    path: 'upload/payment-file-template',
    errorMessage: t('Could not retrieve payment file template'),
  });

  const templateType = useWatch({ name: 'templateType' });
  const fileType = useWatch({ name: 'fileType' });

  const downloadTemplate = useCallback(async (e) => {
    e.preventDefault();
    const isValid = await trigger('templateType');
    if (isValid) {
      downloadPaymentFileTemplate({ paramId: templateType.concat(`/${fileType}`) });
    }
  }, [downloadPaymentFileTemplate, templateType, fileType, trigger]);

  return (
    <Button
      onClick={downloadTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {t('Download payment file template')}
    </Button>
  );
};

const PaymentFileTypeSelect = () => {
  const { setValue } = useFormContext();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants, choices } = useConstantContext();
  const { t } = useTranslation();
  const { fileTypes } = constants;
  const { paymentFileTemplates, mirrorPaymentFileTemplates } = choices;
  const shouldMirror = useWatch({ name: 'shouldMirrorPayment', defaultValue: false });

  useEffect(() => {
    setValue('fileType', shouldMirror ? fileTypes.INBOUND_MIRRORED : fileTypes.INBOUND);
    setValue('templateType', '');
  }, [setValue, shouldMirror, fileTypes.INBOUND_MIRRORED, fileTypes.INBOUND]);

  return (
    <>
      <SelectInput
        label={t('Payment file template')}
        source="templateType"
        validate={[required(t('The payment template is required'))]}
        choices={shouldMirror ? mirrorPaymentFileTemplates : paymentFileTemplates}
      />
      {isFinancer && (
        <BooleanInput
          source="shouldMirrorPayment"
          label={t('Mirror payment')}
        />
      )}
    </>
  );
};

const UploadPaymentFileButton = () => {
  const { t } = useTranslation();
  const { constants, choices } = useConstantContext();
  const { paymentFileTemplates, downloadablePaymentTemplateTypes } = choices;
  const { fileTypes } = constants;

  if (!paymentFileTemplates[0]) return null;

  return (
    <ProcessableFileUploadModalButton
      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.txt"
      uploadPath="upload/payment-file"
      ModalButtonProps={{
        modalTitle: t('Upload payment file'),
        openButtonLabel: t('Upload payment file'),
        actionLabel: t('Upload'),
        formDefaultValue: {
          templateType: paymentFileTemplates[0].id,
          fileType: fileTypes.INBOUND,
          shouldMirrorPayment: false,
        },
      }}
      templateDownloadButton={<DownloadPaymentFileTemplate />}
      selectTemplateInput={<PaymentFileTypeSelect />}
      downloadableTemplateTypes={downloadablePaymentTemplateTypes}
    />
  );
};

export default UploadPaymentFileButton;
