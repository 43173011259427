import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrayField,
  TextField,
  ReferenceField,
  FunctionField,
  DateField,
  SelectField,
  useRecordContext,
} from 'react-admin';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import Datagrid from '../designSystem/react-admin/Datagrid';
import DescriptionField from '../designSystem/react-admin/DescriptionField';

import renderReference from '../../utils/renderReference';
import CurrencyField from '../utils/CurrencyField';
import FeeGrid from '../utils/FeeGrid';
import renderTransactionChip from '../utils/renderTransactionChip';
import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

const FactoringAccountFields = ({ hideOrderReference }) => {
  const { constants, choices } = useConstantContext();
  const { financialProduct } = useRecordContext();
  const { userActions } = constants;
  const { isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );
  const isDisclosedFactoring = financialProduct === constants.financialProducts.DISCLOSED_FACTORING;
  const isRecourseFactoring = financialProduct
    === constants.financialProducts.UNDISCLOSED_FACTORING_RECOURSING
      || financialProduct === constants.financialProducts.DISCLOSED_FACTORING_RECOURSING;

  return (
    <>
      <FieldsGroup title={t('General information')}>
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField label={t('Financer')} source="creditorName" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField source="financialProduct" label={t('Financial product')} choices={choices.financialProducts} />
            </SimpleShowLabeled>
          </Grid>
          {isFinancer && canViewMerchant && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <ReferenceField
                  label={t('Merchant')}
                  source="debtorId"
                  reference="entity-v2/reference"
                  link={false}
                >
                  <ReferenceField
                    label={t('Merchant')}
                    source="entityDetailsId"
                    reference="entity-v2/merchant"
                    link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
                  >
                    <FunctionField render={renderReference} />
                  </ReferenceField>
                </ReferenceField>
              </SimpleShowLabeled>
            </Grid>
          )}
          {!hideOrderReference && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <ReferenceField
                  label={t('Order')}
                  source="factoredOrder"
                  reference="order"
                >
                  <TextField source="orderReferenceNumber" emptyText="-" />
                </ReferenceField>
              </SimpleShowLabeled>
            </Grid>
          )}
          {isRecourseFactoring && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <ReferenceField
                  label={t('Recoursed at')}
                  source="factoredOrder"
                  reference="order"
                  link={false}
                >
                  <DateField source="recourseDate" emptyText="-" />
                </ReferenceField>
              </SimpleShowLabeled>
            </Grid>
          )}
        </Grid>
      </FieldsGroup>
      <FieldsGroup title={t('Financing')}>
        <Grid container>
          {isFinancer && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="exposure" currencySource="currency" label={t('Exposure')} />
            </SimpleShowLabeled>
          </Grid>
          )}
          {isMerchant && !isDisclosedFactoring && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="balance" currencySource="currency" label={t('Factoring balance')} coefficient={-1} />
            </SimpleShowLabeled>
          </Grid>
          )}
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="accumulatedInterest" currencySource="currency" label={t('Accumulated variable fee')} />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField source="markedToBalanceAt" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          {isDisclosedFactoring && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="balance" currencySource="currency" label={t('Factoring reserve')} coefficient={isMerchant ? -1 : 1} />
            </SimpleShowLabeled>
          </Grid>
          )}
          {isDisclosedFactoring && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <ReferenceField
                  label={t('Order Balance')}
                  source="factoredOrder"
                  reference="order"
                  link={false}
                >
                  <CurrencyField source="balance" currencySource="currency" emptyText="-" />
                </ReferenceField>
              </SimpleShowLabeled>
            </Grid>
          )}
        </Grid>
      </FieldsGroup>
      <FieldsGroup title={t('Fee settings')}>
        <FeeGrid source="feeSettings" label="" />
      </FieldsGroup>
      <FieldsGroup title={t('Transactions')}>
        <SimpleShowLabeled fullWidth>
          <ArrayField source="transactions" label="">
            <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount']} cellMaxWidth="unset">
              <FunctionField
                label={t('Type')}
                render={renderTransactionChip}
              />
              <DescriptionField source="description" />
              <DateField source="date" label={t('Date')} emptyText="-" />
              <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
            </Datagrid>
          </ArrayField>
        </SimpleShowLabeled>
      </FieldsGroup>
    </>
  );
};

FactoringAccountFields.propTypes = {
  hideOrderReference: PropTypes.bool,
};

FactoringAccountFields.defaultProps = {
  hideOrderReference: false,
};

export default FactoringAccountFields;
