import React, {
  useEffect,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import {
  WithPermissions,
  useResourceDefinitions,
  useTheme,
  useSidebarState,
  useBasename,
} from 'react-admin';
import FlagIcon from '@mui/icons-material/Flag';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';

import { ReactComponent as DashboardIcon } from '../../../assets/dashboard.svg';
import { ReactComponent as RectangleIcon } from '../../../assets/rectangle_blue.svg';

import hasPermissions from '../../utils/hasPermissions';
import hasUserPermissions from '../../utils/hasUserPermissions';

import useMenuGroupList from '../hooks/useMenuGroupList';
import CustomResourceMenuItem from './CustomResourceMenuItem';
import MenuGroup from './MenuGroup';
import ScrollVerticalButtons from './scrollButtons/ScrollVerticalButtons';

import DashboardMenuItem from '../designSystem/react-admin/menu/DashboardMenuItem';
import MenuItemLink from '../designSystem/react-admin/menu/MenuItemLink';

import { useConstantContext } from '../ConstantsContext';
import config from '../../../config';

const Menu = ({
  dense,
  hasDashboard,
  logout,
}) => {
  const { canEditFeatureFlags } = config;
  const menuGroupList = useMenuGroupList();
  const { constants } = useConstantContext();
  const userActions = constants.userActions || {};
  const mapActionToModules = constants.modulesActions?.mapActionToModules || {};
  const basename = useBasename();
  const resourcesDefinitions = useResourceDefinitions();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [theme] = useTheme();

  const popperRefTop = useRef();
  const popperRefBottom = useRef();
  const [isSidebarOpen] = useSidebarState();
  useEffect(() => {
    // Refresh position of the scroll buttons during the animation of the drawer
    const intervalId = setInterval(() => {
      if (popperRefTop.current) popperRefTop.current.update();
      if (popperRefBottom.current) popperRefBottom.current.update();
    }, 20);
    // Animation time (230ms) plus buffer => 300ms
    const timeoutId = setTimeout(() => clearInterval(intervalId), 300);
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [isSidebarOpen]);

  const menuRef = useRef();
  const isMenuVisible = !isSmall || isSidebarOpen;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 240,
        padding: '0.5rem 0',
        height: 'calc(100% - 1rem)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '& .RaMenuItemLink-active': {
          fontWeight: 700,
          backgroundColor: 'transparent!important',
          '& .RaMenuItemLink-icon path': {
            fill: theme.palette.secondary.main,
          },
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: 'transparent',
          '& .RaMenuItemLink-icon path': {
            fill: theme.palette.secondary.main,
          },
        },
      }}
      ref={menuRef}
    >
      {hasDashboard && (
        <WithPermissions
          render={({ permissions }) => (
            permissions
              && permissions.modules
              && hasPermissions(
                permissions.modules,
                mapActionToModules[userActions.VIEW_DASHBOARD],
              )
              && permissions.userAllowedPermissions
              && hasUserPermissions(
                permissions.userAllowedPermissions,
                userActions.VIEW_DASHBOARD,
              ) ? (
                <DashboardMenuItem
                  leftIcon={(
                    <Box display="flex" alignItems="center" gap="1rem">
                      <RectangleIcon />
                      <DashboardIcon />
                    </Box>
                )}
                  to="/"
                />
              ) : null
          )}
        />
      )}
      {menuGroupList.map((group) => {
        const childrenHaveList = group.children.some(
          (child) => resourcesDefinitions[child] && resourcesDefinitions[child].hasList,
        );
        if (!childrenHaveList) return null;
        return (
          <MenuGroup
            key={group.label}
            label={group.label}
            isSmall={isSmall}
          >
            {group.children.map((resourceName) => (
              <CustomResourceMenuItem
                key={resourceName}
                name={resourceName}
                dense={dense}
              />
            ))}
          </MenuGroup>
        );
      })}
      {canEditFeatureFlags && (
        <MenuItemLink
          to={`${basename}/feature-flags`}
          primaryText={isSidebarOpen ? 'Feature flags' : ''}
          leftIcon={(<FlagIcon />)}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '0.875rem',
              height: '0.875rem',
              color: '#B4AEA6',
            },
            marginLeft: '1.3rem',
          }}
        />
      )}
      {isSmall && logout}
      <ScrollVerticalButtons
        isDisplayed={isMenuVisible}
        scrollableElement={menuRef.current}
        popperRefTop={popperRefTop}
        popperRefBottom={popperRefBottom}
        zIndex={1500}
        dependencies={[resourcesDefinitions, isSidebarOpen]}
        color={theme.palette.primary.main}
        backgroundColor={theme.palette.common.white}
      />
    </Box>
  );
};

Menu.propTypes = {
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
};

Menu.defaultProps = {
  logout: null,
  hasDashboard: false,
  dense: null,
};

export default Menu;
