import React, { useCallback } from 'react';
import {
  Loading, Title,
} from 'react-admin';
import { useQueryClient } from 'react-query';
import { Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import NoDataContent from './NoDataContent';
import FactoringFinancerContentExposureKpis from './financer/FactoringFinancerContentExposureKpis';
import FactoringMerchantContentExposureKpis from './merchant/FactoringMerchantContentExposureKpis';
import useCustomRpc from '../hooks/useCustomRpc';

import { getUserFirstNameFromToken } from '../../utils/getValueFromToken';
import { StatisticsContextProvider, useStatistics } from './StatisticsContext';

import { ReactComponent as RefreshIcon } from '../../../assets/refresh-icon.svg';

import { useDefineLocation } from '../AppLocationContext';
import { useConstantContext } from '../ConstantsContext';
import InfoBox from '../designSystem/InfoBox';
import TitleH1 from '../designSystem/TitleH1';

const RefreshKPIButton = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    mutate: refreshStatistics,
    isLoading: refreshStatisticsLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: 'statistics/refresh-kpi',
    errorMessage: t('Error when refreshing kpis'),
    onSuccess: (data) => {
      queryClient.setQueryData('statistics', data);
    },
  });

  const refreshKpi = useCallback(() => {
    refreshStatistics();
  }, [refreshStatistics]);

  const { statisticsData: { createdAt } } = useStatistics();

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
    >
      <Typography color="headers" fontSize="12px" fontWeight="600" marginRight="2rem">{t('Last update date')}: {createdAt ? new Date(createdAt).toLocaleString('en-GB') : '-'}</Typography>
      <Button
        onClick={refreshKpi}
        variant="outlined"
        size="small"
        disabled={refreshStatisticsLoading}
        startIcon={<RefreshIcon />}
        sx={{
          borderRadius: '2.188rem',
        }}
      >
        {t('Refresh KPI')}
      </Button>
    </Box>
  );
};

const DashboardContent = () => {
  const { statisticsData: statistics, getStatisticsLoading } = useStatistics();

  const {
    isFactoringFinancer,
    isFactoringMerchant,
  } = useUserPermissionsAndRoles();

  if (getStatisticsLoading) return <Loading />;

  if (isEmpty(statistics)) {
    return (
      <>
        <NoDataContent />
        <RefreshKPIButton />
      </>
    );
  }

  let Content = null;
  if (isFactoringMerchant) Content = <FactoringMerchantContentExposureKpis />;
  if (isFactoringFinancer) Content = <FactoringFinancerContentExposureKpis />;

  return (
    <>
      {Content}
      <RefreshKPIButton />
    </>
  );
};

const DashboardTitle = () => {
  const { t } = useTranslation();
  const userFirstName = getUserFirstNameFromToken();

  return (
    <TitleH1 title={<>{t('Welcome,')} <strong>{`${userFirstName.slice(0, 1).toUpperCase() + userFirstName.slice(1)}`}</strong></>} />
  );
};

const Dashboard = () => {
  useDefineLocation('home');
  const { constants } = useConstantContext();
  const userActions = constants.userActions || {};
  const canViewDashboard = useHasUserPermissions(
    constants,
    userActions.VIEW_DASHBOARD,
  );

  const {
    isFactoringFinancer,
    isFactoringMerchant,
  } = useUserPermissionsAndRoles();

  if (!canViewDashboard) return <Title title={<DashboardTitle />} />;

  if (
    isFactoringFinancer === null
    || isFactoringMerchant === null
  ) return <Loading />;

  if (
    !isFactoringMerchant
    && !isFactoringFinancer
  ) return <Navigate to="/notification" />;

  return (
    <Box padding={1}>
      <Title title={<DashboardTitle />} />
      <InfoBox />
      <StatisticsContextProvider>
        <DashboardContent />
      </StatisticsContextProvider>
    </Box>
  );
};

export default Dashboard;
