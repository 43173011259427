import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  required,
  useNotify,
  usePermissions,
} from 'react-admin';
import { t } from 'i18next';

import ModalButton from '../designSystem/ModalButton';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import EmailTemplate from './EmailTemplate';

import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';

import { useConstantContext } from '../ConstantsContext';

import hasUserPermissions from '../../utils/hasUserPermissions';
import { emailValidation } from '../../utils/validationErrors';

const mapDeliveryConfirmationTypeToLabel = (types) => ([
  { id: types.SCRIVE, name: t('Scrive (BankID or signature)') },
  { id: types.EMAIL, name: t('Email (sent via Fimento)') },
  { id: types.EXTERNAL, name: t('Other method (external)') },
]);

const mapAuthenticationMethodToLabel = (types) => ([
  { id: types.BANKID, name: t('BankID') },
  { id: types.STANDARD, name: t('Signature') },
]);

const ScriveInputs = () => {
  const { constants } = useConstantContext();
  const { scriveAuthenticationMethods } = constants;

  const authenticationMethods = mapAuthenticationMethodToLabel(scriveAuthenticationMethods);

  return (
    <>
      <TextInput source="email" label={t('Email')} type="email" validate={[required(), emailValidation]} />
      <TextInput source="templateId" label={t('Scrive template id')} validate={[required()]} />
      <SelectInput
        label={t('Authenticating method')}
        source="authenticationMethod"
        choices={authenticationMethods}
        validate={[required()]}
      />
    </>
  );
};

const EmailInputs = ({ orderId }) => (
  <>
    <TextInput source="name" label={t('Name')} validate={[required()]} />
    <TextInput source="email" label={t('Email')} type="email" validate={[required(), emailValidation]} />
    <TextInput source="senderEmail" label={t('Your email')} type="email" validate={[required(), emailValidation]} />
    <EmailTemplate orderId={orderId} />
  </>
);

EmailInputs.propTypes = {
  orderId: PropTypes.string.isRequired,
};

const CreateDeliveryConfirmationButton = ({ orderId }) => {
  const notify = useNotify();
  const [featureFlags] = useFeatureFlags();

  const { constants } = useConstantContext();
  const { userActions, deliveryConfirmationTypes } = constants;

  const deliveryConfirmationChoices = mapDeliveryConfirmationTypeToLabel(deliveryConfirmationTypes);

  const authenticationData = usePermissions();
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canEditDeliveryConfirmation = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_DELIVERY_CONFIRMATION,
  );
  const [deliveryConfirmationTypeInput, setDeliveryConfirmationTypeInput] = useState('');

  const {
    mutate: createDeliveryConfirmation,
    isLoading,
  } = useCustomRpc({
    path: `order/${orderId}/delivery-confirmation`,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Could not create delivery confirmation'),
    onSuccess: () => {
      notify(t('You successfully created a delivery confirmation'), { type: 'success' });
    },
  });

  const handleDeliveryConfirmationTypeInput = useCallback((e) => {
    setDeliveryConfirmationTypeInput(e.target.value);
  }, []);

  const onSubmit = useCallback((formData) => {
    const data = {
      type: formData.deliveryConfirmationType,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      senderEmail: formData.senderEmail,
      templateId: formData.templateId,
      authenticationMethod: formData.authenticationMethod,
    };
    createDeliveryConfirmation(data);

    return true;
  }, [createDeliveryConfirmation]);

  if (!canEditDeliveryConfirmation || !featureFlags.enableDeliveryConfirmation) return null;

  let inputFields = [];

  if (deliveryConfirmationTypeInput === deliveryConfirmationTypes.SCRIVE) {
    inputFields = <ScriveInputs />;
  } else if (deliveryConfirmationTypeInput === deliveryConfirmationTypes.EMAIL) {
    inputFields = <EmailInputs orderId={orderId} />;
  }
  return (
    <ModalButton
      modalTitle={t('New delivery confirmation request')}
      openButtonLabel={t('Create new')}
      actionLabel={t('Send')}
      onClick={onSubmit}
      onClose={() => setDeliveryConfirmationTypeInput(null)}
      disabled={isLoading}
      width="33rem"
      formDefaultValue={{ deliveryConfirmationTypes: null }}
    >
      <SelectInput
        label={t('Delivery confirmation source')}
        source="deliveryConfirmationType"
        choices={deliveryConfirmationChoices}
        onChange={handleDeliveryConfirmationTypeInput}
        placeholder={t('Select')}
        validate={[required()]}
      />
      {inputFields}
    </ModalButton>
  );
};

CreateDeliveryConfirmationButton.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default CreateDeliveryConfirmationButton;
