import React, { useCallback } from 'react';
import { useRefresh } from 'react-admin';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import useCustomRpc from '../hooks/useCustomRpc';

import { ReactComponent as RefreshIcon } from '../../../assets/refresh-icon.svg';

const RefreshExposureKPIButton = ({
  entityCoreId,
}) => {
  const { t } = useTranslation();
  const refresh = useRefresh();
  const {
    mutate: refreshStatistics,
    isLoading: refreshStatisticsLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `entity-v2/${entityCoreId}/current-exposure-kpis`,
    errorMessage: t('Error when refreshing current exposure'),
    onSuccess: () => {
      refresh();
    },
  });

  const refreshKpi = useCallback(() => {
    refreshStatistics();
  }, [refreshStatistics]);

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      marginTop="0.5rem"
    >
      <Button
        onClick={refreshKpi}
        variant="outlined"
        size="small"
        disabled={refreshStatisticsLoading}
        startIcon={<RefreshIcon />}
        sx={{
          borderRadius: '2.188rem',
        }}
      >
        {t('Refresh KPI')}
      </Button>
    </Box>
  );
};

RefreshExposureKPIButton.propTypes = {
  entityCoreId: PropTypes.string.isRequired,
};

export default RefreshExposureKPIButton;
