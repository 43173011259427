// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import {
  Tab,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FinancingApplicationTitle from './FinancingApplicationTitle';
import ApplicationDetailsGroup from './ApplicationDetailsGroup';
import CustomerDetailsInApplication from './CustomerDetailsInApplication';
import FinancingApplicationTermsGroup from './FinancingApplicationTermsGroup';
import MerchantDetailsInApplication from './MerchantDetailsInApplication';
import FinancingApplicationRulesResultsGroup from './FinancingApplicationRulesResultsGroup';

import TabbedShowLayout from '../../designSystem/react-admin/TabbedShowLayout';
import Show from '../../designSystem/react-admin/Show';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

const FinancingApplicationShow = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  return (
    <Show
      mutationMode="optimistic"
      title={<FinancingApplicationTitle />}
      appLocation="home.financing-application.show"
    >
      <TabbedShowLayout redirect="false">
        <Tab label={t('Summary')}>
          <ApplicationDetailsGroup />
          <CustomerDetailsInApplication />
          {isFinancer && <MerchantDetailsInApplication />}
          <FinancingApplicationTermsGroup />
          <FinancingApplicationRulesResultsGroup />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default FinancingApplicationShow;
