import { t } from 'i18next';
import React from 'react';
import { useRecordContext } from 'react-admin';
import TitleH1 from '../designSystem/TitleH1';

export const getRoleEditTitle = (record) => (record ? `${t('Role')}: ${record.name}` : t('Role'));

const RoleEditTitle = () => {
  const record = useRecordContext();
  const title = getRoleEditTitle(record);
  return <TitleH1 title={title} />;
};

export default RoleEditTitle;
