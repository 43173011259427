import {
  useCallback,
  useEffect,
  useState,
} from 'react';

const SENSIBILITY = 2;

const computeCanBeScrolledBottom = (element) => Math.abs(
  element.scrollHeight
  - element.clientHeight
  - element.scrollTop,
) >= SENSIBILITY;
const getScrollDestinationBottom = (element) => ({
  left: element.scrollLeft,
  top: element.scrollHeight,
});

const computeCanBeScrolledTop = (element) => element.scrollTop >= SENSIBILITY;
const getScrollDestinationTop = (element) => ({
  left: element.scrollLeft,
  top: 0,
});

const computeCanBeScrolledRight = (element) => Math.abs(
  element.scrollWidth
  - element.clientWidth
  - element.scrollLeft,
) >= SENSIBILITY;
const getScrollDestinationRight = (element) => ({
  left: element.scrollWidth,
  top: element.scrollTop,
});

const computeCanBeScrolledLeft = (element) => element.scrollLeft >= SENSIBILITY;
const getScrollDestinationLeft = (element) => ({
  left: 0,
  top: element.scrollTop,
});

const makeUseScroll = (computeCanBeScrolled, getScrollDestination) => {
  const useScroll = (element, dependencies) => {
    const [canBeScrolled, setCanBeScrolled] = useState(false);

    const refreshScrollPosition = useCallback(() => {
      if (element) {
        const newCanBeScrolled = computeCanBeScrolled(element);
        setCanBeScrolled(newCanBeScrolled);
      }
    }, [element]);
    useEffect(() => {
      refreshScrollPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshScrollPosition, ...(dependencies ?? [])]);
    useEffect(() => {
      if (element) {
        element.addEventListener('scroll', refreshScrollPosition);

        return () => {
          element.removeEventListener('scroll', refreshScrollPosition);
        };
      }
      return undefined;
    }, [refreshScrollPosition, element]);

    const scrollTo = useCallback(() => {
      element.scrollTo({
        ...getScrollDestination(element),
        behavior: 'smooth',
      });
    }, [element]);

    return {
      canBeScrolled,
      scrollTo,
    };
  };

  return useScroll;
};

export const useScrollToBottom = makeUseScroll(
  computeCanBeScrolledBottom,
  getScrollDestinationBottom,
);
export const useScrollToTop = makeUseScroll(
  computeCanBeScrolledTop,
  getScrollDestinationTop,
);
export const useScrollToRight = makeUseScroll(
  computeCanBeScrolledRight,
  getScrollDestinationRight,
);
export const useScrollToLeft = makeUseScroll(
  computeCanBeScrolledLeft,
  getScrollDestinationLeft,
);

export default useScrollToBottom;
