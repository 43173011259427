import React from 'react';
import {
  TextField,
  ReferenceField,
  FunctionField,
  useRecordContext,
  SelectField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import FieldsGroup from '../../utils/FieldsGroup';

import { useConstantContext } from '../../ConstantsContext';

const CustomerIdentifier = () => {
  const record = useRecordContext();
  const { constants, choices } = useConstantContext();
  const isBusiness = record.type === constants.coreTypes.BUSINESS;
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Customer identifiers')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="nationalIdentifier" label={isBusiness ? t('Org. number') : t('Social security number')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField label="Business/Consumer " source="type" choices={choices.coreTypes} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Country')}
              source="country"
              reference="countries"
              resource="/countries"
              link={false}
            >
              <FunctionField render={(countryRecord) => (countryRecord.name ? `(${countryRecord.id}) ${countryRecord.name}` : '')} />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          {isBusiness && (
            <SimpleShowLabeled>
              <TextField source="vatNumber" label={t('VAT number')} emptyText="-" />
            </SimpleShowLabeled>
          )}
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

const CustomerIdentifierFields = () => (
  <ReferenceField
    source="customerId"
    reference="entity-v2/core"
    link={false}
  >
    <CustomerIdentifier />
  </ReferenceField>
);

export default CustomerIdentifierFields;
