import React from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import ListChip, { colors } from '../designSystem/ListChip';

const FactoringAccountStatusChip = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { markedToBalanceAt, balance, accountStatus } = record;

  let chipColor;
  let chipLabel;
  if (balance !== 0 && markedToBalanceAt) {
    chipLabel = t('To balance');
    chipColor = colors.orange;
  } else if (accountStatus === 'closed') {
    chipLabel = t('Closed');
    chipColor = colors.gray;
  } else {
    chipLabel = t('Active');
    chipColor = colors.green;
  }

  return (
    <ListChip
      label={chipLabel}
      chipColor={chipColor}
    />
  );
};

export default FactoringAccountStatusChip;
