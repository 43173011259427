import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  FilterContext,
  FilterButton,
  FilterForm,
  useListContext,
} from 'react-admin';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
  Box,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const AccordionFilter = ({ children, filters, withMarginBottom }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    filterValues,
    setFilters,
  } = useListContext();

  const clearFilters = useCallback(() => setFilters({}, {}, false), [setFilters]);
  const numberOfFiltersOn = Object.keys(filterValues).length;

  return (
    <Accordion
      sx={{
        backgroundColor: 'inherit',
        boxShadow: 'none',
        width: '100%',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
          },
        }}
      >
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              padding: 0,
            }}
          >
            <Typography>{t('Filters and Search')}</Typography>
          </AccordionSummary>
          {numberOfFiltersOn > 0 && (
            <Chip
              label={`${numberOfFiltersOn} ${t('active filter')}${numberOfFiltersOn > 1 ? 's' : ''}`}
              onDelete={clearFilters}
            />
          )}
        </Box>
        <Box gap={1} display="flex" alignItems="center">
          {children}
        </Box>
      </Box>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, marginBottom: withMarginBottom ? '1.5rem' : 0 }}>
        <FilterContext.Provider value={filters}>
          <FilterButton />
          <FilterForm />
        </FilterContext.Provider>
      </AccordionDetails>
    </Accordion>
  );
};

AccordionFilter.propTypes = {
  children: ChildrenProp,
  filters: PropTypes.arrayOf(PropTypes.element).isRequired,
  withMarginBottom: PropTypes.bool,
};

AccordionFilter.defaultProps = {
  children: null,
  withMarginBottom: false,
};

export default AccordionFilter;
