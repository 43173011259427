import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import useMapReportsToFilters from './useMapReportToFilters';
import { ReportsFilterContext } from './useReportsFilterContext';

const ReportsFilterContextProvider = ({ children }) => {
  const [hiddenFilters, setHiddenFilters] = useState([]);
  const [filtersForReportType, setFiltersForReportType] = useState([]);
  const [displayedFilters, setDisplayedFilters] = useState([]);
  const mapReportsToFilters = useMapReportsToFilters();
  const { watch, setValue } = useFormContext();
  const reportType = watch('type');

  const handleHide = useCallback((source) => () => {
    const sourcesToHide = Array.isArray(source) ? source : [source];
    const filtersToDisplay = displayedFilters
      .filter((displayedFilter) => !sourcesToHide.includes(displayedFilter.props.source));
    setDisplayedFilters(filtersToDisplay);
    sourcesToHide.forEach((sourceToHide) => {
      setValue(sourceToHide, undefined);
    });
  }, [displayedFilters, setDisplayedFilters, setValue]);

  useEffect(() => {
    // We want changes in the mapReportsToFilters to be reflected in the hiddenFilters
    // As a side effect, changes in the reportType will also be reflected
    // so we don't need handle it in the onChange of the input
    const newFiltersForReportType = mapReportsToFilters[reportType] || [];
    setFiltersForReportType(newFiltersForReportType);
  }, [reportType, mapReportsToFilters]);

  return (
    <ReportsFilterContext.Provider value={{
      hiddenFilters,
      setHiddenFilters,
      filtersForReportType,
      setFiltersForReportType,
      displayedFilters,
      setDisplayedFilters,
      mapReportsToFilters,
      handleHide,
    }}
    >
      {children}
    </ReportsFilterContext.Provider>
  );
};

ReportsFilterContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportsFilterContextProvider;
