import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import {
  usePermissions,
  useRecordContext,
  useNotify,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';

import { useConstantContext } from '../ConstantsContext';
import hasUserPermissions from '../../utils/hasUserPermissions';
import { useCreditDataContext } from '../CreditDataContext';

const ConfirmCreditDataButton = ({ entityId }) => {
  const notify = useNotify();
  const record = useRecordContext();
  const { constants } = useConstantContext();

  const authenticationData = usePermissions();

  const { userActions } = constants;

  const { userAllowedPermissions } = authenticationData.permissions || {};
  const { setCreditReport } = useCreditDataContext();

  const { t } = useTranslation();

  const canPurchaseCreditData = hasUserPermissions(
    userAllowedPermissions,
    userActions.PURCHASE_CREDIT_DATA,
  );

  const coreId = record.entityCoreId || record[entityId];

  const {
    mutate: createCreditData,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: `entity-v2/${coreId}/credit-data`,
    errorMessage: t('Could not create the external data'),
    onSuccess: (data) => {
      setCreditReport(data.data);
      notify('Fetched external data successfully', { type: 'success' });
    },
  });

  const onSubmit = useCallback(() => {
    createCreditData();
    return true;
  }, [createCreditData]);

  if (!canPurchaseCreditData) return null;

  return (
    <ModalButton
      modalTitle={t('Update')}
      openButtonLabel={t('Update')}
      actionLabel={t('Update')}
      onClick={onSubmit}
      disabled={isLoading}
      width="25rem"
      sx={{ margin: '0.5rem' }}
    >
      <Typography>
        {t('Your Creditsafe plan is subject to variation based on the number of companies you monitor within Fimento')}
      </Typography>
    </ModalButton>
  );
};

ConfirmCreditDataButton.propTypes = {
  entityId: PropTypes.string,
};

ConfirmCreditDataButton.defaultProps = {
  entityId: undefined,
};

export default ConfirmCreditDataButton;
