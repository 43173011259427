import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput,
  SelectInput,
  TabbedForm,
  required,
} from 'react-admin';

import { useNavigate } from 'react-router-dom';

import { useConstantContext } from '../ConstantsContext';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import UserActionsGroup from './UserActionsGroup';

import Create from '../designSystem/react-admin/Create';
import TitleH1 from '../designSystem/TitleH1';

const validateRequired = [required()];

export const getRoleCreateTitle = (t) => t('Create role');

const RoleCreate = () => {
  const navigate = useNavigate();
  const [selectedRoleType, setSelectedRoleType] = useState();
  const { t } = useTranslation();

  const redirect = useCallback(() => navigate('/manage-users', { state: { tab: 1 } }), [navigate]);

  const { isOwner } = useUserPermissionsAndRoles();
  const { choices } = useConstantContext();
  const { roleTypes } = choices;

  return (
    <Create
      title={<TitleH1 title={getRoleCreateTitle(t)} />}
      redirect={false}
      mutationOptions={{
        onSuccess: redirect,
      }}
      appLocation="home.manage-users.create/role"
    >
      <TabbedForm
        sanitizeEmptyValues
      >
        <TabbedForm.Tab
          label={t('Create role')}
        >
          <TextInput
            source="name"
            label={t('Role name')}
            validate={validateRequired}
          />
          {isOwner && (
            <SelectInput
              source="roleType"
              label={t('Type')}
              validate={validateRequired}
              choices={roleTypes}
              onChange={(event) => setSelectedRoleType(event.target.value)}
            />
          )}
          <UserActionsGroup roleType={selectedRoleType} />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default RoleCreate;
