import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import {
  required,
  useRecordContext,
  FunctionField,
  ReferenceField,
  TextField,
  ArrayField,
  DateField,
  SelectField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../utils/FieldsGroup';

import { useConstantContext } from '../ConstantsContext';
import UpdateKeyPeopleButton from './UpdateKeyPeople';

import Datagrid from '../designSystem/react-admin/Datagrid';

const validateRequired = [required()];

const KeyPeople = ({ userAction }) => {
  const { choices, constants } = useConstantContext();
  const entityDetailsRecord = useRecordContext();
  const { t } = useTranslation();

  const choiceStates = choices;

  const coreTypeInput = useWatch({ name: 'type' });

  if (!entityDetailsRecord && !coreTypeInput) return null;
  if (entityDetailsRecord && coreTypeInput) throw new Error('Cannot render entity details. One source of truth is required for the entity core type');

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? entityDetailsRecord.coreType === constants.coreTypes.BUSINESS
      : undefined;
  }

  return isBusiness && (
    <FieldsGroup
      title={t('Key people')}
      actions={<UpdateKeyPeopleButton userAction={userAction} />}
    >
      <ArrayField
        source="keyPeople"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="firstName" label={t('First name')} validate={validateRequired} />
          <TextField source="lastName" label={t('Last name')} validate={validateRequired} />
          <ReferenceField
            source="nationality"
            reference="countries"
            resource="/countries"
          >
            <FunctionField render={({ id, name }) => (name ? `(${id}) ${name}` : '')} />
          </ReferenceField>
          <DateField source="dateBirth" label={t('Date of birth')} validate={validateRequired} />
          <SelectField
            margin="none"
            label={t('Role')}
            source="role"
            choices={choiceStates.keyPeopleRoles}
            validate={validateRequired}
          />
          <FunctionField label={t('Is PEP')} render={(record) => (record.isPep ? t('Yes') : t('No'))} />
        </Datagrid>
      </ArrayField>
    </FieldsGroup>
  );
};

KeyPeople.propTypes = {
  userAction: PropTypes.string.isRequired,
};

export default KeyPeople;
