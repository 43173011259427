import React from 'react';

import TabbedForm from '../designSystem/react-admin/TabbedForm';
import Edit from '../designSystem/react-admin/Edit';

import SummaryTab from './tabs/SummaryTab';
import InvoicesTab from './tabs/InvoicesTab';
import TransactionTab from './tabs/TransactionTab';
import LoanTitle from './LoanTitle';
import SaveOnlyToolbar from '../utils/SaveOnlyToolbar';
import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const SaveToolbar = () => {
  const { constants } = useConstantContext();

  const canEdit = useHasUserPermissions(
    constants,
    constants.userActions.EDIT_LOANS,
  );

  return canEdit ? <SaveOnlyToolbar /> : null;
};

const LoanEdit = () => (
  <Edit
    title={<LoanTitle />}
    mutationMode="optimistic"
    redirect={false}
    appLocation="home.loan.edit"
  >
    <TabbedForm
      sanitizeEmptyValues
      toolbar={<SaveToolbar />}
    >
      <SummaryTab />
      <InvoicesTab />
      <TransactionTab />
    </TabbedForm>
  </Edit>
);

export default LoanEdit;
