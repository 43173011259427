import i18n from 'i18next';
import { getJWTPayload } from './jwt';

const languageMap = {
  english: 'en',
  swedish: 'sv',
};

/**
 *
 * @param {string} language our internal language string
 * @returns a two-letter language code, if no match is found, return 'en'
 */
export const getLanguageCodeFromLanguageString = (language) => languageMap[language] ?? 'en';

const setLanguage = (token) => {
  const language = getJWTPayload(token).user_language;
  i18n.changeLanguage(languageMap[language]);
};
export default setLanguage;
