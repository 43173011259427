import React, { useCallback } from 'react';
import { isEmpty, startCase } from 'lodash';
import { TextInput, useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import ModalButton from '../utils/ModalButton';
import ButtonStyles from '../utils/ButtonStyles';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';
import { useConstantContext } from '../ConstantsContext';

const UpdateAcceptanceDecisionButton = () => {
  const {
    id,
    acceptanceDecision,
    applicationStep,
    applicationType,
    acceptanceReason,
    terms,
    requestedTerms,
  } = useRecordContext();
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const {
    applicationSteps,
    acceptanceStatuses,
    resourceTypePaths,
    applicationResourceTypes,
  } = constants;

  const path = resourceTypePaths[applicationResourceTypes[applicationType]] || '';
  const {
    mutate: updateAcceptanceDecision,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${path}/${id}/acceptance-decision`,
    shouldRefresh: true,
    errorMessage: t('Could not update the acceptance decision'),
  });

  const handleClick = useCallback((newAcceptanceDecision) => () => {
    updateAcceptanceDecision({
      acceptanceDecision: newAcceptanceDecision,
    });
  }, [updateAcceptanceDecision]);

  const handleRejectStep = useCallback(({ reason }) => {
    updateAcceptanceDecision({
      acceptanceDecision: acceptanceStatuses.REJECT,
      acceptanceReason: reason,
    });
    return true;
  }, [updateAcceptanceDecision, acceptanceStatuses.REJECT]);

  const { isMerchant } = useUserPermissionsAndRoles();

  if (
    isEmpty(acceptanceStatuses)
    || applicationStep !== applicationSteps.APPROVED
  ) return null;

  const canBeAccepted = (
    acceptanceDecision !== acceptanceStatuses.ACCEPT
    && isMerchant
    && acceptanceDecision !== acceptanceStatuses.COUNTER_OFFER
  ) || (acceptanceDecision === acceptanceStatuses.COUNTER_OFFER && !isMerchant);

  const canBeRejected = (
    acceptanceDecision !== acceptanceStatuses.REJECT
    && isMerchant
    && acceptanceDecision !== acceptanceStatuses.COUNTER_OFFER
  ) || (
    acceptanceDecision === acceptanceStatuses.COUNTER_OFFER
      && !isMerchant
  );

  const canBeReviewed = acceptanceDecision !== acceptanceStatuses.REVIEW
    && acceptanceDecision !== acceptanceStatuses.COUNTER_OFFER
    && isMerchant;

  const termsToDisplay = isMerchant ? terms : requestedTerms;

  const {
    downPayment,
    leasingDuration,
    monthlyPayment,
    residualValue,
  } = termsToDisplay;

  return (
    <Box display="flex" flexDirection="right" sx={{ float: 'right' }}>
      {canBeAccepted && (
        <ModalButton
          title={t('Accept')}
          onClick={handleClick(acceptanceStatuses.ACCEPT)}
          disabled={isLoading}
          variant="contained"
          color="primary"
          size="small"
          sx={ButtonStyles.grantButton}
          formDefaultValue={{
            downPayment,
            leasingDuration,
            monthlyPayment,
            residualValue,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
          >
            {Object.entries(termsToDisplay).map(([key, value]) => (
              <Box key={key} margin="1rem 0.5rem" flex="calc(50% - 1rem)">
                <Typography>{startCase(key)}: {value}</Typography>
              </Box>
            ))}
          </Box>
        </ModalButton>
      )}
      {canBeReviewed && (
        <Box margin="0.5rem 0.3rem">
          <Button
            onClick={handleClick(acceptanceStatuses.REVIEW)}
            variant="contained"
            color="primary"
            size="small"
            disabled={isLoading}
          >
            {t('Review')}
          </Button>
        </Box>
      )}
      {canBeRejected && (
        <ModalButton
          title={t('Reject')}
          onClick={handleRejectStep}
          sx={ButtonStyles.denyButton}
          disabled={isLoading}
          withForm
          formDefaultValue={{ reason: acceptanceReason }}
        >
          <Box>
            <TextInput
              fullWidth
              label={t('Reject reason')}
              source="reason"
            />
          </Box>
        </ModalButton>
      )}
    </Box>
  );
};

export default UpdateAcceptanceDecisionButton;
