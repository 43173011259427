import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext, SaveButton } from 'react-admin';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../utils/FieldsGroup';
import EnhancedFileFields from '../utils/EnhancedFileFields';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import { useConstantContext } from '../ConstantsContext';

import FileInput from '../designSystem/react-admin/inputs/FileInput';

const EntitySaveButton = ({ isMerchant, isCustomer }) => {
  if (isMerchant || isCustomer) {
    return <SaveButton />;
  }
  return null;
};

EntitySaveButton.propTypes = {
  isMerchant: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired,
};

const AdditionalInfoGroup = ({ userAction, route }) => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { userActions } = constants;

  const canEdit = useHasUserPermissions(
    constants,
    userAction,
  );

  const canEditMerchant = useHasUserPermissions(
    constants,
    userActions.CREATE_MERCHANTS,
  );

  const canEditCustomer = useHasUserPermissions(
    constants,
    userActions.CREATE_CUSTOMERS,
  );

  const isMerchant = route === 'merchant' && canEdit && canEditMerchant;
  const isCustomer = route === 'customer' && canEdit && canEditCustomer;

  return (
    <FieldsGroup
      title={t('Additional information')}
      actions={<EntitySaveButton isMerchant={isMerchant} isCustomer={isCustomer} />}
    >
      <FileInput
        source="documents"
        label={t('Documents')}
        accept="application/pdf"
        maxSize={5000000}
        multiple
        options={{ disabled: !canEdit }}
      >
        <EnhancedFileFields
          source="id"
          title={t('originalName')}
          target="_blank"
          parentId={record.id}
          resource="entity-v2"
        />
      </FileInput>
    </FieldsGroup>
  );
};

AdditionalInfoGroup.propTypes = {
  userAction: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default AdditionalInfoGroup;
