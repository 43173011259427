import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import TitleH1 from '../designSystem/TitleH1';

const styles = {
  financed: { marginRight: '5px', backgroundColor: '#cccccc' },
  active: { marginRight: '5px', backgroundColor: '#479F4C', color: '#f2f2f2' },
  overdue1: { marginRight: '5px', backgroundColor: '#FEC93E' },
  overdue30: { marginRight: '5px', backgroundColor: '#F37C22', color: '#f2f2f2' },
  overdue60: { marginRight: '5px', backgroundColor: '#BD371A', color: '#f2f2f2' },
};

export const getOrderTitle = (record) => record.orderReferenceNumber;

const OrderTitle = ({ record: recordProp }) => {
  const { t } = useTranslation();
  const record = useRecordContext() || recordProp;
  if (!record) return null;

  const { isFinanced } = record.financing || {};
  const financedChip = isFinanced
    ? (<Chip style={styles.financed} label={t('Financed')} />)
    : null;
  let statusChip = <Chip style={styles.active} label={t('Active')} />;
  if (record.overdueDays > 1) statusChip = <Chip style={styles.overdue1} label={t('Overdue 1-30 days')} />;
  else if (record.overdueDays > 30) statusChip = <Chip style={styles.overdue30} label={t('Overdue 31-60 days')} />;
  else if (record.overdueDays > 60) statusChip = <Chip style={styles.overdue60} label={t('Overdue 60+ days')} />;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <TitleH1 title={getOrderTitle(record)} sx={{ marginRight: '1rem' }} />
      {statusChip}
      {financedChip}
    </div>
  );
};

OrderTitle.propTypes = {
  record: PropTypes.shape(PropTypes.any),
};
OrderTitle.defaultProps = {
  record: undefined,
};

export default OrderTitle;
