import React from 'react';

import {
  TextField,
  required,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

const validateRequired = [required()];

const CompanyDetailsConsumerContent = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="mainAddress.name" label={t('Name')} validate={validateRequired} />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="mainAddress.town" label={t('Town')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="bic" label="BIC" emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="mainAddress.streetAddress" label={t('Street address')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="mainAddress.postcode" label={t('Postcode')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="iban" label="IBAN" emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="mainAddress.additionalStreetAddress" label={t('Additional street address')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="invoiceEmail" label={t('Invoice email')} type="email" emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="bankgiro" label={t('Bankgiro')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="phone" type="tel" emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField source="contactEmail" label={t('Contact email')} type="email" emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
    </Grid>
  );
};

export default CompanyDetailsConsumerContent;
