import React from 'react';
import { isEmpty } from 'lodash';
import {
  useRecordContext,
  ReferenceField,
  ReferenceManyField,
  DateField,
  TextField,
  EditButton,
  FunctionField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import CurrencyField from '../utils/CurrencyField';
import SafePagination from '../utils/SafePagination';
import FieldsGroup from '../utils/FieldsGroup';

import { useConstantContext } from '../ConstantsContext';

import Datagrid from '../designSystem/react-admin/Datagrid';

import useHasUserPermissions from '../hooks/useHasUserPermissions';

const PendingApplicationsInfoGroup = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const {
    id: customerId,
  } = record;

  const { constants } = useConstantContext();
  const {
    applicationTypes,
    decisionStatuses,
    userActions,
  } = constants;

  const canViewCustomerApplication = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMER_APPLICATIONS,
  );

  if (isEmpty(applicationTypes)
    || isEmpty(decisionStatuses)
    || !canViewCustomerApplication
  ) return null;

  const filterQuery = {
    customerId,
    applicationType: applicationTypes.CUSTOMER_APPLICATION,
    currentDecision: decisionStatuses.REFERRED,
  };

  return (
    <FieldsGroup
      title={t('Pending applications')}
    >
      <ReferenceManyField
        reference="customer-application"
        resource="customer-application"
        source="entityCoreId"
        target="customerId"
        label={false}
        filter={filterQuery}
        pagination={<SafePagination />}
        perPage={5}
      >
        <Datagrid bulkActionButtons={false} rightAlignedColumns={['requestedTerms\\.creditLimit', 'action']}>
          <ReferenceField
            source="applicant"
            reference="user/public"
            label={t('Requesting User')}
            link={false}
            sortable={false}
          >
            <FunctionField render={(user) => `${user.firstName} ${user.lastName}`} />
          </ReferenceField>
          <TextField source="currentDecision" label={t('Decision')} emptyText="-" />
          <DateField source="createdAt" label={t('Creation date')} emptyText="-" />
          <CurrencyField source="requestedTerms.creditLimit" currencySource="requestedTerms.creditLimitCurrency" label={t('Requested credit limit')} emptyText="-" />
          <WrapperField label={false} source="action">
            <EditButton icon={<BorderColorOutlinedIcon />} />
          </WrapperField>
        </Datagrid>
      </ReferenceManyField>
    </FieldsGroup>
  );
};

export default PendingApplicationsInfoGroup;
