import React from 'react';
import { Sidebar, useTheme } from 'react-admin';
import {
  Typography,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import NamedAppBar from './NamedAppBar';
import Menu from '../Menu';
import { ChildrenProp } from '../../../utils/helpers/propTypes';
import Breadcrumbs from '../../Breadcrumbs';

import { AppLocationContextProvider } from '../../AppLocationContext';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: 'fit-content',
  width: '100%',
  color: theme.palette.getContrastText(theme.palette.background.default),
}));

const AppFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: theme.palette.boxes,
}));

const ContentWithSidebar = styled('main')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexBasis: 0,
  padding: 0,
  width: 0,
  [theme.breakpoints.up('xs')]: {
    padding: '1.5rem 1.25rem',
  },
  borderRadius: '1.5rem 0 0 0',
}));

const Layout = ({ children, dashboard }) => {
  const [theme] = useTheme();

  return (
    <Root>
      <AppFrame>
        <NamedAppBar />
        <ContentWithSidebar>
          <Sidebar
            sx={{
              '& .RaSidebar-paper': {
                backgroundColor: theme.palette.boxes,
              },
              '& .RaSidebar-fixed': {
                height: 'calc(100% - 4rem)',
              },
            }}
          >
            <Menu hasDashboard={!!dashboard} />
          </Sidebar>
          <Content>
            <Breadcrumbs />
            <Typography
              variant="h5"
              color="inherit"
              fontWeight="bold"
              overflow="hidden"
              whiteSpace="nowrap"
              marginBottom="2.5rem"
              marginTop="2rem"
              sx={{
                textOverflow: 'ellipsis',
              }}
              id="react-admin-title"
            />
            {children}
          </Content>
        </ContentWithSidebar>
      </AppFrame>
    </Root>
  );
};

const CustomLayout = (props) => (
  <AppLocationContextProvider>
    <Layout
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </AppLocationContextProvider>
);

Layout.propTypes = {
  children: ChildrenProp.isRequired,
  dashboard: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
};

Layout.defaultProps = {
  dashboard: null,
};

export default CustomLayout;
