/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { TabbedForm as RaTabbedForm } from 'react-admin';

import { StyledTabbedLayout } from './TabbedShowLayout';

const TabbedForm = (props) => (
  <StyledTabbedLayout>
    <RaTabbedForm {...props} />
  </StyledTabbedLayout>
);

export default TabbedForm;
