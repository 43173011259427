// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import {
  TextField,
  ReferenceField,
  DateField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import ApplicationButtons from '../ApplicationButtons';

import PeerReviewModalButton from '../../peerReview/PeerReviewModalButton';

import useCanEditOrder from '../../order/useCanEdit';

const ApplicationDetailsGroup = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const canEditOrder = useCanEditOrder();

  const pendingReview = !!record.pendingPeerReviewRequest;

  const applicationAction = (pendingReview ? <PeerReviewModalButton /> : <ApplicationButtons />);

  const hasReason = !!record.decisionReason && record.decisionReason.length > 0;
  const hasPartnerDecision = !!record.partnerDecision;

  return (
    <FieldsGroup
      title={t('Application details')}
      actions={applicationAction}
    >
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="applicationStep" label={t('Application step')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Order reference number')}
              source="resourceId"
              reference="order"
              resource="/order"
              link={canEditOrder ? 'edit' : 'show'}
            >
              <TextField source="orderReferenceNumber" />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Order amount')}
              source="resourceId"
              reference="order"
              resource="/order"
              link={false}
            >
              <CurrencyField source="orderAmount" currencySource="currency" />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="currentDecision" label={t('Current decision')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="issueDate" label={t('Invoice issue date')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="dueDate" label={t('Invoice due date')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        {hasReason
          && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="decisionReason" label={t('Current decision reason')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>
          )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="createdAt" label={t('Creation date')} />
          </SimpleShowLabeled>
        </Grid>
        {hasPartnerDecision
          && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="partnerDecision" label={t('Moank decision')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>
          )}
      </Grid>
    </FieldsGroup>
  );
};

export default ApplicationDetailsGroup;
