import React from 'react';
import PropTypes from 'prop-types';

import {
  ArrayField,
  DateField,
  usePermissions,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../ConstantsContext';

import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import CurrencyField from '../utils/CurrencyField';

import hasUserPermissions from '../../utils/hasUserPermissions';

import Datagrid from '../designSystem/react-admin/Datagrid';
import TitleH4 from '../designSystem/TitleH4';

import InvoiceSubActions from './InvoiceSubActions';

const InvoiceExpand = ({
  currency,
  accountType,
  isCreditor,
}) => {
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const { t } = useTranslation();

  const { accountTypes } = constants;

  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canEditInvoices = hasUserPermissions(
    userAllowedPermissions,
    constants.userActions.MANAGE_INVOICES,
  );

  return (
    <>
      <TitleH4 title={t('Reminders')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled sx={{ minWidth: '30rem' }}>
        <ArrayField source="dunning" label="" sx={{ minWidth: '30rem' }}>
          <Datagrid variant="blueBackground" bulkActionButtons={false} rightAlignedColumns={['fee\\.amount', 'subActions']}>
            <DateField source="issueDate" label={t('Issue date')} />
            <DateField source="expiryDate" label={t('Due date')} emptyText="-" />
            <DateField source="sentAt" label={t('Sent at')} emptyText="-" />
            <CurrencyField source="fee.amount" currency={currency} label={t('Amount')} />
            {isCreditor && canEditInvoices
            && (
            <WrapperField label={false} source="subActions">
              <InvoiceSubActions accountType={accountType} isCreditor={isCreditor} isReminderOrCreditNote />
            </WrapperField>
            )}
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
      {
        (accountType === accountTypes.ORDER || accountType === accountTypes.LOAN) && (
          <>
            <TitleH4 title={t('Credit Notes')} margin="1.2rem 0 -0.3rem" />
            <SimpleShowLabeled style={{ minWidth: '30rem' }}>
              <ArrayField source="creditInvoices" label="">
                <Datagrid variant="blueBackground" bulkActionButtons={false} rightAlignedColumns={['amount', 'subActions']}>
                  <DateField source="date" label={t('Issue date')} />
                  <DateField source="sentAt" label={t('Sent at')} emptyText="-" />
                  <CurrencyField source="amount" currency={currency} label={t('Amount')} />
                  {isCreditor && canEditInvoices
                  && (
                  <WrapperField label={false} source="subActions">
                    <InvoiceSubActions accountType={accountType} isCreditor={isCreditor} isReminderOrCreditNote />
                  </WrapperField>
                  )}
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
          </>
        )
      }
    </>
  );
};

InvoiceExpand.propTypes = {
  currency: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  isCreditor: PropTypes.bool.isRequired,
};

export default InvoiceExpand;
