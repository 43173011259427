import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  useListContext,
  useUnselectAll,
} from 'react-admin';
import {
  Box,
  Tab,
  Tabs,
} from '@mui/material';
import { ChildrenProp } from '../../utils/helpers/propTypes';

import Datagrid from '../designSystem/react-admin/Datagrid';

const PREFIX = 'TabbedDatagrid';

const classes = {
  tabsLine: `${PREFIX}-tabsLine`,
  separator: `${PREFIX}-separator`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  position: 'relative',
  [`& .${classes.tabsLine}`]: { borderBottom: '1px solid lightgray' },
  [`& .${classes.separator}`]: { marginTop: '1.5rem' },
});

const TabbedDatagrid = ({
  children,
  tabs,
  filterField,
  rowStyle,
  perStep,
  bulkActionButtons,
  resource,
  unselectOnTabChange,
  rightAlignedColumns,
  sx,
}) => {
  const {
    setFilters,
    filterValues,
    displayedFilters,
    loading,
  } = useListContext();

  const currentFilter = filterValues[filterField];
  const [selectedFilterTab, setSelectedFilterTab] = useState(currentFilter);
  useEffect(() => {
    setSelectedFilterTab(currentFilter);
  }, [currentFilter]);

  const unselectAll = useUnselectAll();

  const onChangeTab = useCallback((e, value) => {
    if (unselectOnTabChange) {
      unselectAll(resource);
    }
    setFilters({
      ...filterValues,
      [filterField]: value,
    }, displayedFilters);
    setSelectedFilterTab(value);
  }, [
    unselectOnTabChange,
    setFilters,
    filterValues,
    filterField,
    displayedFilters,
    unselectAll,
    resource,
  ]);

  const fullyLoaded = !loading && (selectedFilterTab === currentFilter);

  return (
    <Root>
      <Box className={classes.tabsLine}>
        <Tabs
          variant="fullWidth"
          centered
          value={selectedFilterTab}
          onChange={onChangeTab}
        >
          {tabs.map(({ value, label }) => (
            <Tab
              key={value}
              value={value}
              label={`${label} (${perStep[value]})`}
            />
          ))}
        </Tabs>
      </Box>
      {fullyLoaded && (
        <Datagrid
          className={classes.separator}
          rowStyle={rowStyle}
          bulkActionButtons={bulkActionButtons}
          rightAlignedColumns={rightAlignedColumns}
          sx={{
            ...(bulkActionButtons ? {
              '& > div:first-of-type': {
                position: 'absolute',
                width: '100%',
                top: 0,
              },
            } : {}),
            ...sx,
          }}
        >
          {children}
        </Datagrid>
      )}
    </Root>
  );
};

TabbedDatagrid.propTypes = {
  children: ChildrenProp.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  filterField: PropTypes.string.isRequired,
  perStep: PropTypes.shape({}).isRequired,
  rowStyle: PropTypes.func,
  bulkActionButtons: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  resource: PropTypes.string,
  unselectOnTabChange: PropTypes.bool,
  rightAlignedColumns: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.shape({}),
};

TabbedDatagrid.defaultProps = {
  rowStyle: () => { },
  bulkActionButtons: false,
  resource: '',
  unselectOnTabChange: false,
  rightAlignedColumns: [],
  sx: {},
};

export default TabbedDatagrid;
