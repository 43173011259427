import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { required, useRecordContext } from 'react-admin';
import InputsGroup from '../designSystem/InputsGroup';
import { EditModalButton } from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import { positiveNumberValidation } from '../../utils/validationErrors';
import { useConstantContext } from '../ConstantsContext';
import useCustomRpc from '../hooks/useCustomRpc';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const EditEntityGroupButton = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const record = useRecordContext();
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_COMPANY_GROUPS,
  );

  const {
    mutate: updateEntityGroup,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `entity-group/${record.id}`,
    shouldRefresh: true,
    errorMessage: t('Could not update the entity group'),
  });

  const onSubmit = useCallback((data) => {
    updateEntityGroup(data);
    return true;
  }, [updateEntityGroup]);

  if (!canEdit) return null;

  return (
    <EditModalButton
      modalTitle={t('Update terms')}
      onClick={onSubmit}
      width="30rem"
      disabled={isLoading}
      formDefaultValue={record}
    >
      <InputsGroup>
        <TextInput
          source="groupName"
          label={t('Group name')}
          validate={[required()]}
        />
        <NumberInput
          label={t('Credit limit of group')}
          source="creditLimit"
          validate={[positiveNumberValidation, required()]}
        />
      </InputsGroup>
    </EditModalButton>
  );
};

export default EditEntityGroupButton;
