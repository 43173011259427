import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TitleH1 from '../designSystem/TitleH1';

export const getOrderDraftTitle = (record) => (record?.orderReferenceNumber ? record.orderReferenceNumber : '');

const OrderDraftTitle = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const title = record?.orderReferenceNumber ? `${t('Order draft')}: ${getOrderDraftTitle(record)}` : t('Order draft');
  return <TitleH1 title={title} />;
};

export default OrderDraftTitle;
