import React from 'react';
import {
  TextField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';
import TransactionField from '../utils/TransactionField';

import { getEntityIdFromToken, getEntityNameFromToken } from '../../utils/getValueFromToken';

import { useConstantContext } from '../ConstantsContext';

import ReferenceField, { getLink } from '../designSystem/react-admin/ReferenceField';
import List from '../designSystem/react-admin/list/List';
import DateField from '../designSystem/react-admin/DateField';
import useTransactionFilters from './useTransactionFilters';
import TextInputWithLens from '../designSystem/react-admin/inputs/TextInputWithLens';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import TitleH1 from '../designSystem/TitleH1';

export const getTransactionListTitle = (t) => t('Payments');

const PaymentReference = () => {
  const record = useRecordContext();
  const { type: paymentType } = record;
  const { constants: { transactionTypes } } = useConstantContext();

  const source = paymentType === transactionTypes.USER_PAYMENT
    ? 'payinDetails.reference'
    : 'paymentDetails.payoutReference';

  return (
    <TextField source={source} emptyText="-" />
  );
};

const getLinkToAccount = (isNotAllowedToRedirectToOrder, constants) => (_id, _resource, record) => {
  const { accountType, accountId } = record;

  const { accountTypes, entityRoles } = constants;
  if (!accountTypes || !entityRoles) return null;

  const mapLinkToAccountType = {
    [accountTypes.SETTLEMENT]: `/settlement-account/${accountId}/show`,
    [accountTypes.ORDER]: isNotAllowedToRedirectToOrder ? undefined : `/order/${accountId}`,
    [accountTypes.BILLING]: undefined,
    [accountTypes.FACTORING]: undefined,
    [accountTypes.LOAN]: `/loan/${accountId}`,
    [accountTypes.UNIDENTIFIED]: `/unidentified-account/${accountId}`,
  };

  return mapLinkToAccountType[accountType];
};

const TransactionPayerPayee = () => {
  const record = useRecordContext();
  const { id, entityRoles, entityDetailsId } = record;

  const entityId = getEntityIdFromToken();
  const entityName = getEntityNameFromToken();
  const { constants } = useConstantContext();

  const link = getLink(entityDetailsId, entityRoles, constants.entityRoles);

  return (id === entityId) ? <TextField source="entityName" record={{ entityName }} /> : (
    <ReferenceField
      referenceSource="entityDetailsId"
      reference="entity-v2"
      link={link}
      source="mainAddress.name"
      FieldComponent={TextField}
    />
  );
};

const TransactionList = () => {
  const { constants } = useConstantContext();
  const entityId = getEntityIdFromToken();
  const { t } = useTranslation();

  const { accountTypes, entityRoles, userActions } = constants;

  const transactionFilters = useTransactionFilters();

  const canEditOrder = useHasUserPermissions(
    constants,
    userActions.CREATE_ORDERS,
  );
  const canViewOrder = useHasUserPermissions(
    constants,
    userActions.VIEW_ORDERS,
  );
  const isNotAllowedToRedirectToOrder = !canEditOrder && !canViewOrder;

  if (!accountTypes || !entityRoles) return null;

  return (
    <List
      mainFilterInputs={[<TextInputWithLens placeholder={t('Reference')} source="custom_reference" />]}
      subFilterInputs={transactionFilters}
      empty={false}
      title={<TitleH1 title={getTransactionListTitle(t)} />}
      sx={{ width: '100%', padding: '0.9rem' }}
      resource="transaction"
      disableSyncWithLocation
      sort={{ field: 'date', order: 'DESC' }}
      appLocation="home.payment"
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick={getLinkToAccount(isNotAllowedToRedirectToOrder, constants)}
        rightAlignedColumns={['amount']}
      >
        <TransactionField label={t('Type')} source="type" choicesSource="transactionTypes" />
        <ReferenceField
          label={t('Payer')}
          referenceSource="payer"
          source="payer" // Not used by FieldComponent but used for the column class name
          reference="entity-v2/reference"
          link={({ payer }) => (payer === entityId ? `/my-company/${payer}` : false)}
          FieldComponent={TransactionPayerPayee}
        />
        <ReferenceField
          label={t('Payee')}
          referenceSource="payee"
          source="payee" // Not used by FieldComponent but used for the column class name
          reference="entity-v2/reference"
          link={({ payee }) => (payee === entityId ? `/my-company/${payee}` : false)}
          FieldComponent={TransactionPayerPayee}
        />
        <DateField source="date" label={t('Date')} emptyText="-" />
        <TextField source="bankReferenceId" label={t('Bank reference')} emptyText="-" />
        <PaymentReference label={t('Payment reference')} />
        <CurrencyField source="amount" currencySource="currency" label={t('Amount')} sortable={false} />
      </DatagridConfigurable>
    </List>
  );
};

export default TransactionList;
