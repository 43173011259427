import React from 'react';
import { useTranslation } from 'react-i18next';

import LogListDetails from './LogListDetails';
import List from '../designSystem/react-admin/list/List';
import useLogFilters from './useLogFilters';
import TitleH1 from '../designSystem/TitleH1';

export const getLogsListTitle = (t) => t('Activity history');

const LogsList = ({ resourceId }) => {
  const logFilters = useLogFilters(resourceId);
  const { t } = useTranslation();

  return (
    <List
      subFilterInputs={logFilters}
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
      disableSyncWithLocation
      resource="logs"
      filter={resourceId ? { resourceId } : {}}
      title={resourceId ? <></> : <TitleH1 title={getLogsListTitle(t)} />}
      appLocation={resourceId ? undefined : 'home.logs'}
    >
      <LogListDetails resourceId={resourceId} />
    </List>
  );
};

LogsList.propTypes = LogListDetails.propTypes;
LogsList.defaultProps = LogListDetails.defaultProps;

export default LogsList;
