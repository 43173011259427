import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceField,
  FunctionField,
  DateField,
  useRecordContext,
  ReferenceOneField,
  useRedirect,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import ModalButton from '../utils/ModalButton';
import FieldsGroup from '../utils/OldFieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import ButtonStyles from '../utils/ButtonStyles';
import { getUserIdFromToken } from '../../utils/getValueFromToken';

import useCustomRpc from '../hooks/useCustomRpc';

import { useConstantContext } from '../ConstantsContext';
import PeerReviewPayload from './PeerReviewPayload';

const PeerReviewModalContext = ({ applicationType }) => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { peerReviewStatuses, applicationTypes } = constants;
  const record = useRecordContext();
  const userId = getUserIdFromToken();
  const redirect = useRedirect();

  const redirectToResource = useCallback(({ data }) => {
    if (applicationType === applicationTypes.LOAN && data.resourceId) {
      redirect(`/loan/${data.resourceId}`);
    }
  }, [redirect, applicationType, applicationTypes]);

  const {
    mutate: handlePeerReviewRequest,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `peer-review/${record.id}/handle-peer-review-request`,
    shouldRefresh: true,
    errorMessage: t('Could not update peer review request status'),
    onSuccess: redirectToResource,
  });

  const handleClick = useCallback(({ status }) => {
    handlePeerReviewRequest({ status });
  }, [handlePeerReviewRequest]);

  const isRequestingUser = userId === record.requestingUserId;

  return (
    <ModalButton
      title={t('Peer review')}
      disabled={isLoading}
      right
      withForm
      hideSubmitButton
      hideCloseButton
      formDefaultValue={record}
    >
      <FieldsGroup name={t('Peer review request information')}>
        <SimpleShowLabeled>
          <DateField label={t('Date of request')} source="createdAt" />
        </SimpleShowLabeled>
        <SimpleShowLabeled>
          <ReferenceField
            label={t('Requesting user')}
            source="requestingUserId"
            reference="user"
            link={false}
          >
            <FunctionField render={(userRecord = {}) => `${userRecord.firstName} ${userRecord.lastName} (${userRecord.email})`} />
          </ReferenceField>
        </SimpleShowLabeled>
        <PeerReviewPayload action={record.action} />
      </FieldsGroup>
      {isRequestingUser && (
        <Typography variant="caption" color="error" align="center">
          {t('You are the one requesting this peer review, and therefore can not approve it.')}<br />
          {t('If you want update the request you need to revoke the current one first.')}
        </Typography>
      )}
      <Box display="flex" flexDirection="row">
        <Box margin="1rem 0.5rem ">
          <Button
            onClick={() => handleClick({ status: peerReviewStatuses.APPROVED })}
            variant="contained"
            color="primary"
            size="small"
            sx={ButtonStyles.grantButton}
            disabled={isLoading || isRequestingUser}
          >
            {t('Approve')}
          </Button>
        </Box>
        <Box margin="1rem 0.5rem ">
          <Button
            onClick={() => handleClick({ status: peerReviewStatuses.REJECTED })}
            variant="contained"
            color="primary"
            size="small"
            sx={ButtonStyles.denyButton}
            disabled={isLoading}
          >
            {!isRequestingUser ? t('Reject') : t('Revoke')}
          </Button>
        </Box>
      </Box>
    </ModalButton>
  );
};

PeerReviewModalContext.propTypes = {
  applicationType: PropTypes.string.isRequired,
};

const PeerReviewModalButton = () => {
  const { constants } = useConstantContext();
  const { peerReviewStatuses } = constants;
  const { applicationType } = useRecordContext();

  return (
    <ReferenceOneField
      target="resourceId"
      reference="peer-review"
      filter={{ status: peerReviewStatuses.PENDING }}
      fullWidth
    >
      <PeerReviewModalContext applicationType={applicationType} />
    </ReferenceOneField>
  );
};

export default PeerReviewModalButton;
