import React from 'react';
import Grid from '@mui/material/Grid';
import {
  ArrayField,
  DateField,
  FormTab,
  FunctionField,
  LinearProgress,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useListContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Datagrid from '../../designSystem/react-admin/Datagrid';
import DescriptionField from '../../designSystem/react-admin/DescriptionField';

import FactoringAccountFieldsEditView from '../../factoringAccount/FactoringAccountFields';

import useCanEdit from '../useCanEdit';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import renderTransactionChip from '../../utils/renderTransactionChip';
import renderReference from '../../../utils/renderReference';
import FeeGrid from '../../utils/FeeGrid';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import EmptyData from '../../EmptyData';

const MerchantReference = () => {
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();
  if (isMerchant) return null;

  return (
    <Grid item lg={4} md={6}>
      <SimpleShowLabeled>
        <ReferenceField
          source="debtorId"
          label={t('Merchant')}
          reference="entity-v2/reference"
          link={false}
        >
          <FunctionField render={renderReference} />
        </ReferenceField>
      </SimpleShowLabeled>
    </Grid>
  );
};

const FactoringFields = () => {
  const {
    data,
    isLoading,
  } = useListContext();
  const { t } = useTranslation();
  const isEmpty = Array.isArray(data) && data.length === 0;
  const canEdit = useCanEdit();

  if (isLoading) return <LinearProgress />;

  const record = data[0];
  return (
    isEmpty ? (
      <FieldsGroup><EmptyData /></FieldsGroup>
    ) : (
      <RecordContextProvider value={record}>
        {canEdit
          ? <FactoringAccountFieldsEditView hideOrderReference fullWidth />
          : (
            <>
              <FieldsGroup title={t('General information')}>
                <Grid container>
                  <Grid item lg={4} md={6}>
                    <SimpleShowLabeled><TextField source="creditorName" /></SimpleShowLabeled>
                  </Grid>
                  <MerchantReference />
                  <Grid item lg={4} md={6}>
                    <SimpleShowLabeled><CurrencyField source="balance" currencySource="currency" label={t('Factoring balance')} /></SimpleShowLabeled>
                  </Grid>
                  <Grid item lg={4} md={6}>
                    <SimpleShowLabeled><CurrencyField source="accumulatedInterest" currencySource="currency" label={t('Accumulated variable fee')} /></SimpleShowLabeled>
                  </Grid>
                </Grid>
              </FieldsGroup>
              <FieldsGroup title={t('Fee settings')}>
                <FeeGrid source="feeSettings" label="" />
              </FieldsGroup>
              <FieldsGroup title={t('Transactions')}>
                <SimpleShowLabeled fullWidth>
                  <ArrayField source="transactions" label="">
                    <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount']} cellMaxWidth="unset">
                      <FunctionField
                        label={t('Type')}
                        render={renderTransactionChip}
                      />
                      <DescriptionField source="description" />
                      <DateField source="date" label={t('Date')} emptyText="-" />
                      <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
                    </Datagrid>
                  </ArrayField>
                </SimpleShowLabeled>
              </FieldsGroup>
            </>
          )}
      </RecordContextProvider>
    )
  );
};

const FactoringTab = (props) => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Factoring')} {...props}>
      <ReferenceManyField
        source="id"
        reference="factoring-account"
        target="factoredOrder"
        label=""
      >
        <FactoringFields />
      </ReferenceManyField>
    </FormTab>
  );
};

export default FactoringTab;
