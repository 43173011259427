import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const TitleH4 = ({ title, ...props }) => (
  <Typography
    marginBottom="0.5rem"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    fontSize="15px"
    color="headers"
  >
    {title}
  </Typography>
);

TitleH4.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleH4;
