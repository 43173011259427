import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ArrayInput,
  SelectInput,
  NumberInput,
  required,
  maxValue,
  minValue,
  SimpleFormIterator,
  FormDataConsumer,
  useRecordContext,
  useGetMany,
} from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';

import { feeLengthValidation, strictlyPositiveNumberValidation } from '../../utils/validationErrors';
import roundNumber from '../../utils/roundNumber';

import validateFeeField from '../utils/validateFeeField';
import PercentageInput from '../utils/PercentageInput';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import { useConstantContext } from '../ConstantsContext';

const validateRequired = [required()];
const validateFeeLength = [feeLengthValidation(), ...validateRequired];

const FinancingApplicationTermsForm = ({
  merchantId,
  orderAmount,
  orderCurrency,
  termsFinancingCurrency,
  termsFeeSettings,
}) => {
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();

  const { data: financingInfo, isLoading, error } = useGetMany(
    'entity-v2/financing-info',
    { ids: [merchantId] },
  );

  const record = useRecordContext();
  const percentageAmount = (record.financeAmount / (orderAmount * (record.fxRate || 1))) * 100;

  const financingCurrency = useWatch({ name: 'financingCurrency' });
  const fxRate = useWatch({ name: 'fxRate' }) || 1;
  const feeSettings = useWatch({ name: 'feeSettings' });
  const convertedOrderAmount = useMemo(() => orderAmount * (fxRate > 0 ? fxRate : 1), [
    orderAmount,
    fxRate,
  ]);
  const currentPercentage = getValues('percentageAmount') || percentageAmount;

  const onChangeFinanceAmount = useCallback((e) => {
    setValue('percentageAmount', (e.target.value / convertedOrderAmount) * 100);
  }, [convertedOrderAmount, setValue]);

  const onChangePercentageAmount = useCallback((e) => {
    setValue('financeAmount', (e.target.value / 100) * convertedOrderAmount);
  }, [convertedOrderAmount, setValue]);

  const onChangeFinancingCurrency = useCallback((e) => {
    const inputFinancingCurrency = e.target.value;
    setValue('fxRate', 1);
    setValue('financeAmount', orderAmount * (currentPercentage / 100));
    let feeSettingsCurrency;
    if (termsFinancingCurrency === inputFinancingCurrency) {
      feeSettingsCurrency = [...termsFeeSettings];
    } else {
      feeSettingsCurrency = financingInfo && financingInfo[0].feeSettingsV2
        ? financingInfo[0].feeSettingsV2.filter(
          (fee) => fee.currency === inputFinancingCurrency,
        ) : [];
    }
    setValue('feeSettings', feeSettingsCurrency);
  }, [
    currentPercentage,
    orderAmount,
    setValue,
    financingInfo,
    termsFeeSettings,
    termsFinancingCurrency,
  ]);

  const onChangeFxRate = useCallback((e) => {
    const parsedFxRate = parseFloat(e.target.value) || 1;
    const newOrderAmount = orderAmount * parsedFxRate;
    setValue('financeAmount', newOrderAmount * (currentPercentage / 100));
  }, [orderAmount, currentPercentage, setValue]);

  const { constants, choices } = useConstantContext();
  const {
    rateTypes,
    feeTriggers,
    feeReferenceAmounts,
    dayCountConventions,
    billingMethods,
    currencies,
  } = choices;
  const {
    rateTypes: rateTypesConstants,
    feeTriggers: feeTriggersConstants,
    feeReferenceAmounts: feeReferenceAmountsConstants,
    billingMethods: billingMethodsConstants,
  } = constants;

  const validate = validateFeeField({
    rateTypesConstants,
    feeTriggersConstants,
    feeReferenceAmountsConstants,
    billingMethodsConstants,
  });

  if (isLoading || error) return null;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        columnGap={2}
      >
        <NumberInput
          label={t('Financing Amount')}
          source="financeAmount"
          min={0}
          max={convertedOrderAmount}
          InputProps={{ endAdornment: <InputAdornment position="end">{financingCurrency}</InputAdornment> }}
          InputLabelProps={{ shrink: true }}
          validate={[minValue(0), maxValue(convertedOrderAmount), ...validateRequired]}
          onChange={onChangeFinanceAmount}
        />
        <PercentageInput
          label={`${t('Financing Amount')} (%)`}
          source="percentageAmount"
          defaultValue={percentageAmount}
          options={{ helperText: false }}
          skipValidation
          onChange={onChangePercentageAmount}
        />
        <SelectInput
          margin="none"
          label={t('Financing Currency')}
          source="financingCurrency"
          choices={currencies}
          validate={validateRequired}
          onChange={onChangeFinancingCurrency}
        />
      </Box>
      {financingCurrency !== orderCurrency
        && (
          <SimpleShowLabeled>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              columnGap={2}
              label={t('Financing in other currency')}
              marginTop="0.5rem"
            >
              <NumberInput
                label={t('Financing FX Rate')}
                source="fxRate"
                InputProps={{ endAdornment: <InputAdornment position="end">{orderCurrency} to {financingCurrency}</InputAdornment> }}
                InputLabelProps={{ shrink: true }}
                validate={strictlyPositiveNumberValidation}
                onChange={onChangeFxRate}
              />
              <FormDataConsumer>
                {({ formData }) => !!formData.financeAmount && !!formData.fxRate && (
                  <Box>
                    <Typography variant="caption">{t('Finance amount in order currency')}</Typography>
                    <Typography variant="body1">{roundNumber(formData.financeAmount / formData.fxRate, 4)} {orderCurrency}</Typography>
                  </Box>
                )}
              </FormDataConsumer>
            </Box>
          </SimpleShowLabeled>
        )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {feeSettings.length === 0 && (
        <Box marginBottom="1rem">
          <Typography color="error">{t('No fee setting found for this currency, please add this below or in the merchant details')}</Typography>
        </Box>
        )}
        <ArrayInput source="feeSettings" label={t('Fees')} validate={validateRequired}>
          <SimpleFormIterator validate={validateFeeLength} inline>
            <SelectInput
              margin="none"
              label={t('Rate Type')}
              source="rateType"
              choices={rateTypes}
              validate={validateRequired}
            />
            <SelectInput
              margin="none"
              label={t('Trigger')}
              source="feeTrigger"
              choices={feeTriggers}
              validate={[validate, ...validateRequired]}
            />
            <SelectInput
              margin="none"
              label={t('Billing Method')}
              source="billingMethod"
              choices={billingMethods}
              validate={[validate, ...validateRequired]}
            />
            <SelectInput
              margin="none"
              label={t('Reference Amount')}
              source="feeReferenceAmount"
              choices={feeReferenceAmounts}
              validate={[validate, ...validateRequired]}
            />
            <SelectInput
              margin="none"
              label={t('Day Count Convention')}
              source="dayCountConvention"
              choices={dayCountConventions}
              validate={validate}
            />
            <PercentageInput
              label={t('Fee Percentage')}
              source="percentageValue"
              validate={validateRequired}
            />
            <NumberInput
              label={t('Fee Cap')}
              source="cap.value"
              min={0}
              format={(value) => value && roundNumber(parseFloat(value))}
              validate={[minValue(0)]}
            />
            <PercentageInput
              label={t('VAT Percentage')}
              source="vatPercentage"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </Box>
  );
};

FinancingApplicationTermsForm.propTypes = {
  orderAmount: PropTypes.number.isRequired,
  orderCurrency: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  termsFinancingCurrency: PropTypes.string.isRequired,
  termsFeeSettings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FinancingApplicationTermsForm;
