import React from 'react';
import PropTypes from 'prop-types';
import {
  FunctionField,
  TextField,
  TitlePropType,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import UploadedFileSummary from '../utils/UploadedFileSummary';

import { useConstantContext } from '../ConstantsContext';

import List from '../designSystem/react-admin/list/List';
import DateField from '../designSystem/react-admin/DateField';
import ReferenceField from '../designSystem/react-admin/ReferenceField';
import Datagrid from '../designSystem/react-admin/Datagrid';
import TextInputWithLens from '../designSystem/react-admin/inputs/TextInputWithLens';
import DescriptionField from '../designSystem/react-admin/DescriptionField';

import DropDownMenu from '../../utils/DropDownMenu';
import ProtectedFileFieldButton from '../utils/ProtectedFileFieldButton';
import { getEntityAllowMerchantPaymentToken } from '../../utils/getValueFromToken';

import UploadPaymentFileButton from '../payment/UploadPaymentFileButton';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const FileListActions = ({ fileType }) => {
  const allowMerchantPayment = getEntityAllowMerchantPaymentToken();
  const { constants } = useConstantContext();
  const {
    fileTypes,
    userActions,
  } = constants;
  const allowEntityPayment = useHasUserPermissions(
    constants,
    userActions.MANAGE_PAYMENTS,
  ) || allowMerchantPayment;
  if (isEmpty(fileTypes)) return null;

  if (fileType === fileTypes.INBOUND) {
    if (allowEntityPayment) {
      return <UploadPaymentFileButton />;
    }
  }
  return null;
};

FileListActions.propTypes = {
  fileType: PropTypes.string,
};

FileListActions.defaultProps = {
  fileType: undefined,
};

const FileList = ({
  fileType,
  title,
  resource,
  disableSyncWithLocation,
}) => {
  const { constants } = useConstantContext();
  const { fileTypes, userActions } = constants;
  const { t } = useTranslation();

  const isOutboundFile = fileTypes.OUTBOUND === fileType;

  const canEditOutbound = useHasUserPermissions(
    constants,
    userActions.MANAGE_SETTLEMENT_ACCOUNTS,
  );
  const canViewUsers = useHasUserPermissions(
    constants,
    userActions.VIEW_USERS,
  );
  const canEditUsers = useHasUserPermissions(
    constants,
    userActions.CREATE_USERS,
  );

  let userLink = false;
  if (canViewUsers) userLink = 'show';
  if (canEditUsers) userLink = 'edit';

  return (
    <List
      resource={resource}
      sort={{ field: 'date', order: 'DESC' }}
      actions={<FileListActions fileType={fileType} />}
      title={title}
      mainFilterInputs={[<TextInputWithLens source="custom_originalName" placeholder={t('File name')} />]}
      disableSyncWithLocation={disableSyncWithLocation}
      appLocation={fileType ? undefined : 'home.file'}
    >
      <Datagrid
        bulkActionButtons={false}
        expand={!isOutboundFile ? (
          <UploadedFileSummary
            successLabel={t('Processed')}
            infoLabel={t('Info')}
            warningLabel={t('Warnings')}
            errorLabel={t('Errors')}
          />
        ) : null}
        rightAlignedColumns={['subActions']}
        cellMaxWidth="unset"
      >
        <DateField source="date" label={t('Creation date')} showTime emptyText="-" />
        {!fileType && <TextField source="type" label={t('File type')} emptyText="-" sx={{ textTransform: 'capitalize' }} sortable={false} />}
        <DescriptionField source="originalName" label={t('File name')} emptyText="-" />
        <ReferenceField
          label={t('Uploaded by')}
          referenceSource="adminId"
          source="admin" // Not used by FunctionField but used for column class name
          reference="user"
          resource="/file"
          link={userLink}
          FieldComponent={FunctionField}
          FieldComponentProps={{ render: (record = {}) => `${record.firstName} ${record.lastName}` }}
        />
        {(!isOutboundFile || canEditOutbound) && (
          <WrapperField source="subActions" label={false}>
            <DropDownMenu>
              <ProtectedFileFieldButton label={t('Download')} downloadResource="file" />
            </DropDownMenu>
          </WrapperField>
        )}
      </Datagrid>
    </List>
  );
};

FileList.propTypes = {
  fileType: PropTypes.string,
  title: TitlePropType.isRequired,
  resource: PropTypes.string,
  disableSyncWithLocation: PropTypes.bool,
};

FileList.defaultProps = {
  fileType: undefined,
  resource: undefined,
  disableSyncWithLocation: false,
};

export default FileList;
