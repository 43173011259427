import React from 'react';
import {
  ArrayField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import Datagrid from '../designSystem/react-admin/Datagrid';
import TitleH4 from '../designSystem/TitleH4';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';

import TransactionGrid from './TransactionGrid';
import { useConstantContext } from '../ConstantsContext';

const SettlementAccountCurrencyBreakdown = () => {
  const {
    settlementsInOrderCurrency: breakdown,
    currency,
  } = useRecordContext();
  const { t } = useTranslation();

  const shouldDisplayBreakDown = breakdown
  && breakdown.length > 0
  && (breakdown.length > 1
    || breakdown[0].orderCurrency !== currency);

  if (!shouldDisplayBreakDown) return null;

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <TitleH4 title={t('Currency breakdown')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled height="100%">
        <ArrayField source="settlementsInOrderCurrency" label="">
          <Datagrid
            variant="greyBackgroundWithStretch"
            bulkActionButtons={false}
            sx={{ marginBottom: '0.5rem' }}
            rightAlignedColumns={['convertedSum', 'originalSum']}
          >
            <CurrencyField source="convertedSum" currencySource="orderCurrency" label={t('Order currency')} />
            <FunctionField
              label={t('Financing currency')}
              source="originalSum"
              render={(record) => <CurrencyField customRecord={{ ...record, currency }} source="originalSum" currencySource="currency" />}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
    </Box>
  );
};

const SettlementAccountMerchantBreakdown = () => {
  const { clientInfo } = useConstantContext();
  const {
    currency,
    debtorId,
  } = useRecordContext();
  const { t } = useTranslation();

  const isPartnerSettlement = clientInfo.defaultPartnerFinancer
    && clientInfo.defaultPartnerFinancer === debtorId;

  if (!isPartnerSettlement) return null;

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <TitleH4 title={t('Merchant breakdown')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled>
        <ArrayField source="settlementsInOrderCurrencyPerMerchant" label="">
          <Datagrid
            variant="greyBackground"
            bulkActionButtons={false}
            sx={{ marginBottom: '0.5rem' }}
            rightAlignedColumns={['originalSum']}
          >
            <EntityReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2/merchant"
              link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
            />
            <FunctionField
              label={t('Total amount')}
              source="originalSum"
              render={(record) => <CurrencyField customRecord={{ ...record, currency }} source="originalSum" currencySource="currency" />}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
    </Box>
  );
};

const SettlementAccountBreakdown = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" gap="2rem">
        <SettlementAccountCurrencyBreakdown />
        <SettlementAccountMerchantBreakdown />
      </Box>
      <TitleH4 title={t('Transactions')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled fullWidth>
        <ArrayField source="transactions" label="">
          <TransactionGrid />
        </ArrayField>
      </SimpleShowLabeled>
    </>
  );
};

export default SettlementAccountBreakdown;
