import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  BooleanField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import { AssignLabelButton, DeleteLabelButton } from './BulkOrderButtons';
import CurrencyField from '../utils/CurrencyField';
import ApplicationStatusChip from '../utils/ApplicationStatusChip';
import LabelsChipGroup from '../utils/LabelsChipGroup';

import Datagrid, { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import DateField from '../designSystem/react-admin/DateField';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';

import resources from '../../constants/resources';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import OverdueDaysField from '../utils/OverdueDaysField';

const OrderListFields = ({ canEdit, filter }) => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { pathname } = useLocation();
  const { userActions } = constants;

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );

  const shouldShowMerchantsColumn = !filter.sellerId;
  const shouldShowCustomersColumn = !filter.debtorId;

  const isConfigurable = pathname.includes(resources.ORDER);

  const DatagridComponent = isConfigurable
    ? DatagridConfigurable : Datagrid;

  return (
    <DatagridComponent
      rowClick={canEdit ? 'edit' : 'show'}
      bulkActionButtons={canEdit && isFinancer ? <><AssignLabelButton /><DeleteLabelButton /></> : false}
      omit={['financing.isFinanced', 'hasRecoursed', 'isAccountPaid', 'labels']}
      cellMaxWidth="10rem"
      rightAlignedColumns={['balance', 'applicationStatus']}
    >
      {([
        <TextField source="orderReferenceNumber" label={t('Order reference')} sortable={false} />,
        canViewMerchant && shouldShowMerchantsColumn && (
          <EntityReferenceField
            label={t('Merchant')}
            source="sellerId"
            reference="entity-v2/merchant"
            link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
          />
        ),
        shouldShowCustomersColumn && (
          <EntityReferenceField
            label={t('Customer')}
            source="buyerId"
            reference="entity-v2/customer"
            link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
          />
        ),
        <DateField source="activationDate" label={t('Activation date')} />,
        <DateField source="financing.financedAt" label={t('Financing date')} sortable={false} emptyText={t('Not financed')} />,
        <DateField source="mainInvoiceDueDate" label={t('Due date')} sortable={false} style={{ whiteSpace: 'nowrap' }} />,
        <OverdueDaysField label={t('Overdue')} source="overdueDays" />,
        isFinancer && (
          <WrapperField
            source="labels" // Necessary for hiding the column by default
            label={t('Labels')}
          >
            <LabelsChipGroup />
          </WrapperField>
        ),
        <BooleanField source="hasRecoursed" label={t('Recoursed')} sortable={false} />,
        <CurrencyField source="balance" currencySource="currency" label={t('Balance')} />,
        <ApplicationStatusChip label={t('Appl. status')} source="applicationStatus" sortable={false} subSource="partnerFinancing.partnerDecision" />,
      ]).filter((col) => !!col).map((col) => React.cloneElement(col, { key: col.props.source }))}
    </DatagridComponent>
  );
};

OrderListFields.propTypes = {
  canEdit: PropTypes.bool,
  filter: PropTypes.shape({
    sellerId: PropTypes.string,
    debtorId: PropTypes.string,
  }),
};

OrderListFields.defaultProps = {
  canEdit: undefined,
  filter: {},
};

export default OrderListFields;
