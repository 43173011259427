import React from 'react';
import PropTypes from 'prop-types';

import {
  useRecordContext,
  useReference,
  ResourceContextProvider,
  ReferenceFieldView,
} from 'react-admin';

import get from 'lodash/get';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const EmptiableReferenceField = (props) => {
  const {
    source,
    reference,
    children,
    link,
    ...rest
  } = props;

  const record = useRecordContext(props);
  const id = get(record, source);
  const referenceResult = useReference({
    reference,
    id,
  });

  if (!referenceResult.referenceRecord) return <>{children}</>;

  return (
    <ResourceContextProvider value={reference}>
      <ReferenceFieldView
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...referenceResult}
        resourceLinkPath={link}
        reference={reference}
      >
        {children}
      </ReferenceFieldView>
    </ResourceContextProvider>
  );
};

EmptiableReferenceField.propTypes = {
  reference: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  children: ChildrenProp,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
};

EmptiableReferenceField.defaultProps = {
  children: undefined,
  link: 'edit',
};

export default EmptiableReferenceField;
