import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const TitleH5 = ({ title, ...rest }) => (
  <Typography
    marginBottom="0.5rem"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    fontSize="12px"
    color="headers"
  >
    {title}
  </Typography>
);

TitleH5.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default TitleH5;
