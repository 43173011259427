import { useConstantContext } from '../ConstantsContext';
import useEntityType from './useEntityType';
import useHasModule from './useHasModule';

const useUserPermissionsAndRoles = () => {
  const { constants } = useConstantContext();
  const { isFinancer, isMerchant, isOwner } = useEntityType();
  const moduleNames = constants.moduleNames || {};
  const hasFactoring = useHasModule(moduleNames.FACTORING);
  const hasLoan = useHasModule(moduleNames.LOAN);
  const isFactoringFinancer = isFinancer && hasFactoring;
  const isFactoringMerchant = isMerchant && hasFactoring;
  const isLoanFinancer = isFinancer && hasLoan;

  return {
    isFinancer,
    isMerchant,
    isOwner,
    isFactoringFinancer,
    isFactoringMerchant,
    hasFactoring,
    hasLoan,
    isLoanFinancer,
  };
};

export default useUserPermissionsAndRoles;
