import React, { createContext, useMemo, useState } from 'react';
import propTypes from 'prop-types';

export const PartnerSelectionContext = createContext({
  isPartner: false,
  setIsPartner: () => {},
});

const PartnerSelectionContextProvider = ({ children }) => {
  const [isPartner, setIsPartner] = useState(false);
  const partnerValue = useMemo(
    () => ({ isPartner, setIsPartner }),
    [isPartner],
  );

  return (
    <PartnerSelectionContext.Provider value={partnerValue}>
      {children}
    </PartnerSelectionContext.Provider>
  );
};

PartnerSelectionContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default PartnerSelectionContextProvider;
