import React from 'react';

import {
  TextField,
  ReferenceField,
  FunctionField,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import UpdateKycButton from './UpdateKycButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import CurrencyField from '../utils/CurrencyField';

import { useConstantContext } from '../ConstantsContext';

import BooleanField from '../designSystem/react-admin/BooleanField';
import TitleH3 from '../designSystem/TitleH3';
import DateField from '../designSystem/react-admin/DateField';

const renderCreditScore = (record) => {
  if (!record) return '';
  const { creditScore, creditScoreProvider } = record.kyc || {};
  if (!creditScore || !creditScoreProvider) return '-';
  return `${creditScore} (${creditScoreProvider})`;
};
const renderPaymentScore = (record) => {
  if (!record) return '';
  const { paymentScore, paymentScoreProvider } = record.kyc || {};
  if (!paymentScore || !paymentScoreProvider) return '-';
  return `${paymentScore} (${paymentScoreProvider})`;
};

const KycGroup = () => {
  const resource = useResourceContext();
  const { hasFactoring, isFinancer } = useUserPermissionsAndRoles();
  const { t } = useTranslation();
  const { clientInfo } = useConstantContext();

  if (!hasFactoring || !isFinancer) return null;

  return (
    <ReferenceField
      label={false}
      source="id"
      reference="entity-v2/kyc-info"
      link={false}
    >
      <FieldsGroup
        title={t('Insurance and KYC')}
        actions={<UpdateKycButton entityResource={resource} />}
      >
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField showTime source="lastKycDoneAt" label={t('Last update')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField render={renderCreditScore} label={t('Credit score')} />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField render={renderPaymentScore} label={t('Payment score')} />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField source="kyc.insuranceStartDate" label={t('Insurance start date')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField source="kyc.insuranceEndDate" label={t('Insurance end date')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="kyc.financerScore" label={t('Financer score')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="kyc.expectedVolume" label={t('Expected monthly order volume')} emptyText="-" currency={clientInfo.referenceCurrency} />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <Box margin="3.5rem 0rem">
          <TitleH3 title={t('Rating')} />
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="kyc.ratingProvider" label={t('Rating provider')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>

            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="kyc.rating" label={t('Rating')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>

            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="kyc.creditQualityStep" label={t('Credit quality step (1-6)')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column" margin="1rem 0rem">
          <BooleanField source="kyc.nonInsurableCompany" label={t('Non-insurable company')} />
          <BooleanField source="kyc.financialInstitution" label={t('Financial institution')} />
        </Box>
      </FieldsGroup>
    </ReferenceField>
  );
};

export default KycGroup;
