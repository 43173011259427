import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  FormTab,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';

import ModalButton from '../designSystem/ModalButton';
import TooltipBox from '../designSystem/TooltipBox';

import FieldsGroup from '../utils/FieldsGroup';

import InputRule from './InputRule';

import resources from '../../constants/resources';

const mapResource = (
  id,
) => ({
  [resources.MERCHANT]: {
    path: `entity-v2/${id}/edit-rules`,
    errorMessage: t('Could not update the decision rules for the merchant'),
  },
  [resources.MY_COMPANY]: {
    path: 'my-company/edit-rules',
    errorMessage: t('Could not update the decision rules in the my company page'),
  },
});

const DecisionRuleTab = ({ location, ...props }) => {
  const { id } = useRecordContext();
  const { constants } = useConstantContext();
  const { userActions, decisionRules } = constants;
  const notify = useNotify();
  const { getValues } = useFormContext();
  const { isMerchant } = useUserPermissionsAndRoles();
  const theme = useTheme();

  const showNotification = useCallback(() => {
    notify(t('Rules updated'), {
      autoHideDuration: 5000,
    });
  }, [notify]);

  const {
    path,
    errorMessage,
  } = mapResource(
    id,
  )[location];

  const {
    mutate: updateRules,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path,
    errorMessage,
    onSuccess: showNotification,
  });

  const handleUpdateRules = useCallback(() => {
    const {
      financingDecisionRulesV2,
    } = getValues();
    updateRules({ financingDecisionRulesV2 });
    return true;
  }, [getValues, updateRules]);

  const canEdit = useHasUserPermissions(
    constants,
    userActions.EDIT_CREDIT_RULES,
  );

  if (isMerchant) return null;

  return (
    <FormTab
      label={t('Credit rules')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FieldsGroup
        title={t('Credit rules: Factoring')}
      >
        <Box
          display="flex"
          marginY="0.63rem"
          flexDirection="column"
          marginBottom="0.63rem"
        >
          <Typography width="42rem">{t('Toggle to activate rules. Any order that trigger one or more rules will fail automatic')}</Typography>
          <Typography width="42rem">{t('granting and be sent to the application page.')}</Typography>
          {location === resources.MERCHANT && <Typography width="42rem">{t('Credit rule set on ‘My company’ level will be overrided as default.')}</Typography>}
          <Grid container paddingTop="1.88rem" paddingBottom="1.25rem" marginTop="0.63rem">
            <Grid
              item
              lg={3}
              md={6}
            >
              <TooltipBox
                tooltip={t('Switch to blue toggle to activate rules. Any financial request that trigger one or more rules will fail automatic granting and be sent for referral')}
              >
                <Typography alignSelf="center">{t('Activate rule')}</Typography>
              </TooltipBox>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
            />
            <Grid
              item
              lg={3}
              md={6}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignContent="center"
            >
              <Typography alignSelf="center">{t('Settings')}</Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
            >
              <TooltipBox
                tooltip={t('When the strict rule is activated, any orders that meet one or more rule conditions will be marked as "Denied"')}
                justifyContent="flex-end"
              >
                <Typography alignSelf="center">{t('Strictly deny')}</Typography>
              </TooltipBox>
            </Grid>
          </Grid>
          <FieldsGroup
            title={t('Order')}
            fillAvailable
          >
            <InputRule ruleSet="financingDecisionRulesV2" rule={decisionRules.REQUIRE_MANUAL_DECISION} disableStrictlyDeny />
            <InputRule ruleSet="financingDecisionRulesV2" rule={decisionRules.KYC} disableStrictlyDeny />
            <InputRule ruleSet="financingDecisionRulesV2" rule={decisionRules.HAS_OVERDUE_INVOICES} overdueDaysSetting />
            <InputRule ruleSet="financingDecisionRulesV2" rule={decisionRules.ORDER_HAS_NOT_EXPIRED} maxExpiringDaysSetting />
            <InputRule ruleSet="financingDecisionRulesV2" rule={decisionRules.LARGE_REPAYMENT_PERIOD} repaymentPeriodSetting />
            <InputRule ruleSet="financingDecisionRulesV2" rule={decisionRules.OTHER_CURRENCY_FINANCING} disableStrictlyDeny />
          </FieldsGroup>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingX="1rem"
        >
          <ModalButton
            modalTitle={location === resources.MERCHANT ? t('Override global settings') : t('Global credit rule setting')}
            openButtonLabel={t('Save')}
            onClick={handleUpdateRules}
            disabled={isLoading || !canEdit}
            sx={{
              padding: '0.5rem 2rem',
              backgroundColor: theme.palette.secondary.main,
              ':hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            {location === resources.MERCHANT
              ? (
                <Box>
                  <Typography width="30rem">{t('The settings on this page will override the global settings from the "My Company" page for this merchant.')}</Typography>
                  <Typography width="30rem">{t('Once saved, changes to the global settings will not affect this merchant.')}</Typography>
                </Box>
              ) : (
                <Typography width="27rem">{t('This setting applies to all merchants who don\'t have individual rules set up on their own pages.')}</Typography>
              )}
          </ModalButton>
        </Box>
      </FieldsGroup>
    </FormTab>
  );
};

DecisionRuleTab.propTypes = {
  location: PropTypes.string.isRequired,
};

export default DecisionRuleTab;
