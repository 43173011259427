import React from 'react';
import { Loading, useRecordContext } from 'react-admin';
import propTypes from 'prop-types';

import { startCase, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../utils/validators';

import { useConstantContext } from '../ConstantsContext';

import StatusChip, { colors } from '../designSystem/StatusChip';

const handlePendingStatus = (record, t, isBillingAccount) => {
  const {
    sentAt,
    pdfGeneratedAt,
    emailSentAt,
  } = record;
  let color = colors.orange;
  let label;

  if (!sentAt && pdfGeneratedAt) {
    color = colors.gray;
    label = t('Created');
  } else if (!sentAt && !pdfGeneratedAt) {
    color = colors.red;
    label = t('Missing data');
  } else if (sentAt && pdfGeneratedAt) {
    label = t('Sent');
    if (!emailSentAt && isBillingAccount) {
      label = t('Externally sent');
    }
  } else if (sentAt && !pdfGeneratedAt) {
    label = t('Externally sent');
  }
  return { chipColor: color, chipLabel: label };
};

const InvoiceStatusChip = ({ accountType }) => {
  const record = useRecordContext();
  const { status } = record;
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { invoiceStatuses, accountTypes } = constants;
  const isBillingAccount = accountType === accountTypes.BILLING;

  if (isEmpty(invoiceStatuses)) return <Loading />;
  if (!isDefined(status)) return null;

  let chipColor;
  let chipLabel;

  switch (status) {
    case invoiceStatuses.PAID:
      chipColor = colors.green;
      break;
    case invoiceStatuses.PENDING:
      ({ chipColor, chipLabel } = handlePendingStatus(record, t, isBillingAccount));
      break;
    case invoiceStatuses.LATE:
      chipColor = colors.red;
      break;
    default:
      throw new Error(`Invalid invoice status ${status} for account ${record._id}`);
  }

  return (
    <StatusChip
      label={chipLabel || startCase(status)}
      chipColor={chipColor}
      fontWeight="600"
      width="6.25rem"
      height="1.44rem"
    />
  );
};

InvoiceStatusChip.propTypes = {
  accountType: propTypes.string,
};

InvoiceStatusChip.defaultProps = {
  accountType: undefined,
};

export default InvoiceStatusChip;
