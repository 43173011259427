import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
  // if you're using a language detector, do not define the lng option
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    returnEmptyString: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: false,
    pluralSeparator: '@',
  });

export default i18n;
