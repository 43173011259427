import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import { useStatistics } from '../StatisticsContext';

import TitleH4 from '../../designSystem/TitleH4';

import { CurrentExposureKpi } from '../KpiContainer';

import RefreshExposureKPIButton from '../../entityv2/RefreshExposureKPIButton';
import { getEntityIdFromToken } from '../../../utils/getValueFromToken';

const CurrentExposureKpis = () => {
  const { statisticsData } = useStatistics();
  const entityCoreId = getEntityIdFromToken();
  const { t } = useTranslation();

  const {
    currency,
    kpis: {
      outstandingInvoicedAmount,
      outstandingFinancedAmount,
      overdueAmount,
    },
  } = statisticsData;

  const overdueAmountRatio = outstandingFinancedAmount
    ? overdueAmount / outstandingFinancedAmount
    : null;

  return (
    <Box display="flex" flexDirection="column" marginBottom="2rem">
      <TitleH4 title={t('Overview')} />
      <Grid
        container
        sx={{
          '@media': {
            maxWidth: 'calc(100% + 1rem)',
          },
        }}
      >
        <Grid item lg={4}><CurrentExposureKpi value={outstandingInvoicedAmount} label={t('Outstanding invoiced')} currency={currency} /></Grid>
        <Grid item lg={4}><CurrentExposureKpi value={outstandingFinancedAmount} label={t('Outstanding financed')} currency={currency} /></Grid>
        <Grid item lg={4}>
          <CurrentExposureKpi
            value={overdueAmount}
            label={t('Overdue amount')}
            currency={currency}
            subValue={overdueAmountRatio}
            subLabel={t('of outstanding financing')}
            isRatio
          />
        </Grid>
      </Grid>
      <RefreshExposureKPIButton entityCoreId={entityCoreId} />
    </Box>
  );
};

export default CurrentExposureKpis;
