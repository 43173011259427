import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InputAdornment } from '@mui/material';

import useActionsAvailableToModuleAndRoleType from './useActionsAvailableToModuleAndRoleType';
import useActionNames from './ActionsNames';
import { positiveNumberValidation } from '../../utils/validationErrors';

import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import { useConstantContext } from '../ConstantsContext';

const InputAction = ({
  action,
  actionsToEnable,
  actionsToDisable,
  isPeerReviewable,
  canBeLimited,
}) => {
  const { t } = useTranslation();
  const { clientInfo } = useConstantContext();
  const actionNames = useActionNames();
  const { setValue, getValues } = useFormContext();
  const switchStatus = getValues(`actions.${action}.hasPermission`);

  const setAction = useCallback((event) => {
    const value = event.target.checked;
    if (value) {
      for (let i = 0; i < actionsToEnable.length; i += 1) {
        const currentAction = actionsToEnable[i];
        setValue(`actions.${currentAction}.hasPermission`, true);
        setValue(`actions.${currentAction}.peerReview`, false);
      }
      setValue(`actions.${action}.peerReview`, false);
    } else {
      for (let i = 0; i < actionsToDisable.length; i += 1) {
        const currentAction = actionsToDisable[i];
        setValue(`actions.${currentAction}.hasPermission`, false);
        setValue(`actions.${currentAction}.peerReview`, false);
      }
      setValue(`actions.${action}.peerReview`, false);
      setValue(`actions.${action}.limit`, null);
    }
  }, [actionsToEnable, setValue, actionsToDisable, action]);

  const { field } = useInput({
    source: `actions.${action}.hasPermission`,
    onChange: setAction,
    type: 'checkbox',
  });

  const { field: peerReview } = useInput({
    source: `actions.${action}.peerReview`,
    type: 'checkbox',
  });

  const roleType = useWatch({ name: 'roleType' });
  const isActionAvailable = useActionsAvailableToModuleAndRoleType({
    roleType,
    actions: [action],
  });
  if (!isActionAvailable) {
    if (field.value) setValue(`actions.${action}.hasPermission`, false);
    return null;
  }

  return (
    <Box
      border="0.06rem solid"
      borderRadius="0.625rem"
      flexDirection="column"
      display="flex"
      justifyContent="space-between"
      backgroundColor="white"
      borderColor="#D3D3D3"
      margin="0.5rem"
    >
      <Box
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
      >
        <Typography margin="0.8rem"> {actionNames[action]} </Typography>
        <Box
          flexDirection="row"
          display="flex"
        >
          {canBeLimited && (
            <Box
              marginRight="1rem"
              width="15rem"
              marginTop="0.5rem"
            >
              <NumberInput
                source={`actions.${action}.limit`}
                label={t('Approval limit')}
                validate={positiveNumberValidation}
                InputProps={{ endAdornment: <InputAdornment position="end">{clientInfo.referenceCurrency}</InputAdornment> }}
                disabled={!switchStatus}
              />
            </Box>
          )}
          <FormControlLabel
            control={(
              <Switch
                name={field.name}
                onChange={field.onChange}
                checked={field.value || false}
              />
            )}
            label={switchStatus ? t('Enabled') : t('Disabled')}
            width="8rem"
          />
        </Box>
      </Box>
      {isPeerReviewable && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          margin="0.5rem"
        >
          <FormControlLabel
            control={(
              <Switch
                name={peerReview.name}
                onChange={peerReview.onChange}
                checked={peerReview.value || false}
                disabled={!switchStatus}
              />
            )}
            label={t('Require peer review')}
          />
        </Box>
      )}
    </Box>
  );
};

InputAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionsToEnable: PropTypes.arrayOf(PropTypes.string),
  actionsToDisable: PropTypes.arrayOf(PropTypes.string),
  isPeerReviewable: PropTypes.bool,
  canBeLimited: PropTypes.bool,
};

InputAction.defaultProps = {
  actionsToEnable: [],
  actionsToDisable: [],
  isPeerReviewable: false,
  canBeLimited: false,
};

export default InputAction;
