import React from 'react';
import { useTranslation } from 'react-i18next';

import EntityBillingsTab from '../merchant/tabs/BillingsTab';
import EntitySettlementsTab from '../merchant/tabs/SettlementsTab';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import SummaryTab from './tabs/SummaryTab';

import TabbedForm from '../../designSystem/react-admin/TabbedForm';
import Edit from '../../designSystem/react-admin/Edit';
import TitleH1 from '../../designSystem/TitleH1';

import LabelListTab from '../../label/LabelListTab';

import DecisionRuleTab from '../../decisionRule/DecisionRuleTab';
import resources from '../../../constants/resources';

export const getMyCompanyTitle = (t) => t('My company');

const MyCompany = () => {
  const { isMerchant, isFinancer, hasFactoring } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  return (
    <Edit
      title={<TitleH1 title={getMyCompanyTitle(t)} />}
      appLocation="home.my-company"
      mutationMode="optimistic"
    >
      <TabbedForm toolbar={null} sanitizeEmptyValues>
        <SummaryTab />
        {hasFactoring && isFinancer && <LabelListTab />}
        {hasFactoring && isMerchant && <EntityBillingsTab />}
        {hasFactoring && isMerchant && <EntitySettlementsTab />}
        {hasFactoring && <DecisionRuleTab location={resources.MY_COMPANY} />}
      </TabbedForm>
    </Edit>
  );
};

export default MyCompany;
