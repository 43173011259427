import { usePermissions } from 'react-admin';

import { useConstantContext } from '../ConstantsContext';

const useEntityType = () => {
  const { constants } = useConstantContext();
  const { entityRoles } = constants;
  const { permissions } = usePermissions();
  const { entityRoles: userEntityRoles } = permissions || {};

  if (!userEntityRoles || !entityRoles) {
    return {
      isMerchant: null,
      isFinancer: null,
      isOwner: null,
    };
  }

  return {
    isMerchant: userEntityRoles.includes(entityRoles.MERCHANT),
    isFinancer: userEntityRoles.includes(entityRoles.FINANCER),
    isOwner: userEntityRoles.includes(entityRoles.OWNER),
  };
};

export default useEntityType;
