import React, { useCallback, useState } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { ChromePicker } from 'react-color';

import { useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';

import { getEntityIdFromToken } from '../../utils/getValueFromToken';
import useCustomRpc from '../hooks/useCustomRpc';
import Create from '../designSystem/react-admin/Create';
import TitleH1 from '../designSystem/TitleH1';

const validateRequired = [required()];

export const getLabelCreateTitle = (t) => t('Create label');

const LabelCreate = () => {
  const redirect = useRedirect();
  const companyId = getEntityIdFromToken();
  const resource = useResourceContext();
  const theme = useTheme();
  const [labelColor, setLabelColor] = useState('#3D6FAE');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const { t } = useTranslation();

  const onLabelSuccessfullyCreated = useCallback(() => {
    redirect(`/my-company/${companyId}/1`);
  }, [redirect, companyId]);

  const {
    mutate: createLabel,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: `${resource}`,
    shouldRefresh: true,
    successMessage: t('Label created'),
    errorMessage: t('Could not create label'),
    onSuccess: onLabelSuccessfullyCreated,
  });

  const onSubmit = useCallback((formValues) => {
    const labelDetails = formValues;
    labelDetails.color = labelColor;
    createLabel(labelDetails);
    return true;
  }, [createLabel, labelColor]);

  return (
    <Create
      title={<TitleH1 title={getLabelCreateTitle(t)} />}
      appLocation="home.my-company.create/label"
    >
      <SimpleForm
        onSubmit={onSubmit}
        disabled={isLoading}
      >
        <TextInput label={t('Label name')} source="name" validate={validateRequired} />
        <Chip
          label={t('Pick a colour')}
          onClick={() => setDisplayColorPicker(!displayColorPicker)}
          sx={{
            marginRight: '0.3rem',
            backgroundColor: labelColor,
            color: theme.palette.getContrastText(labelColor),
            borderRadius: 1,
            height: 40,
          }}
        />
        {displayColorPicker && (
          <ChromePicker
            color={labelColor}
            onChangeComplete={(color) => setLabelColor(color.hex)}
            disableAlpha
          />
        )}
      </SimpleForm>
    </Create>
  );
};

export default LabelCreate;
