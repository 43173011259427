import { useAuthState } from 'react-admin';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import useCustomQuery from './hooks/useCustomQuery';
import { ChildrenProp } from '../utils/helpers/propTypes';

const NotificationContext = React.createContext({});

export const NotificationContextProvider = ({ children }) => {
  const { isLoading, authenticated } = useAuthState({});
  const { t } = useTranslation();
  const {
    data: notificationData,
  } = useCustomQuery({
    key: 'count-notifications',
    httpMethod: 'GET',
    path: 'statistics/notifications',
    errorMessage: t('Error when fetching notifications'),
    refetchInterval: 60000,
    enabled: !isLoading && authenticated,
  });

  return (
    <NotificationContext.Provider value={notificationData?.data ?? {}}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

export const useNotifications = () => {
  const notifications = useContext(NotificationContext);
  return notifications;
};
