import React from 'react';
import {
  Tab,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FinancingApplicationTitle from './FinancingApplicationTitle';

import TabbedShowLayout from '../../designSystem/react-admin/TabbedShowLayout';
import Show from '../../designSystem/react-admin/Show';

import ApplicationDetailsGroup from './ApplicationDetailsGroup';
import FinancingApplicationRulesResultsGroup from './FinancingApplicationRulesResultsGroup';
import FinancingApplicationTermsGroup from './FinancingApplicationTermsGroup';
import CustomerDetailsInApplication from './CustomerDetailsInApplication';

import DeliveryConfirmationGroup from '../../order/DeliveryConfirmationGroup';

const FinancingApplicationShowNew = () => {
  const { t } = useTranslation();

  return (
    <Show
      mutationMode="optimistic"
      title={<FinancingApplicationTitle />}
      appLocation="home.financing-application.show"
    >
      <TabbedShowLayout redirect="false">
        <Tab label={t('Summary')}>
          <ApplicationDetailsGroup />
          <CustomerDetailsInApplication />
          <FinancingApplicationRulesResultsGroup />
          <DeliveryConfirmationGroup />
          <FinancingApplicationTermsGroup />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default FinancingApplicationShowNew;
