// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import { FormTab, ReferenceField } from 'react-admin';
import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import ApplicationDetailsGroup from '../../application/financingApplication/ApplicationDetailsGroup';
import CustomerDetailsInApplication from '../../application/financingApplication/CustomerDetailsInApplication';
import DeliveryConfirmationGroup from '../DeliveryConfirmationGroup';
import MerchantDetailsInApplication from '../../application/financingApplication/MerchantDetailsInApplication';
import FinancingApplicationTermsGroup from '../../application/financingApplication/FinancingApplicationTermsGroup';
import FinancingApplicationRulesResultsGroup from '../../application/financingApplication/FinancingApplicationRulesResultsGroup';

const SummaryTab = (props) => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  return (
    <ReferenceField
      label=""
      source="applicationId"
      reference="financing-application"
      resource="/financing-application"
      link={false}
    >
      <FormTab
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        label={t('Application')}
      >
        <ApplicationDetailsGroup />
        <CustomerDetailsInApplication />
        {isFinancer && <MerchantDetailsInApplication />}
        <FinancingApplicationTermsGroup />
        <FinancingApplicationRulesResultsGroup />
        <DeliveryConfirmationGroup orderIdSource="orderId" />
      </FormTab>
    </ReferenceField>
  );
};

export default SummaryTab;
