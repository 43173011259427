import React from 'react';
import Box from '@mui/material/Box';
import { useRecordContext } from 'react-admin';

import DecisionChip from './DecisionChip';

import { useConstantContext } from '../ConstantsContext';

const RulesDecisionChips = () => {
  const record = useRecordContext();
  const { decisionData } = record || {};
  const { constants: { decisionStatuses } } = useConstantContext();

  const decisionsToShow = decisionData.results.filter(({ decision }) => (
    decision === decisionStatuses.REFERRED || decision === decisionStatuses.DENIED
  ));
  return (
    <Box
      display="flex"
      gap="0.5rem"
      maxWidth="15rem"
      flexWrap="wrap"
      padding="0.5rem 0"
    >
      {decisionsToShow.map(({
        decision,
        ruleName,
        id,
        reason,
      }) => (
        <DecisionChip
          decision={decision}
          ruleName={ruleName}
          id={id}
          reason={reason}
        />
      ))}
    </Box>
  );
};

export default RulesDecisionChips;
