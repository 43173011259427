import { useTranslation } from 'react-i18next';

const useActionNames = () => {
  const { t } = useTranslation();
  return ({
    'view-billing-accounts': t('View billing accounts'),
    'manage-billing-accounts': t('Manage billing accounts'),
    'view-settlement-accounts': t('Download files'),
    'manage-settlement-accounts': t('Manage outgoing payments'),
    'view-unidentified-payments': t('View unidentified payments'),
    'manage-unidentified-payments': t('Manage unidentified payments'),
    'create-orders': t('Create & edit orders'),
    'view-orders': t('View orders'),
    'manage-labels': t('Manage labels'),
    'view-labels': t('View labels'),
    'make-returns': t('Add credit note'),
    'add-comments-on-orders': t('Add comments'),
    'manage-order-drafts': t('Manage order drafts'),
    'view-order-drafts': t('View order drafts'),
    'view-customer-applications': t('View customer applications'),
    'edit-customer-applications': t('Create & edit customer applications'),
    'set-customer-KYC': t('Set & update customer KYC'),
    'update-customer-applications-terms': t('Update customer application settings'),
    'take-decision-on-customer-applications': t('Take decision on customer applications'),
    'edit-financing-applications': t('Manage financing applications'),
    'view-financing-applications': t('View financing applications'),
    'update-financing-applications-terms': t('Update financing application terms'),
    'take-financing-decision': t('Take financing decision'),
    'create-company-groups': t('Create & edit entity groups'),
    'view-company-groups': t('View entity groups'),
    'add-comments-on-merchants': t('Add comments'),
    'create-merchants': t('Create & edit merchants'),
    'view-merchants': t('View merchants'),
    'update-company-core-information': t('Update companies core information'),
    'update-merchant-financial-settings': t('Update financial settings of merchants'),
    'view-financial-settings': t('View financial settings of merchants'),
    'set-merchant-KYC': t('Set & update merchant KYC'),
    'add-comments-on-customers': t('Add comments'),
    'create-customers': t('Create & edit customers'),
    'view-customers': t('View customers'),
    'merge-customers': t('Merge customers'),
    'manage-customer-credit-limit-for-merchants': t('Manage customer credit limit'),
    'view-dashboard': t('Refresh dashboard'),
    'view-activity-history': t('View activity history'),
    'view-notifications': t('View notifications'),
    'view-my-company-information': t('View my company information'),
    'edit-my-company-information': t('Manage company information'),
    'create-users': t('Create & manage users'),
    'view-users': t('View users'),
    'manage-user-permissions': t('Manage user permissions'),
    'view-payments': t('View payments'),
    'manage-payments': t('Manage inbound payments'),
    'generate-reports': t('Generate reports'),
    'view-uploaded-files': t('View uploaded files'),
    'view-reports': t('Download generated reports'),
    'view-exchange-rates': t('View exchange rates'),
    'view-loss-reservation': t('View loss reservation'),
    'edit-loss-reservation': t('Create & edit loss reservation'),
    'view-loans': t('View loans'),
    'edit-loans': t('Edit loans'),
    'view-loan-applications': t('View loan applications'),
    'edit-loan-applications': t('Edit loan applications'),
    'manage-invoices': t('Manage invoices'),
    'view-invoices': t('View invoices'),
    'take-loan-decision': t('Take loan decision'),
    'update-loan-applications-terms': t('Update loan application terms'),
    'view-credit-data': t('View external data'),
    'purchase-credit-data': t('Update external data'),
    'edit-credit-rules': t('Edit credit rules'),
    'view-delivery-confirmation': t('View delivery confirmation'),
    'manage-delivery-confirmation': t('Manage delivery confirmation'),
  });
};

export default useActionNames;
