import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ArrayField } from 'react-admin';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChildrenProp } from '../../utils/helpers/propTypes';

// This is a workaround to grab fill-available width in the component
const getFillAvailableBasedOnBrowser = () => {
  const { userAgent } = global.navigator;
  if (userAgent.indexOf('Firefox') > -1) {
    return '-moz-available';
  }
  // This handles WebKit browsers (Chrome, Safari, Opera, Edge)
  if (userAgent.indexOf('Chrome') > -1) {
    return '-webkit-fill-available';
  }

  return 'fill-available';
};

const OldFieldsGroup = ({
  name,
  children,
  width,
  fillAvailable,
  allowOverflow,
  column,
  withBorder,
  actions,
  sx,
}) => {
  const childrenArray = React.Children.toArray(children);
  const hasHeader = (!!actions || !!name);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const expectedColumn = isTablet ? 1 : column;

  const chunk = Math.ceil(childrenArray.length / expectedColumn);

  const paperStyleWidth = fillAvailable ? getFillAvailableBasedOnBrowser() : 'calc(100% - 1.5rem)';

  const paperStyle = {
    padding: withBorder ? '0.75rem' : '',
    margin: withBorder ? '0.75rem' : '',
    ...(hasHeader ? { marginTop: '1rem' } : {}),
    width: paperStyleWidth,
    border: withBorder ? undefined : 'none',
    boxSizing: 'border-box',
  };

  const boxStyle = {
    margin: hasHeader ? '0.5rem 0' : '',
    width: '100%',
  };

  const shouldOverflow = allowOverflow ? 'auto' : 'visible';

  return (
    <Box display="flex" width={width} sx={sx}>
      <Paper style={paperStyle} variant="outlined">
        {name && <Typography variant="overline">{name}</Typography>}
        {actions}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          rowGap={1}
          style={boxStyle}
        >
          {Array.from({ length: expectedColumn }, (_el, index) => (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                width: `calc(100% / ${expectedColumn})`,
                minWidth: '260px',
                overflow: shouldOverflow,
              }}
              key={`box-${index}`}
            >
              {React.Children.map(
                childrenArray.slice(chunk * index, chunk * (index + 1)),
                (el) => {
                  if (!el) return null;
                  if (el.type === OldFieldsGroup) return cloneElement(el, { ...el.props });
                  if (el.type === ArrayField) return cloneElement(el, { ...el.props });
                  return cloneElement(el, { ...el.props });
                },
              )}
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

OldFieldsGroup.propTypes = {
  children: ChildrenProp.isRequired,
  name: PropTypes.string,
  width: PropTypes.string,
  allowOverflow: PropTypes.bool,
  fillAvailable: PropTypes.bool,
  column: PropTypes.number,
  withBorder: PropTypes.bool,
  actions: PropTypes.node,
  sx: PropTypes.shape({}),
};

OldFieldsGroup.defaultProps = {
  name: '',
  width: '100%',
  column: 1,
  withBorder: false,
  actions: null,
  allowOverflow: false,
  fillAvailable: false,
  sx: undefined,
};

export default OldFieldsGroup;
