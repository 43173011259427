import React from 'react';

import CustomerIdentifierFields from './CustomerIdentifierFields';

import ApplicationDetails from './ApplicationDetails';
import CreditSettings from './CreditSettings';
import RequestedCreditSettings from './RequestedCreditSettings';
import KycInformation from './KycInformation';
import Note from './Note';
import CustomerDetails from './CustomerDetails';

const CustomerApplicationFields = () => (
  <>
    <ApplicationDetails />
    <CustomerIdentifierFields />
    <RequestedCreditSettings />
    <KycInformation />
    <CreditSettings />
    <CustomerDetails />
    <Note />
  </>
);

export default CustomerApplicationFields;
