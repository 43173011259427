import {
  Box,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  FilterContext,
  useListContext,
  SelectColumnsButton,
} from 'react-admin';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import Cancel from '@mui/icons-material/Cancel';
import Tune from '@mui/icons-material/Tune';
import { MainFilterForm, SubFilterForm } from './FilterForm';
import FilterButton from './FilterButton';

const listActionIconStyle = { width: '1.5rem', height: '1.5rem' };

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,

  minHeight: 'unset!important', // Mui applies a min height on expand
  '& .MuiAccordionSummary-content': {
    margin: '0!important', // Mui applies a margin on expand
  },
}));

const StyledMainFilterBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.boxes,
  padding: '0.75rem',
  borderRadius: '3.125rem',
  '& .MuiButtonBase-root': {
    borderRadius: '2rem',
    minHeight: '2rem',
  },
}));

const ListAction = ({
  mainFilterInputs,
  subFilterInputs,
  actions,
  configurable,
}) => {
  const {
    filterValues,
    setFilters,
  } = useListContext();
  const { t } = useTranslation();

  const clearFilters = useCallback(() => setFilters({}, {}, false), [setFilters]);
  const numberOfFiltersOn = Object.keys(filterValues).length;

  const [isExpanded, setIsExpanded] = useState(false);
  const onChange = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  let hasHiddenFilters = false;
  const styledSubFilterInputs = subFilterInputs.map((subFilterInput) => {
    const isHidden = !subFilterInput.props.alwaysOn;
    if (!hasHiddenFilters && isHidden) hasHiddenFilters = true;

    return React.cloneElement(
      subFilterInput,
      { InputLabelProps: { sx: { paddingLeft: isHidden ? '2rem' : 0 } } },
    );
  });

  const styledMainFilterInputs = mainFilterInputs.map((mainFilterInput) => React.cloneElement(
    mainFilterInput,
    { fimentoVariant: 'filter', alwaysOn: true },
  ));
  return (
    <Accordion
      sx={{
        backgroundColor: 'inherit',
        boxShadow: 'none',
        marginBottom: '0!important', // Mui adds margin bottom on expand
        width: '100%',
      }}
    >
      <StyledMainFilterBar
        display="flex"
        justifyContent="space-between"
        backgroundColor="white"
        padding="0.75rem"
        borderRadius="3.125rem"
      >
        <Box display="flex" gap="1.25rem" alignItems="center">
          {styledMainFilterInputs.length > 0 && (
            <FilterContext.Provider value={styledMainFilterInputs}>
              <MainFilterForm />
            </FilterContext.Provider>
          )}
          {configurable && (
            <SelectColumnsButton
              variant="blueBackgroundOnHoverContained"
              startIcon={<ViewWeekIcon sx={listActionIconStyle} />}
            />
          )}
          {subFilterInputs && subFilterInputs.length > 0 && (
            <StyledAccordionSummary onClick={onChange}>
              <Button
                variant={isExpanded ? 'blueBackgroundOnHoverContainedActive' : 'blueBackgroundOnHoverContained'}
                startIcon={<Tune sx={listActionIconStyle} />}
              >
                {t('Filters')}
              </Button>
            </StyledAccordionSummary>
          )}
          {numberOfFiltersOn > 0 && (
            <Button
              variant="blueBackgroundOnHoverContained"
              onClick={clearFilters}
              endIcon={<Cancel sx={listActionIconStyle} />}
            >
              {t('Filters')}&nbsp;({numberOfFiltersOn})
            </Button>
          )}
        </Box>
        <Box display="flex" gap="1.25rem" alignItems="center">{actions}</Box>
      </StyledMainFilterBar>
      <AccordionDetails
        sx={{
          marginTop: '1rem',
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '0.625rem',
          width: '100%',
        }}
      >
        <FilterContext.Provider value={styledSubFilterInputs}>
          <SubFilterForm />
          {hasHiddenFilters && <FilterButton />}
        </FilterContext.Provider>
      </AccordionDetails>
    </Accordion>
  );
};

ListAction.propTypes = {
  mainFilterInputs: PropTypes.arrayOf(PropTypes.node),
  subFilterInputs: PropTypes.arrayOf(PropTypes.node),
  actions: PropTypes.node,
  configurable: PropTypes.bool,
};

ListAction.defaultProps = {
  mainFilterInputs: [],
  subFilterInputs: [],
  actions: undefined,
  configurable: false,
};

export default ListAction;
