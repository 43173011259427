import React from 'react';

import {
  TextField,
  SelectField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import { useConstantContext } from '../ConstantsContext';

import ReferenceField, { EntityReferenceField, EntityReferenceFieldComponent } from '../designSystem/react-admin/ReferenceField';

const LoanOverviewGroup = () => {
  const { choices } = useConstantContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Overview')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField // could not use entityReferenceField because of the view financer permissions
              label={t('Financer')}
              referenceSource="creditorId"
              source="creditorId"
              reference="entity-v2/reference"
              link={false}
              FieldComponent={EntityReferenceFieldComponent}
            />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <EntityReferenceField
              label={t('Customer')}
              source="debtorId"
              reference="entity-v2/customer"
              link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
            />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField source="terms.repaymentType" label={t('Repayment type')} emptyText="-" choices={choices.repaymentTypes} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="paymentReference" label={t('Payment reference')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="id" label={t('System ID')} />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default LoanOverviewGroup;
