import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route } from 'react-router-dom';
import {
  Resource,
  CustomRoutes,
  AdminUI,
  usePermissions,
} from 'react-admin';
import { isEmpty } from 'lodash/fp';

import UserIcon from '@mui/icons-material/AccountCircle';
import OutboundPaymentFileIcon from '@mui/icons-material/Receipt';
import NotificationsIcon from '@mui/icons-material/CircleNotificationsSharp';
import MyCompanyIcon from '@mui/icons-material/Apartment';
import { ReactComponent as OrderIcon } from '../../assets/order.svg';
import { ReactComponent as ApplicationIcon } from '../../assets/application.svg';
import { ReactComponent as FinancingIcon } from '../../assets/financing.svg';
import { ReactComponent as EntityIcon } from '../../assets/customer.svg';
import { ReactComponent as GroupsIcon } from '../../assets/entityGroup.svg';
import { ReactComponent as MerchantIcon } from '../../assets/merchant.svg';
import { ReactComponent as AccountIcon } from '../../assets/account.svg';
import { ReactComponent as PaymentIcon } from '../../assets/payment.svg';
import { ReactComponent as UnidentifiedAccountIcon } from '../../assets/unidentified.svg';
import { ReactComponent as ReportIcon } from '../../assets/report.svg';
import { ReactComponent as LogsIcon } from '../../assets/history.svg';
import { ReactComponent as UploadedFilesIcon } from '../../assets/upload.svg';
import { ReactComponent as ExchangeRateIcon } from '../../assets/exchangeRate.svg';
import { ReactComponent as LoanIcon } from '../../assets/loan.svg';
import { ReactComponent as BillingIcon } from '../../assets/billing.svg';

import Dashboard from './dashboards/Dashboard';

import OrderList from './order/OrderList';
import OrderEdit from './order/OrderEdit';
import OrderCreate from './order/OrderCreate';

import FinancingApplicationList from './application/financingApplication/FinancingApplicationList';
import FinancingApplicationEdit from './application/financingApplication/FinancingApplicationEdit';
import FinancingApplicationShow from './application/financingApplication/FinancingApplicationShow';
import FinancingApplicationShowNew from './application/financingApplication/FinancingApplicationShowNew';

import ExchangeRateList from './exchangeRate/ExchangeRateList';

import UnidentifiedAccountEdit from './unidentifiedAccount/UnidentifiedAccountEdit';
import UnidentifiedAccountList from './unidentifiedAccount/UnidentifiedAccountList';

import UserShow from './user/UserShow';
import UserEdit from './user/UserEdit';
import UserCreate from './user/UserCreate';

import OrderDraftList from './orderDraft/OrderDraftList';
import OrderDraftEdit from './orderDraft/OrderDraftEdit';
import OrderDraftShow from './orderDraft/OrderDraftShow';

import Login from './utils/Login';
import CustomLayout from './utils/topToolbar/CustomLayout';

import hasPermissions from '../utils/hasPermissions';
import LogsList from './logs/LogsList';
import NotificationList from './notification/NotificationList';
import UploadedFileList from './file/UploadedFileList';

import CustomerList from './entityv2/customer/List';
import CustomerEdit from './entityv2/customer/Edit';
import CustomerCreate from './entityv2/customer/Create';

import MerchantList from './entityv2/merchant/List';
import MerchantEdit from './entityv2/merchant/Edit';
import MerchantCreate from './entityv2/merchant/Create';

import FactoringAccountList from './factoringAccount/FactoringAccountList';
import FactoringAccountEdit from './factoringAccount/FactoringAccountEdit';

import FinancerList from './entityv2/financer/List';

import MyCompany from './entityv2/my-company/MyCompany';

import CreditInfoCreate from './entityv2/credit-info/Create';
import AssignedCreditInfoCreate from './entityv2/assigned-credit-info/Create';

import CustomerApplicationList from './entityv2/customerApplication/List';
import CustomerApplicationEdit from './entityv2/customerApplication/Edit';

import ReportsList from './reports/ReportsList';

import SettlementAccountList from './settlementAccount/SettlementAccountList';
import SettlementAccountShow from './settlementAccount/SettlementAccountShow';

import { getEntityIdFromToken } from '../utils/getValueFromToken';
import EntityGroupCreate from './entityGroup/EntityGroupCreate';
import EntityGroupEdit from './entityGroup/EntityGroupEdit';
import EntityGroupList from './entityGroup/EntityGroupList';

import LabelShow from './label/LabelShow';
import LabelEdit from './label/LabelEdit';
import LabelCreate from './label/LabelCreate';

import RoleEdit from './role/RoleEdit';
import RoleCreate from './role/RoleCreate';

import LoanList from './loan/LoanList';
import LoanEdit from './loan/LoanEdit';

import LoanApplicationList from './application/loanApplication/LoanApplicationList';
import LoanApplicationEdit from './application/loanApplication/LoanApplicationEdit';
import LoanApplicationCreate from './application/loanApplication/LoanApplicationCreate';

import BillingAccountLists from './billingAccount/BillingAccountLists';

import LossReservationList from './factoringAccount/LossReservationList';

import ResetPasswordPage from './resetPassword/ResetPasswordPage';

import FeatureFlagPage from './featureFlags/FeatureFlagPage';

import { useConstantContext } from './ConstantsContext';

import hasUserPermissions from '../utils/hasUserPermissions';
import ManagerShow from './utils/ManagerShow';
import TransactionListTab from './transaction/TransactionListTab';

import config from '../../config';

import useFeatureFlags from './hooks/useFeatureFlags';

const useGetPermissionSettings = () => {
  const { constants } = useConstantContext();

  return {
    modulesActions: constants.modulesActions,
    userActions: constants.userActions || {},
  };
};

const Resources = () => {
  const { canEditFeatureFlags } = config;
  const authenticationData = usePermissions();
  const { t } = useTranslation();
  const [featureFlags] = useFeatureFlags();
  const {
    entityRoles,
    userAllowedPermissions,
    modules,
  } = authenticationData.permissions || {};
  const {
    modulesActions,
    userActions,
  } = useGetPermissionSettings();
  const { constants, choices } = useConstantContext();

  const permissionWrapper = (component, action) => {
    const value = action
      && !isEmpty(constants) && !isEmpty(choices) && modulesActions.mapActionToModules[action]
      && hasPermissions(modules, modulesActions.mapActionToModules[action])
      && hasUserPermissions(userAllowedPermissions, action)
      ? component : null;
    return value;
  };

  const entityId = getEntityIdFromToken();

  if (!entityRoles) return null;

  return (
    <AdminUI
      dashboard={Dashboard}
      loginPage={Login}
      layout={CustomLayout}
      title={t('Fimento Admin')}
    >
      <Resource name="entity-v2/core" />
      <Resource name="entity-v2/reference" />
      <Resource name="entity-v2/kyc-info" />
      <Resource name="entity-v2/credit-data" />
      <Resource
        name="notification"
        list={permissionWrapper(
          NotificationList,
          userActions.VIEW_NOTIFICATIONS,
        )}
        icon={NotificationsIcon}
        options={{ label: t('Notifications') }}
      />
      <Resource
        name="order"
        list={permissionWrapper(
          OrderList,
          userActions.VIEW_ORDERS,
        )}
        show={permissionWrapper(
          OrderEdit,
          userActions.VIEW_ORDERS,
        )}
        edit={permissionWrapper(
          OrderEdit,
          userActions.CREATE_ORDERS,
        )}
        create={permissionWrapper(
          OrderCreate,
          userActions.CREATE_ORDERS,
        )}
        icon={OrderIcon}
        options={{ label: t('Orders') }}
      />
      <Resource
        name="order-draft"
        list={permissionWrapper(
          OrderDraftList,
          userActions.VIEW_ORDER_DRAFTS,
        )}
        edit={permissionWrapper(
          OrderDraftEdit,
          userActions.MANAGE_ORDER_DRAFTS,
        )}
        show={permissionWrapper(
          OrderDraftShow,
          userActions.VIEW_ORDER_DRAFTS,
        )}
        icon={OrderIcon}
        options={{ label: t('Order drafts') }}
      />
      <Resource
        name="financing-application"
        list={permissionWrapper(
          FinancingApplicationList,
          userActions.VIEW_FINANCING_APPLICATIONS,
        )}
        show={permissionWrapper(
          featureFlags.enableAnalyticsAndRedesign
            ? FinancingApplicationShowNew
            : FinancingApplicationShow,
          userActions.VIEW_FINANCING_APPLICATIONS,
        )}
        edit={permissionWrapper(
          FinancingApplicationEdit,
          userActions.EDIT_FINANCING_APPLICATIONS,
        )}
        icon={ApplicationIcon}
        options={{ label: t('Applications') }}
      />
      <Resource
        name="entity-v2/customer"
        list={permissionWrapper(
          CustomerList,
          userActions.VIEW_CUSTOMERS,
        )}
        edit={permissionWrapper(
          CustomerEdit,
          userActions.VIEW_CUSTOMERS,
        )}
        create={permissionWrapper(
          CustomerCreate,
          userActions.CREATE_CUSTOMERS,
        )}
        icon={EntityIcon}
        options={{ label: t('Customers') }}
      />
      <Resource
        name="loan"
        list={permissionWrapper(
          LoanList,
          userActions.VIEW_LOANS,
        )}
        edit={permissionWrapper(
          LoanEdit,
          userActions.EDIT_LOANS,
        )}
        icon={LoanIcon}
        options={{ label: t('Loans') }}
      />
      <Resource
        name="loan-application"
        list={permissionWrapper(
          LoanApplicationList,
          userActions.VIEW_LOAN_APPLICATIONS,
        )}
        edit={permissionWrapper(
          LoanApplicationEdit,
          userActions.EDIT_LOAN_APPLICATIONS,
        )}
        create={permissionWrapper(
          LoanApplicationCreate,
          userActions.EDIT_LOAN_APPLICATIONS,
        )}
        icon={ApplicationIcon}
        options={{ label: t('Applications') }}
      />
      <Resource
        name="customer-application"
        list={permissionWrapper(
          CustomerApplicationList,
          userActions.VIEW_CUSTOMER_APPLICATIONS,
        )}
        edit={permissionWrapper(
          CustomerApplicationEdit,
          userActions.VIEW_CUSTOMER_APPLICATIONS,
        )}
        icon={ApplicationIcon}
        options={{ label: t('Applications') }}
      />
      <Resource name="account" />
      {featureFlags.enableBillingList && entityRoles.includes('financer') && (
        <Resource
          name="billing-account"
          list={permissionWrapper(
            BillingAccountLists,
            userActions.VIEW_BILLING_ACCOUNTS,
          )}
          icon={BillingIcon}
          options={{ label: t('Billings') }}
        />
      )}
      <Resource
        name="entity-v2/merchant"
        list={permissionWrapper(
          MerchantList,
          userActions.VIEW_MERCHANTS,
        )}
        edit={permissionWrapper(
          MerchantEdit,
          userActions.VIEW_MERCHANTS,
        )}
        create={permissionWrapper(
          MerchantCreate,
          userActions.CREATE_MERCHANTS,
        )}
        icon={MerchantIcon}
        options={{ label: t('Merchants') }}
      />
      {entityRoles.includes('financer') && (
        <Resource
          name="settlement-account"
          list={permissionWrapper(
            SettlementAccountList,
            userActions.VIEW_SETTLEMENT_ACCOUNTS,
          )}
          show={permissionWrapper(
            SettlementAccountShow,
            userActions.VIEW_SETTLEMENT_ACCOUNTS,
          )}
          icon={AccountIcon}
          options={{ label: t('Accounts') }}
        />
      )}
      <Resource
        name="factoring-account"
        list={permissionWrapper(
          FactoringAccountList,
          userActions.VIEW_ORDERS,
        )}
        edit={permissionWrapper(
          FactoringAccountEdit,
          userActions.VIEW_ORDERS,
        )}
        icon={FinancingIcon}
        options={{ label: t('Financings') }}
      />
      <Resource
        name="label"
        show={permissionWrapper(
          LabelShow,
          userActions.VIEW_LABELS,
        )}
        edit={permissionWrapper(
          LabelEdit,
          userActions.MANAGE_LABELS,
        )}
        create={permissionWrapper(
          LabelCreate,
          userActions.MANAGE_LABELS,
        )}
        options={{ label: '' }}
      />
      <Resource
        name="file/outbound-payments"
        icon={OutboundPaymentFileIcon}
        options={{ label: t('Outbound') }}
      />
      <Resource
        name="unidentified-account"
        list={permissionWrapper(
          UnidentifiedAccountList,
          userActions.VIEW_UNIDENTIFIED_PAYMENTS,
        )}
        edit={permissionWrapper(
          UnidentifiedAccountEdit,
          userActions.VIEW_UNIDENTIFIED_PAYMENTS,
        )}
        icon={UnidentifiedAccountIcon}
        options={{ label: t('Unidentified') }}
      />
      <Resource
        name="file"
        list={permissionWrapper(
          UploadedFileList,
          userActions.VIEW_UPLOADED_FILES,
        )}
        icon={UploadedFilesIcon}
        options={{ label: t('Uploaded files') }}
      />
      <Resource
        name="entity-v2/financer"
        list={permissionWrapper(
          FinancerList,
          userActions.VIEW_FINANCERS,
        )}
        icon={EntityIcon}
        options={{ label: t('Financer') }}
      />
      <Resource
        name="entity-v2/credit-info"
        create={permissionWrapper(
          CreditInfoCreate,
          userActions.MANAGE_CUSTOMER_CREDIT_LIMIT_FOR_MERCHANTS,
        )}
        options={{ label: t('Credit limit') }}
      />
      <Resource
        name="entity-v2/assigned-credit-info"
        create={permissionWrapper(
          AssignedCreditInfoCreate,
          userActions.EDIT_CUSTOMER_APPLICATIONS,
        )}
        options={{ label: t('Request credit limit') }}
      />
      <Resource name="entity-v2/financing-info" />
      <Resource name="countries" />
      <Resource name="client" />
      <Resource name="user/public" />
      <Resource
        name="marketplace"
      />
      <Resource
        name="exchangeRate"
        list={permissionWrapper(
          ExchangeRateList,
          userActions.VIEW_EXCHANGE_RATES,
        )}
        icon={ExchangeRateIcon}
        options={{ label: t('Exchange rates') }}
      />
      <Resource
        name="manage-users"
        list={permissionWrapper(
          ManagerShow,
          userActions.VIEW_USERS,
        )}
        icon={UserIcon}
        options={{ label: t('Users') }}
      />
      <Resource
        name="user"
        show={permissionWrapper(
          UserShow,
          userActions.VIEW_USERS,
        )}
        edit={permissionWrapper(
          UserEdit,
          userActions.CREATE_USERS,
        )}
        create={permissionWrapper(
          UserCreate,
          userActions.CREATE_USERS,
        )}
      />
      <Resource
        name="role"
        create={permissionWrapper(
          RoleCreate,
          userActions.MANAGE_USER_PERMISSIONS,
        )}
        edit={permissionWrapper(
          RoleEdit,
          userActions.MANAGE_USER_PERMISSIONS,
        )}
      />
      <Resource
        name="my-company"
        list={permissionWrapper(
          () => <Navigate to={`/my-company/${entityId}`} />,
          userActions.VIEW_MY_COMPANY_INFORMATION,
        )}
        edit={permissionWrapper(
          MyCompany,
          userActions.VIEW_MY_COMPANY_INFORMATION,
        )}
        icon={MyCompanyIcon}
        options={{ label: t('My company') }}
      />
      <Resource
        name="logs"
        list={permissionWrapper(
          LogsList,
          userActions.VIEW_ACTIVITY_HISTORY,
        )}
        icon={LogsIcon}
        options={{ label: t('Activity history') }}
      />
      <Resource
        name="report"
        list={permissionWrapper(
          ReportsList,
          userActions.VIEW_REPORTS,
        )}
        icon={ReportIcon}
        options={{ label: t('Reports') }}
      />
      <Resource name="entity-group/credit-info" />
      <Resource
        name="entity-group"
        list={permissionWrapper(
          EntityGroupList,
          userActions.VIEW_COMPANY_GROUPS,
        )}
        create={
          permissionWrapper(
            EntityGroupCreate,
            userActions.CREATE_COMPANY_GROUPS,
          )
        }
        edit={permissionWrapper(
          EntityGroupEdit,
          userActions.VIEW_COMPANY_GROUPS,
        )}
        icon={GroupsIcon}
        options={{ label: t('Entity groups') }}
      />
      <Resource
        name="payments"
        list={permissionWrapper(
          TransactionListTab,
          userActions.VIEW_PAYMENTS,
        )}
        icon={PaymentIcon}
        options={{ label: t('Payments') }}
      />
      <Resource
        name="loss-reservation"
        list={permissionWrapper(
          LossReservationList,
          userActions.VIEW_LOSS_RESERVATION,
        )}
      />
      <Resource name="peer-review" />
      <CustomRoutes noLayout>
        <Route exact path="/reset-password" element={<ResetPasswordPage />} />,
      </CustomRoutes>
      {canEditFeatureFlags && (
        <CustomRoutes>
          <Route exact path="/feature-flags" element={<FeatureFlagPage />} />
        </CustomRoutes>
      )}
    </AdminUI>
  );
};

export default Resources;
