import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-admin';
import useCustomQuery from './hooks/useCustomQuery';
import { ChildrenProp } from '../utils/helpers/propTypes';

const ConstantContext = React.createContext();

const choiceNormalizer = (array) => array.map((i) => ({ id: i.value, name: i.label }));

export const ConstantContextProvider = ({ children }) => {
  const { isLoading, authenticated } = useAuthState();
  const isFullyAuthenticated = !isLoading && authenticated;

  const [constants, setConstants] = useState({});
  const [choices, setChoices] = useState({});
  const [clientInfo, setClientInfo] = useState({});

  const {
    data: constantsData,
  } = useCustomQuery({
    httpMethod: 'GET',
    path: 'constants',
    key: 'constants',
    enabled: isFullyAuthenticated,
  });

  const {
    data: choicesData,
  } = useCustomQuery({
    httpMethod: 'GET',
    path: 'choices',
    key: 'choices',
    enabled: isFullyAuthenticated,
  });

  const {
    data: clientData,
  } = useCustomQuery({
    httpMethod: 'GET',
    path: 'client',
    key: 'client',
    enabled: isFullyAuthenticated,
  });

  useEffect(() => {
    setConstants(constantsData?.data ?? {});
    setClientInfo(clientData?.data ?? {});
  }, [constantsData?.data, clientData?.data]);

  useEffect(() => {
    const data = choicesData?.data ?? {};
    const normalizedData = Object.keys(data)
      .reduce((acc, key) => ({ ...acc, [key]: choiceNormalizer(data[key]) }), {});
    setChoices(normalizedData);
  }, [choicesData?.data]);

  return (
    <ConstantContext.Provider value={{ constants, choices, clientInfo }}>
      {children}
    </ConstantContext.Provider>
  );
};

ConstantContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

export const useConstantContext = () => {
  const context = useContext(ConstantContext);
  return context;
};
