import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';

import { useWatch, useFormContext } from 'react-hook-form';

import { emailValidation } from '../../utils/validationErrors';
import renderReference from '../../utils/renderReference';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';
import Create from '../designSystem/react-admin/Create';
import TitleH1 from '../designSystem/TitleH1';

const validateRequired = [required()];

export const getUserCreateTitle = (t) => t('Create user');

const FormFields = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const { entityRoles, userActions } = constants;

  const { isOwner } = useUserPermissionsAndRoles();

  const entityId = useWatch({ name: 'entityId' });
  const { setValue } = useFormContext();

  const canCreateUsers = useHasUserPermissions(
    constants,
    userActions.CREATE_USERS,
  );
  if (!canCreateUsers) return null;
  return (
    <>
      {isOwner && (
        <ReferenceInput
          source="entityId"
          reference="entity-v2/reference"
          filter={{ entityRoles: [entityRoles.MERCHANT, entityRoles.FINANCER] }}
        >
          <AutocompleteInput
            label={t('Company')}
            validate={validateRequired}
            optionText={renderReference}
            suggestionLimit={20}
            onChange={() => setValue('roleIds', [])}
          />
        </ReferenceInput>
      )}
      <TextInput source="email" type="email" validate={[emailValidation, ...validateRequired]} />
      <TextInput source="firstName" validate={validateRequired} label={t('First Name')} />
      <TextInput source="lastName" validate={validateRequired} label={t('Last Name')} />
      <ReferenceArrayInput
        source="roleIds"
        reference="role"
        filter={{ entityId }}
      >
        <AutocompleteArrayInput
          variant="filled"
          optionText="name"
          label={t('Role')}
          disabled={isOwner && !entityId}
        />
      </ReferenceArrayInput>
    </>
  );
};

const UserCreate = () => {
  const { t } = useTranslation();
  return (
    <Create
      title={<TitleH1 title={getUserCreateTitle(t)} />}
      appLocation="home.manage-users.create/user"
    >
      <SimpleForm sanitizeEmptyValues>
        <FormFields />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
