import React from 'react';

import {
  TextField,
  ReferenceField,
  FunctionField,
  useRecordContext,
  SelectField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import UpdateCoreInfoButton from './UpdateCoreInfoButton';

import { useConstantContext } from '../ConstantsContext';

const CoreInfoGroupContent = () => {
  const record = useRecordContext();
  const { constants, choices } = useConstantContext();
  const { t } = useTranslation();

  const isBusiness = record.type === constants.coreTypes.BUSINESS;

  const infos = [
    <SimpleShowLabeled sx={{ paddingRight: '0.9rem' }}>
      <ReferenceField
        label={t('System ID')}
        source="id"
        reference="entity-v2/reference"
        link={false}
      >
        <TextField source="id" emptyText="-" sx={{ overflowX: 'auto' }} />
      </ReferenceField>
    </SimpleShowLabeled>,
    <SimpleShowLabeled sx={{ paddingRight: '0.9rem' }}>
      <ReferenceField
        label={t('Alias')}
        source="id"
        reference="entity-v2/reference"
        link={false}
      >
        <TextField source="marketplaceReferenceId" emptyText="-" sx={{ overflowX: 'auto' }} />
      </ReferenceField>
    </SimpleShowLabeled>,
    <SimpleShowLabeled>
      <TextField source="nationalIdentifier" label={isBusiness ? t('Org. number') : t('Social security number')} />
    </SimpleShowLabeled>,
    ...isBusiness
      ? [
        <SimpleShowLabeled>
          <TextField source="vatNumber" label={t('VAT number')} emptyText="-" />
        </SimpleShowLabeled>,
      ] : [],
    <SimpleShowLabeled>
      <ReferenceField
        label={t('Country')}
        source="country"
        reference="countries"
        resource="/countries"
        link={false}
      >
        <FunctionField render={({ id, name }) => (name ? `(${id}) ${name}` : '')} />
      </ReferenceField>
    </SimpleShowLabeled>,
    <SimpleShowLabeled>
      <SelectField label="Business/Consumer" source="type" choices={choices.coreTypes} />
    </SimpleShowLabeled>,
  ];

  return (
    <FieldsGroup
      actions={<UpdateCoreInfoButton />}
      title={t('Core information')}
    >
      <Grid container>
        {React.Children.map(infos, (info) => (
          <Grid item lg={4} md={6}>
            {info}
          </Grid>
        ))}
      </Grid>
    </FieldsGroup>
  );
};

const CoreInfoGroup = () => (
  <ReferenceField
    label={false}
    source="entityCoreId"
    reference="entity-v2/core"
    link={false}
  >
    <CoreInfoGroupContent />
  </ReferenceField>
);

export default CoreInfoGroup;
