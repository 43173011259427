import React from 'react';
import {
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../../ConstantsContext';
import CustomerApplicationFields from './CustomerApplicationFields';

import SimpleForm from '../../designSystem/react-admin/SimpleForm';
import Edit from '../../designSystem/react-admin/Edit';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import TitleH1 from '../../designSystem/TitleH1';

export const getCustomerApplicationEditTitle = (record) => (record && record.debtorInfo && record.debtorInfo.mainAddress ? record.debtorInfo.mainAddress.name : '');

const Title = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Customer application')}: ${getCustomerApplicationEditTitle(record)}`;
  return <TitleH1 title={title} />;
};

const CustomerApplicationEdit = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canViewCustomerApplication = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMER_APPLICATIONS,
  );
  const canEditCustomerApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_CUSTOMER_APPLICATIONS,
  );
  // TODO: Redirect instead
  if (!canViewCustomerApplication) return null;

  return (
    <Edit
      title={<Title />}
      mutationMode="optimistic"
      redirect={false}
      appLocation="home.customer-application.edit"
    >
      <SimpleForm
        toolbar={canEditCustomerApplication ? <Toolbar><SaveButton /></Toolbar> : null}
        sanitizeEmptyValues
      // warnWhenUnsavedChanges
      >
        <CustomerApplicationFields />
      </SimpleForm>
    </Edit>
  );
};

export default CustomerApplicationEdit;
