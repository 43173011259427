import React, { useCallback } from 'react';
import { isEmpty, startCase } from 'lodash';
import {
  TextField,
  EditButton,
  ShowButton,
  List,
  FunctionField,
  ReferenceField,
  BulkDeleteButton,
  DateField,
  useListContext,
  useNotify,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import SafePagination from '../utils/SafePagination';
import { getEntityNameFromToken } from '../../utils/getValueFromToken';
import CurrencyField from '../utils/CurrencyField';
import TabbedDatagrid from '../utils/TabbedDatagrid';

import getOrderDraftStatuses from '../../utils/getOrderDraftStatuses';

import useCustomRpc from '../hooks/useCustomRpc';
import useCustomQuery from '../hooks/useCustomQuery';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import { useDefineLocation } from '../AppLocationContext';
import TitleH1 from '../designSystem/TitleH1';

export const getOrderDraftListTitle = (t) => t('Order drafts');

const ActivateViewsButton = () => {
  const { selectedIds } = useListContext();
  const { t } = useTranslation();
  const notify = useNotify();

  const {
    mutate: activateDraftBatch,
    isLoading,
  } = useCustomRpc({
    path: 'order-draft/activate',
    httpMethod: 'PUT',
    shouldRefresh: true,
    shouldUnselectAll: 'order-draft',
    errorMessage: t('Could not convert the draft(s) into real order(s)'),
    onSuccess: ({ data }) => {
      // TODO: Refresh notification
      const { message } = data;
      if (message) notify(message);
    },
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    activateDraftBatch({ selectedIds });
  }, [activateDraftBatch, selectedIds]);

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="small"
      disabled={isLoading}
      style={{ backgroundColor: 'green', float: 'right' }}
    >
      {t('Convert into order')}
    </Button>
  );
};

const BulkActivateButtons = () => (
  <>
    <ActivateViewsButton />
    <BulkDeleteButton mutationMode="undoable" variant="text" />
  </>
);

const OrderDraftList = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { draftStatuses, userActions } = constants;

  const canEdit = useHasUserPermissions(
    constants,
    userActions.MANAGE_ORDER_DRAFTS,
  );
  useDefineLocation('home.order-draft');

  const tabs = getOrderDraftStatuses(draftStatuses);

  const {
    data,
  } = useCustomQuery({
    key: 'order-drafts-per-step',
    path: 'order-draft/order-drafts-per-step',
    httpMethod: 'GET',
    errorMessage: t('Could not get order drafts per step'),
  });
  const orderDraftsPerStep = data?.data;

  if (isEmpty(orderDraftsPerStep) || isEmpty(draftStatuses)) return null;

  const defaultTab = orderDraftsPerStep.ready === 0
    ? draftStatuses.TO_COMPLETE : draftStatuses.READY;

  return (
    <List
      title={<TitleH1 title={getOrderDraftListTitle(t)} />}
      exporter={false}
      pagination={<SafePagination />}
      perPage={50}
      filterDefaultValues={{ draftStatus: defaultTab }}
    >
      <TabbedDatagrid
        tabs={tabs}
        filterField="draftStatus"
        perStep={orderDraftsPerStep}
        resource="order-draft"
        unselectOnTabChange
        bulkActionButtons={canEdit ? <BulkActivateButtons /> : false}
        rightAlignedColumns={['orderAmount', 'action']}
      >
        <TextField source="orderReferenceNumber" label={t('Order Reference Number')} emptyText="-" />
        <FunctionField label={t('Merchant')} render={getEntityNameFromToken} />
        <TextField source="submitted.customerName" label={t('Customer')} emptyText="-" />
        <DateField source="dueDate" label={t('Due Date')} emptyText="-" />
        <DateField source="activationDate" label={t('Activation Date')} />
        <ReferenceField
          label={t('Created By')}
          source="createdBy"
          reference="user"
          sortable={false}
          link={false}
        >
          <FunctionField render={(user) => startCase(`${user.firstName} ${user.lastName}`)} />
        </ReferenceField>
        <CurrencyField source="orderAmount" currencySource="currency" label={t('Order amount')} emptyText="-" />
        <WrapperField label={false} source="action">
          {canEdit ? <EditButton /> : <ShowButton />}
        </WrapperField>
      </TabbedDatagrid>
    </List>
  );
};

export default OrderDraftList;
