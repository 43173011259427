import React, { useCallback } from 'react';
import { t } from 'i18next';
import { required, useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import { useConstantContext } from '../ConstantsContext';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import ModalButton from '../designSystem/ModalButton';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import InputsGroup from '../designSystem/InputsGroup';

import useCustomRpc from '../hooks/useCustomRpc';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import BooleanField from '../designSystem/react-admin/BooleanField';

const validateFeeAmount = (feeAmount) => {
  if (feeAmount < 0) {
    return t('The reminder fee cannot be a negative number');
  }
  return undefined;
};

const validateTermsOfPayments = (termsOfPayment) => {
  if (!termsOfPayment || termsOfPayment <= 0) {
    return t('The terms of payment must be at least one day');
  }
  return undefined;
};

const RemindInvoiceButton = ({ isCreditor, accountId, shouldSendInvoices }) => {
  const { id, status } = useRecordContext();
  const { choices, constants: { invoiceStatuses } } = useConstantContext();
  const { isMerchant } = useUserPermissionsAndRoles();

  const { emailLayoutTypesReminder, reminderLayoutTypes } = choices;

  const path = `account/${accountId}/invoice/${id}/reminder`;

  const {
    mutate: generateManualReminder,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Could not send reminder'),
  });

  const onSubmit = useCallback(({
    feeAmount,
    termsOfPayment,
    description,
    reminderLayout,
    emailLayout,
  }) => {
    generateManualReminder({
      feeAmount,
      termsOfPayment,
      description,
      reminderLayout,
      emailLayout,
    });
    return true;
  }, [generateManualReminder]);

  if (status !== invoiceStatuses.LATE
    || isMerchant
    || !isCreditor) return null;

  return (
    <ModalButton
      modalTitle={t('Add reminder')}
      openButtonLabel={t('Add reminder')}
      actionLabel={t('Add reminder')}
      onClick={onSubmit}
      disabled={isLoading}
      formDefaultValue={{
        feeAmount: 0,
        termsOfPayment: 0,
      }}
      variant="subAction"
      width="25rem"
    >
      <InputsGroup>
        <NumberInput
          source="feeAmount"
          label={t('Reminder fee amount')}
          validate={[required(), validateFeeAmount]}
        />
        <NumberInput
          source="termsOfPayment"
          label={t('Reminder terms of payment')}
          validate={[required(), validateTermsOfPayments]}
        />
        <TextInput
          source="description"
          label={t('Description')}
          validate={[required()]}
        />
        {
          shouldSendInvoices
          && (
            <SelectInput
              label={t('Email layout')}
              source="emailLayout"
              choices={emailLayoutTypesReminder}
              defaultValue={emailLayoutTypesReminder[0].id}
              validate={[required()]}
            />
          )
        }
        {
          shouldSendInvoices
          && (
            <SelectInput
              label={t('Reminder layout')}
              source="reminderLayout"
              choices={reminderLayoutTypes}
              defaultValue={reminderLayoutTypes[0].id}
              validate={[required()]}
            />
          )
        }
      </InputsGroup>
      {
        !shouldSendInvoices && (
          <BooleanField
            label={t('Send invoice')}
            looseValue
            helperText={t('Invoice sending turned off. Activate invoice sending in the invoicing settings if you also want to send the reminder with this action.')}
          />
        )
      }
    </ModalButton>
  );
};

RemindInvoiceButton.propTypes = {
  isCreditor: PropTypes.bool.isRequired,
  accountId: PropTypes.string,
  shouldSendInvoices: PropTypes.bool,
};

RemindInvoiceButton.defaultProps = {
  accountId: '',
  shouldSendInvoices: false,
};

export default RemindInvoiceButton;
