import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import InvoiceDetailsGroup from '../InvoiceDetailsGroup';
import FinancingDetailsGroup from '../FinancingDetailsGroup';
import InvoicesGroup from '../InvoicesGroup';
import TransactionsGroup from '../TransactionsGroup';
import DeliveryConfirmationGroup from '../DeliveryConfirmationGroup';

import AccountInvoiceSettings from '../../entityv2/AccountInvoiceSettings';

import resources from '../../../constants/resources';

const SummaryTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <InvoiceDetailsGroup />
      <FinancingDetailsGroup />
      <AccountInvoiceSettings resource={resources.ORDER} />
      <InvoicesGroup />
      <TransactionsGroup />
      <DeliveryConfirmationGroup orderIdSource="id" />
    </FormTab>
  );
};

export default SummaryTab;
