import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import config from '../../../config';
import { useConstantContext } from '../ConstantsContext';

const InfoBox = () => {
  const { clientInfo } = useConstantContext();
  const { fimentoClientId } = config;
  const isDemoAccount = clientInfo.id === fimentoClientId;
  const { t } = useTranslation();

  if (!isDemoAccount) return null;

  return (
    <Box
      bgcolor="#BDDDD3"
      borderRadius="1rem"
      padding={2}
      paddingLeft={3}
      marginBottom="2rem"
    >
      <Typography variant="h6"><strong>{t('Demo account')}</strong></Typography>
      <Typography>{t('This data is simulated to show how Fimento s platform work. It will not reflect a real factoring business.')}</Typography>
    </Box>
  );
};

export default InfoBox;
