import React from 'react';
import PropTypes from 'prop-types';
import {
  useRecordContext,
} from 'react-admin';

import DropDownMenu from '../../utils/DropDownMenu';

import IdentifyButton from '../unidentifiedAccount/IdentifyButton';

import RevertPaymentButton from './RevertPaymentButton';

const DropDownMenuUnidentifiedAccount = ({
  transactions,
  accountId,
  balance,
}) => {
  const { id } = useRecordContext();
  const indexOfPayment = transactions
    .findIndex((transaction) => transaction.id === id);

  if (balance === 0 || indexOfPayment !== transactions.length - 1) return null;
  return (
    <DropDownMenu>
      <RevertPaymentButton />
      <IdentifyButton id={accountId} />
    </DropDownMenu>
  );
};
DropDownMenuUnidentifiedAccount.propTypes = {
  transactions: PropTypes.arrayOf({}).isRequired,
  accountId: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
};
export default DropDownMenuUnidentifiedAccount;
