import React, { useContext } from 'react';
import { ChildrenProp } from '../utils/helpers/propTypes';

const CreditDataContext = React.createContext({});

export const CreditDataContextProvider = ({ children }) => {
  const [creditReport, setCreditReport] = React.useState(undefined);

  return (
    <CreditDataContext.Provider value={{ creditReport, setCreditReport }}>
      {children}
    </CreditDataContext.Provider>
  );
};

CreditDataContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

export const useCreditDataContext = () => {
  const CreditData = useContext(CreditDataContext);
  return CreditData;
};
