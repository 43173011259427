import React from 'react';

import { ReferenceField, useRecordContext } from 'react-admin';

import CompanyDetails from '../company-details/CompanyDetails';
import { useConstantContext } from '../../ConstantsContext';
import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

const CustomerDetails = () => {
  const { isFinancer } = useUserPermissionsAndRoles();

  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const pendingReview = !!record.pendingPeerReviewRequest;

  return (
    isFinancer && (
      <ReferenceField
        label={false}
        source="customerId"
        reference="entity-v2/core"
        link={false}
      >
        <CompanyDetails
          viewUserAction={userActions.VIEW_CUSTOMER_APPLICATIONS}
          createUserAction={userActions.EDIT_CUSTOMER_APPLICATIONS}
          detailsSource="customerApplication"
          pendingReview={pendingReview}
          applicationRecord={record}
          enableAliasInput
        />
      </ReferenceField>
    )
  );
};

export default CustomerDetails;
