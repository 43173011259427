import { green, red } from '@mui/material/colors';

const buttonStyles = {
  denyButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[800],
    },
  },
  grantButton: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
};
export default buttonStyles;
