import { usePermissions } from 'react-admin';
import isAvailableToModule from '../../utils/isAvailableToModule';
import { useConstantContext } from '../ConstantsContext';

const useActionsAvailableToModuleAndRoleType = ({
  roleType,
  actions,
}) => {
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const {
    modules,
  } = authenticationData.permissions || {};
  const {
    roleTypes,
    merchantActions,
    financerActions,
    modulesActions,
  } = constants;

  const isModuleCompatible = isAvailableToModule(
    actions,
    modules,
    modulesActions,
  );

  if (roleType === roleTypes.FINANCER) {
    const isModuleCompatibleToRole = actions.some((action) => financerActions.includes(action));
    return isModuleCompatibleToRole && isModuleCompatible;
  }
  if (roleType === roleTypes.MERCHANT) {
    const isModuleCompatibleToRole = actions.some((action) => merchantActions.includes(action));
    return isModuleCompatibleToRole && isModuleCompatible;
  }
  return isModuleCompatible;
};

export default useActionsAvailableToModuleAndRoleType;
