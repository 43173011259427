import React, { useCallback } from 'react';

import {
  useRecordContext,
  ReferenceField,
  ArrayField,
  FunctionField,
  ShowBase,
  useResourceContext,
} from 'react-admin';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';
import Datagrid from '../designSystem/react-admin/Datagrid';
import TitleH2 from '../designSystem/TitleH2';
import InputsGroup from '../designSystem/InputsGroup';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import renderReference from '../../utils/renderReference';

import useCustomRpc from '../hooks/useCustomRpc';

import CustomerForm from './CustomerForm';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const UpdateCustomerTermsButton = () => {
  const record = useRecordContext();
  const {
    id: applicationId,
    currentDecision,
    applicationStep,
    terms,
  } = record;
  const { t } = useTranslation();
  const resource = useResourceContext();

  const { constants } = useConstantContext();
  const {
    resourceTypePaths,
    resourceTypes,
    applicationSteps,
    decisionStatuses,
    userActions,
  } = constants;

  const canEditCustomerApplication = useHasUserPermissions(
    constants,
    userActions.UPDATE_CUSTOMER_APPLICATIONS_TERMS,
  );

  const isCreated = applicationSteps.CREATED === applicationStep;
  const isDenied = decisionStatuses.DENIED === currentDecision;

  const {
    mutate: updateDebtorTerms,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resourceTypePaths[resourceTypes.CUSTOMER_APPLICATION]}/${applicationId}/terms`,
    shouldRefresh: true,
    errorMessage: t('Could not update the terms'),
  });

  const onSubmit = useCallback((newTerms) => {
    updateDebtorTerms(newTerms);
    return true;
  }, [updateDebtorTerms]);

  if (!canEditCustomerApplication) return null;

  return (
    <EditModalButton
      modalTitle={t('Set credit settings')}
      onClick={onSubmit}
      disabled={isLoading || !isCreated || isDenied}
      formDefaultValue={terms}
    >
      <ShowBase id={record.id} resource={resource}>
        <ReferenceField
          label=""
          source="customerId"
          reference="entity-v2/reference"
          link={false}
        >
          <ReferenceField
            label={false}
            source="entityDetailsId"
            reference="entity-v2/credit-info"
            link={false}
          >
            <Box>
              <InputsGroup layout="column">
                <SimpleShowLabeled>
                  <CurrencyField source="creditLimit" currencySource="creditLimitCurrency" label={t('Credit limit')} />
                </SimpleShowLabeled>
                <SimpleShowLabeled>
                  <CurrencyField source="creditLimitUtilised" currencySource="creditLimitCurrency" label={t('Utilised credit')} />
                </SimpleShowLabeled>
                <SimpleShowLabeled>
                  <CurrencyField source="creditInsuranceLimit" currencySource="creditInsuranceLimitCurrency" label={t('Insurance limit')} />
                </SimpleShowLabeled>
                <SimpleShowLabeled>
                  <CurrencyField source="utilisedInsurance" currencySource="creditInsuranceLimitCurrency" label={t('Utilised insurance')} />
                </SimpleShowLabeled>
              </InputsGroup>
              <SimpleShowLabeled fullWidth marginTop="2.5rem">
                <ArrayField label={t('Assigned credit limit')} source="assignedCreditLimitInfo">
                  <Datagrid bulkActionButtons={false} rightAlignedColumns={['assignedCreditLimit', 'usedCreditLimit']}>
                    <ReferenceField
                      label={t('Merchant')}
                      source="creditorId"
                      reference="entity-v2/reference"
                      link={false}
                    >
                      <FunctionField render={renderReference} />
                    </ReferenceField>
                    <CurrencyField source="assignedCreditLimit" currencySource="creditLimitCurrency" label={t('Reserved')} />
                    <CurrencyField source="usedCreditLimit" currencySource="creditLimitCurrency" label={t('Utilised')} />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLabeled>
            </Box>
          </ReferenceField>
        </ReferenceField>
        <InputsGroup title={t('Requested credit settings')} layout="column">
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2/reference"
              link={false}
            >
              <FunctionField render={renderReference} />
            </ReferenceField>
          </SimpleShowLabeled>
          <SimpleShowLabeled>
            <CurrencyField source="requestedTerms.creditLimit" currencySource="requestedTerms.creditLimitCurrency" label={t('Credit limit')} />
          </SimpleShowLabeled>
        </InputsGroup>
      </ShowBase>
      <Box marginTop="2rem">
        <TitleH2 title={t('Set new credit settings')} marginBottom="1.5rem" />
        <CustomerForm />
      </Box>
    </EditModalButton>
  );
};

export default UpdateCustomerTermsButton;
