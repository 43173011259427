import React from 'react';
import { styled } from '@mui/material';

import TextInput from './TextInput';

const StyledNumberInput = styled(TextInput)`
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const NumberInput = (props) => <StyledNumberInput {...props} type="number" />;

export default NumberInput;
