import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-admin';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const StyledSection = styled('section')(({ withForm }) => ({
  width: '80%',
  height: 'auto',
  minWidth: 'min(35rem, calc(90% - 4rem))',
  minHeight: 'min(15rem, calc(90% - 4rem))',
  maxWidth: 'min(50rem, calc(90% - 4rem))',
  maxHeight: 'calc(80% - 4rem)',
  padding: '2rem',
  boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.2)',
  position: 'absolute',
  backgroundColor: 'white',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...(withForm ? {
    justifyContent: 'flex-start',
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexGrow: 1,
      width: '100%',
    },
  } : {
    justifyContent: 'space-between',
  }),
}));

const ModalActionBar = ({
  hideSubmitButton,
  hideCloseButton,
  handleClick,
  disabled,
  buttonLabel,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
    >
      {hideSubmitButton ? null : (
        <Button
          type="submit"
          variant="contained"
          onClick={handleClick}
          disabled={disabled}
          color="primary"
          size="small"
          sx={{ margin: '0.5rem' }}
        >
          {buttonLabel}
        </Button>
      )}
      {hideCloseButton ? null : (
        <Button
          variant="contained"
          onClick={handleCloseModal}
          color="grey"
          size="small"
          sx={{ margin: '0.5rem' }}
        >
          {t('Close')}
        </Button>
      )}
    </Box>
  );
};

ModalActionBar.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  hideSubmitButton: PropTypes.bool.isRequired,
  hideCloseButton: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

ModalActionBar.defaultProps = {
  handleClick: undefined,
};

const NoFormModalController = ({
  children,
  onClick,
  handleCloseModal,
  disabled,
  buttonLabel,
  hideSubmitButton,
}) => {
  const handleClick = useCallback((e) => {
    e.preventDefault();
    const submitResult = onClick();
    if (submitResult) {
      handleCloseModal(e);
    }
  }, [onClick, handleCloseModal]);

  return (
    <>
      {children}
      <ModalActionBar
        handleClick={handleClick}
        hideSubmitButton={hideSubmitButton}
        disabled={disabled}
        buttonLabel={buttonLabel}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

NoFormModalController.propTypes = {
  children: ChildrenProp.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  hideSubmitButton: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

const ModalButton = (props) => {
  const {
    disabled,
    title,
    modalTitle,
    actionLabel,
    children,
    right,
    onClick,
    onOpen,
    onClose,
    hideSubmitButton,
    hideCloseButton,
    sx,
    withForm,
    formDefaultValue,
    variant,
  } = props;
  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    onClose();
    setOpen(false);
  }, [onClose]);

  const onSubmitForm = useCallback((data, e) => {
    const submitResult = onClick(data);
    if (submitResult) {
      handleCloseModal(e);
    }
  }, [onClick, handleCloseModal]);

  const onSubmitNoForm = useCallback((e) => {
    e.preventDefault();
    const submitResult = onClick();
    if (submitResult) {
      handleCloseModal(e);
    }
  }, [onClick, handleCloseModal]);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleOpenModal}
        variant={variant}
        color="primary"
        size="small"
        sx={{
          margin: '0.5rem 0.3rem',
          ...(right ? { float: 'right' } : {}),
          ...sx,
        }}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleCloseModal}
      >
        <StyledSection withForm={withForm}>
          <Typography align="center" variant="h5" gutterBottom>{modalTitle || title}</Typography>
          {withForm ? (
            <Form
              record={formDefaultValue}
              onSubmit={onSubmitForm}
              sanitizeEmptyValues
            >
              {children}
              <ModalActionBar
                hideSubmitButton={hideSubmitButton}
                hideCloseButton={hideCloseButton}
                disabled={disabled}
                buttonLabel={actionLabel || title}
                handleCloseModal={handleCloseModal}
              />
            </Form>
          ) : (
            <>
              {children}
              <ModalActionBar
                handleClick={onSubmitNoForm}
                hideSubmitButton={hideSubmitButton}
                hideCloseButton={hideCloseButton}
                disabled={disabled}
                buttonLabel={actionLabel || title}
                handleCloseModal={handleCloseModal}
              />
            </>
          )}
        </StyledSection>
      </Modal>
    </>
  );
};

ModalButton.propTypes = {
  title: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
  variant: PropTypes.string,
  children: ChildrenProp.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  right: PropTypes.bool,
  disabled: PropTypes.bool,
  actionLabel: PropTypes.string,
  hideSubmitButton: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  sx: PropTypes.shape({}),
  withForm: PropTypes.bool,
  formDefaultValue: PropTypes.shape({}),
};

ModalButton.defaultProps = {
  onClose: () => null,
  onOpen: () => null,
  onClick: () => null,
  right: false,
  disabled: false,
  actionLabel: undefined,
  hideSubmitButton: false,
  hideCloseButton: false,
  sx: {},
  withForm: false,
  formDefaultValue: {},
  modalTitle: undefined,
  variant: 'contained',
};

export default ModalButton;
