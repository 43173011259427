import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useNotify,
  useRefresh,
  FunctionField,
  useListContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import useCustomRpc from '../hooks/useCustomRpc';
import ModalButton from '../designSystem/ModalButton';
import renderLabelChip from '../utils/renderLabelChip';

import ReferenceArrayInput from '../designSystem/react-admin/inputs/ReferenceArrayInput';
import AutocompleteArrayInput from '../designSystem/react-admin/inputs/AutocompleteArrayInput';

export const AssignLabelButton = ({ disabled, disabledTooltip }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedIds } = useListContext();
  const {
    mutate: setBulkOrderLabel,
  } = useCustomRpc({
    path: 'order/bulk-order-update',
    httpMethod: 'PUT',
    shouldUnselectAll: 'order',
    errorMessage: t('Could not update selected orders'),
    onSuccess: ({ data }) => {
      notify(`${data.updatedOrders} ${t('orders updated')}`);
      if (data.updatedOrders) refresh();
    },
  });

  const onClickAssign = useCallback((formValues) => {
    setBulkOrderLabel({ selectedIds, labelIds: formValues.labelIds });
    return true;
  }, [setBulkOrderLabel, selectedIds]);

  return (
    <ModalButton
      modalTitle={t('Assign labels')}
      openButtonLabel={t('Assign labels')}
      actionLabel={t('Save')}
      formDefaultValue={{}}
      onClick={onClickAssign}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
      width="25rem"
    >
      <ReferenceArrayInput
        source="labelIds"
        reference="label"
      >
        <AutocompleteArrayInput
          variant="standard"
          label={t('Labels')}
          inputText={(label) => label.name}
          matchSuggestion={(filter, label) => (
            label.name.includes(filter)
          )}
          optionText={(
            <FunctionField
              label={t('Type')}
              render={renderLabelChip({ theme })}
            />
          )}
        />
      </ReferenceArrayInput>
    </ModalButton>
  );
};

export const DeleteLabelButton = ({ disabled, disabledTooltip }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const theme = useTheme();
  const { selectedIds } = useListContext();
  const { t } = useTranslation();

  const {
    mutate: deleteBulkOrderLabel,
  } = useCustomRpc({
    path: 'order/bulk-order-delete',
    httpMethod: 'PUT',
    shouldUnselectAll: 'order',
    errorMessage: t('Could not remove selected order labels'),
    onSuccess: ({ data }) => {
      notify(`${data.updatedOrders} ${t('orders updated')}`);
      if (data.updatedOrders) refresh();
    },
  });

  const onClickDelete = useCallback((formValues) => {
    deleteBulkOrderLabel({ selectedIds, labelIds: formValues.labelIds });
    return true;
  }, [deleteBulkOrderLabel, selectedIds]);

  return (
    <ModalButton
      modalTitle={t('Delete labels')}
      openButtonLabel={t('Delete labels')}
      actionLabel={t('Save')}
      formDefaultValue={{}}
      onClick={onClickDelete}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
      width="25rem"
    >
      <ReferenceArrayInput
        source="labelIds"
        reference="label"
      >
        <AutocompleteArrayInput
          variant="standard"
          label={t('Labels')}
          inputText={(label) => label.name}
          matchSuggestion={(filter, label) => (
            label.name.includes(filter)
          )}
          optionText={(
            <FunctionField
              label={t('Type')}
              render={renderLabelChip({ theme })}
            />
          )}
        />
      </ReferenceArrayInput>
    </ModalButton>
  );
};

const labelButtonActionPropTypes = {
  props: PropTypes.shape({}),
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
};

const labelButtonActionDefaultProps = {
  props: {},
  disabled: false,
  disabledTooltip: undefined,
};

AssignLabelButton.propTypes = labelButtonActionPropTypes;
DeleteLabelButton.propTypes = labelButtonActionPropTypes;
AssignLabelButton.defaultProps = labelButtonActionDefaultProps;
DeleteLabelButton.defaultProps = labelButtonActionDefaultProps;
