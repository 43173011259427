import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';

const useInputCLFilters = () => {
  const { t } = useTranslation();
  return useState([
    <DateInput
      source="filters.createdDateTo"
      label={t('Invoices created until')}
    />,
    <ReferenceArrayInput
      label={t('Labels')}
      source="filters.labelIds"
      reference="label"
    >
      <AutocompleteArrayInput
        label={t('Labels')}
        variant="filled"
        optionText="name"
      />
    </ReferenceArrayInput>,
  ]);
};

export default useInputCLFilters;
