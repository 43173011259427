import React from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const TooltipBox = ({ tooltip, children, ...props }) => (
  <Box
    justifyContent="flex-start"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    flexDirection="row"
    display="flex"
    alignContent="center"
    columnGap="0.31rem"
  >
    {children}
    <Tooltip title={tooltip}>
      <InfoIcon color="secondary" fontSize="small" />
    </Tooltip>
  </Box>
);

TooltipBox.propTypes = {
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.node,
};

TooltipBox.defaultProps = {
  children: null,
};

export default TooltipBox;
