import React from 'react';
import PropTypes from 'prop-types';
import ScrollButtonLeft from './ScrollButtonLeft';
import ScrollButtonRight from './ScrollButtonRight';

const ScrollHorizontalButtons = ({
  isDisplayed,
  scrollableElement,
  color,
  backgroundColor,
  extraPadding,
  dependencies,
  popperRefLeft,
  popperRefRight,
  zIndex,
}) => (
  <>
    <ScrollButtonLeft
      isDisplayed={isDisplayed}
      scrollableElement={scrollableElement}
      color={color}
      backgroundColor={backgroundColor}
      extraPadding={extraPadding}
      dependencies={dependencies}
      popperRef={popperRefLeft}
      zIndex={zIndex}
    />
    <ScrollButtonRight
      isDisplayed={isDisplayed}
      scrollableElement={scrollableElement}
      color={color}
      backgroundColor={backgroundColor}
      extraPadding={extraPadding}
      dependencies={dependencies}
      popperRef={popperRefRight}
      zIndex={zIndex}
    />
  </>
);

ScrollHorizontalButtons.propTypes = {
  scrollableElement: PropTypes.instanceOf(window.Element),
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool,
  extraPadding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
  dependencies: PropTypes.arrayOf(PropTypes.any),
  popperRefLeft: PropTypes.shape(),
  popperRefRight: PropTypes.shape(),
  zIndex: PropTypes.number,
};

ScrollHorizontalButtons.defaultProps = {
  scrollableElement: null,
  isDisplayed: true,
  extraPadding: {},
  dependencies: [],
  popperRefLeft: undefined,
  popperRefRight: undefined,
  zIndex: undefined,
};

export default ScrollHorizontalButtons;
