import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, startCase } from 'lodash';

import {
  TextField,
  useRecordContext,
  FunctionField,
  Loading,
  WrapperField,
} from 'react-admin';

import GenerateReport from './GenerateReport';
import ProtectedFileFieldButton from '../utils/ProtectedFileFieldButton';
import DropDownMenu from '../../utils/DropDownMenu';
import { isDefined } from '../../utils/validators';

import { useConstantContext } from '../ConstantsContext';

import List from '../designSystem/react-admin/list/List';
import ReferenceField from '../designSystem/react-admin/ReferenceField';
import DateField from '../designSystem/react-admin/DateField';
import ListChip, { colors } from '../designSystem/ListChip';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import TitleH1 from '../designSystem/TitleH1';

export const getReportListTitle = (t) => t('Reports');

const ReportStatusChip = () => {
  const record = useRecordContext();
  const status = get(record, 'status');
  const { constants } = useConstantContext();
  const { reportStatuses } = constants;

  if (isEmpty(reportStatuses)) return <Loading />;
  if (!isDefined(status)) return null;

  let chipColor;
  if (status === reportStatuses.PENDING) chipColor = colors.orange;
  else if (status === reportStatuses.DONE) chipColor = colors.green;
  else if (status === reportStatuses.FAILED) chipColor = colors.red;
  else throw new Error(`Unknown report status ${status} for report ${record._id}`);

  return <ListChip label={startCase(status)} chipColor={chipColor} />;
};

const FileActions = () => {
  const { constants: { reportStatuses } } = useConstantContext();
  const { t } = useTranslation();
  const record = useRecordContext();
  const downloadCondition = ({ status }) => status === reportStatuses.DONE;

  if (!record || !downloadCondition(record)) return null;

  return (
    <DropDownMenu>
      <ProtectedFileFieldButton label={t('Download')} downloadResource="report" condition={downloadCondition} />
    </DropDownMenu>
  );
};

const ReportsList = () => {
  const { t } = useTranslation();
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      title={<TitleH1 title={getReportListTitle(t)} />}
      actions={<GenerateReport />}
      disableSyncWithLocation
      appLocation="home.reports"
    >
      <DatagridConfigurable bulkActionButtons={false} rightAlignedColumns={['subActions']}>
        <DateField source="createdAt" label={t('Generation date')} showTime emptyText="-" />
        <TextField source="type" label={t('Report type')} emptyText="-" />
        <ReferenceField
          referenceSource="userId"
          source="user" // Not used by the function field but used for the column class name
          reference="user/public"
          resource="/logs"
          label={t('User')}
          FieldComponent={FunctionField}
          FieldComponentProps={{ render: (user) => `${user.firstName} ${user.lastName}` }}
        />
        <ReportStatusChip label={t('Status')} />
        <WrapperField label={false} source="subActions">
          <FileActions />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default ReportsList;
