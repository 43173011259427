import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateInput from '../../designSystem/react-admin/inputs/DateInput';

const useLoansReportFilters = () => {
  const { t } = useTranslation();

  return useState([
    <DateInput
      source="date"
      label={t('Snapshot date')}
    />,
    <DateInput
      source="filters.startDate"
      label={t('Payout date (from)')}
    />,
    <DateInput
      source="filters.endDate"
      label={t('Payout date (to)')}
    />,
  ]);
};

export default useLoansReportFilters;
