const chartStyle = {
  title: {
    align: 'left',
    margin: 10,
    style: {
      fontSize: '20px',
      fontWeight: 400,
    },
  },
  subtitle: {
    align: 'right',
    style: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#7a7979',
    },
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          name: {
            fontSize: '12px',
            fontWeight: 400,
          },
          value: {
            fontSize: '20px',
            fontWeight: 600,
            color: '#373d3f',
          },
          total: {
            fontSize: '20px',
            fontWeight: 400,
            color: '#373d3f',
          },
        },
      },
    },
  },
  dataLabels: {
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  legend: {
    style: {
      cssClass: 'xaxis-legend',
    },
    markers: {
      radius: 9,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '10px',
        cssClass: 'xaxis-labels',
      },
    },
    title: {
      style: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#7a7979',
      },
    },
  },
};

export default chartStyle;
