/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import {
  Box,
  Typography,
  Switch,
  styled,
} from '@mui/material';
import { sanitizeInputRestProps, useInput } from 'react-admin';

const StyledSwitch = styled(Switch)`
${({ theme }) => `
  & .MuiSwitch-switchBase {
    color: ${theme.palette.switch};
    &.Mui-checked {
      color: ${theme.palette.switchChecked};
      & +.MuiSwitch-track {
        background-color: ${theme.palette.switchCheckedTrack};
        opacity: 1;
      };
    };
  };
  & .MuiSwitch-track {
    background-color: ${theme.palette.switchTrack};
    opacity: 1;
  };
`}
`;

const BooleanInput = ({ ...restProps }) => {
  const {
    className,
    defaultValue = false,
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    disabled,
    parse,
    resource,
    source,
    validate,
    options,
    sx,
    ...rest
  } = restProps;
  const {
    id,
    field,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    onBlur,
    onChange,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const fontOpacity = field.value ? 1 : 0.6;

  const handleChange = useCallback(
    (event) => {
      field.onChange(event);
      // Ensure field is considered as touched
      field.onBlur();
    },
    [field],
  );

  return (
    <Box display="flex" height="100%" justifyContent="flex-end" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        // Reproduce text input behaviour
        height="3rem"
        marginBottom="0.2rem"
        marginLeft="-0.5rem"
      >
        <StyledSwitch
          id={id}
          name={field.name}
          onChange={handleChange}
          onFocus={onFocus}
          checked={field.value}
          {...sanitizeInputRestProps(rest)}
          {...options}
          disabled={disabled}
        />
        <Typography
          fontSize="15px"
          color={field.value ? 'switch' : '#000'}
          fontWeight="500"
          sx={{ opacity: fontOpacity }}
          padding="0.5rem 0"
        >
          {label}
        </Typography>
      </Box>
      <Typography
        fontSize="12px"
        color="switch"
        marginLeft="0.5rem"
        maxWidth="35rem"
      >
        {helperText}
      </Typography>
    </Box>
  );
};

export default BooleanInput;
