import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import HistoryGroup from '../HistoryGroup';
import useCanEdit from '../useCanEdit';
import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

const HistoryTab = (props) => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const canEdit = useCanEdit();
  const { t } = useTranslation();

  if (!canEdit || !isFinancer) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('History')} {...props}>
      <HistoryGroup />
    </FormTab>
  );
};

export default HistoryTab;
