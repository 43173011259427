import React, { useCallback } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import useCustomRpc from '../hooks/useCustomRpc';

const MarkAllAsReadButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { t } = useTranslation();

  const {
    mutate: markAllAsRead,
  } = useCustomRpc({
    path: 'notification/mark-all-as-read',
    httpMethod: 'PUT',
    errorMessage: t('Could not mark the notification(s) as read'),
    onSuccess: ({ data }) => {
      notify(`${data.markedAsRead} ${t('Notification(s) mark as read')}`);
      if (data.markedAsRead) refresh();
    },
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    markAllAsRead();
  }, [markAllAsRead]);

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="small"
    >
      {t('Mark all as read')}
    </Button>
  );
};

export default MarkAllAsReadButton;
