import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import DonutChart from '../../charts/DonutChart';
import ColumnsChart from '../../charts/ColumnsChart';
import ChartContainer from '../../charts/ChartContainer';

import roundNumber from '../../../utils/roundNumber';

import { useStatistics } from '../StatisticsContext';

import useFeatureFlags from '../../hooks/useFeatureFlags';

const MerchantCharts = () => {
  const [featureFlag] = useFeatureFlags();
  const {
    statisticsData: {
      currency,
      kpis: {
        usedFinancingLimit,
        financingLimit,
        utilisedCreditLimit = {},
        financedAmountPerMonth = [],
      },
    },
  } = useStatistics();
  const { t } = useTranslation();

  const financingLimitLabels = [t('utilised').toUpperCase(), t('available').toUpperCase()];
  const financingLimitSerie = [
    roundNumber(usedFinancingLimit),
    roundNumber(financingLimit - usedFinancingLimit),
  ];

  const financedLabels = financedAmountPerMonth.map((elem) => elem.month);
  const financedSerie = [{
    name: t('Financed'),
    data: financedAmountPerMonth.map((elem) => roundNumber(elem.amount)),
  }];

  if (featureFlag.enableAnalyticsAndRedesign) {
    return (
      <Grid
        container
        sx={{
          '@media': {
            maxWidth: 'calc(100% + 16px)',
          },
        }}
      >
        <Grid item lg={12}>
          <ChartContainer>
            <ColumnsChart
              title={t('Financed per month')}
              colours={['#1755F4']}
              labels={financedLabels}
              xTitle={new Date().getFullYear().toString()}
              serie={financedSerie}
              currency={currency}
            />
          </ChartContainer>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        '@media': {
          maxWidth: 'calc(100% + 16px)',
        },
      }}
    >
      <Grid item lg={3} md={6}>
        <ChartContainer>
          <DonutChart
            serie={financingLimitSerie}
            labels={financingLimitLabels}
            totalLabel={t('Total limit')}
            colours={['#204A89', '#74C6AD']}
            title={t('Financing limit balance')}
          />
        </ChartContainer>
      </Grid>
      <Grid item lg={3} md={6}>
        <ChartContainer>
          <DonutChart
            serie={[
              utilisedCreditLimit.low,
              utilisedCreditLimit.medium,
              utilisedCreditLimit.high,
            ]}
            labels={['LOW: 0-60%', 'MEDIUM: 61-90%', 'HIGH: >90%']}
            totalLabel={t('Customers')}
            colours={['#74C6AD', '#204A89', '#FF6961']}
            title={t('Utilised credit limit')}
          />
        </ChartContainer>
      </Grid>
      <Grid item lg={6}>
        <ChartContainer>
          <ColumnsChart
            title={t('Financed per month')}
            colours={['#1755F4']}
            labels={financedLabels}
            xTitle={new Date().getFullYear().toString()}
            serie={financedSerie}
            currency={currency}
          />
        </ChartContainer>
      </Grid>
    </Grid>
  );
};

export default MerchantCharts;
