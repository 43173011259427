import React from 'react';
import {
  SimpleForm,
  NumberInput,
  required,
  TextInput,
  SelectInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { positiveNumberValidation } from '../../utils/validationErrors';
import { useConstantContext } from '../ConstantsContext';
import Create from '../designSystem/react-admin/Create';
import TitleH1 from '../designSystem/TitleH1';

const validateRequired = required();

export const getEntityGroupCreateTitle = (t) => t('Create entity group');

const EntityGroupCreate = () => {
  const { choices } = useConstantContext();
  const { currencies } = choices;
  const { t } = useTranslation();

  return (
    <Create title={<TitleH1 title={getEntityGroupCreateTitle(t)} />} appLocation="home.entity-group.create">
      <SimpleForm sanitizeEmptyValues>
        <TextInput label={t('Group name')} source="groupName" validate={validateRequired} />
        <NumberInput
          label={t('Credit limit')}
          source="creditLimit"
          validate={[positiveNumberValidation, validateRequired]}
        />
        <SelectInput
          margin="none"
          source="currency"
          choices={currencies}
          validate={[validateRequired]}
        />
      </SimpleForm>
    </Create>
  );
};

export default EntityGroupCreate;
