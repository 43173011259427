const useMenuGroupList = () => [
  {
    label: 'Orders & financing',
    children: [
      'order-draft',
      'order',
      'financing-application',
      'factoring-account',
    ],
  },
  {
    label: 'Loans',
    children: [
      'loan-application',
      'loan',
    ],
  },
  {
    label: 'Customers',
    children: [
      'entity-v2/customer',
      'customer-application',
      'entity-group',
    ],
  },
  {
    label: 'Merchants',
    children: [
      'entity-v2/merchant',
      'settlement-account',
      'billing-account',
    ],
  },
  {
    label: 'Payments',
    children: [
      'file/inbound-payments',
      // 'file/outbound-payments',
      'payments',
      'unidentified-account',
    ],
  },
  {
    label: 'Reports & activity',
    children: [
      'report',
      'logs',
      'file',
      'exchangeRate',
    ],
  },
];

export default useMenuGroupList;
