import React from 'react';
import { styled } from '@mui/material';

import TextInput from './TextInput';

import calendarTodayIconUrl from '../../../../../assets/calendar_today.svg';

export const StyledDateInput = styled(TextInput)`
  input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url(${calendarTodayIconUrl});
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const DateInput = (props) => <StyledDateInput {...props} type="date" inputProps={{ max: '2999-12-31' }} />;

export default DateInput;
