import React from 'react';
import PropTypes from 'prop-types';

import { DateField as RaDateField } from 'react-admin';

const DateField = ({ showTime, ...rest }) => (
  <RaDateField
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    options={{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      ...showTime ? {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h24',
      } : {},
    }}
  />
);

DateField.propTypes = {
  showTime: PropTypes.bool,
};

DateField.defaultProps = {
  showTime: false,
};

export default DateField;
