import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import CommentsGroup from '../CommentsGroup';

const CommentsTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Comments')} {...props}>
      <CommentsGroup />
    </FormTab>
  );
};

export default CommentsTab;
