import React from 'react';
import { useTranslation } from 'react-i18next';

import renderReference from '../../utils/renderReference';
import { useConstantContext } from '../ConstantsContext';

import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import TextInputWithLens from '../designSystem/react-admin/inputs/TextInputWithLens';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';

const useLoanFilters = () => {
  const { constants, choices } = useConstantContext();
  const { t } = useTranslation();
  const {
    loanStatuses,
  } = choices;
  const {
    resourceTypes,
    resourceTypePaths,
    entityRoles,
  } = constants;

  if (!resourceTypes || !resourceTypePaths) return null;

  return [
    <TextInputWithLens
      placeholder={t('Search loan id')}
      source="q"
    />,
    <ReferenceInput
      source="debtorId"
      reference="entity-v2/reference"
      filter={{ entityRoles: entityRoles.CUSTOMER }}
    >
      <AutocompleteInput
        placeholder={t('Customer')}
        optionText={renderReference}
        suggestionLimit={10}
      />
    </ReferenceInput>,
    <SelectInput
      placeholder={t('Payment Status')}
      source="accountStatus"
      choices={loanStatuses}
    />,
  ];
};

export default useLoanFilters;
