import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { East } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useCreatePath, useRecordContext, useResourceContext } from 'react-admin';

const OpenInNewButton = ({ linkType }) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();

  const onClick = useCallback((e) => {
    // To prevent click behaviour in a datagrid with rowClick
    e.stopPropagation();
  }, []);

  return (
    <IconButton onClick={onClick} href={`${window.location.origin}#${createPath({ type: linkType, resource, id: record.id })}`}>
      <East
        color="secondary"
        sx={{ width: '1.25rem', height: '1.25rem' }}
      />
    </IconButton>
  );
};

OpenInNewButton.propTypes = {
  linkType: PropTypes.string.isRequired,
};

export default OpenInNewButton;
