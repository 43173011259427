import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Typography } from '@mui/material';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';

const CustomSuffixNumberInput = (props) => {
  const { customSuffix } = props;
  return (
    <NumberInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              component="span"
              variant="body2"
            >
              {customSuffix}
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};

CustomSuffixNumberInput.propTypes = {
  customSuffix: PropTypes.node.isRequired,
};

export default CustomSuffixNumberInput;
