import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { percentageFormat } from './PercentageField';

const colours = {
  OK: '#8DEA7C',
  WARNING: '#FFDC25',
  DANGER: '#FF8771',
};

const UtilisationField = ({
  source,
}) => {
  const record = useRecordContext();
  const percentage = get(record, source, null);

  let colour;
  if (percentage > 90) colour = colours.DANGER;
  else if (percentage > 60) colour = colours.WARNING;
  else if (percentage >= 0) colour = colours.OK;

  return (
    <>
      <Typography
        component="span"
        variant="body2"
        // sx={{ float: 'right' }} // Uncomment when aligning CurrencyField too
      >
        {percentage === null
          ? '-'
          : (
            <>
              {percentageFormat(percentage)}&nbsp;
              <CircleIcon sx={{ color: colour, width: '0.6rem', height: '0.6rem' }} />
            </>
          )}
      </Typography>
    </>
  );
};

UtilisationField.propTypes = {
  source: PropTypes.string.isRequired,
};

export default UtilisationField;
