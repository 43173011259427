import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../../ConstantsContext';

import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import renderReference from '../../../utils/renderReference';

const useBillingAndSettlementReportFilters = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { entityRoles } = constants;
  const { isMerchant } = useUserPermissionsAndRoles();

  return useState(isMerchant ? [
    <DateInput
      source="date"
      label={t('Snapshot date')}
    />,
  ] : [
    <DateInput
      source="date"
      label={t('Snapshot date')}
    />,
    <ReferenceArrayInput
      source="filters.debtorIds"
      label={t('Merchant')}
      reference="entity-v2/reference"
      resource="/entity-v2/reference"
      filter={{ entityRoles: entityRoles.MERCHANT }}
    >
      <AutocompleteArrayInput
        label={t('Merchant')}
        optionText={renderReference}
        suggestionLimit={20}
      />
    </ReferenceArrayInput>,
  ]);
};

export default useBillingAndSettlementReportFilters;
