import React from 'react';
import {
  TextField,
  FunctionField,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DateField from '../designSystem/react-admin/DateField';
import ReferenceField from '../designSystem/react-admin/ReferenceField';
import Datagrid from '../designSystem/react-admin/Datagrid';

const LogListDetails = (props) => {
  const { resourceId } = props;
  const { t } = useTranslation();

  return (
    <Datagrid bulkActionButtons={false} cellMaxWidth="unset">
      <DateField source="createdAt" label={t('Event date')} showTime />
      <TextField source="resourceType" label={t('Origin')} sortable={false} />
      <TextField source="action" label={t('Action')} sortable={false} />
      {!resourceId && <TextField source="resourceId" label={t('Resource ID')} sortable={false} emptyText="-" />}
      <ReferenceField
        label={t('User')}
        source="user"
        referenceSource="userId"
        reference="user/public"
        resource="/logs"
        FieldComponent={FunctionField}
        FieldComponentProps={{ render: (record = {}) => `${record.firstName} ${record.lastName}` }}
      />
    </Datagrid>
  );
};

LogListDetails.propTypes = {
  resourceId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

LogListDetails.defaultProps = {
  resourceId: undefined,
};

export default LogListDetails;
