import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material';

const WarningBox = ({ warningText, ...props }) => {
  const theme = useTheme();
  const { warning } = theme.palette;
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      flexDirection="row"
      display="flex"
      backgroundColor={warning.background}
      padding="1rem 1.25rem"
      borderRadius="0.625rem"
      alignItems="center"
      gap="1rem"
    >
      <WarningIcon sx={{ color: warning.content }} fontSize="small" />
      <Typography variant="caption" color={warning.content} maxWidth="15rem">{warningText}</Typography>
    </Box>
  );
};

WarningBox.propTypes = {
  warningText: PropTypes.string.isRequired,
};

export default WarningBox;
