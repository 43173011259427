import downloadDocumentOnSuccess from '../../utils/downloadDocumentOnSuccess';
import useCustomRpc from './useCustomRpc';
import config from '../../../config';

const useDownloadDocument = ({
  path,
  errorMessage,
  shouldRefresh,
  shouldUnselectAll,
}) => {
  const customRpc = useCustomRpc({
    errorMessage,
    path: 'authorization/signed-request',
    httpMethod: 'POST',
    shouldRefresh,
    shouldUnselectAll,
    onSuccess: downloadDocumentOnSuccess,
  });

  const extendMutate = async (data, options) => customRpc.mutateAsync({
    ...data,
    url: `${config.apiBase}/${path}`,
  }, options);
  return {
    ...customRpc,
    mutate: extendMutate,
    mutateAsync: extendMutate,
  };
};

export default useDownloadDocument;
