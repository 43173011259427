import React, { useCallback } from 'react';

import { Typography } from '@mui/material';
import {
  useNotify,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../ConstantsContext';

import useFeatureFlags from '../hooks/useFeatureFlags';
import useUploadFileCustomRpc from '../hooks/useUploadFileCustomRpc';

import hasUserPermissions from '../../utils/hasUserPermissions';
import EnhancedFileField from '../utils/EnhancedFileFields';

import ModalButton from '../designSystem/ModalButton';
import FileInput from '../designSystem/react-admin/inputs/FileInput';

import theme from '../../theme';
import useCustomRpc from '../hooks/useCustomRpc';

const ConfirmDeliveryConfimrationButton = () => {
  const { id: deliveryConfirmationId, orderId, status } = useRecordContext();
  const { constants } = useConstantContext();
  const [featureFlags] = useFeatureFlags();
  const { t } = useTranslation();
  const authenticationData = usePermissions();
  const notify = useNotify();

  const { userActions, deliveryConfirmationStatuses } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canEditDeliveryConfirmation = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_DELIVERY_CONFIRMATION,
  );

  const {
    mutate: uploadConfirmationDocument,
    reset: resetFileUpload,
    isLoading: waitingForFileUpload,
  } = useUploadFileCustomRpc({
    httpMethod: 'PUT',
    path: `upload/${deliveryConfirmationId}/upload-delivery-confirmation-file`,
    shouldRefresh: false,
    errorMessage: t('Could not upload the delivery confirmation document'),
    onSuccess: () => {
      notify(t('You successfully uploaded the delivery confirmation document'), { type: 'success' });
    },
  });

  const {
    mutate: markConfirmationAsConfirmed,
    isLoading: waitingForSetStatus,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `order/${orderId}/delivery-confirmation/${deliveryConfirmationId}/status`,
    shouldRefresh: true,
    errorMessage: t('Could not mark delivery confirmation as confirmed'),
    onSuccess: () => {
      notify(t('You successfully mark the delivery confirmation as confirmed'), { type: 'success' });
    },
  });

  const onConfirm = useCallback(async ({ file }) => {
    if (file && file.rawFile) {
      await uploadConfirmationDocument(file.rawFile);
    }

    markConfirmationAsConfirmed({ status: deliveryConfirmationStatuses.CONFIRMED });

    return true;
  }, [
    markConfirmationAsConfirmed,
    deliveryConfirmationStatuses.CONFIRMED,
    uploadConfirmationDocument,
  ]);

  const onClose = useCallback(() => {
    resetFileUpload();
  }, [resetFileUpload]);

  if (!featureFlags.enableDeliveryConfirmation
    || !canEditDeliveryConfirmation
    || status === deliveryConfirmationStatuses.CONFIRMED
  ) return null;

  return (
    <ModalButton
      modalTitle={t('Mark as confirmed')}
      openButtonLabel={t('Mark as confirmed')}
      actionLabel={t('Update')}
      onClick={onConfirm}
      onClose={onClose}
      disabled={waitingForFileUpload || waitingForSetStatus}
      variant="subAction"
      width="33rem"
      formDefaultValue={{}}
    >
      <Typography variant="body2">{t('Once your delivery confirmation is granted, you can update its status here and provide any necessary documentation, if required')}</Typography>
      <FileInput
        label={t('Proof of confirmation')}
        source="file"
        accept="application/pdf"
        multiple={false}
        placeHolder={(<Typography fontSize="12px">{t('Drag and drop file here or you can')} <br /><span style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>{t('click to select a file')}</span></Typography>)}
      >
        <EnhancedFileField source="src" title={t('title')} />
      </FileInput>
    </ModalButton>
  );
};

export default ConfirmDeliveryConfimrationButton;
