import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';

const FinancingDateInput = (props) => {
  const { setValue } = useFormContext();

  const onChange = useCallback((e) => {
    setValue('date', e.target.value);
  }, [setValue]);

  return (
    <DateInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={onChange}
    />
  );
};

const ACCSFilters = () => {
  const { t } = useTranslation();
  return useState([
    <DateInput
      source="filters.financeFrom"
      label={t('Financing date (from)')}
    />,
    <FinancingDateInput
      source="filters.financeTo"
      label={t('Financing date (to)')}
    />,
  ]);
};

export default ACCSFilters;
