import React from 'react';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../utils/FieldsGroup';

import InvoicesData from '../invoice/InvoicesData';

const InvoicesGroup = () => {
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Invoices')}>
      <InvoicesData />
    </FieldsGroup>
  );
};

export default InvoicesGroup;
