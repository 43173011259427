import React, { useCallback } from 'react';
import { useRecordContext, useResourceContext } from 'react-admin';
import { useTranslation } from 'react-i18next';

import ModalButton from '../utils/ModalButton';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';
import { useConstantContext } from '../ConstantsContext';
import FinancingApplicationTermsForm from './FinancingApplicationTermsForm';

const SendCounterOfferButton = () => {
  const {
    id,
    applicationStep,
    acceptanceDecision,
    terms,
    applicationType,
    orderAmount,
    orderCurrency,
    merchantId,
  } = useRecordContext();
  const resource = useResourceContext();
  const { constants } = useConstantContext();

  const { applicationSteps, applicationTypes, acceptanceStatuses } = constants;
  const { isMerchant, isFinancer } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  const {
    mutate: updateAcceptanceDecision,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resource}/${id}/acceptance-decision`,
    shouldRefresh: true,
    errorMessage: t('Could not update the acceptance decision'),
  });

  const handleClick = useCallback((newTerms) => {
    updateAcceptanceDecision({
      acceptanceDecision: acceptanceStatuses.COUNTER_OFFER,
      newMerchantTerms: newTerms,
    });
    return true;
  }, [updateAcceptanceDecision, acceptanceStatuses.COUNTER_OFFER]);

  const merchantCanCounterOffer = (acceptanceDecision === acceptanceStatuses.COUNTER_OFFER
    || applicationStep === applicationSteps.APPROVED);

  if (applicationStep !== applicationSteps.APPROVED
    || acceptanceDecision === acceptanceStatuses.COUNTER_OFFER
    || (isMerchant && !merchantCanCounterOffer)
    || isFinancer
  ) return null;

  return (
    <ModalButton
      title={t('Counter offer')}
      onClick={handleClick}
      disabled={isLoading}
      right
      fullWidth
      withForm
      formDefaultValue={terms}
    >
      {applicationType === applicationTypes.FINANCING && (
        <FinancingApplicationTermsForm
          orderAmount={orderAmount}
          orderCurrency={orderCurrency}
          merchantId={merchantId}
          termsFinancingCurrency={terms.financingCurrency}
          termsFeeSettings={terms.feeSettings}
        />
      )}
    </ModalButton>
  );
};

export default SendCounterOfferButton;
