import React from 'react';
import { useRecordContext } from 'react-admin';

import UpdateDecisionButton from './UpdateDecisionButton';
import UpdateAcceptanceDecisionButton from './UpdateAcceptanceDecisionButton';
import MarkAsSignedButton from './MarkAsSignedButton';
import MarkAsConfirmedButton from './MarkAsConfirmedButton';
import SendCounterOfferButton from './SendCounterOfferButton';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';

import useHasUserPermissions from '../hooks/useHasUserPermissions';

const ApplicationButtons = () => {
  const { isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions, decisionStatuses } = constants;
  const record = useRecordContext();
  const { applicationStatus, deniedApplicationReceivedFromPartner } = record;

  const canEditApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_FINANCING_APPLICATIONS,
  );
  const sentToPartner = applicationStatus === decisionStatuses.PARTNER_PENDING
    || applicationStatus === decisionStatuses.PARTNER_GRANTED;

  if (!canEditApplication || sentToPartner || deniedApplicationReceivedFromPartner) return null;

  return (
    <>
      {isFinancer && <UpdateDecisionButton />}
      <UpdateAcceptanceDecisionButton isMerchant={isMerchant} />
      {isFinancer && <MarkAsSignedButton />}
      {isMerchant && <MarkAsConfirmedButton />}
      <SendCounterOfferButton />
    </>
  );
};

export default ApplicationButtons;
