import React from 'react';
import {
  TextField,
  FunctionField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../../utils/CurrencyField';
import ApplicationStatusChip from '../../utils/ApplicationStatusChip';

import { useConstantContext } from '../../ConstantsContext';
import List from '../../designSystem/react-admin/list/List';
import ReferenceField, { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';
import DateField from '../../designSystem/react-admin/DateField';
import { DatagridConfigurable } from '../../designSystem/react-admin/Datagrid';

import useApplicationFilters from '../../application/useApplicationFilters';
import OpenInNewButton from '../../application/OpenInNewButton';
import RulesDecisionChips from '../../application/RulesDecisionChips';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import TitleH1 from '../../designSystem/TitleH1';

export const getCustomerApplicationListTitle = (t) => t('Customer applications');

const CustomerApplicationList = () => {
  const { constants } = useConstantContext();
  const {
    decisionStatuses,
    userActions,
  } = constants;
  const { t } = useTranslation();

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );
  const applicationFilters = useApplicationFilters();

  return (
    <List
      title={<TitleH1 title={getCustomerApplicationListTitle(t)} />}
      configurable
      mainFilterInputs={applicationFilters}
      filterDefaultValues={{
        currentDecision: decisionStatuses.REFERRED,
      }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      appLocation="home.customer-application"
    >
      <DatagridConfigurable
        cellMaxWidth="10rem"
        bulkActionButtons={false}
        rowClick="edit"
        rightAlignedColumns={['openInNew', 'requestedTerms\\.creditLimit', 'currentDecision']}
      >
        {[
          <TextField
            source="debtorInfo.marketplaceReferenceId"
            label={t('Customer alias')}
          />,
          <TextField
            label={t('Customer name')}
            source="debtorInfo.mainAddress.name"
          />,
          canViewMerchant && (
            <EntityReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2/merchant"
              link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
            />
          ),
          <DateField source="createdAt" label={t('Creation date')} />,
          <ReferenceField
            referenceSource="applicant"
            source="applicant" // Not used by the function field but used for the column class name
            reference="user/public"
            label={t('Requesting user')}
            FieldComponent={FunctionField}
            FieldComponentProps={{ render: (user) => `${user.firstName} ${user.lastName}` }}
          />,
          <RulesDecisionChips label={t('Decisions')} source="decisionData" />,
          <CurrencyField
            label={t('Requested credit limit')}
            source="requestedTerms.creditLimit"
            currencySource="requestedTerms.creditLimitCurrency"
            sortable={false}
          />,
          <ApplicationStatusChip source="currentDecision" label={t('Application status')} />,
          <WrapperField label={t('Open')} source="openInNew">
            <OpenInNewButton linkType="edit" />
          </WrapperField>,
        ].filter((col) => !!col).map((col) => React.cloneElement(col, { key: col.props.source }))}
      </DatagridConfigurable>
    </List>
  );
};

export default CustomerApplicationList;
