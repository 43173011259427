import React from 'react';
import {
  ReferenceField,
  ReferenceOneField,
  useRecordContext,
} from 'react-admin';

import Grid from '@mui/material/Grid';

import MerchantDetailsGroup from '../application/financingApplication/MerchantDetailsGroup';
import CustomerDetailsGroup from '../application/financingApplication/CustomerDetailsGroup';
import CustomerDetailsInApplication from '../application/financingApplication/CustomerDetailsInApplication';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const EntityDetailsGroup = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const record = useRecordContext() || {};
  const terms = record.terms || {};

  return (
    <>
      {isFinancer ? (
        <Grid container>
          <Grid item lg={6} md={12}>
            <ReferenceField
              source="merchantId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceOneField
                reference="entity-v2/merchant"
                source="entityDetailsId"
                target="id"
                link={false}
                filter={{ custom_kpiReferenceCurrency: terms.financingCurrency }}
              >
                <MerchantDetailsGroup />
              </ReferenceOneField>
            </ReferenceField>
          </Grid>
          <Grid item lg={6} md={12}>
            <ReferenceField
              source="debtorId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceOneField
                reference="entity-v2/customer"
                source="entityDetailsId"
                target="id"
                link={false}
                filter={{ custom_kpiReferenceCurrency: terms.financingCurrency }}
              >
                <CustomerDetailsGroup />
              </ReferenceOneField>
            </ReferenceField>
          </Grid>
        </Grid>
      ) : <CustomerDetailsInApplication />}
    </>
  );
};

export default EntityDetailsGroup;
