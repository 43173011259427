/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  Datagrid as RaDatagrid,
  DatagridConfigurable as RaDatagridConfigurable,
  useListContext,
  useTheme,
} from 'react-admin';

import EmptyData from '../../EmptyData';
import ScrollHorizontalButtons from '../../utils/scrollButtons/ScrollHorizontalButtons';
import { ChildrenProp } from '../../../utils/helpers/propTypes';

const datagridVariants = (theme) => ({
  blueBackground: `
    border-left: 2px solid ${theme.palette.secondary.main};
    background-color: ${theme.palette.hover};
  `,
  greyBackground: `
    background-color: ${theme.palette.background.default};
  `,
  greyBackgroundWithStretch: `
    background-color: ${theme.palette.background.default};
    flex: 1;
  `,
  whiteBackground: `
    padding: 0rem 0rem;
  `,
});

export const getDatagridStyle = ({
  theme,
  cellMaxWidth,
  rowClick,
  rightAlignedColumns,
}) => {
  const blueBackgroundOnHoverVariantStyle = theme.components.MuiButton.variants
    .find(({ props: { variant } }) => variant === 'blueBackgroundOnHover');

  return {
    ...rightAlignedColumns
      ? {
        [`& ${rightAlignedColumns.map((amountColumn) => `.column-${amountColumn} > *`).join(',')}`]: {
          float: 'right',
        },
      } : {},
    '& .RaDatagrid-headerCell > *': {
      color: theme.palette.headers,

      '& .MuiTableSortLabel-icon path': { fill: theme.palette.secondary.main },
    },
    '& .RaDatagrid-rowCell': {
      color: theme.palette.switch,
      ':last-child a': { marginRight: `-${blueBackgroundOnHoverVariantStyle.style.paddingRight}` }, // Assuming all the a tags in scrollable list have the blueBackgroundOnHover style
      '& a': { marginLeft: `-${blueBackgroundOnHoverVariantStyle.style.paddingLeft}` }, // Assuming all the a tags in scrollable list have the blueBackgroundOnHover style
    },
    '& .MuiTableCell-root': {
      padding: '0.25rem 0.5rem',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      fontWeight: '500',
      '& .MuiTableSortLabel-root': { fontWeight: 700 },
    },
    '& .MuiTableRow-root': {
      height: '2.75rem',
      '& .column-openInNew > *': { display: 'none' },
      '& .column-openInNew': { minWidth: '3.25rem' },
      '&:hover .column-openInNew:not(.RaDatagrid-headerCell) > *': { display: 'inline-flex' },
    },
    ...rowClick ? {
      '& .MuiTableRow-hover': {
        ':hover': {
          backgroundColor: `${theme.palette.listActive}!important`, // Mui adds the class via js so it will always get on top of this one. Hence the !important
          cursor: 'pointer',
        },
      },
    } : {},
    '& .MuiTypography-root': {
      maxWidth: cellMaxWidth || '15rem',
      width: 'fit-content',
      display: 'block', // Ellipsis works on block containers. See https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow or https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
};

const StyledDatagridRoot = styled(Box)`
  padding: 1rem 2rem;
  & > * .RaDatagrid-headerCell {
    background-color: transparent!important;
  };
  border-radius: 10px;

  ${({ variant, theme }) => (datagridVariants(theme)[variant] ? datagridVariants(theme)[variant] : '')}

  ${({ theme }) => ({
    '& .RaBulkActionsToolbar-toolbar': {
      backgroundColor: `${theme.palette.listActive}!important`,

      '& .RaBulkActionsToolbar-title': {
        '& h6': {
          fontSize: '12px',
          fontWeight: 600,
          margin: 'auto',
        },
        '& button > svg': { fill: 'black' },
      },
    },
  })}
`;

const ScrollableDatagrid = ({ variant, children }) => {
  const [element, setElement] = useState();
  const { isLoading } = useListContext();
  useEffect(() => {
    if (!isLoading) {
      const result = window.document.getElementsByClassName('RaDatagrid-tableWrapper')[0];
      setElement(result);
    }
  }, [isLoading]);

  const [theme] = useTheme();

  return (
    <StyledDatagridRoot variant={variant}>
      <ScrollHorizontalButtons
        scrollableElement={element}
        color={theme.palette.primary.main}
        backgroundColor={theme.palette.common.white}
        extraPadding={{ top: 48 }} // header height
      />
      {children}
    </StyledDatagridRoot>
  );
};
ScrollableDatagrid.propTypes = {
  children: ChildrenProp.isRequired,
  variant: PropTypes.string.isRequired,
};

const StyledDatagrid = styled(RaDatagrid, {
  shouldForwardProp: (prop) => !['cellMaxWidth', 'rightAlignedColumns'].includes(prop),
})(getDatagridStyle);

const StyledDatagridConfigurable = styled(RaDatagridConfigurable, {
  shouldForwardProp: (prop) => !['cellMaxWidth', 'rightAlignedColumns'].includes(prop),
})(getDatagridStyle);

const Datagrid = ({ variant, empty, ...restProps }) => (
  <ScrollableDatagrid variant={variant}>
    <StyledDatagrid empty={empty || <EmptyData />} {...restProps} />
  </ScrollableDatagrid>
);

export const DatagridConfigurable = ({ variant, ...restProps }) => (
  <ScrollableDatagrid variant={variant}>
    <StyledDatagridConfigurable empty={<EmptyData />} {...restProps} />
  </ScrollableDatagrid>
);

Datagrid.propTypes = {
  variant: PropTypes.string,
};
DatagridConfigurable.propTypes = Datagrid.propTypes;
Datagrid.defaultProps = {
  variant: 'whiteBackground',
};
DatagridConfigurable.defaultProps = Datagrid.defaultProps;

export default Datagrid;
