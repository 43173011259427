import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { useRecordContext } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import NumberInput from './NumberInput';

const CurrencyInput = ({
  currency,
  currencySource,
  ...rest
}) => {
  const record = useRecordContext();

  const currencyValue = currency ?? get(record, currencySource);

  return (
    <NumberInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      InputProps={{ endAdornment: <InputAdornment position="end"><Typography fontSize="15px" color="headers">{currencyValue || ''}</Typography></InputAdornment> }}
    />
  );
};

CurrencyInput.propTypes = {
  currency: PropTypes.string,
  currencySource: PropTypes.string,
};

CurrencyInput.defaultProps = {
  currency: undefined,
  currencySource: '',
};

export default CurrencyInput;
