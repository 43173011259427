import React from 'react';
import { TextField } from 'react-admin';
import { styled } from '@mui/material';

const StyledTextField = styled(TextField)`
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const DescriptionField = (props) => <StyledTextField {...props} sx={{ whiteSpace: 'break-spaces!important' }} />;

export default DescriptionField;
