import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceArrayInput as RaReferenceArrayInput } from 'react-admin';
import { ChildrenProp } from '../../../../utils/helpers/propTypes';

const ReferenceArrayInput = ({
  InputLabelProps,
  children,
  handleHide,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RaReferenceArrayInput {...rest}>
    {React.cloneElement(
      children,
      { ...children.props, InputLabelProps, handleHide },
    )}
  </RaReferenceArrayInput>
);

ReferenceArrayInput.propTypes = {
  InputLabelProps: PropTypes.shape(),
  children: ChildrenProp.isRequired,
  handleHide: PropTypes.func,
};

ReferenceArrayInput.defaultProps = {
  handleHide: undefined,
  InputLabelProps: {},
};

export default ReferenceArrayInput;
