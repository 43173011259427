import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import {
  required,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';
import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import InputsGroup from '../designSystem/InputsGroup';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

import {
  bicValidation,
  emailValidation,
  ibanValidation,
  phoneValidation,
  positiveNumberValidation,
} from '../../utils/validationErrors';

import useCustomRpc from '../hooks/useCustomRpc';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

const validateRequired = [required()];

const UpdateCompanyDetailsButton = ({
  userAction,
  enableAliasInput,
  isMyCompanyPage,
}) => {
  const {
    id: entityDetailsId,
    entityRoles,
    contactEmail,
    invoiceEmail,
    phone,
    mainAddress,
    iban,
    bic,
    bankgiro,
    naceCode,
    isSME,
    isRelated,
    balanceSheetTotal,
    annualTurnover,
    staffHeadcount,
    sector,
    marketplaceReferenceId,
  } = useRecordContext();
  const { t } = useTranslation();

  const { choices, constants } = useConstantContext();
  const entityDetailsRecord = useRecordContext();

  const canEdit = useHasUserPermissions(
    constants,
    userAction,
  );

  const coreTypeInput = useWatch({ name: 'type' });
  const choiceStates = choices;

  const path = isMyCompanyPage ? 'my-company/company-details' : `entity-v2/${entityRoles[0]}/${entityDetailsId}`;

  const {
    mutate: updateCompanyDetails,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: isMyCompanyPage ? t('Could not update the company details') : `${t('Could not update the details of')} ${entityRoles[0]}`,
  });

  const onSubmit = useCallback((formData) => {
    updateCompanyDetails(formData);
    return true;
  }, [updateCompanyDetails]);

  if (!entityDetailsRecord && !coreTypeInput) return null;
  if (entityDetailsRecord && coreTypeInput) throw new Error('Cannot render entity details. One source of truth is required for the entity core type');

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? entityDetailsRecord.coreType === constants.coreTypes.BUSINESS
      : undefined;
  }

  if (!canEdit) return null;

  return (
    <EditModalButton
      modalTitle={isBusiness ? t('Update company details') : t('Update consumer details')}
      onClick={onSubmit}
      disabled={isLoading}
      width="47.5rem"
      formDefaultValue={{
        id: entityDetailsId,
        contactEmail,
        invoiceEmail,
        phone,
        mainAddress,
        iban,
        bic,
        bankgiro,
        naceCode,
        isSME,
        isRelated,
        balanceSheetTotal,
        annualTurnover,
        staffHeadcount,
        sector,
        marketplaceReferenceId,
      }}
    >
      <InputsGroup layout="column">
        {enableAliasInput && <TextInput source="marketplaceReferenceId" label={t('Alias')} validate={validateRequired} />}
        <TextInput source="mainAddress.name" label={t('Name')} validate={validateRequired} />
        <TextInput source="iban" label="IBAN" validate={[ibanValidation]} />
        <TextInput source="mainAddress.streetAddress" label={t('Street address')} />
        <TextInput source="bic" label="BIC" validate={[bicValidation]} />
        <TextInput source="mainAddress.additionalStreetAddress" label={t('Additional street address')} />
        <TextInput source="bankgiro" label={t('Bankgiro')} />
        <TextInput source="mainAddress.town" label={t('Town')} />
        <TextInput source="phone" type="tel" label={t('Phone')} validate={[phoneValidation]} />
        <TextInput source="mainAddress.postcode" label={t('Postcode')} />
        <TextInput source="invoiceEmail" label={t('Invoice email')} type="email" validate={[emailValidation]} />
        <TextInput source="contactEmail" label={t('Contact email')} type="email" validate={[emailValidation]} />
        {isBusiness && (
          <AutocompleteInput source="naceCode" label={t('NACE code')} choices={choiceStates.naceCodes} />
        )}
        {isBusiness && !isMyCompanyPage && (
          <NumberInput source="staffHeadcount" label={t('Staff headcount')} validate={[positiveNumberValidation]} />
        )}
        {isBusiness && !isMyCompanyPage && (
          <NumberInput source="annualTurnover" label={t('Annual turnover')} />
        )}
        {isBusiness && !isMyCompanyPage && (
          <NumberInput source="balanceSheetTotal" label={t('Balance sheet total')} />
        )}
        {isBusiness && !isMyCompanyPage && (
          <AutocompleteInput source="sector" label={t('Sector')} choices={choiceStates.companySectors} />
        )}
      </InputsGroup>
      {isBusiness && !isMyCompanyPage
        && (
          <InputsGroup title={t('Additional information')}>
            <BooleanInput
              label={t('Is a small or medium enterprise')}
              source="isSME"
            />
            <BooleanInput source="isRelated" label={t('Is related or same entity group')} />
          </InputsGroup>
        )}
    </EditModalButton>
  );
};

UpdateCompanyDetailsButton.propTypes = {
  userAction: PropTypes.string.isRequired,
  enableAliasInput: PropTypes.bool,
  isMyCompanyPage: PropTypes.bool,
};

UpdateCompanyDetailsButton.defaultProps = {
  enableAliasInput: false,
  isMyCompanyPage: false,
};

export default UpdateCompanyDetailsButton;
