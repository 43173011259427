import { styled } from '@mui/material';
import { FilterButton as RaFilterButton } from 'react-admin';

const StyledFilterButton = styled(RaFilterButton)(({ theme }) => ({
  marginTop: '1.25rem',
  marginLeft: '-0.3rem',
  '& .MuiButtonBase-root': theme.components.MuiButton.variants
    .find(({ props: { variant } }) => variant === 'blueBackgroundOnHover').style,
}));

const FilterButton = StyledFilterButton;

export default FilterButton;
