import React, { useCallback } from 'react';
import { required } from 'react-admin';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ModalButton from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import { useConstantContext } from '../ConstantsContext';

import useCustomRpc from '../hooks/useCustomRpc';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const IdentifyButton = ({ id }) => {
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canManage = useHasUserPermissions(
    constants,
    userActions.MANAGE_UNIDENTIFIED_PAYMENTS,
  );

  const {
    mutate: identifyUnidentifiedPayment,
    isLoading,
  } = useCustomRpc({
    path: `unidentified-account/${id}/identify`,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Failed to identify the payment'),
    successMessage: t('Payment identified successfully'),
  });

  const onSubmitIdentify = useCallback(({ paymentReference }) => {
    identifyUnidentifiedPayment({ paymentReference });
    return true;
  }, [identifyUnidentifiedPayment]);

  if (!canManage) return null;

  return (
    <ModalButton
      modalTitle={t('Identify')}
      onClick={onSubmitIdentify}
      disabled={isLoading}
      openButtonLabel={t('Identify')}
      actionLabel={t('Identify')}
      width="30rem"
      formDefaultValue={{}}
      variant="subAction"
    >
      <TextInput
        label={t('Payment reference')}
        source="paymentReference"
        placeholder={t('Enter the payment reference')}
        validate={[required(t('Please enter a payment reference to the account the unidentified payment should be matched to'))]}
      />
    </ModalButton>
  );
};

IdentifyButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default IdentifyButton;
