import React from 'react';
import { Loading } from 'react-admin';
import { isEmpty } from 'lodash';
import FileList from './FileList';
import { useConstantContext } from '../ConstantsContext';

const InboundPaymentFileList = () => {
  const { constants } = useConstantContext();
  const { fileTypes } = constants;
  if (isEmpty(fileTypes)) return <Loading />;

  return (
    <FileList
      fileType={fileTypes.INBOUND}
      title={<></>}
      resource="file/inbound-payments"
      disableSyncWithLocation
    />
  );
};

export default InboundPaymentFileList;
