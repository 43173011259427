import React from 'react';
import {
  SimpleForm,
} from 'react-admin';
import { Navigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import CompanyDetailsCreate from '../CompanyDetailsCreate';
import CoreInputGroup from '../CoreInputGroup';
import KeyPeopleInputs from '../KeyPeopleInputs';

import { useConstantContext } from '../../ConstantsContext';
import Create from '../../designSystem/react-admin/Create';

import TitleH1 from '../../designSystem/TitleH1';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

export const getMerchantCreateTitle = (t) => t('Create merchant');

const MerchantCreate = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canCreateMerchant = useHasUserPermissions(
    constants,
    userActions.CREATE_MERCHANTS,
  );

  if (!canCreateMerchant) return <Navigate to="/notification" />;

  return (
    <Create title={<TitleH1 title={getMerchantCreateTitle(t)} />} appLocation="home.entity-v2/merchant.create">
      <SimpleForm sanitizeEmptyValues>
        <CoreInputGroup isMerchant />
        <CompanyDetailsCreate userAction={userActions.CREATE_MERCHANTS} enableAliasInput />
        <KeyPeopleInputs userAction={userActions.CREATE_MERCHANTS} />
      </SimpleForm>
    </Create>
  );
};

export default MerchantCreate;
