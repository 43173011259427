import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useRecordContext,
  usePermissions,
} from 'react-admin';

import { Typography } from '@mui/material';

import useCustomRpc from '../hooks/useCustomRpc';

import hasUserPermissions from '../../utils/hasUserPermissions';

import { useConstantContext } from '../ConstantsContext';
import ModalButton from '../designSystem/ModalButton';

const RevertPaymentButton = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const { id, type, relatedTransaction } = record;
  const { userActions, transactionTypes } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const {
    mutate: revertPayment,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'put',
    path: `transaction/${id}`,
    shouldRefresh: true,
    errorMessage: 'Could not revert payment',
  });

  const onSubmit = useCallback(() => {
    revertPayment();
    return true;
  }, [revertPayment]);

  const canRevertPayment = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_PAYMENTS,
  );

  const isUserPayment = type === transactionTypes.USER_PAYMENT;
  // Some form of check to make sure they cannot revert on closed settlement accounts
  const alreadyReverted = !!relatedTransaction;

  if (!canRevertPayment || !isUserPayment || alreadyReverted) return null;

  return (
    <ModalButton
      modalTitle={t('Revert incorrect payment')}
      openButtonLabel={t('Revert payment')}
      actionLabel={t('Revert incorrect payment')}
      variant="subAction"
      onClick={onSubmit}
      disabled={isLoading}
      withForm
      width="47.5rem"
    >
      <Typography>
        {t('A transaction will be generated to revert the payment selected.')}
        {t('This action cannot be reverted.')}
      </Typography>
    </ModalButton>
  );
};

export default RevertPaymentButton;
