import React from 'react';

import {
  TextField,
  ReferenceField,
  FunctionField,
  useRecordContext,
} from 'react-admin';

import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../ConstantsContext';

import TooltipBox from '../designSystem/TooltipBox';

const FinancerName = () => {
  const { t } = useTranslation();
  const { partnerId, partnerBillingId, debtorId } = useRecordContext();
  const { clientInfo } = useConstantContext();
  const { pathname } = useLocation();
  const isBilling = pathname.includes('billing');
  const checkedId = isBilling ? partnerBillingId : partnerId;

  const displayPartnerId = checkedId
    && clientInfo.defaultPartnerFinancer;

  const isPartnerSettlement = debtorId
    && clientInfo.defaultPartnerFinancer
    && debtorId === clientInfo.defaultPartnerFinancer;

  if (displayPartnerId) {
    return (
      <ReferenceField
        source="partnerId"
        reference="entity-v2/core"
        emptyText="-"
        link={false}
      >
        {isBilling ? (
          <TooltipBox tooltip={t('This billing is mirrored from the partner, only the partner can charge billings in settlements and move billings to invoiced.')}>
            <FunctionField render={({ name }) => `${name}`} />
          </TooltipBox>
        ) : (
          <FunctionField render={({ name }) => `${name}`} />
        )}
      </ReferenceField>
    );
  }

  if (isPartnerSettlement) {
    return (
      <ReferenceField
        source="debtorId"
        reference="entity-v2/core"
        emptyText="-"
        link={false}
      >
        <FunctionField render={({ name }) => `${name}`} />
      </ReferenceField>
    );
  }

  return (
    <TextField source="creditorName" />
  );
};

export default FinancerName;
