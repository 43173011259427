import React from 'react';
import {
  SimpleForm,
} from 'react-admin';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CoreInputGroup from '../CoreInputGroup';
import CompanyDetailsCreate from '../CompanyDetailsCreate';
import KeyPeopleInputs from '../KeyPeopleInputs';

import { useConstantContext } from '../../ConstantsContext';
import Create from '../../designSystem/react-admin/Create';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import TitleH1 from '../../designSystem/TitleH1';

export const getCustomerCreateTitle = (t) => t('Create customer');

const CustomerCreate = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { userActions } = constants;

  const canCreateCustomer = useHasUserPermissions(
    constants,
    userActions.CREATE_CUSTOMERS,
  );

  if (!canCreateCustomer) return <Navigate to="/notification" />;

  return (
    <Create title={<TitleH1 title={getCustomerCreateTitle(t)} />} appLocation="home.entity-v2/customer.create">
      <SimpleForm sanitizeEmptyValues defaultValues={{ type: constants.coreTypes.BUSINESS }}>
        <CoreInputGroup />
        <CompanyDetailsCreate userAction={userActions.CREATE_CUSTOMERS} enableAliasInput />
        <KeyPeopleInputs userAction={userActions.CREATE_CUSTOMERS} />
      </SimpleForm>
    </Create>
  );
};

export default CustomerCreate;
