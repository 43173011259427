import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../ConstantsContext';

import TransactionList from './TransactionList';
import InboundPaymentFileList from '../file/InboundPaymentFileList';

import TabbedForm from '../designSystem/react-admin/TabbedForm';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const TransactionListTab = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const { userActions } = constants;

  const canManagePaymentsPermission = useHasUserPermissions(
    constants,
    userActions.MANAGE_PAYMENTS,
  );

  return (
    <TabbedForm>
      <FormTab label={t('Payments')} sx={{ alignItems: 'stretch' }}>
        <TransactionList />
      </FormTab>
      {canManagePaymentsPermission && (
        <FormTab label={t('Inbound payment files')} sx={{ alignItems: 'stretch' }}>
          <InboundPaymentFileList />
        </FormTab>
      )}
    </TabbedForm>
  );
};

export default TransactionListTab;
