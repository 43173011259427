const renderReference = (record) => {
  if (!record) return '';

  const { mainAddress, marketplaceReferenceId } = record;

  if (mainAddress && mainAddress.name) return `${mainAddress.name}`;
  if (marketplaceReferenceId) return `(${marketplaceReferenceId}) -`;

  return '';
};

export default renderReference;
