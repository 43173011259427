import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  BooleanField,
  ArrayField,
  FunctionField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import renderRoles from './renderRoles';
import Show from '../designSystem/react-admin/Show';
import UserTitle from './UserTitle';

const UserShow = () => {
  const { t } = useTranslation();
  return (
    <Show
      title={<UserTitle />}
      appLocation="home.manage-users.show/user"
    >
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="email" type="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <ArrayField
          label={t('Roles')}
          source="roles"
        >
          <FunctionField render={renderRoles} />
        </ArrayField>
        <BooleanField source="settings.notificationEmailAllowed" label={t('Allow Notification Emails')} />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
