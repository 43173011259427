import resources from '../../constants/resources';

export const resourceAction = {
  SHOW: 'show',
  EDIT: 'edit',
};

const mapResourceAction = (userActions) => (
  {
    [resources.FINANCING_APPLICATION]: {
      [resourceAction.SHOW]: [userActions.VIEW_FINANCING_APPLICATIONS],
      [resourceAction.EDIT]: [
        userActions.EDIT_FINANCING_APPLICATIONS,
        userActions.UPDATE_FINANCING_APPLICATIONS_TERMS,
      ],
    },
    [resources.CUSTOMER_APPLICATION]: {
      [resourceAction.SHOW]: [userActions.VIEW_CUSTOMER_APPLICATIONS],
      [resourceAction.EDIT]: [
        userActions.EDIT_CUSTOMER_APPLICATIONS,
        userActions.UPDATE_CUSTOMER_APPLICATIONS_TERMS,
        userActions.TAKE_DECISION_ON_CUSTOMER_APPLICATIONS,
      ],
    },
    [resources.LOAN_APPLICATION]: {
      [resourceAction.SHOW]: [userActions.VIEW_LOAN_APPLICATIONS],
      [resourceAction.EDIT]: [
        userActions.EDIT_LOAN_APPLICATIONS,
      ],
    },
    [resources.CUSTOMER]: {
      [resourceAction.SHOW]: [userActions.VIEW_CUSTOMERS],
      [resourceAction.EDIT]: [
        userActions.ADD_COMMENTS_ON_CUSTOMERS,
        userActions.CREATE_CUSTOMERS,
        userActions.MANAGE_CUSTOMER_CREDIT_LIMIT_FOR_MERCHANTS,
      ],
    },
    [resources.MERCHANT]: {
      [resourceAction.SHOW]: [userActions.VIEW_MERCHANTS],
      [resourceAction.EDIT]: [
        userActions.CREATE_MERCHANTS,
        userActions.ADD_COMMENTS_ON_MERCHANTS,
        userActions.SET_MERCHANT_KYC,
        userActions.ADD_COMMENTS_ON_MERCHANTS,
      ],
    },
    [resources.UNIDENTIFIED_ACCOUNT]: {
      [resourceAction.SHOW]: [userActions.VIEW_UNIDENTIFIED_PAYMENTS],
      [resourceAction.EDIT]: [userActions.MANAGE_UNIDENTIFIED_PAYMENTS],
    },
    [resources.REPORT]: {
      [resourceAction.SHOW]: [userActions.VIEW_REPORTS],
      [resourceAction.EDIT]: [userActions.GENERATE_REPORTS],
    },
    [resources.ORDER]: {
      [resourceAction.SHOW]: [userActions.VIEW_ORDERS],
      [resourceAction.EDIT]: [userActions.CREATE_ORDERS],
    },
    [resources.LOAN]: {
      [resourceAction.SHOW]: [userActions.VIEW_LOANS],
      [resourceAction.EDIT]: [userActions.EDIT_LOANS],
    },
  }
);
export default mapResourceAction;
