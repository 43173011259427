import React from 'react';
import PropTypes from 'prop-types';
import {
  FormTab,
  ReferenceField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FactoringAccountFields from './FactoringAccountFields';

import TabbedForm from '../designSystem/react-admin/TabbedForm';
import Edit from '../designSystem/react-admin/Edit';

import { useConstantContext } from '../ConstantsContext';
import LossReservationList from './LossReservationList';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import TitleH1 from '../designSystem/TitleH1';

const FactoringName = ({ isBreadcrumbsTitle }) => {
  const { orderReferenceNumber } = useRecordContext();
  const { t } = useTranslation();
  const title = isBreadcrumbsTitle ? orderReferenceNumber
    : <TitleH1 title={`${t('Financing')}: ${orderReferenceNumber}`} />;
  return title;
};
FactoringName.propTypes = {
  isBreadcrumbsTitle: PropTypes.bool,
};
FactoringName.defaultProps = {
  isBreadcrumbsTitle: false,
};

export const FactoringTitle = ({ isBreadcrumbsTitle }) => (
  <ReferenceField
    source="factoredOrder"
    reference="order"
    link={false}
  >
    <FactoringName isBreadcrumbsTitle={isBreadcrumbsTitle} />
  </ReferenceField>
);
FactoringTitle.propTypes = FactoringName.propTypes;
FactoringTitle.defaultProps = FactoringName.defaultProps;

const FactoringAccountEdit = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewLossReservation = useHasUserPermissions(
    constants,
    userActions.VIEW_LOSS_RESERVATION,
  );

  return (
    <Edit
      title={<FactoringTitle />}
      mutationMode="optimistic"
      redirect={false}
      appLocation="home.factoring-account.edit"
    >
      <TabbedForm toolbar={null}>
        <FormTab label={t('Factoring')}>
          <FactoringAccountFields />
        </FormTab>
        {canViewLossReservation && (
          <FormTab label={t('Loss reservation')}>
            <LossReservationList />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default FactoringAccountEdit;
