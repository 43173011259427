import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'react-admin';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import useCustomRpc from '../hooks/useCustomRpc';

const GetPaymentPlanButton = ({
  accountId,
  setInvoices,
}) => {
  const { t } = useTranslation();
  const {
    mutate: getIncomingInvoices,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'GET',
    path: `loan/${accountId}/payment-plan`,
    shouldRefresh: true,
    errorMessage: t('Could not get payment plan'),
    onSuccess: (data) => {
      setInvoices(data.data);
    },
  });

  if (isLoading) return <Loading loadingPrimary="" loadingSecondary="" />;

  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignSelf="flex-end"
    >
      <Button
        onClick={getIncomingInvoices}
        variant="contained"
        color="primary"
        size="small"
      >
        {t('Get payment plan')}
      </Button>
    </Box>
  );
};

GetPaymentPlanButton.propTypes = {
  accountId: PropTypes.string.isRequired,
  setInvoices: PropTypes.func.isRequired,
};

export default GetPaymentPlanButton;
