import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';
import resources from '../../../constants/resources';
import InvoiceGroup from '../../order/InvoicesGroup';
import AccountInvoiceSettings from '../../entityv2/AccountInvoiceSettings';
import PaymentPlanList from '../PaymentPlanList';

const InvoicesTab = (props) => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Invoices')} {...props}>
      <AccountInvoiceSettings resource={resources.LOAN} />
      <InvoiceGroup />
      <PaymentPlanList />
    </FormTab>
  );
};

export default InvoicesTab;
