import React, { useCallback } from 'react';

import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/DeleteOutline';

import useCustomRpc from '../hooks/useCustomRpc';

const RemoveEntityButton = () => {
  const record = useRecordContext();
  const { _id, groupId } = record;
  const { t } = useTranslation();

  const {
    mutate: removeEntity,
  } = useCustomRpc({
    path: `entity-group/${groupId}/remove-entity`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not remove entity from group'),
  });

  const onClick = useCallback(() => {
    const data = {
      _id,
      groupId,
    };
    removeEntity(data);
    return true;
  }, [removeEntity, _id, groupId]);

  return (
    <IconButton
      onClick={onClick}
      color="secondary"
    >
      <Delete sx={{ width: '1.25rem', height: '1.25rem' }} />
    </IconButton>
  );
};

export default RemoveEntityButton;
