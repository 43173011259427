import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';
import useCustomRpc from '../hooks/useCustomRpc';

import FinancingApplicationTermsForm from './FinancingApplicationTermsFormNew';
import { useConstantContext } from '../ConstantsContext';

const UpdateFinancingTermsButton = () => {
  const {
    id,
    applicationStep,
    orderAmount,
    terms,
    orderCurrency,
    merchantId,
  } = useRecordContext();
  const { constants } = useConstantContext();
  const {
    applicationSteps,
    resourceTypePaths,
    resourceTypes,
  } = constants;
  const { t } = useTranslation();

  const {
    mutate: updateFinancingTerms,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resourceTypePaths[resourceTypes.FINANCING_APPLICATION]}/${id}/terms`,
    shouldRefresh: true,
    errorMessage: t('Could not update the terms'),
  });

  const onSubmit = useCallback((newTerms) => {
    updateFinancingTerms(newTerms);
    return true;
  }, [updateFinancingTerms]);

  if (applicationStep !== applicationSteps.CREATED) return null;

  return (
    <EditModalButton
      modalTitle={t('Update terms')}
      onClick={onSubmit}
      disabled={isLoading}
      formDefaultValue={terms}
      width="47.5rem"
    >
      <FinancingApplicationTermsForm
        orderAmount={orderAmount}
        orderCurrency={orderCurrency}
        merchantId={merchantId}
        termsFinancingCurrency={terms.financingCurrency}
        termsFeeSettings={terms.feeSettings}
      />
    </EditModalButton>
  );
};

export default UpdateFinancingTermsButton;
