import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useListContext,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Button } from '@mui/material';
import useCustomRpc from '../hooks/useCustomRpc';

const MarkAsReadBatchButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds } = useListContext();
  const { t } = useTranslation();

  const {
    mutate: markBatchAsRead,
  } = useCustomRpc({
    path: 'notification/mark-as-read',
    httpMethod: 'PUT',
    shouldUnselectAll: 'notification',
    errorMessage: t('Could not mark the notification(s) as read'),
    onSuccess: ({ data }) => {
      notify(`${data.markedAsRead} ${t('Notification(s) mark as read')}`);
      if (data.markedAsRead) refresh();
    },
  });
  const onClick = useCallback((e) => {
    e.preventDefault();
    markBatchAsRead({ selectedIds });
  }, [markBatchAsRead, selectedIds]);

  return (
    <Button
      onClick={onClick}
      variant="contained"
      size="small"
    >
      {t('Mark as read')}
    </Button>
  );
};

export default MarkAsReadBatchButton;
