import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import {
  useNotify,
  useRecordContext,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import {
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { ReactComponent as Redo } from '../../../assets/redo.svg';

import ModalButton from '../designSystem/ModalButton';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import renderReference from '../../utils/renderReference';

import useCustomRpc from '../hooks/useCustomRpc';

const MakeRefundButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const { t } = useTranslation();
  const { id } = record;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const {
    mutate: refundPayment,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: `order/${id}/repay-overpayment`,
    shouldRefresh: true,
    errorMessage: t('Could not repay the overpayment'),
    onSuccess: () => {
      notify(t('Success! The overpaid balance has been adjusted, and the refund has been issued to the merchant\'s settlement account'),
        {
          autoHideDuration: 5000,
        });
    },
  });

  const handleClick = useCallback(() => {
    refundPayment();
  }, [refundPayment]);

  return (
    <ModalButton
      modalTitle={t('Make a refund for overpaid order')}
      actionLabel={t('Refund')}
      openButtonLabel={t('Make refund')}
      onClick={handleClick}
      startIcon={<Redo />}
      disabled={isLoading}
      width="30rem"
    >
      <Typography>
        {t('This order has an overpaid balance with the amount stated below. To adjust it and issue a refund to the merchant\'s settlement account, please proceed with the action. Note that this action cannot be reversed')}
      </Typography>

      <Grid
        container
        display="flex"
        alignItems="start"
        backgroundColor={theme.palette.grey.input}
        borderRadius="0.625rem"
        mt="1rem"
        ml="0"
        justifyContent="space-between"
        padding="0 0 1rem 0"
      >
        <Grid item lg={4} md={6} flex="flex" whiteSpace="nowrap" textAlign="left">
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Merchant')}
              source="sellerId"
              reference="entity-v2/reference"
              link={false}
            >
              <FunctionField render={renderReference} />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6} mr="1rem" textAlign={isSmallScreen ? 'left' : 'right'}>
          <SimpleShowLabeled>
            <CurrencyField
              source="balance"
              currencySource="currency"
              label={t('Overpaid amount')}
              coefficient={-1}
            />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </ModalButton>
  );
};
export default MakeRefundButton;
