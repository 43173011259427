import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  required,
  useRecordContext,
} from 'react-admin';
import Add from '@mui/icons-material/Add';

import useCustomRpc from '../hooks/useCustomRpc';
import renderReference from '../../utils/renderReference';

import { useConstantContext } from '../ConstantsContext';

import ModalButton from '../designSystem/ModalButton';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

const validateRequired = [required()];

const AddEntityModalButton = () => {
  const { constants } = useConstantContext();
  const { entityRoles } = constants;
  const record = useRecordContext();
  const { id: entityGroupId } = record;
  const { t } = useTranslation();

  const {
    mutate: addEntity,
  } = useCustomRpc({
    path: `entity-group/${entityGroupId}/add-entity`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the entity group'),
  });

  const onSubmit = useCallback((entity) => {
    const data = {
      id: entity._id,
      creditLimit: record.creditLimit,
      entities: record.entities,
      groupName: record.groupName,
    };
    addEntity(data);
    return true;
  }, [addEntity, record]);

  return (
    <ModalButton
      openButtonLabel={t('Add entity')}
      modalTitle={t('Add entity')}
      actionLabel={t('Add')}
      onClick={onSubmit}
      formDefaultValue={{}}
      width="30rem"
      startIcon={<Add />}
      sx={{ margin: '0.5rem' }}
    >
      <ReferenceInput
        source="_id"
        reference="entity-v2/reference"
        filter={{ entityRoles: [entityRoles.MERCHANT, entityRoles.CUSTOMER] }}
      >
        <AutocompleteInput
          label={t('Entity')}
          validate={validateRequired}
          optionText={renderReference}
          suggestionLimit={20}
        />
      </ReferenceInput>
    </ModalButton>
  );
};

export default AddEntityModalButton;
