import React from 'react';

import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { useSidebarState } from 'react-admin';
import { styled } from '@mui/material';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const StyledListSubheader = styled(ListSubheader)`
  font-size: 12px;
  font-weight: 600;
  line-height: unset;
  margin-top: 2.3rem;
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.palette.headers}
`;

const MenuGroup = ({
  label,
  children,
}) => {
  const [open] = useSidebarState();

  return (
    <List
      sx={{
        paddingBottom: '0',
      }}
      subheader={open ? (
        <StyledListSubheader disableSticky>
          {label}
        </StyledListSubheader>
      ) : null}
    >
      {children}
    </List>
  );
};

MenuGroup.propTypes = {
  label: PropTypes.string,
  children: ChildrenProp,
  leftIcon: PropTypes.shape({}),
};

MenuGroup.defaultProps = {
  label: null,
  children: null,
  leftIcon: {},
};

export default MenuGroup;
