import React from 'react';
import {
  ArrayField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import CurrencyField from '../../utils/CurrencyField';
import SimpleShowLabeledList from '../../utils/SimpleShowLabeledList';

import Datagrid from '../../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';

const AssignedCreditLimitsGrid = () => {
  const { t } = useTranslation();

  return (
    <Box overflow="auto" whiteSpace="nowrap" marginTop="2.5rem">
      <SimpleShowLabeledList fullWidth label={t('Assigned credit limits')}>
        <ArrayField source="terms.assignedCreditLimits">
          <Datagrid bulkActionButtons={false}>
            <EntityReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2/merchant"
              link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
            />
            <CurrencyField source="assignedCreditLimit" currencySource="creditLimitCurrency" label={t('Assigned credit limit')} emptyText="-" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeledList>
    </Box>
  );
};

export default AssignedCreditLimitsGrid;
