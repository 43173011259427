import React from 'react';
import {
  TabbedForm,
  TextField,
  required,
  useRecordContext,
  ReferenceManyField,
  FunctionField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import SaveOnlyToolbar from '../utils/SaveOnlyToolbar';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import SafePagination from '../utils/SafePagination';
import FieldsGroup from '../utils/FieldsGroup';

import RemoveUserButton from './RemoveUserRoleButton';
import AddUserRoleButton from './AddUserRoleButton';
import RoleEditTitle from './RoleEditTitle';
import UserActionsGroup from './UserActionsGroup';

import Edit from '../designSystem/react-admin/Edit';
import Datagrid from '../designSystem/react-admin/Datagrid';
import TextInput from '../designSystem/react-admin/inputs/TextInput';

const validateRequired = [required()];

const EditDetails = () => {
  const { id, roleType } = useRecordContext();
  const { t } = useTranslation();

  return (
    <TabbedForm sanitizeEmptyValues toolbar={<SaveOnlyToolbar />}>
      <TabbedForm.Tab label={t('Role Settings')}>
        <TextInput
          label={t('Role name')}
          source="name"
          validate={validateRequired}
        />
        <UserActionsGroup roleType={roleType} />
      </TabbedForm.Tab>
      <TabbedForm.Tab label={t('Users with role')}>
        <FieldsGroup actions={<AddUserRoleButton />}>
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            margin: '1rem',
          }}
          >
            <SimpleShowLabeled>
              <TextField label={t('Role users')} source="usersWithRole" />
            </SimpleShowLabeled>

          </Box>
          <Box sx={{ width: '100%' }}>
            <ReferenceManyField
              reference="user"
              resource="/user"
              target="roles.roleId"
              link={false}
              pagination={<SafePagination />}
              perPage={25}
            >
              <Datagrid
                bulkActionButtons={false}
                rowStyle={(record) => ({ opacity: record.active ? 1.0 : 0.4 })}
                rightAlignedColumns={['action']}
              >
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <FunctionField render={(record) => (!record.active ? t('Deactivated') : '')} />
                <WrapperField label={false} source="action">
                  <RemoveUserButton roleId={id} />
                </WrapperField>
              </Datagrid>
            </ReferenceManyField>
          </Box>
        </FieldsGroup>
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

const RoleEdit = () => (
  <Edit
    mutationMode="optimistic"
    redirect={false}
    title={<RoleEditTitle />}
    appLocation="home.manage-users.edit/role"
  >
    <EditDetails />
  </Edit>
);

export default RoleEdit;
