import React, {
  useContext,
  useEffect,
  createContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const AppLocationContext = createContext('');

export const AppLocationContextProvider = ({ children }) => {
  const [appLocation, setAppLocation] = useState('');
  const [record, setRecord] = useState(null);

  const providerValue = {
    appLocation,
    setAppLocation,
    record,
    setRecord,
  };
  return (
    <AppLocationContext.Provider value={providerValue}>
      {children}
    </AppLocationContext.Provider>
  );
};
AppLocationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppLocation = () => {
  const { appLocation, record } = useContext(AppLocationContext);
  return { appLocation, record };
};

export const useDefineLocation = (location) => {
  const { setAppLocation, setRecord } = useContext(AppLocationContext);
  const record = useRecordContext();

  useEffect(() => {
    if (location) setAppLocation(location);
  }, [setAppLocation, location]);

  useEffect(() => {
    setRecord(record);
  }, [setRecord, record]);
};
