import React from 'react';

import {
  SelectField,
  TextField,
  useRecordContext,
} from 'react-admin';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../../utils/FieldsGroup';
import PeerReviewModalButton from '../../peerReview/PeerReviewModalButton';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import ApplicationButtons from '../ApplicationButtons';

import { useConstantContext } from '../../ConstantsContext';

import { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const LoanApplicationFields = () => {
  const record = useRecordContext();
  const { choices, constants } = useConstantContext();
  const { userActions } = constants;

  const { t } = useTranslation();

  const canEditApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_LOAN_APPLICATIONS,
  );

  const pendingReview = !!record.pendingPeerReviewRequest;

  const applicationAction = canEditApplication && (pendingReview ? <PeerReviewModalButton /> : <ApplicationButtons />);

  return (
    <FieldsGroup
      title={t('Application details')}
      actions={applicationAction}
    >
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField source="currentDecision" label={t('Status')} emptyText="-" choices={choices.decisionStatuses} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="paymentReference" label={t('Payment reference')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <EntityReferenceField
              label={t('Customer')}
              source="customerId"
              reference="entity-v2/customer"
              link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
            />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="applicationStep" label={t('Application step')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default LoanApplicationFields;
