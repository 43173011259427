import React from 'react';
import {
  TextField,
  SelectField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';
import SafePagination from '../utils/SafePagination';
import CustomSuffixTextField from '../utils/CustomSuffixTextField';
import OverdueDaysField from '../utils/OverdueDaysField';

import { useConstantContext } from '../ConstantsContext';
import useLoanFilters from './useLoanFilters';

import DateField from '../designSystem/react-admin/DateField';
import List from '../designSystem/react-admin/list/List';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import TitleH1 from '../designSystem/TitleH1';

export const getLoanListTitle = (t) => t('Loans');

const LoanList = () => {
  const { choices } = useConstantContext();
  const loanFilters = useLoanFilters();
  const { t } = useTranslation();

  return (
    <List
      disableSyncWithLocation
      resource="loan"
      appLocation="home.loan"
      title={<TitleH1 title={getLoanListTitle(t)} />}
      mainFilterInputs={loanFilters}
      pagination={<SafePagination />}
      perPage={50}
      sort={{ field: 'activationDate', order: 'DESC' }}
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick="edit"
        rightAlignedColumns={['remainingRepaymentTime', 'remainingCapitalAmount', 'overdueAmount', 'overdueDays']}
      >
        <TextField source="id" label={t('Loan ID')} sortable={false} />
        <EntityReferenceField
          label={t('Customer')}
          source="debtorId"
          reference="entity-v2/customer"
          link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
        />
        <SelectField source="terms.repaymentType" label={t('Repayment type')} choices={choices.repaymentTypes} />
        <DateField source="activationDate" label={t('Activation date')} />
        <DateField source="terms.payoutDate" label={t('Payout date')} />
        <CustomSuffixTextField source="remainingRepaymentTime" label={t('Remaining repayment period')} emptyText="-" customSuffix={t('months')} sortable={false} />
        <CurrencyField source="remainingCapitalAmount" currencySource="currency" label={t('Remaining capital amount')} sortable={false} />
        <CurrencyField source="overdueAmount" currencySource="currency" label={t('Overdue amount')} emptyText="-" sortable={false} />
        <OverdueDaysField label={t('Overdue')} source="overdueDays" />
      </DatagridConfigurable>
    </List>
  );
};

export default LoanList;
