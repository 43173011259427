import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ArrayField,
  TextField,
  required,
  maxValue,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useConstantContext } from '../ConstantsContext';

import ModalButton from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import Datagrid from '../designSystem/react-admin/Datagrid';

import CurrencyField from './CurrencyField';

import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useFeatureFlags from '../hooks/useFeatureFlags';

import { getEntityIdFromToken, getEntityEnableCreditNotesToken } from '../../utils/getValueFromToken';
import { positiveNumberValidation, strictlyPositiveNumberValidation } from '../../utils/validationErrors';

import CurrencyInput from '../designSystem/react-admin/inputs/CurrencyInput';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';

const validateRequired = [required()];

const CreateCreditNote = ({ accountRecord }) => {
  const invoiceRecord = useRecordContext();
  const { t } = useTranslation();
  const { isOwner, isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const enableCreditNotes = getEntityEnableCreditNotesToken();
  const resource = useResourceContext();
  const [featureFlags] = useFeatureFlags();

  const entityId = getEntityIdFromToken();

  const { accountTypes } = constants;

  const {
    id,
    articles,
    balance,
    currency,
    creditorId,
    accountType,
    customVatPercentage,
  } = accountRecord;

  const {
    amount: totalAmount,
    remainingBalance,
  } = invoiceRecord;

  const isCreditor = creditorId === entityId;

  const {
    mutate: addAdjustment,
    isLoading,
  } = useCustomRpc({
    path: `${resource}/${id}/add-adjustment`,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Could not create credit note'),
  });

  const onSubmit = useCallback((formData) => {
    const data = {
      amount: formData.creditAmount,
      description: formData.comment,
      ...featureFlags.enableVAT
        ? { vatPercentage: formData.vatPercentage }
        : { vatPercentage: 0 },
    };
    addAdjustment(data);
    return true;
  }, [addAdjustment, featureFlags.enableVAT]);

  if ((!isOwner && !enableCreditNotes)
    || (!isCreditor && isMerchant)
    || balance <= 0
    || accountType === accountTypes.BILLING
    || (accountType === accountTypes.ORDER && !articles)
  ) return null;

  const customRecord = {
    customArticles: [...articles ?? [], { description: 'Total amount', amount: totalAmount }],
  };

  return (
    <ModalButton
      modalTitle={t('Create credit note')}
      openButtonLabel={t('Credit note')}
      actionLabel={t('Create credit note')}
      variant="subAction"
      onClick={onSubmit}
      disabled={isLoading}
      withForm
      width="47.5rem"
      formDefaultValue={{
        articles,
        currency,
        totalAmount,
        remainingBalance,
      }}
    >
      <Typography marginBottom="0.5rem" fontSize="15px" maxWidth="30rem">
        {t('The credit note affects the customers upcoming payment \n by deducting the specified amount from the order amount')}
      </Typography>
      <ArrayField source="customArticles" label={false} record={customRecord}>
        <Datagrid
          bulkActionButtons={false}
          rightAlignedColumns={['amount']}
          sx={{
            '& .RaDatagrid-row:last-child > * > .MuiTypography-root': {
              fontWeight: 'bold',
            },
          }}
        >
          <TextField source="description" label={t('Item description')} />
          <CurrencyField source="amount" currency={currency} label={t('Amount')} emptyText="-" />
        </Datagrid>
      </ArrayField>

      <Box
        maxWidth="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="3rem"
      >
        <CurrencyInput
          label={t('Credit amount')}
          source="creditAmount"
          placeholder={t('The amount')}
          currency={currency}
          validate={[
            maxValue(remainingBalance),
            strictlyPositiveNumberValidation,
            ...validateRequired,
          ]}
        />
        {featureFlags.enableVAT && (
          <PercentageInput
            source="vatPercentage"
            label={`${t('VAT')} (%)`}
            validate={[
              positiveNumberValidation,
              ...validateRequired,
            ]}
            defaultValue={customVatPercentage}
          />
        )}
      </Box>

      <TextInput
        sx={{ width: '100%' }}
        multiline
        source="comment"
        label={t('Comments')}
        placeholder={t('Add comment or reason for the credit note')}
        validate={validateRequired}
      />
    </ModalButton>
  );
};

CreateCreditNote.propTypes = {
  accountRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creditorId: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape({})),
    accountType: PropTypes.string.isRequired,
    customVatPercentage: PropTypes.number,
  }),
};

CreateCreditNote.defaultProps = {
  accountRecord: {},
};

export default CreateCreditNote;
