import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  required,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import ModalButton from '../designSystem/ModalButton';
import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import InputsGroup from '../designSystem/InputsGroup';

import resources from '../../constants/resources';

import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const AddCommentButton = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { isMerchant } = useUserPermissionsAndRoles();
  const { id } = record;
  const { userActions } = constants;

  const {
    mutate: createComment,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: `${resource}/${id}/comment`,
    shouldRefresh: true,
    errorMessage: t('Could not create comment'),
  });

  const onSubmit = useCallback(({ message, shouldPublishComment }) => {
    const data = {
      message,
      shouldPublishComment, // Entity endpoint
      shouldPublishInEntities: shouldPublishComment, // Order endpoint
    };
    createComment(data);
    return true;
  }, [createComment]);

  const canAddCustomerComments = useHasUserPermissions(
    constants,
    userActions.ADD_COMMENTS_ON_CUSTOMERS,
  );
  const canAddMerchantComments = useHasUserPermissions(
    constants,
    userActions.ADD_COMMENTS_ON_MERCHANTS,
  );
  const canAddOrderComment = useHasUserPermissions(
    constants,
    userActions.ADD_COMMENTS_ON_ORDERS,
  );

  let shareCommentInputLabel;
  if (resource.endsWith(resources.CUSTOMER)) {
    if (!canAddCustomerComments) return null;
    shareCommentInputLabel = t('Share the comment with the merchants of this customer');
  } else if (resource.endsWith(resources.MERCHANT)) {
    if (!canAddMerchantComments) return null;
    shareCommentInputLabel = undefined;
  } else if (resource.endsWith(resources.ORDER)) {
    if (!canAddOrderComment) return null;
    shareCommentInputLabel = t('Publish the comment in the customer and merchant cards');
  } else throw new Error('Comments are not supported for this resource');

  return (
    <ModalButton
      modalTitle={t('Add a comment')}
      actionLabel={t('Send')}
      openButtonLabel={t('Add comment')}
      onClick={onSubmit}
      disabled={isLoading}
      width="30rem"
      formDefaultValue={{}}
    >
      <InputsGroup>
        <TextInput
          source="message"
          label={t('Comment')}
          validate={[required()]}
          placeholder={t('Write your message here')}
          multiline
        />
        {!isMerchant && shareCommentInputLabel && (
        <BooleanInput
          label={shareCommentInputLabel}
          source="shouldPublishComment"
        />
        )}
      </InputsGroup>
    </ModalButton>
  );
};

export default AddCommentButton;
