import React from 'react';
import moment from 'moment';
import {
  ReferenceField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FieldsGroup from '../utils/FieldsGroup';
import TitleH5 from '../designSystem/TitleH5';

import AddCommentButton from '../utils/AddCommentButton';
import { DATE_WITH_HOUR_FORMAT } from '../../theme';

const EntityCommentsGroup = () => {
  const { comments } = useRecordContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Comments')}>
      <TitleH5 title={t('Oldest first')} />
      {comments.map((comment) => (
        <Box marginBottom="2rem">
          <Box display="flex" alignItems="flex-end" marginBottom="0.5rem">
            <ReferenceField // That is very heavy. Do this in the API instead
              label={t('Author')}
              source="author"
              reference="user/public"
              link={false}
              record={comment}
            >
              <FunctionField render={(user) => <Typography fontWeight="600" fontSize="15px">{user.firstName} {user.lastName}</Typography>} />
            </ReferenceField>
            <Typography marginLeft="1rem" variant="caption">{moment(comment.createdAt).format(DATE_WITH_HOUR_FORMAT)}</Typography>
          </Box>
          <Typography>{comment.message}</Typography>
        </Box>
      ))}
      <AddCommentButton />
    </FieldsGroup>
  );
};

export default EntityCommentsGroup;
