import React from 'react';
import {
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import EmptyData from '../EmptyData';

import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';

const LossReservationList = () => {
  const record = useRecordContext();
  const { id } = record;
  const { t } = useTranslation();

  return (
    <List
      title={<></>}
      resource="loss-reservation"
      actions={false}
      empty={<EmptyData />}
      sort={{ field: 'date', order: 'DESC' }}
      sx={{ width: '100%' }}
      filter={{ accountId: id }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <DateField label={t('Date')} source="date" />
        <NumberField source="amount" label={t('Amount')} />
        <ReferenceField
          source="accountId"
          label={t('Currency')}
          reference="factoring-account"
          target="id"
          link={false}
        >
          <TextField source="currency" />
        </ReferenceField>
      </DatagridConfigurable>
    </List>
  );
};
export default LossReservationList;
