import React from 'react';

import TabbedForm from '../../designSystem/react-admin/TabbedForm';
import Edit from '../../designSystem/react-admin/Edit';

import LoanApplicationTitle from './LoanApplicationTitle';
import SaveOnlyToolbar from '../../utils/SaveOnlyToolbar';
import SummaryTab from './tabs/SummaryTab';

const LoanApplicationEdit = () => (
  <Edit
    mutationMode="optimistic"
    title={<LoanApplicationTitle />}
    redirect={false}
    appLocation="home.loan-application.edit"
  >
    <TabbedForm
      sanitizeEmptyValues
      toolbar={<SaveOnlyToolbar />}
    >
      <SummaryTab />
    </TabbedForm>
  </Edit>
);

export default LoanApplicationEdit;
