import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { titleH1Style } from '../../theme';

const TitleH1 = ({ title, color, ...rest }) => (
  <Typography
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    fontSize={titleH1Style.fontSize}
    fontWeight={titleH1Style.fontWeight}
    color={color}
  >
    {title}
  </Typography>
);

TitleH1.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

TitleH1.defaultProps = {
  color: titleH1Style.color,
};

export default TitleH1;
