import React from 'react';
import PropTypes from 'prop-types';

import {
  ArrayField,
  TextField,
  usePermissions,
  SelectField,
  // ImageField,
  FunctionField,
  ReferenceField,
  SingleFieldList,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import UpdateEntityInvoiceSettingsButton from './UpdateEntityInvoiceSettingsButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import hasUserPermissions from '../../utils/hasUserPermissions';
// import UploadInvoiceLogoButton from '../utils/UploadInvoiceLogoButton';

import Datagrid from '../designSystem/react-admin/Datagrid';
import BooleanField from '../designSystem/react-admin/BooleanField';
import TitleH3 from '../designSystem/TitleH3';

import { useConstantContext } from '../ConstantsContext';

import resources from '../../constants/resources';

const EntityInvoiceSettings = ({ resource }) => {
  const { constants, choices } = useConstantContext();
  const entityRecord = useRecordContext();
  const authenticationData = usePermissions();
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();

  const { userActions } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const {
    emailLayoutTypesInvoice,
    emailLayoutTypesReminder,
    invoiceDefaultLayoutTypes,
    reminderLayoutTypes,
    currencies,
  } = choices;

  const canView = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_INVOICES,
  );

  const isEntityResource = resource === resources.MY_COMPANY || resource === resources.MERCHANT;

  if (!canView || !isFinancer || !isEntityResource || !entityRecord) return null;

  return (
    <FieldsGroup
      title={t('Invoicing settings')}
      actions={(
        <Box display="flex" gap="0.5rem" flexDirection="row">
          {/* <UploadInvoiceLogoButton /> */}
          <UpdateEntityInvoiceSettingsButton resource={resource} />
        </Box>
      )}
    >
      <Box display="flex" flexDirection="column">
        {/* resource === resources.MY_COMPANY && (
          <SimpleShowLabeled>
            <ImageField source="invoiceLogo" label={t('Invoice logo')} emptyText={t('No logo uploaded yet')} />
          </SimpleShowLabeled>
        ) */}
        <BooleanField source="invoicingSettings.shouldSendInvoices" label={t('Send invoice')} looseValue />
        <Grid container marginTop="1rem">
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Invoice sender')}
                source="invoicingSettings.displaySenderId"
                reference="entity-v2/reference"
                link={false}
                emptyText="-"
              >
                <TextField source="mainAddress.name" />
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.streetAddress" label={t('Street address')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceEmail" label={t('Invoice email')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.additionalStreetAddress" label={t('Additional street address')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.town" label={t('Town')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.postcode" label={t('Postcode')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField
                source="invoicingSettings.invoiceLayout"
                label={t('Invoice layout')}
                choices={invoiceDefaultLayoutTypes}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField
                source="invoicingSettings.emailLayout"
                label={t('Email layout')}
                choices={emailLayoutTypesInvoice}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <Box marginY="1rem">
          <TitleH3 title={t('Currency settings')} />
        </Box>
        <SimpleShowLabeled fullWidth>
          <ArrayField label={false} source="invoicingSettings.currencySettings">
            <SingleFieldList linkType={false}>
              <Grid container marginBottom="1rem">
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <SelectField
                      source="currency"
                      label={t('Currency')}
                      choices={currencies}
                      emptyText="-"
                    />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <TextField source="iban" label="IBAN" emptyText="-" />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <TextField source="lateInterestRate" label={`${t('Late interest')} (%)`} emptyText="-" />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <TextField source="invoiceFee" label={t('Invoice fee')} emptyText="-" />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <TextField source="bankgiro" label={t('Bankgiro')} emptyText="-" />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <TextField source="bic" label="BIC" emptyText="-" />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={4} md={6}>
                  <SimpleShowLabeled>
                    <FunctionField
                      render={(record) => {
                        const denunciationText = record?.denunciationText;
                        if (denunciationText) {
                          return denunciationText.length > 25
                            ? `${denunciationText.substring(0, 25)}...`
                            : denunciationText;
                        }
                        return '-';
                      }}
                      label={t('Denunciation text')}
                      emptyText="-"
                    />
                  </SimpleShowLabeled>
                </Grid>
                <Grid item lg={12}>
                  <SimpleShowLabeled fullWidth>
                    <ArrayField label={t('Dunning settings')} source="dunningSettings">
                      <Datagrid bulkActionButtons={false} rightAlignedColumns={['feeAmount', 'issueDay']}>
                        <SelectField
                          source="emailLayout"
                          label={t('Email layout')}
                          choices={emailLayoutTypesReminder}
                          emptyText="-"
                        />
                        <SelectField
                          source="reminderLayout"
                          label={t('Reminder layout')}
                          choices={reminderLayoutTypes}
                          emptyText="-"
                        />
                        <TextField source="description" label={t('Description')} emptyText="-" />
                        <TextField source="feeAmount" label={t('Fee amount')} emptyText="-" />
                        <TextField source="issueDay" label={t('Issue day')} emptyText="-" />
                      </Datagrid>
                    </ArrayField>
                  </SimpleShowLabeled>
                </Grid>
              </Grid>
            </SingleFieldList>
          </ArrayField>
        </SimpleShowLabeled>
      </Box>
    </FieldsGroup>
  );
};

EntityInvoiceSettings.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default EntityInvoiceSettings;
