import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NoDataContent = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        padding="2rem 0"
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Typography variant="h5" gutterBottom>{t('You have no KPIs to display')}</Typography>
      </Box>
    </Grid>
  );
};

export default NoDataContent;
