// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { useRecordContext } from 'react-admin';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { isEmptyObject } from '../../utils/validators';

import { CurrentExposureKpi } from '../dashboards/KpiContainer';
import TitleH4 from '../designSystem/TitleH4';
import RefreshExposureKPIButton from './RefreshExposureKPIButton';

const CurrentExposureItems = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const { kpis, entityCoreId } = record;

  if (!kpis || isEmptyObject(kpis)) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      marginBottom="2rem"
    >
      <TitleH4 title={t('Current exposure')} />
      <Card sx={{ backgroundColor: 'transparent' }}>
        <CardContent sx={{ padding: '0' }}>
          <Grid container spacing={0}>
            <Grid container item>
              <Grid item lg={4}><CurrentExposureKpi value={kpis.outstandingInvoicedAmount} label={t('Outstanding invoiced amount')} currency={kpis.currency} cardContentVariant="currentExposureEntity" /></Grid>
              <Grid item lg={4}><CurrentExposureKpi value={kpis.outstandingFinancedAmount} label={t('Outstanding financed amount')} currency={kpis.currency} cardContentVariant="currentExposureEntity" /></Grid>
              <Grid item lg={4}><CurrentExposureKpi value={kpis.overdueAmount} label={t('Overdue amount')} currency={kpis.currency} ratioValue={kpis.overdueAmountRatio} ratioLabel={t('Ratio overdue amount')} showRatio cardContentVariant="currentExposureEntity" /></Grid>
            </Grid>
          </Grid>
          <RefreshExposureKPIButton entityCoreId={entityCoreId} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CurrentExposureItems;
