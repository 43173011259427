import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';
import InputsGroup from '../designSystem/InputsGroup';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

import renderReference from '../../utils/renderReference';
import { useConstantContext } from '../ConstantsContext';

const CustomerPairForm = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { entityRoles } = constants;
  return (
    <InputsGroup layout="row">
      <ReferenceInput
        source="oldCustomerCore"
        reference="entity-v2/reference"
        filter={{ entityRoles: [entityRoles.CUSTOMER] }}
      >
        <AutocompleteInput
          label={t('Remove customer')}
          placeholder={t('Select customer')}
          optionText={renderReference}
          suggestionLimit={20}
          options={{
            suggestionsContainerProps: {
              style: { zIndex: 1500 },
            },
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        source="newCustomerCore"
        reference="entity-v2/reference"
        filter={{ entityRoles: [entityRoles.CUSTOMER] }}
      >
        <AutocompleteInput
          label={t('Keep and move orders to')}
          placeholder={t('Select customer')}
          optionText={renderReference}
          suggestionLimit={20}
          options={{
            suggestionsContainerProps: {
              style: { zIndex: 1500 },
            },
          }}
        />
      </ReferenceInput>
    </InputsGroup>
  );
};

const MergeCustomerButton = () => {
  const { t } = useTranslation();
  const {
    mutate: updateCustomerPair,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: 'entity-v2/merge-customer',
    shouldRefresh: true,
    errorMessage: t('Could not merge customers'),
  });

  const onSubmit = useCallback((newTerms) => {
    updateCustomerPair(newTerms);
    return true;
  }, [updateCustomerPair]);

  return (
    <ModalButton
      modalTitle={t('Merge customer')}
      openButtonLabel={t('Merge customer')}
      actionLabel={t('Merge')}
      disabled={isLoading}
      onClick={onSubmit}
      formDefaultValue={{}}
    >
      <Typography width="22rem">{t('This action permanently removes a customer and reassigns all associated resources.')}</Typography>
      <Typography width="22rem">{t('Any information, key contacts, and historical data related to the customer will be permanently deleted.')}</Typography>
      <CustomerPairForm />
    </ModalButton>
  );
};

export default MergeCustomerButton;
