import * as Sentry from '@sentry/react';

import configs from '../../config';

const errorLogger = (error) => {
  if (configs.sentryEnabled) {
    Sentry.captureException(error);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default errorLogger;
