import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext, useWatch } from 'react-hook-form';
import Button from '@mui/material/Button';

import useDownloadDocument from '../hooks/useDownloadDocument';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

import { ProcessableFileUploadModalButton } from '../designSystem/ModalButton';

const DownloadCustomerFileTemplate = (props) => {
  const { trigger } = useFormContext();
  const { t } = useTranslation();

  const {
    mutate: downloadCustomerFileTemplate,
  } = useDownloadDocument({
    path: 'entity-v2/customer-file-template',
    errorMessage: t('Could not retrieve customer file template'),
  });

  const templateType = useWatch({ name: 'templateType' });

  const downloadTemplate = useCallback(async (e) => {
    e.preventDefault();
    const isValid = await trigger('templateType');
    if (isValid) {
      downloadCustomerFileTemplate({ paramId: templateType });
    }
  }, [downloadCustomerFileTemplate, templateType, trigger]);

  return (
    <Button
      onClick={downloadTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {t('Download customer file template')}
    </Button>
  );
};

const ImportCustomerButton = () => {
  const {
    constants,
    choices: {
      customerFileTemplates,
      downloadableCustomerTemplateTypes,
    },
  } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canCreate = useHasUserPermissions(
    constants,
    userActions.CREATE_CUSTOMERS,
  );

  if (!canCreate || !customerFileTemplates[0]) return null;

  return (
    <ProcessableFileUploadModalButton
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      uploadPath="upload/customer-file"
      ModalButtonProps={{
        modalTitle: t('Upload customer file'),
        openButtonLabel: t('Upload customer file'),
        actionLabel: t('Upload'),
        formDefaultValue: {
          templateType: customerFileTemplates[0].id,
        },
      }}
      templateDownloadButton={<DownloadCustomerFileTemplate />}
      choices={customerFileTemplates}
      downloadableTemplateTypes={downloadableCustomerTemplateTypes}
    />
  );
};

export default ImportCustomerButton;
