import { capitalize } from 'lodash';

const getOrderDraftStatuses = (draftStatuses) => [
  draftStatuses.READY,
  draftStatuses.TO_COMPLETE,
].map((draftStatus) => ({
  value: draftStatus,
  label: draftStatus && capitalize(draftStatus),
}));

export default getOrderDraftStatuses;
