import React from 'react';

import { Edit as RaEdit } from 'react-admin';
import PropTypes from 'prop-types';

import { useDefineLocation } from '../../AppLocationContext';

const DefineLocation = ({ appLocation }) => {
  useDefineLocation(appLocation);
  return null;
};
DefineLocation.propTypes = {
  appLocation: PropTypes.string,
};
DefineLocation.defaultProps = {
  appLocation: undefined,
};

const Edit = ({ appLocation, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RaEdit actions={false} {...rest}>
    <>
      <DefineLocation appLocation={appLocation} />
      {children}
    </>
  </RaEdit>
);

Edit.propTypes = {
  ...DefineLocation.propTypes,
  children: PropTypes.node.isRequired,
};
Edit.defaultProps = DefineLocation.defaultProps;

export default Edit;
