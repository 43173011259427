import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactComponent as EmptyFile } from '../../assets/emptyFile.svg';

const EmptyData = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <EmptyFile />
      <Typography>{t('No data available')}</Typography>
    </Box>
  );
};

export default EmptyData;
