import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useStatistics } from '../StatisticsContext';

import TitleH4 from '../../designSystem/TitleH4';
import TitleH5 from '../../designSystem/TitleH5';

import greaterThanSymbol from '../../../../assets/arrow.png';

const PendingTask = ({ value, to, header }) => {
  if (value === 0) return null;

  return (
    <Grid item lg={3} md={6} sm={12}>
      <Card>
        <CardContent variant="pendingTask">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <TitleH5 title={header} />
            <Box display="flex">
              <Typography
                fontSize="25px"
                fontWeight="600"
                color="primary"
              >
                {value}
              </Typography>
              <ViewButton to={to} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

const ViewButton = ({ to }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="smallButton"
      component={Link}
      to={to}
    >
      {t('View')}
      <img src={greaterThanSymbol} alt="arrow" />
    </Button>
  );
};

const PendingTasks = () => {
  const { statisticsData } = useStatistics();
  const { t } = useTranslation();
  const {
    pendingCustomerApplications,
    pendingFinancingApplications,
    pendingPayouts,
  } = statisticsData;

  const numberOfPendingTasks = pendingCustomerApplications
  + pendingFinancingApplications
  + pendingPayouts;

  return (
    <Box display="flex" flexDirection="column" marginBottom="2rem">
      <TitleH4 title={t('Pending tasks')} />
      {numberOfPendingTasks === 0
        ? (
          <Grid item lg={12} md={12} sm={12}>
            <Typography>
              {t('Done, no pending tasks')} <span role="img" aria-label="celebrate">🎉</span>
            </Typography>
          </Grid>
        ) : (
          <Grid container item spacing={2}>
            <PendingTask to="/financing-application" value={pendingFinancingApplications} header={t('Financing applications')} />
            <PendingTask to="/customer-application" value={pendingCustomerApplications} header={t('Customer applications')} />
            <PendingTask to="/settlement-account" value={pendingPayouts} header={t('Outbound payments')} />
          </Grid>
        )}
    </Box>
  );
};

PendingTask.propTypes = {
  value: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

ViewButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default PendingTasks;
