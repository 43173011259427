import { createContext, useContext } from 'react';

export const ReportsFilterContext = createContext();

const useReportsFilterContext = () => {
  const context = useContext(ReportsFilterContext);
  return context;
};

export default useReportsFilterContext;
