import React from 'react';
import {
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  FunctionField,
  SelectInput,
  DateInput,
  ArrayInput,
  ReferenceInput,
  FormDataConsumer,
  DateField,
  AutocompleteInput,
  SimpleFormIterator,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import FieldsGroup from '../utils/OldFieldsGroup';
import { getEntityNameFromToken } from '../../utils/getValueFromToken';
import { positiveNumberValidation } from '../../utils/validationErrors';
import renderReference from '../../utils/renderReference';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import OrderDraftToolbar from './OrderDraftToolbar';
import OrderDraftTitle from './OrderDraftTitle';
import { useConstantContext } from '../ConstantsContext';
import PercentageInput from '../utils/PercentageInput';
import Edit from '../designSystem/react-admin/Edit';

const OrderDraftEdit = () => {
  const { constants, choices } = useConstantContext();
  const { t } = useTranslation();
  const {
    feeTypes,
    currencies,
    financialProducts,
  } = choices;
  const {
    entityRoles,
  } = constants;

  return (
    <Edit
      mutationMode="optimistic"
      title={<OrderDraftTitle />}
      redirect={false}
      appLocation="home.order-draft.edit"
    >
      <TabbedForm toolbar={<OrderDraftToolbar />} sanitizeEmptyValues>
        <FormTab label={t('summary')}>
          <FieldsGroup column={2}>
            <FieldsGroup name={t('Order Draft Details')} column={1} withBorder>
              <FieldsGroup column={2}>
                <FieldsGroup>
                  <SimpleShowLabeled>
                    <DateField source="activationDate" label={t('Activation Date')} emptyText="-" />
                  </SimpleShowLabeled>
                  <TextInput source="orderReferenceNumber" label={t('Order Reference Number')} />
                  <TextInput source="paymentReference" label={t('Payment Reference')} />
                  <SelectInput
                    margin="none"
                    source="financialProduct"
                    choices={financialProducts}
                  />
                  <SelectInput
                    margin="none"
                    source="currency"
                    choices={currencies}
                  />
                </FieldsGroup>
                <FieldsGroup>
                  <SimpleShowLabeled>
                    <FunctionField label={t('Merchant')} render={getEntityNameFromToken} />
                  </SimpleShowLabeled>
                  <FormDataConsumer>
                    {({ formData }) => formData && formData.marketplaceId && (
                      <ReferenceInput
                        source="debtorId"
                        reference="entity-v2/reference"
                        resource="/entity-v2/reference"
                        filter={{ entityRoles: entityRoles.CUSTOMER }}
                      >
                        <AutocompleteInput
                          label={t('Customer')}
                          optionText={renderReference}
                          suggestionLimit={20}
                        />
                      </ReferenceInput>
                    )}
                  </FormDataConsumer>
                  <DateInput source="issueDate" label={t('Issue Date')} />
                  <NumberInput source="termsOfPayment" label={t('Terms of Payment')} validate={[positiveNumberValidation]} />
                  <NumberInput source="requestedFinancingAmount" label={t('Requested Financing Amount')} validate={[positiveNumberValidation]} />
                </FieldsGroup>
              </FieldsGroup>
              <ArrayInput source="items" style={{ width: '100%' }}>
                <SimpleFormIterator>
                  <SelectInput margin="none" source="feeType" choices={feeTypes} label={t('Type')} />
                  <NumberInput source="amount" label={t('Order Amount')} validate={[positiveNumberValidation]} />
                  <PercentageInput source="vatPercentage" label={`${t('VAT')} (%)`} />
                  <TextInput source="description" label={t('Description')} />
                </SimpleFormIterator>
              </ArrayInput>
            </FieldsGroup>
            <FieldsGroup name={t('Customer Details')} column={2} withBorder>
              <TextInput source="submitted.marketplaceReferenceCustomerId" label={t('Customer Alias')} />
              <TextInput source="submitted.customerName" label={t('Customer Name')} />
            </FieldsGroup>
          </FieldsGroup>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OrderDraftEdit;
