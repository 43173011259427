import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  ArrayInput,
  ReferenceInput,
  required,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
import { EditModalButton } from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import DateInput from '../designSystem/react-admin/inputs/DateInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';

import useCustomRpc from '../hooks/useCustomRpc';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const validateRequired = [required()];

const UpdateKeyPeopleButton = ({ userAction }) => {
  const record = useRecordContext();
  const { choices, constants } = useConstantContext();
  const { t } = useTranslation();

  const {
    mutate: updateCompanyDetails,
    isLoading,
  } = useCustomRpc({
    path: `entity-v2/${record.entityRoles[0]}/${record.id}`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: `${t('Could not update the details of')} ${record.entityRoles[0]}`,
  });

  const onSubmit = useCallback((formData) => {
    updateCompanyDetails(formData);
    return true;
  }, [updateCompanyDetails]);

  const canEdit = useHasUserPermissions(
    constants,
    userAction,
  );
  if (!canEdit) return null;

  return (
    <EditModalButton
      modalTitle={t('Key people')}
      onClick={onSubmit}
      disabled={isLoading}
      width="47.5rem"
      formDefaultValue={record}
    >
      <ArrayInput
        source="keyPeople"
        label={false}
        disabled={!canEdit}
      >
        <SimpleFormIterator addButtonLabel={t('Add new key person')} itemLabel={t('Key person')}>
          <InputsGroup layout="column">
            <TextInput source="firstName" label={t('First name')} validate={validateRequired} disabled={!canEdit} />
            <TextInput source="lastName" label={t('Last name')} validate={validateRequired} disabled={!canEdit} />
            <ReferenceInput
              source="nationality"
              reference="countries"
              resource="/countries"
            >
              <AutocompleteInput
                optionText={(rec) => (rec && rec.name ? `(${rec.id}) ${rec.name}` : '')}
                suggestionLimit={20}
                disabled={!canEdit}
                label={t('Nationality')}
                validate={validateRequired}
              />
            </ReferenceInput>
            <DateInput source="dateBirth" label={t('Date of birth')} validate={validateRequired} disabled={!canEdit} />
            <SelectInput
              margin="none"
              label={t('Role')}
              source="role"
              choices={choices.keyPeopleRoles}
              validate={validateRequired}
            />
            <BooleanInput source="isPep" label={t('Is PEP')} disabled={!canEdit} />
          </InputsGroup>
        </SimpleFormIterator>
      </ArrayInput>
    </EditModalButton>
  );
};

UpdateKeyPeopleButton.propTypes = {
  userAction: PropTypes.string.isRequired,
};

export default UpdateKeyPeopleButton;
