import React, {
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { PartnerSelectionContext } from './PartnerSelectionContext';

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: '5.4rem',
  backgroundColor: theme.palette.grey.select,
  marginTop: '0.2rem',
  '& .MuiSelect-select': {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '15px',
    padding: '0.2rem 0.8rem',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.secondary.main,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
    '&.Mui-focused': {
      border: 'none',
    },
  },
}));

const menuItemStyles = (theme) => ({
  '& .MuiMenuItem-root': {
    fontWeight: 600,
    paddingLeft: '1rem',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: theme.palette.grey.select,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.grey.select,
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
});

const PartnerSelection = () => {
  const { isPartner, setIsPartner } = useContext(PartnerSelectionContext);
  const { t } = useTranslation();

  const handleChange = useCallback((event) => {
    setIsPartner(event.target.value);
  }, [setIsPartner]);

  return (
    <FormControl>
      <StyledSelect
        value={isPartner}
        onChange={handleChange}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            sx: menuItemStyles,
          },
        }}
      >
        <MenuItem value={false}>{t('Lidion')}</MenuItem>
        <MenuItem value>{t('Moank')}</MenuItem>
      </StyledSelect>
    </FormControl>
  );
};

export default PartnerSelection;
