import { useAuthState, useGetList } from 'react-admin';
import React, { useContext } from 'react';
import { useConstantContext } from './ConstantsContext';
import { ChildrenProp } from '../utils/helpers/propTypes';
import useHasUserPermissions from './hooks/useHasUserPermissions';

const UnidentifiedContext = React.createContext({});

export const UnidentifiedContextProvider = ({ children }) => {
  const { isLoading, authenticated } = useAuthState({});
  const { constants } = useConstantContext();
  const userActions = constants.userActions || {};
  const ledgerConstants = constants.ledgerConstants || {};
  const canViewUnidentified = useHasUserPermissions(
    constants,
    userActions.VIEW_UNIDENTIFIED_PAYMENTS,
  );

  const { total } = useGetList(
    'unidentified-account',
    {
      filter: {
        balance: { $lt: -ledgerConstants.ROUNDING_THRESHOLD },
      },
    },
    {
      enabled: !isLoading && authenticated && canViewUnidentified,
    },
  );

  return (
    <UnidentifiedContext.Provider value={total ?? 0}>
      {children}
    </UnidentifiedContext.Provider>
  );
};

UnidentifiedContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

export const useUnidentified = () => {
  const unidentifiedAccounts = useContext(UnidentifiedContext);
  return unidentifiedAccounts;
};
