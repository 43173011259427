import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material';
import {
  Link as RouterLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RecordContextProvider } from 'react-admin';

import { useAppLocation } from './AppLocationContext';

import { getOrderListTitle } from './order/OrderList';
import { getOrderCreateTitle } from './order/OrderCreate';
import { getOrderTitle } from './order/OrderTitle';
import { getFinancingApplicationListTitle } from './application/financingApplication/FinancingApplicationList';
import { getFinancingApplicationTitle } from './application/financingApplication/FinancingApplicationTitle';
import { getFactoringAccountListTitle } from './factoringAccount/FactoringAccountList';
import { FactoringTitle } from './factoringAccount/FactoringAccountEdit';
import { getCustomerListTitle } from './entityv2/customer/List';
import { getCustomerCreateTitle } from './entityv2/customer/Create';
import { getCustomerEditTitle } from './entityv2/customer/Edit';
import { getCustomerApplicationListTitle } from './entityv2/customerApplication/List';
import { getAssignedCreditInfoCreateTitle } from './entityv2/assigned-credit-info/Create';
import { getCreditInfoCreateTitle } from './entityv2/credit-info/Create';
import { getCustomerApplicationEditTitle } from './entityv2/customerApplication/Edit';
import { getEntityGroupListTitle } from './entityGroup/EntityGroupList';
import { getEntityGroupCreateTitle } from './entityGroup/EntityGroupCreate';
import { getEntityGroupEditTitle } from './entityGroup/EntityGroupEdit';
import { getMerchantListTitle } from './entityv2/merchant/List';
import { getMerchantCreateTitle } from './entityv2/merchant/Create';
import { getMerchantEditTitle } from './entityv2/merchant/Edit';
import { getSettlementAccountListTitle } from './settlementAccount/SettlementAccountList';
import { SettlementTitle } from './settlementAccount/SettlementAccountShow';
import { getTransactionListTitle } from './transaction/TransactionList';
import { getUnidentifiedAccountListTitle } from './unidentifiedAccount/UnidentifiedAccountList';
import { getUnidentifiedAccountTitle } from './unidentifiedAccount/UnidentifiedAccountEdit';
import { getReportListTitle } from './reports/ReportsList';
import { getLogsListTitle } from './logs/LogsList';
import { getUploadedFileListTitle } from './file/UploadedFileList';
import { getExchangeRateListTitle } from './exchangeRate/ExchangeRateList';
import { getNotificationListTitle } from './notification/NotificationList';
import { getMyCompanyTitle } from './entityv2/my-company/MyCompany';
import { getUserListTitle } from './user/UserList';
import { getUserCreateTitle } from './user/UserCreate';
import { getUserTitle } from './user/UserTitle';
import { getRoleCreateTitle } from './role/RoleCreate';
import { getRoleEditTitle } from './role/RoleEditTitle';
import { getLoanApplicationListTitle } from './application/loanApplication/LoanApplicationList';
import { getLoanApplicationCreateTitle } from './application/loanApplication/LoanApplicationCreate';
import { getLoanApplicationTitle } from './application/loanApplication/LoanApplicationTitle';
import { getLoanListTitle } from './loan/LoanList';
import { getLoanTitle } from './loan/LoanTitle';
import { getLabelCreateTitle } from './label/LabelCreate';
import { getLabelEditTitle } from './label/LabelEdit';
import { getLabelShowTitle } from './label/LabelShow';
import { getOrderDraftListTitle } from './orderDraft/OrderDraftList';
import { getOrderDraftTitle } from './orderDraft/OrderDraftTitle';
import errorLogger from '../utils/errorLogger';
import { getBillingAccountListTitle } from './billingAccount/BillingAccountLists';

const useGetBreadcrumbNameMap = () => {
  const { t } = useTranslation();
  return ({
    home: () => ({ name: t('Home'), path: '/' }),
    'home.order': () => ({ name: t(getOrderListTitle()), path: '/order' }),
    'home.order.create': () => ({ name: getOrderCreateTitle(t), path: '/order/create' }),
    'home.order.edit': (record = {}) => ({ name: getOrderTitle(record), path: `/order/${record.id}` }),
    'home.financing-application': () => ({ name: getFinancingApplicationListTitle(t), path: '/financing-application' }),
    'home.financing-application.edit': (record = {}) => ({ name: getFinancingApplicationTitle(record), path: `/financing-application/${record.id}` }),
    'home.financing-application.show': (record = {}) => ({ name: getFinancingApplicationTitle(record), path: `/financing-application/${record.id}/show` }),
    'home.factoring-account': () => ({ name: getFactoringAccountListTitle(t), path: '/factoring-account' }),
    'home.factoring-account.edit': (record = {}) => ({ name: <RecordContextProvider value={record}><FactoringTitle isBreadcrumbsTitle /></RecordContextProvider>, path: `/factoring-account/${record.id}` }),
    'home.entity-v2/customer': () => ({ name: getCustomerListTitle(t), path: '/entity-v2/customer' }),
    'home.entity-v2/customer.create': () => ({ name: getCustomerCreateTitle(t), path: '/entity-v2/customer/create' }),
    'home.entity-v2/customer.edit': (record = {}) => ({ name: getCustomerEditTitle(record), path: `/entity-v2/customer/${record.id}` }),
    'home.customer-application': () => ({ name: getCustomerApplicationListTitle(t), path: '/customer-application' }),
    'home.entity-v2/customer.create-assigned-credit-info': () => ({ name: getAssignedCreditInfoCreateTitle(t), path: '/entity-v2/assigned-credit-info/create' }),
    'home.entity-v2/customer.create-credit-info': () => ({ name: getCreditInfoCreateTitle(t), path: '/entity-v2/credit-info/create' }),
    'home.customer-application.edit': (record = {}) => ({ name: getCustomerApplicationEditTitle(record), path: `/customer-application/${record.id}` }),
    'home.entity-group': () => ({ name: getEntityGroupListTitle(t), path: '/entity-group' }),
    'home.entity-group.create': () => ({ name: getEntityGroupCreateTitle(t), path: '/entity-group/create' }),
    'home.entity-group.edit': (record = {}) => ({ name: getEntityGroupEditTitle(record), path: `/entity-group/${record.id}` }),
    'home.entity-v2/merchant': () => ({ name: getMerchantListTitle(t), path: '/entity-v2/merchant' }),
    'home.entity-v2/merchant.create': () => ({ name: getMerchantCreateTitle(t), path: '/entity-v2/merchant/create' }),
    'home.entity-v2/merchant.edit': (record = {}) => ({ name: getMerchantEditTitle(record), path: `/entity-v2/merchant/${record.id}` }),
    'home.settlement-account': () => ({ name: getSettlementAccountListTitle(t), path: '/settlement-account' }),
    'home.settlement-account.show': (record = {}) => ({ name: <RecordContextProvider value={record}><SettlementTitle isBreadcrumbsTitle /></RecordContextProvider>, path: `/settlement-account/${record.id}/show` }), // We need a referenceField here, hence the custom title
    'home.payment': () => ({ name: getTransactionListTitle(t), path: '/payments' }),
    'home.unidentified-account': () => ({ name: getUnidentifiedAccountListTitle(t), path: '/unidentified-account' }),
    'home.unidentified-account.edit': (record = {}) => ({ name: getUnidentifiedAccountTitle(record), path: `/unidentified-account/${record.id}` }),
    'home.reports': () => ({ name: getReportListTitle(t), path: '/report' }),
    'home.logs': () => ({ name: getLogsListTitle(t), path: '/logs' }),
    'home.file': () => ({ name: getUploadedFileListTitle(t), path: '/file' }),
    'home.exchangeRate': () => ({ name: getExchangeRateListTitle(t), path: '/exchangeRate' }),
    'home.notification': () => ({ name: t(getNotificationListTitle()), path: '/notification' }),
    'home.my-company': () => ({ name: getMyCompanyTitle(t), path: '/my-company' }),
    'home.manage-users': () => ({ name: getUserListTitle(t), path: '/manage-users' }),
    'home.manage-users.create/user': () => ({ name: getUserCreateTitle(t), path: '/users/create' }),
    'home.manage-users.edit/user': (record = {}) => ({ name: getUserTitle(record), path: `/users/${record.id}` }),
    'home.manage-users.show/user': (record = {}) => ({ name: getUserTitle(record), path: `/users/${record.id}/show` }),
    'home.manage-users.create/role': () => ({ name: getRoleCreateTitle(t), path: '/role/create' }),
    'home.manage-users.edit/role': (record = {}) => ({ name: getRoleEditTitle(record), path: '/role' }),
    'home.loan-application': () => ({ name: getLoanApplicationListTitle(t), path: '/loan-application' }),
    'home.loan-application.edit': (record = {}) => ({ name: getLoanApplicationTitle(record), path: `/loan-application/${record.id}` }),
    'home.loan-application.create': () => ({ name: getLoanApplicationCreateTitle(t), path: '/loan-application/create' }),
    'home.loan': () => ({ name: getLoanListTitle(t), path: '/loan' }),
    'home.loan.edit': (record = {}) => ({ name: getLoanTitle(record), path: `/loan/${record.id}` }),
    'home.my-company.create/label': () => ({ name: getLabelCreateTitle(t), path: '/label/create' }),
    'home.my-company.edit/label': (record = {}) => ({ name: getLabelEditTitle(t), path: `/label/${record.id}` }),
    'home.my-company.show/label': (record = {}) => ({ name: getLabelShowTitle(record), path: `/label/${record.id}/show` }),
    'home.order-draft': () => ({ name: getOrderDraftListTitle(t), path: '/order-draft' }),
    'home.order-draft.edit': (record = {}) => ({ name: getOrderDraftTitle(record), path: `/order-draft/${record.id}` }),
    'home.order-draft.show': (record = {}) => ({ name: getOrderDraftTitle(record), path: `/order-draft/${record.id}/show` }),
    'home.billing-account': () => ({ name: getBillingAccountListTitle(t), path: '/billing-account' }),
  });
};

// eslint-disable-next-line react/jsx-props-no-spreading
const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const StyledBreadcrumbsLink = styled(LinkRouter)(() => ({
  color: '#000',
  opacity: 0.55,
  fontSize: '15px',
  fontWeight: 400,
}));

const StyledBreadcrumbsTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.switch,
  fontWeight: 600,
  fontSize: '15px',
}));

const StyledBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.switch,
    fontWeight: 700,
    fontSize: '15px',
  },
}));

const Breadcrumbs = () => {
  const breadcrumbNameMap = useGetBreadcrumbNameMap();
  const { appLocation, record } = useAppLocation();
  if (!appLocation) return null;
  const pathnames = appLocation.split('.').filter((x) => x);

  if (pathnames.length === 1) return null; // Do not show breadcrumbs when on home page

  const breadcrumbs = pathnames.map((value, index) => {
    const last = index === pathnames.length - 1;
    const to = `${pathnames.slice(0, index + 1).join('.')}`;
    if (!breadcrumbNameMap[to]) return null;
    const { name, path } = breadcrumbNameMap[to](record);
    return last ? (
      <StyledBreadcrumbsTypography key={path}>
        {name}
      </StyledBreadcrumbsTypography>
    ) : (
      <StyledBreadcrumbsLink underline="hover" to={path} key={path}>
        {name}
      </StyledBreadcrumbsLink>
    );
  });

  const hasEmptyElement = breadcrumbs.indexOf(null) !== -1;
  if (hasEmptyElement) {
    errorLogger(`Missing breadcrumbs for path name ${appLocation}`);
    return null;
  }

  return (
    <StyledBreadcrumbs>
      {breadcrumbs}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
