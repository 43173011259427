import { useConstantContext } from '../ConstantsContext';

const entityDropdownFilter = (formData = {}, entityRoles) => (
  formData.marketplaceId ? ({ marketplaceId: formData.marketplaceId, entityRoles }) : null
);

export const useMerchantDropdownFilter = (hasForm) => {
  const { constants } = useConstantContext();
  const { entityRoles } = constants;
  if (hasForm) return (formData) => entityDropdownFilter(formData, entityRoles.MERCHANT);
  return () => ({ entityRoles: entityRoles.MERCHANT });
};

export const useCustomerDropdownFilter = (hasForm) => {
  const { constants } = useConstantContext();
  const { entityRoles } = constants;
  if (hasForm) return (formData) => entityDropdownFilter(formData, entityRoles.CUSTOMER);
  return () => ({ entityRoles: entityRoles.CUSTOMER });
};

export default entityDropdownFilter;
