import React from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import TitleH1 from '../../designSystem/TitleH1';

export const getFinancingApplicationTitle = (record) => record?.orderReferenceNumber || '';

const FinancingApplicationTitle = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Financing application')}: ${getFinancingApplicationTitle(record)}`;
  return <TitleH1 title={title} />;
};

export default FinancingApplicationTitle;
