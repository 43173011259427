import React from 'react';
import PropTypes from 'prop-types';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import TitleH5 from '../designSystem/TitleH5';

import convertToHumanReadable from '../../utils/convertToHumanReadable';
import { isDefined, isNumber } from '../../utils/validators';
import { percentageFormatOrigin } from '../utils/PercentageField';

const KpiContainer = ({
  value,
  currency,
  label,
  subValue,
  subLabel,
  secondSubValue,
  secondSubLabel,
  isRatio,
}) => {
  const formattedValue = isNumber(subValue) && isRatio
    ? percentageFormatOrigin(subValue, 2).toString()
    : convertToHumanReadable(subValue).toString();

  const secondFormattedValue = convertToHumanReadable(secondSubValue).toString();

  return (
    <>
      <Typography
        fontSize="12px"
        fontWeight="500"
        color="headers"
      >
        {label}
      </Typography>
      <Typography
        fontSize="20px"
        fontWeight="600"
        color="primary"
      >
        {convertToHumanReadable(value, 9, currency)}
      </Typography>
      {isDefined(subValue) && isDefined(subLabel) && (
        <TitleH5
          title={(
            <>
              <strong>{isNumber(subValue) ? `${formattedValue}` : '-'}</strong>
              &nbsp;{subLabel}
              &nbsp;
              <strong>{isNumber(secondSubValue) ? `${secondFormattedValue}` : ''}</strong>
              &nbsp;{secondSubLabel}
            </>
          )}
        />
      )}
    </>
  );
};

export const CurrentExposureKpi = ({
  value,
  currency,
  label,
  subValue,
  subLabel,
  secondSubValue,
  secondSubLabel,
  isRatio,
  cardContentVariant,
}) => (
  <Card sx={{ height: '100%' }}>
    <CardContent variant={cardContentVariant}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <KpiContainer
          value={value}
          currency={currency}
          label={label}
          subValue={subValue}
          subLabel={subLabel}
          secondSubValue={secondSubValue}
          secondSubLabel={secondSubLabel}
          isRatio={isRatio}
        />
      </Box>
    </CardContent>
  </Card>
);

export const OverviewKpi = ({
  value,
  currency,
  label,
  subValue,
  subLabel,
  secondSubValue,
  secondSubLabel,
  isRatio,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    padding="1.9rem 1.5rem 1.5rem 0"
  >
    <KpiContainer
      value={value}
      currency={currency}
      label={label}
      subValue={subValue}
      subLabel={subLabel}
      secondSubValue={secondSubValue}
      secondSubLabel={secondSubLabel}
      isRatio={isRatio}
    />
  </Box>
);

KpiContainer.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  currency: PropTypes.string,
  subValue: PropTypes.number,
  subLabel: PropTypes.string,
  secondSubValue: PropTypes.number,
  secondSubLabel: PropTypes.string,
  isRatio: PropTypes.bool,
};
KpiContainer.defaultProps = {
  subValue: undefined,
  subLabel: '',
  secondSubValue: undefined,
  secondSubLabel: '',
  currency: undefined,
};
CurrentExposureKpi.propTypes = {
  ...KpiContainer.propTypes,
  cardContentVariant: PropTypes.string,
};
CurrentExposureKpi.defaultProps = {
  ...KpiContainer.defaultProps,
  cardContentVariant: 'currentExposure',
};
OverviewKpi.propTypes = KpiContainer.propTypes;
OverviewKpi.defaultProps = KpiContainer.defaultProps;
