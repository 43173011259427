import React from 'react';
import {
  List,
  EditButton,
  TextField,
  TopToolbar,
  CreateButton,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import TextInputWithLens from '../utils/TextInputWithLens';
import AccordionFilter from '../utils/AccordionFilter';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';

const useRoleListFilters = () => {
  const { t } = useTranslation();
  return [
    <TextInputWithLens alwaysOn label={t('Role name')} source="name" />,
  ];
};

const RoleActions = () => {
  const roleListFilters = useRoleListFilters();
  return (
    <TopToolbar>
      <AccordionFilter filters={roleListFilters}>
        <CreateButton />
      </AccordionFilter>
    </TopToolbar>
  );
};

const RoleList = () => {
  const { t } = useTranslation();
  return (
    <List
      actions={<RoleActions />}
      resource="role"
      empty={false}
      disableSyncWithLocation
      title={t('Roles')}
      sx={{ width: '100%', padding: '0.9rem' }}
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rightAlignedColumns={['action', 'usersWithRole']}
      >
        <TextField source="name" label={t('Name')} />
        <TextField source="usersWithRole" label={t('Users')} />
        <WrapperField label={false} source="action">
          <EditButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default RoleList;
