import React from 'react';

import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FunctionField,
  SelectField,
  useRecordContext,
} from 'react-admin';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import PercentageField from '../../utils/PercentageField';
import CurrencyField from '../../utils/CurrencyField';

import DateField from '../../designSystem/react-admin/DateField';

import { useConstantContext } from '../../ConstantsContext';
import LoanPayoutPlanGrid from '../../loan/LoanPayoutPlanGrid';
import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import UpdateLoanTermsButton from '../UpdateLoanTermsButton';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import CustomSuffixTextField from '../../utils/CustomSuffixTextField';

const LoanApplicationTerms = () => {
  const { choices, constants } = useConstantContext();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { decisionStatuses, userActions } = constants;
  const record = useRecordContext();
  const { financingCurrency } = record.terms;
  const {
    repaymentTypes: repaymentTypesConstants,
  } = constants;
  const { t } = useTranslation();

  const canEditApplication = useHasUserPermissions(
    constants,
    userActions.UPDATE_LOAN_APPLICATIONS_TERMS,
  );

  const pendingReview = !!record.pendingPeerReviewRequest;

  const repaymentTypeInput = useWatch({ name: 'terms.repaymentType' });
  const isFixedAmortisation = repaymentTypeInput === repaymentTypesConstants.FIXED_AMORTISATION;
  const shouldShowPayoutPlan = repaymentTypeInput === repaymentTypesConstants.INTEREST_ONLY;
  const shouldShowAmortisementFree = repaymentTypeInput === repaymentTypesConstants.ANNUITY;

  return (
    <FieldsGroup
      title={t('Terms')}
      actions={isFinancer
        && record.currentDecision === decisionStatuses.REFERRED
        && canEditApplication
        && !pendingReview
        && (<UpdateLoanTermsButton />)}
    >
      <Grid container sx={{ marginBottom: '2.5rem' }}>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="terms.financeAmount" currency={financingCurrency} label={t('Loan amount')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <PercentageField source="terms.nominalRate" label={t('Nominal rate')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CustomSuffixTextField source="terms.repaymentTime" label={t('Repayment period')} customSuffix="months" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="terms.startupFee" currency={financingCurrency} label={t('Startup fees')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="terms.payoutDate" label={t('Payout date')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField source="terms.repaymentType" label={t('Repayment type')} emptyText="-" choices={choices.repaymentTypes} />
          </SimpleShowLabeled>
        </Grid>
        {isFixedAmortisation && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="terms.fixedAmortisation" currency={financingCurrency} label={t('Fixed amortisation')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        )}
        {shouldShowAmortisementFree && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField
                label="Next invoice amortisation"
                render={({ terms: termsRecord }) => `${termsRecord.amortisementFree ? 'Amortisement free' : 'Regular'}`}
              />
            </SimpleShowLabeled>
          </Grid>
        )}
      </Grid>
      <LoanPayoutPlanGrid currency={financingCurrency} shouldShowPayoutPlan={shouldShowPayoutPlan} />
    </FieldsGroup>
  );
};

export default LoanApplicationTerms;
