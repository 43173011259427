import { usePermissions } from 'react-admin';

const useHasModule = (module) => {
  const { permissions: userPermissions } = usePermissions();

  if (
    !userPermissions
    || !userPermissions.modules
    || !Array.isArray(userPermissions.modules)
  ) return null;

  return userPermissions.modules.includes(module);
};

export default useHasModule;
