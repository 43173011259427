import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import useDownloadDocument from '../hooks/useDownloadDocument';

const DownloadAccountBreakdownButton = (props) => {
  const {
    color,
    size,
    variant,
    sx,
  } = props;

  const record = useRecordContext();
  const { id } = record;
  const { t } = useTranslation();

  const {
    mutate: downloadDocument,
    isLoading,
  } = useDownloadDocument({
    path: `account/${id}/account-breakdown`,
    errorMessage: t('Could not retrieve account breakdown'),
  });

  const handleClick = useCallback((e) => {
    e.preventDefault();
    downloadDocument();
  }, [downloadDocument]);

  return (
    <Button
      disabled={isLoading}
      onClick={handleClick}
      variant={variant}
      color={color}
      size={size}
      sx={sx}
    >
      {t('Download breakdown')}
    </Button>
  );
};

DownloadAccountBreakdownButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  sx: PropTypes.shape(),
  variant: PropTypes.string,
};

DownloadAccountBreakdownButton.defaultProps = {
  color: undefined,
  size: undefined,
  sx: {},
  variant: 'contained',
};

export default DownloadAccountBreakdownButton;
