import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TitleH3 from './TitleH3';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  minHeight: 'unset !important',
  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
    padding: '0 !important',
  },
  '& .MuiAccordionSummary-root': {
    padding: '0',
    flexDirection: 'row-reverse',
    gap: '0.5rem',
  },
  '& .MuiAccordionDetails-root': {
    padding: '1rem 0rem 1rem',
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: `1px solid ${theme.palette.switchTrack}`,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme, sx }) => ({
  borderTop: `1px solid ${theme.palette.switchTrack}`,
  ...sx.hasBorderBottom ? { borderBottom: `1px solid ${theme.palette.switchTrack}` } : {},
}));

const AccordionGroup = ({ title, children, hasBorderBottom }) => (
  <StyledAccordion
    defaultExpanded
    disableGutters
  >
    <StyledAccordionSummary
      sx={{
        hasBorderBottom,
      }}
      expandIcon={(
        <ExpandMoreIcon
          sx={{ color: '#1755F4' }}
        />
      )}
    >
      <TitleH3 title={title} marginBottom="0rem" />
    </StyledAccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </StyledAccordion>
);

AccordionGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hasBorderBottom: PropTypes.bool,
};

AccordionGroup.defaultProps = {
  hasBorderBottom: false,
};

export default AccordionGroup;
