// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import {
  useRecordContext,
  NumberField,
} from 'react-admin';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import UpdateFinancingTermsButtonNew from '../UpdateFinancingTermsButton';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import PercentageField from '../../utils/PercentageField';
import FeeGrid from '../../utils/FeeGrid';

import { useConstantContext } from '../../ConstantsContext';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const FinancingApplicationTermsGroup = () => {
  const record = useRecordContext();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { decisionStatuses, userActions } = constants;
  const { t } = useTranslation();

  const canEditApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_FINANCING_APPLICATIONS,
  );

  const pendingReview = !!record.pendingPeerReviewRequest;

  return (
    <>
      <FieldsGroup title={t('Requested terms')}>
        <Grid container sx={{ marginBottom: '2.5rem' }}>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="requestedTerms.financeAmount" currencySource="requestedTerms.financingCurrency" label={t('Finance amount')} />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <PercentageField source="requestedFinanceAmountPercentage" label={`${t('Finance amount')} (%)`} />
            </SimpleShowLabeled>
          </Grid>
          {record.extraInfo && record.extraInfo.financing_currency
            && (
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <NumberField source="extraInfo.financing_currency" label={t('Requested financing currency')} emptyText="-" />
                </SimpleShowLabeled>
              </Grid>
            )}
          {record.extraInfo && record.extraInfo.foreign_exchange_rate
            && (
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <NumberField source="extraInfo.foreign_exchange_rate" label={t('FX rate for financing currency')} emptyText="-" />
                </SimpleShowLabeled>
              </Grid>
            )}
        </Grid>
        <FeeGrid source="requestedTerms.feeSettings" />
      </FieldsGroup>
      <FieldsGroup
        title={t('Terms')}
        actions={isFinancer
        && record.currentDecision === decisionStatuses.REFERRED
        && canEditApplication
        && !pendingReview
        && <UpdateFinancingTermsButtonNew />}
      >
        <Grid container sx={{ marginBottom: '2.5rem' }}>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="terms.financeAmount" currencySource="terms.financingCurrency" label={t('Finance amount')} isRounded />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <PercentageField source="financeAmountPercentage" label={`${t('Finance amount')} (%)`} />
            </SimpleShowLabeled>
          </Grid>
          {record.terms.fxRate
          && (
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <NumberField source="terms.fxRate" label={t('Financing FX rate')} />
              </SimpleShowLabeled>
            </Grid>
          )}
        </Grid>
        <FeeGrid source="terms.feeSettings" />
      </FieldsGroup>
    </>
  );
};
export default FinancingApplicationTermsGroup;
