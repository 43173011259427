import React from 'react';
import PropTypes from 'prop-types';

import CompanyDetailsBusinessContentCustomerApplication from './CompanyDetailsBusinessContentCustomerApplication';
import CompanyDetailsConsumerContentForCustomerApplication from './CompanyDetailsConsumerContentForCustomerApplication';

const CompanyDetailsForCustomerApplication = ({ isBusiness }) => (
  isBusiness
    ? <CompanyDetailsBusinessContentCustomerApplication />
    : <CompanyDetailsConsumerContentForCustomerApplication />
);

CompanyDetailsForCustomerApplication.propTypes = {
  isBusiness: PropTypes.bool,
};

CompanyDetailsForCustomerApplication.defaultProps = {
  isBusiness: true,
};

export default CompanyDetailsForCustomerApplication;
