import React from 'react';
import { Popper, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { AutocompleteInput as RaAutocompleteInput, useChoicesContext, useInput } from 'react-admin';

import { baseSelectProps, getStyledInputStyle } from './SelectInput';
import InputWrapper from './InputWrapper';

const StyledAutocompleteInput = styled(RaAutocompleteInput, {
  shouldForwardProp: (prop) => prop !== 'fimentoVariant',
})(({
  theme,
  error,
  fimentoVariant,
  shouldUseNarrowLabel,
}) => ({
  ...getStyledInputStyle({ theme, error, fimentoVariant }),

  '& .MuiAutocomplete-popupIndicator ': {
    width: '1.75rem',
    height: '1.75rem',
    '& svg path': { fill: theme.palette.secondary.main },
  },

  ...shouldUseNarrowLabel && {
    '.MuiButtonBase-root': {
      borderRadius: '1rem',
      minHeight: '1rem',
    },
  },
}));

const getStyledPopper = (fimentoVariant) => styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-paper': {
    backgroundColor: baseSelectProps(fimentoVariant)
      .MenuProps.MenuListProps.sx.backgroundColor(theme),
  },
}));

const AutocompleteInput = ({
  label,
  placeholder,
  InputLabelProps,
  handleHide,
  ...rest
}) => {
  const { source } = useChoicesContext();
  const {
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ source, ...rest });

  const inputId = `${source}-fimento-reference-input`;
  const variantBaseSelectProps = baseSelectProps(rest.fimentoVariant);
  return (
    <InputWrapper
      inputId={inputId}
      error={invalid}
      label={label}
      isRequired={isRequired}
      InputLabelProps={InputLabelProps}
      fimentoVariant={rest.fimentoVariant}
      handleHide={handleHide(source)}
    >
      <StyledAutocompleteInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error?.message : ''} // Override react admin that reserves space
        id={inputId}
        TextFieldProps={{ label: placeholder || label }}
        label={false}
        variant="outlined"
        PopperComponent={getStyledPopper(rest.fimentoVariant)}
        popupIcon={<variantBaseSelectProps.IconComponent />}
      />
    </InputWrapper>
  );
};

AutocompleteInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.shape(),
  handleHide: PropTypes.func,
};

AutocompleteInput.defaultProps = {
  placeholder: '',
  label: undefined,
  InputLabelProps: {},
  handleHide: () => undefined,
};

export default AutocompleteInput;
