import React, { useState } from 'react';
import {
  useRecordContext,
} from 'react-admin';

import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material';

const LabelsChipGroup = () => {
  const { labels } = useRecordContext();
  const theme = useTheme();
  const [labelPopOver, setLabelPopOver] = useState(null);

  if (!labels || !labels.length) return null;

  const remaingLabels = labels.length > 1 ? `+${labels.length - 1}` : null;
  const chipText = labels[0].name.length > 5
    ? `${labels[0].name.slice(0, 5)}...`
    : labels[0].name;
  const initialChipColor = labels[0].color;

  return (
    <>
      <Chip
        label={chipText}
        style={{
          margin: '2px',
          backgroundColor: initialChipColor,
          color: theme.palette.getContrastText(initialChipColor),
        }}
      />
      {remaingLabels && (
      <>
        <Chip label={remaingLabels} style={{ margin: '2px' }} onClick={(e) => setLabelPopOver(e.currentTarget)} />
        <Popover
          open={!!labelPopOver}
          anchorEl={labelPopOver}
          onClose={() => setLabelPopOver(null)}
        >
          {
            labels.slice(1).map((label) => (
              <Chip
                label={label.name}
                style={{
                  margin: '2px',
                  backgroundColor: label.color,
                  color: theme.palette.getContrastText(label.color),
                }}
                key={label.id}
              />
            ))
          }
        </Popover>
      </>
      )}
    </>
  );
};

export default LabelsChipGroup;
