import { getJWTPayload } from './jwt';
import store from './store';

const getValueFromToken = (key) => () => {
  const token = store.getItem('token');
  if (token) {
    const value = getJWTPayload(token)[key];
    return value || '';
  }
  return '';
};

export const getEntityNameFromToken = getValueFromToken('entity_name');
export const getEntityIdFromToken = getValueFromToken('entity_id');
export const getEntityAllowMerchantPaymentToken = getValueFromToken('entity_allowMerchantPayment');
export const getEntityEnableCreditNotesToken = getValueFromToken('entity_enableCreditNotes');
export const getUserFirstNameFromToken = getValueFromToken('user_firstName');
export const getUserLastNameFromToken = getValueFromToken('user_lastName');
export const getUserLanguageFromToken = getValueFromToken('user_language');
export const getUserIdFromToken = getValueFromToken('user_id');

export default getValueFromToken;
