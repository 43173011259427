// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import {
  ReferenceField,
  TextField,
  FunctionField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import renderReference from '../../../utils/renderReference';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import { FinancialProductsField } from '../../entityv2/FinancingInfoGroup';

const MerchantDetailsInApplication = () => {
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Merchant details')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                label={t('Merchant')}
                source="entityDetailsId"
                reference="entity-v2/merchant"
                link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
              >
                <FunctionField render={renderReference} />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Financial limit')}
              source="merchantId"
              reference="entity-v2/financing-info"
              link={false}
            >
              <CurrencyField source="financingLimit" currencySource="financingLimitCurrency" />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Available financial limit')}
              source="merchantId"
              reference="entity-v2/financing-info"
              link={false}
            >
              <CurrencyField source="financingLimitAvailable" currencySource="financingLimitCurrency" />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Financial products')}
              source="merchantId"
              reference="entity-v2/financing-info"
              link={false}
            >
              <FinancialProductsField />
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Merchant email')}
              source="merchantId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/merchant"
                link={false}
              >
                <TextField source="contactEmail" emptyText="-" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Merchant phone number')}
              source="merchantId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/merchant"
                link={false}
              >
                <TextField source="phone" emptyText="-" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default MerchantDetailsInApplication;
