import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  CreateButton,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Inbox from '@mui/icons-material/Inbox';
import Box from '@mui/material/Box';
import ImportOrderButton from './ImportOrderButton';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const styles = {
  card: {
    width: '100%',
    marginTop: '1rem',
  },
  message: (theme) => ({
    textAlign: 'center',
    opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
    margin: '0.1rem',
    color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
  }),
  icon: {
    width: '9rem',
    height: '9rem',
  },
  toolbar: {
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
};

const EmptyOrderList = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { t } = useTranslation();
  const theme = useTheme();

  if (isFinancer) {
    return (
      <Card sx={styles.card}>
        <Box sx={styles.message(theme)}>
          <Inbox sx={styles.icon} />
          <Typography variant="h4" paragraph>{t('No orders yet')}</Typography>
          <Typography variant="body1">{t('Do you want to create a new one?')}</Typography>
        </Box>
        <Box sx={styles.toolbar}>
          <CreateButton variant="contained" />
        </Box>
        <Box sx={styles.message(theme)}>
          <Typography variant="body1">{t('Or upload orders')}</Typography>
        </Box>
        <Box sx={styles.toolbar}>
          <ImportOrderButton />
        </Box>
      </Card>
    );
  }
  return (
    <Card sx={styles.card}>
      <Box sx={styles.message}>
        <Inbox sx={styles.icon} />
        <Typography variant="h4" paragraph>{t('No orders yet')}</Typography>
      </Box>
      <Box sx={styles.message}>
        <Typography variant="body1">{t('Upload orders')}</Typography>
      </Box>
      <Box textAlign="center" marginTop="1rem" marginBottom="1rem">
        <ImportOrderButton />
      </Box>
    </Card>
  );
};

export default EmptyOrderList;
