import 'core-js/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AdminContext, defaultI18nProvider } from 'react-admin';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import authUser from './authUser';

import theme from './theme';

import config from '../config';
import dataProvider from './dataProvider';
import Resources from './components/Resources';
import { ConstantContextProvider } from './components/ConstantsContext';
import { NotificationContextProvider } from './components/NotificationContext';
import { UnidentifiedContextProvider } from './components/UnidentifiedContext';
import store from './utils/store';
import hotjar from './utils/hotjar';

import './translations/i18n';

Sentry.init({
  dsn: config.sentryDsn,
  enabled: config.sentryEnabled,
  environment: config.sentryEnvironment,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
});

hotjar.init();

const root = createRoot(window.document.getElementById('root'));

root.render(
  <AdminContext
    theme={theme}
    dataProvider={dataProvider}
    authProvider={authUser}
    i18nProvider={defaultI18nProvider}
    store={store}
  >
    <ConstantContextProvider>
      <NotificationContextProvider>
        <UnidentifiedContextProvider>
          <Resources />
        </UnidentifiedContextProvider>
      </NotificationContextProvider>
    </ConstantContextProvider>
  </AdminContext>,
);
