import { t } from 'i18next';

const validateFeeField = ({
  rateTypesConstants,
  feeTriggersConstants,
  feeReferenceAmountsConstants,
  billingMethodsConstants,
}) => (fieldValue, formValues, fieldInfo) => {
  const { source: fullFieldName } = fieldInfo;
  const fieldName = fullFieldName.match(/[\feeSettingsV2\feeSettings]\.\d+\.([\w\d.]+)$/i)[1];
  const feeIndex = fullFieldName.match(/[\feeSettingsV2\feeSettings]\.(\d+)\./i)[1];
  const relatedFeeSettings = formValues.feeSettingsV2
    ? formValues.feeSettingsV2[feeIndex] : formValues.feeSettings[feeIndex];
  if (!relatedFeeSettings) return '';
  let error;

  if (relatedFeeSettings.rateType === rateTypesConstants.VARIABLE) {
    if (fieldName === 'feeTrigger' && fieldValue === feeTriggersConstants.ON_FINANCING) error = t('Invalid Trigger for variable fee');
    if (fieldName === 'dayCountConvention' && !fieldValue) error = t('Day Count Convention is required for variable fee');
    if (fieldName === 'feeReferenceAmount' && fieldValue !== feeReferenceAmountsConstants.FINANCED_AMOUNT) error = t('Invalid Reference Amount for variable fee');
    if (fieldName === 'billingMethod' && fieldValue === billingMethodsConstants.DEDUCT) error = t('Invalid billing method for variable fee');
  } else if (relatedFeeSettings.rateType === rateTypesConstants.FLAT) {
    if (fieldName === 'feeTrigger' && fieldValue === feeTriggersConstants.MONTHLY_UNTIL_PAID) error = t('Invalid Trigger for flat fee');
    if (fieldName === 'dayCountConvention' && !!fieldValue) error = t('Day Count Convention should not be set for flat fee');
  }
  if (fieldName === 'cap.currency') {
    if (!!fieldValue && !!formValues.terms && fieldValue !== formValues.terms.financingCurrency) error = t('Cap currency should be equal to the financing one');
    if (!fieldValue && relatedFeeSettings.cap && relatedFeeSettings.cap.value) error = t('Cap currency is required when the Cap value is set');
  }
  return error;
};

export default validateFeeField;
