import React from 'react';
import {
  SelectField,
  CreateButton,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import PercentageField from '../../utils/PercentageField';
import CurrencyField from '../../utils/CurrencyField';
import ApplicationStatusChip from '../../utils/ApplicationStatusChip';
import CustomSuffixTextField from '../../utils/CustomSuffixTextField';

import List from '../../designSystem/react-admin/list/List';
import { DatagridConfigurable } from '../../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';

import { useConstantContext } from '../../ConstantsContext';

import useApplicationFilters from '../useApplicationFilters';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import TitleH1 from '../../designSystem/TitleH1';

export const getLoanApplicationListTitle = (t) => t('Loan applications');

const LoanApplicationList = () => {
  const { choices, constants } = useConstantContext();
  const { decisionStatuses, userActions } = constants;
  const applicationFilters = useApplicationFilters();
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.EDIT_LOAN_APPLICATIONS,
  );

  return (
    <List
      title={<TitleH1 title={getLoanApplicationListTitle(t)} />}
      mainFilterInputs={applicationFilters}
      filterDefaultValues={{
        currentDecision: decisionStatuses.REFERRED,
      }}
      actions={canEdit ? <CreateButton label={t('Add loan application')} /> : null}
      appLocation="home.loan-application"
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick="edit"
        rightAlignedColumns={['terms\\.repaymentTime', 'currentDecision', 'terms\\.financeAmount', 'terms\\.nominalRate']}
      >
        <EntityReferenceField
          label={t('Customer')}
          source="customerId"
          reference="entity-v2/customer"
          link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
        />
        <SelectField source="terms.repaymentType" label={t('Repayment type')} choices={choices.repaymentTypes} />
        <PercentageField source="terms.nominalRate" label={t('Nominal rate')} />
        <CustomSuffixTextField source="terms.repaymentTime" label={t('Repayment period')} customSuffix={t('months')} />
        <CurrencyField source="terms.financeAmount" currencySource="terms.financingCurrency" label={t('Loan amount')} />
        <ApplicationStatusChip label={t('Application status')} source="currentDecision" />
      </DatagridConfigurable>
    </List>
  );
};
export default LoanApplicationList;
