import React from 'react';
import {
  TextField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../../utils/CurrencyField';
import PercentageField from '../../utils/PercentageField';
import { DatagridConfigurable } from '../../designSystem/react-admin/Datagrid';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../ConstantsContext';

import List from '../../designSystem/react-admin/list/List';
import DateField from '../../designSystem/react-admin/DateField';
import { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';

import useApplicationFilters from '../useApplicationFilters';
import ApplicationStatusChip from '../../utils/ApplicationStatusChip';

import OpenInNewButton from '../OpenInNewButton';
import RulesDecisionChips from '../RulesDecisionChips';
import ApplicationListButtons from '../ApplicationListButtons';
import TitleH1 from '../../designSystem/TitleH1';

export const getFinancingApplicationListTitle = (t) => t('Financing applications');

const ApplicationListDetails = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.EDIT_FINANCING_APPLICATIONS,
  );
  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );

  const linkType = isFinancer && canEdit ? 'edit' : 'show';
  return (
    <DatagridConfigurable
      cellMaxWidth="20rem"
      omit={['orderAmount', 'financeFeePercentage']}
      bulkActionButtons={canEdit ? <ApplicationListButtons /> : false}
      rowClick={linkType}
      sort={{ field: 'createdAt', order: 'DESC' }}
      rightAlignedColumns={['orderAmount', 'financeAmount', 'financeFeePercentage', 'currentDecision', 'openInNew']}
    >
      {[
        <TextField source="orderReferenceNumber" label={t('Order reference')} sortable={false} />,
        canViewMerchant && (
          <EntityReferenceField
            label={t('Merchant')}
            source="merchantId"
            reference="entity-v2/merchant"
            link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
          />
        ),
        <EntityReferenceField
          label={t('Customer')}
          source="buyerId"
          reference="entity-v2/customer"
          link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
        />,
        <DateField source="createdAt" label={t('Creation date')} />,
        <RulesDecisionChips label={t('Decisions')} source="decisionData" />,
        <CurrencyField source="orderAmount" currencySource="orderCurrency" label={t('Order amount')} sortable={false} />,
        <CurrencyField source="financeAmount" currencySource="terms.financingCurrency" label={t('Finance amount')} sortable={false} />,
        <PercentageField source="financeFeePercentage" label={t('Finance fee')} sortable={false} digitsPrecision={2} />,
        <ApplicationStatusChip label={t('Application status')} source="currentDecision" subSource="partnerDecision" />,
        <WrapperField label={t('Open')} source="openInNew">
          <OpenInNewButton linkType={linkType} />
        </WrapperField>,
      ].filter((col) => !!col).map((col) => React.cloneElement(col, { key: col.props.source }))}
    </DatagridConfigurable>
  );
};

const FinancingApplicationList = () => {
  const { constants } = useConstantContext();
  const { decisionStatuses } = constants;
  const applicationFilters = useApplicationFilters();
  const { t } = useTranslation();

  return (
    <List
      title={<TitleH1 title={getFinancingApplicationListTitle(t)} />}
      configurable
      mainFilterInputs={applicationFilters}
      filterDefaultValues={{
        currentDecision: decisionStatuses.REFERRED,
      }}
      appLocation="home.financing-application"
    >
      <ApplicationListDetails />
    </List>
  );
};

export default FinancingApplicationList;
