/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Box, styled } from '@mui/material';
import { SimpleForm as RaSimpleForm } from 'react-admin';

const StyledSimpleForm = styled(Box)`
  ${({ theme }) => `background-color: ${theme.palette.background.default};`}
`;

const SimpleForm = (props) => (
  <StyledSimpleForm>
    <RaSimpleForm {...props} />
  </StyledSimpleForm>
);

export default SimpleForm;
