import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrayField,
  FormTab,
  FunctionField,
  TextField,
  WrapperField,
  useRecordContext,
} from 'react-admin';

import Grid from '@mui/material/Grid';

import CurrencyField from '../utils/CurrencyField';
import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import renderReference from '../../utils/renderReference';

import { useConstantContext } from '../ConstantsContext';

import AddCompanyModalButton from './AddEntityModalButton';
import EditEntityGroupButton from './EditEntityGroupButton';
import RemoveEntityButton from './RemoveEntityButton';

import Datagrid from '../designSystem/react-admin/Datagrid';
import DateField from '../designSystem/react-admin/DateField';
import Edit from '../designSystem/react-admin/Edit';
import ReferenceField from '../designSystem/react-admin/ReferenceField';
import TabbedForm from '../designSystem/react-admin/TabbedForm';

import TitleH1 from '../designSystem/TitleH1';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import LogsList from '../logs/LogsList';

export const getEntityGroupEditTitle = (record) => (record && record.groupName ? record.groupName : '');

const Title = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Entity group')}: ${getEntityGroupEditTitle(record)}`;
  return <TitleH1 title={title} />;
};

const CreditInfoReferenceField = ({ source }) => (
  <ReferenceField
    referenceSource="_id"
    source="entityDetailsId"
    reference="entity-v2/reference"
    FieldComponent={ReferenceField}
    FieldComponentProps={{
      referenceSource: 'entityDetailsId', // Necessary for the prop validation
      reference: 'entity-v2/credit-info',
      resource: 'entity-v2/credit-info',
      source,
      FieldComponent: CurrencyField,
      FieldComponentProps: { currencySource: 'creditLimitCurrency' },
    }}
  />
);

CreditInfoReferenceField.propTypes = {
  source: PropTypes.string.isRequired,
};
CreditInfoReferenceField.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  sortable: false,
};

const EntityLogsList = () => {
  const record = useRecordContext();

  return <LogsList resourceId={record.id} />;
};

const EntityGroupEdit = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_COMPANY_GROUPS,
  );

  return (
    <Edit
      mutationMode="optimistic"
      title={<Title />}
      redirect={false}
      appLocation="home.entity-group.edit"
    >
      <TabbedForm sanitizeEmptyValues toolbar={false}>
        <FormTab label={t('Details')}>
          <FieldsGroup title={t('Core information')} actions={<EditEntityGroupButton />}>
            <Grid container>
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <TextField
                    source="groupName"
                    label={t('Group name')}
                  />
                </SimpleShowLabeled>
              </Grid>
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <CurrencyField
                    label={t('Credit limit of group')}
                    source="creditLimit"
                    currencySource="currency"
                  />
                </SimpleShowLabeled>
              </Grid>
            </Grid>
          </FieldsGroup>
          <FieldsGroup title={t('Information')}>
            <Grid container>
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <DateField
                    label={t('Creation date')}
                    source="createdAt"
                  />
                </SimpleShowLabeled>
              </Grid>
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <ReferenceField
                    referenceSource="id"
                    source="entitiesTotalCreditLimit"
                    reference="entity-group/credit-info"
                    label={t('Total credit limit of entities')}
                    FieldComponent={CurrencyField}
                    FieldComponentProps={{ currencySource: 'currency' }}
                  />
                </SimpleShowLabeled>
              </Grid>
              <Grid item lg={4} md={6}>
                <SimpleShowLabeled>
                  <ReferenceField
                    referenceSource="id"
                    source="entitiesUtilisedCreditLimit"
                    reference="entity-group/credit-info"
                    label={t('Utilised credit limit of group')}
                    FieldComponent={CurrencyField}
                    FieldComponentProps={{ currencySource: 'currency' }}
                  />
                </SimpleShowLabeled>
              </Grid>
            </Grid>
          </FieldsGroup>
          <FieldsGroup title={t('Credit limit breakdown')} actions={canEdit && <AddCompanyModalButton />}>
            <ArrayField source="entities">
              <Datagrid bulkActionButtons={false} rightAlignedColumns={['creditLimitUtilised', 'creditLimit', 'action']}>
                <ReferenceField
                  referenceSource="_id"
                  source="name " // Necessary for the props
                  label={t('Entity')}
                  reference="entity-v2/reference"
                  FieldComponent={FunctionField}
                  FieldComponentProps={{ render: renderReference }}
                />
                <CreditInfoReferenceField source="creditLimitUtilised" label={t('Utilised credit limit')} />
                <CreditInfoReferenceField source="creditLimit" label={t('Credit limit')} />
                {canEdit && <WrapperField label={t('Remove')} source="action"><RemoveEntityButton /></WrapperField>}
              </Datagrid>
            </ArrayField>
          </FieldsGroup>
        </FormTab>
        <FormTab
          label={t('History')}
        >
          <EntityLogsList />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EntityGroupEdit;
