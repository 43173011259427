import React from 'react';

import {
  ReferenceManyField,
  TextField,
  EditButton,
  DateField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';

import { useConstantContext } from '../ConstantsContext';

import Datagrid from '../designSystem/react-admin/Datagrid';

const EntityGroupsGroup = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewEntityGroups = useHasUserPermissions(
    constants,
    userActions.VIEW_COMPANY_GROUPS,
  );

  if (!isFinancer || !canViewEntityGroups) return null;

  return (
    <FieldsGroup title={t('Entity groups')}>
      <ReferenceManyField
        basePath="entity-group"
        resource="entity-group"
        source="entityCoreId"
        reference="entity-group"
        target="entities._id"
        label={false}
        fullWidth
      >
        <Datagrid bulkActionButtons={false} rightAlignedColumns={['creditLimit', 'action']}>
          <TextField source="groupName" label={t('Group name')} />
          <DateField source="createdAt" label={t('Creation date')} />
          <CurrencyField source="creditLimit" currencySource="currency" label={t('Credit limit')} />
          <WrapperField label={t('View')} source="action"><EditButton /></WrapperField>
        </Datagrid>
      </ReferenceManyField>
    </FieldsGroup>
  );
};

export default EntityGroupsGroup;
