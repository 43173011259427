import React from 'react';

import { useRecordContext } from 'react-admin';

import Box from '@mui/material/Box';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Typography from '@mui/material/Typography';

import get from 'lodash/get';

const BooleanField = ({ ...restProps }) => {
  const {
    source,
    label,
    helperText,
  } = restProps;

  const record = useRecordContext(restProps);

  const value = get(record, source);
  const fontOpacity = value ? 1 : 0.6;

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="center"
        margin="0.5rem 0"
      >
        {
          value
            ? <TaskAltIcon fontSize="small" sx={{ color: 'secondary.main' }} />
            : <RadioButtonUncheckedIcon fontSize="small" sx={{ color: '#000', opacity: 0.6 }} />
        }
        <Box marginLeft="0.5rem">
          <Typography
            fontSize="15px"
            fontWeight="500"
            margin="0rem"
            color={value ? 'switch' : '#000'}
            sx={{ opacity: fontOpacity }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
      {
        helperText && (
          <Typography
            fontSize="12px"
            color="switch"
            marginLeft="0.5rem"
          >
            {helperText}
          </Typography>
        )
      }
    </Box>
  );
};

export default BooleanField;
