import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const useCanEdit = () => {
  const { constants } = useConstantContext();

  return useHasUserPermissions(
    constants,
    constants.userActions.CREATE_ORDERS,
  );
};

export default useCanEdit;
