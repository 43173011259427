import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useDownloadDocument from '../hooks/useDownloadDocument';

import { useConstantContext } from '../ConstantsContext';
import { ProcessableFileUploadModalButton } from '../designSystem/ModalButton';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const DownloadMerchantFileTemplate = (props) => {
  const { t } = useTranslation();
  const {
    mutate: downloadSellerFileTemplate,
  } = useDownloadDocument({
    path: 'entity-v2/merchant-file-template',
    errorMessage: t('Could not receive seller file template'),
  });

  const downloadTemplate = useCallback((e) => {
    e.preventDefault();
    downloadSellerFileTemplate();
  }, [downloadSellerFileTemplate]);

  return (
    <Button
      onClick={downloadTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {t('Download merchant file template')}
    </Button>
  );
};

const ImportMerchantButton = () => {
  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { userActions } = constants;

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_MERCHANTS,
  );

  if (!canEdit || !hasFactoring) return null;

  return (
    <ProcessableFileUploadModalButton
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      uploadPath="upload/seller-file"
      ModalButtonProps={{
        modalTitle: t('Upload merchant file'),
        openButtonLabel: t('Upload merchant file'),
        actionLabel: t('Upload'),
        formDefaultValue: {},
      }}
      templateDownloadButton={<DownloadMerchantFileTemplate />}
    />
  );
};

export default ImportMerchantButton;
