import { t } from 'i18next';
import moment from 'moment';

const validateStartEndDateInSameYear = () => (fieldValue, formValues) => {
  const { filters } = formValues;

  if (moment(filters.startDate).year() !== moment(filters.endDate).year()) return t('Start date and end date need to be on the same year');

  return '';
};

export default validateStartEndDateInSameYear;
