import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import PropTypes from 'prop-types';

import { SelectField, useRecordContext } from 'react-admin';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WalletIcon from '@mui/icons-material/Wallet';
import RequestQuoteIcon from '@mui/icons-material/RequestQuoteOutlined';

import { isStrictlyNegativeNumber } from '../../utils/validators';

import { useConstantContext } from '../ConstantsContext';

import { ReactComponent as RefundOverpayment } from '../../../assets/refund_overpayment.svg';
import { ReactComponent as IdentifiedPayment } from '../../../assets/identifiedPayment.svg';
import { ReactComponent as ActionKey } from '../../../assets/action_key.svg';

const TransactionTypeDisplay = ({ source, choicesSource }) => {
  const record = useRecordContext();
  const {
    choices,
    constants: {
      transactionOrigins,
      transactionTypes,
      accountTypes,
    },
  } = useConstantContext();
  const { t } = useTranslation();

  if (record.type === transactionTypes.INTERNAL
    && record.origin.type === transactionOrigins.PAYIN) {
    if (isStrictlyNegativeNumber(record.amount)) {
      return (
        <Typography variant="body2">{t('Unidentified action')}</Typography>
      );
    }
    return (
      <Typography variant="body2">{t('Identified pay-in')}</Typography>
    );
  }
  if (record.accountType === accountTypes.BILLING) {
    return (
      <>
        <Typography variant="body2">{t('Billing')}</Typography>
        <SelectField source={source} choices={choices[choicesSource]} sx={{ textTransform: 'lowercase' }} />
      </>
    );
  }
  return (
    <SelectField source={source} choices={choices[choicesSource]} />
  );
};
const ICON_SIZE = '1rem';

const TransactionIcon = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();

  const { accountType, origin, type } = record;
  const { accountTypes, transactionTypes, transactionOrigins } = constants;

  if (!accountTypes || !transactionTypes || !transactionOrigins) return null;

  const mapTransactionTypeToIcon = {
    [transactionTypes.WITHDRAWAL]: (accountType === accountTypes.BILLING
      ? <DescriptionOutlinedIcon color="secondary" />
      : <ExitToAppIcon color="secondary" sx={{ transform: 'rotate(180deg)' }} />),
    [transactionTypes.USER_PAYMENT]: (accountType === accountTypes.BILLING
      ? <DescriptionOutlinedIcon color="secondary" />
      : <ExitToAppIcon color="secondary" />),
  };

  const mapOriginTypeToIcon = {
    [transactionOrigins.PAYIN]: <IdentifiedPayment color="secondary" />,
    [transactionOrigins.PAYOUT]: <ExitToAppIcon color="secondary" sx={{ transform: 'rotate(180deg)' }} />,
    [transactionOrigins.FINANCING]: <AccountBalanceIcon color="secondary" />,
    [transactionOrigins.AMORTISATION]: <WalletIcon color="secondary" />,
    [transactionOrigins.FACTORING_RESERVE]: <WalletIcon color="secondary" />,
    [transactionOrigins.FACTORING_FEE]: <RequestQuoteIcon color="secondary" />,
    [transactionOrigins.OVERPAYMENT]: <RefundOverpayment />,
  };

  let icon;
  if (type === transactionTypes.WITHDRAWAL || type === transactionTypes.USER_PAYMENT) {
    icon = mapTransactionTypeToIcon[type];
  } else if (type === transactionTypes.INTERNAL
    && origin.type === transactionOrigins.PAYIN
    && isStrictlyNegativeNumber(record.amount)) {
    icon = <ActionKey color="secondary" />;
  } else {
    icon = mapOriginTypeToIcon[origin.type];
  }

  return React.cloneElement(
    icon,
    { sx: { ...icon.props.sx, width: ICON_SIZE, height: ICON_SIZE } },
  );
};

const TransactionField = ({ source, choicesSource }) => (
  <Box display="flex" gap="0.5rem" alignItems="center">
    <TransactionIcon />
    <TransactionTypeDisplay source={source} choicesSource={choicesSource} />
  </Box>
);

TransactionField.propTypes = {
  source: PropTypes.string.isRequired,
  choicesSource: PropTypes.string.isRequired,
};
TransactionTypeDisplay.propTypes = {
  source: PropTypes.string.isRequired,
  choicesSource: PropTypes.string.isRequired,
};

export default TransactionField;
