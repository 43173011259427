import { t } from 'i18next';
import React from 'react';
import {
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import Show from '../designSystem/react-admin/Show';
import TitleH1 from '../designSystem/TitleH1';

export const getLabelShowTitle = (record) => (record ? `${t('Label')}: ${record.name}` : t('Label'));

const LabelShow = () => (
  <Show title={<TitleH1 title={getLabelShowTitle()} />} appLocation="home.my-company.show/label">
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export default LabelShow;
