import React, { useCallback } from 'react';
import {
  FormTab,
  List,
  CreateButton,
  EditButton,
  ShowButton,
  FunctionField,
  useNotify,
  useRedirect,
  DeleteWithConfirmButton,
  TopToolbar,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import Datagrid from '../designSystem/react-admin/Datagrid';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { getEntityIdFromToken } from '../../utils/getValueFromToken';

import { useConstantContext } from '../ConstantsContext';

import renderLabelChip from '../utils/renderLabelChip';

const LabelListTab = (props) => {
  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { userActions } = constants;

  const notify = useNotify();
  const redirect = useRedirect();
  const theme = useTheme();
  const entityId = getEntityIdFromToken();

  const onSuccess = useCallback(() => {
    notify(t('Label deleted'));
    redirect(`/my-company/${entityId}/1`);
  }, [notify, redirect, entityId, t]);

  const canView = useHasUserPermissions(
    constants,
    userActions.VIEW_LABELS,
  );
  const canEdit = useHasUserPermissions(
    constants,
    userActions.MANAGE_LABELS,
  );

  if (!hasFactoring || !canView) return null;

  return (
    <FormTab
      label={t('Labels')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <List
        exporter={false}
        resource="label"
        sx={{ width: '100%' }}
        empty={false}
        actions={canEdit
          ? (
            <TopToolbar sx={{ alignItems: 'center' }}>
              <CreateButton />
            </TopToolbar>
          )
          : null}
        title={<></>}
      >
        <Datagrid
          bulkActionButtons={false}
          rightAlignedColumns={['action']}
        >
          <FunctionField
            label={t('Type')}
            render={renderLabelChip({ theme })}
          />
          {canEdit && (
            <DeleteWithConfirmButton
              confirmTitle={t('Delete label')}
              confirmContent={t('Are you sure you want to delete this label?')}
              size="medium"
              mutationOptions={{ onSuccess }}
              variant="text"
            />
          )}
          {canEdit
            ? <WrapperField label={false} source="action"><EditButton /></WrapperField>
            : <WrapperField label={false} source="action"><ShowButton /></WrapperField>}
        </Datagrid>
      </List>
    </FormTab>
  );
};

export default LabelListTab;
