import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { ArrayField, useRecordContext } from 'react-admin';

import Box from '@mui/material/Box';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import DateField from '../designSystem/react-admin/DateField';
import Datagrid from '../designSystem/react-admin/Datagrid';

const LoanPayoutPlanGrid = ({ currency, shouldShowPayoutPlan }) => {
  const record = useRecordContext();
  const { t } = useTranslation();
  if (!shouldShowPayoutPlan) return null;
  if (!record.terms.payoutPlan || record.terms.payoutPlan.length <= 1) return null;

  return (
    <Box overflow="auto" whiteSpace="nowrap">
      <SimpleShowLabeled fullWidth>
        <ArrayField label={t('Payout plan')} source="terms.payoutPlan" fullWidth>
          <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount']}>
            <DateField source="date" label={t('Payout date')} />
            <DateField source="paidOutAt" label={t('Paid out date')} emptyText="-" />
            <CurrencyField source="amount" currency={currency} label={t('Amount')} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
    </Box>
  );
};

LoanPayoutPlanGrid.propTypes = {
  currency: PropTypes.string.isRequired,
  shouldShowPayoutPlan: PropTypes.bool.isRequired,
};

export default LoanPayoutPlanGrid;
