import { Button, useRecordContext } from 'react-admin';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useCustomRpc from '../hooks/useCustomRpc';
import downloadDocumentOnSuccess from '../../utils/downloadDocumentOnSuccess';

const ProtectedFileButton = ({
  downloadResource,
  condition,
}) => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const documentId = record.id;

  const {
    mutate: downloadDocument,
  } = useCustomRpc({
    httpMethod: 'GET',
    path: `${downloadResource}/${documentId}/download`,
    errorMessage: t('Could not retrieve file'),
    onSuccess: downloadDocumentOnSuccess,
  });

  const onClick = useCallback(() => {
    downloadDocument();
  }, [downloadDocument]);

  return condition(record) ? <Button label={t('Download file')} variant="subAction" onClick={onClick} /> : null;
};

ProtectedFileButton.propTypes = {
  downloadResource: PropTypes.string.isRequired,
  condition: PropTypes.func,
};

ProtectedFileButton.defaultProps = {
  condition: () => true,
};

export default ProtectedFileButton;
