import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useRecordContext } from 'react-admin';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ColumnsChart from '../charts/ColumnsChart';

const charts = {
  PROFITS: 'Revenue vs profits',
  SOLVENCY: 'Solvency',
  CASH_FLOW: 'Cash flow',
};

const useGetChartsLabel = () => {
  const { t } = useTranslation();
  return ({
    [charts.PROFITS]: t('Revenue vs profits'),
    [charts.SOLVENCY]: t('Solvency'),
    [charts.CASH_FLOW]: t('Cash flow'),
  });
};

const CreditSafeChartContext = createContext({
  chart: '',
  setChart: () => {},
});

const ChartSelection = () => {
  const chartLabels = useGetChartsLabel();
  const { chart, setChart } = useContext(CreditSafeChartContext);
  const handleClick = useCallback((e) => {
    setChart(e.target.id);
  }, [setChart]);

  return (
    <Box display="flex" flexDirection="row" gap="0.5rem" flexWrap="wrap">
      {Object.entries(chartLabels).map(([id, label]) => (
        <Button
          id={id}
          key={id}
          variant={(chart === id) ? 'contained' : 'outlined'}
          onClick={handleClick}
          color={(chart === id) ? 'secondary' : 'primary'}
          sx={{ maxHeight: '1.25rem', alignSelf: 'center' }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

const CreditSafeHistoryCharts = () => {
  const { t } = useTranslation();
  const { chart } = useContext(CreditSafeChartContext);
  const record = useRecordContext();

  let valueToCompare = [];
  let comparedValue = [];
  let lossValues = [];
  let serieNameToCompare;
  let serieName;
  if (chart === charts.PROFITS) {
    valueToCompare = record.profitsHistory.map((value) => {
      if (value > 0) return value;
      return 0;
    });
    comparedValue = record.revenuesHistory.map((value) => {
      if (value > 0) return value;
      return 0;
    });
    lossValues = record.profitsHistory.map((value) => {
      if (value < 0) return value;
      return 0;
    });
    serieName = t('REVENUE');
    serieNameToCompare = t('PROFITS');
  } else if (chart === charts.SOLVENCY) {
    comparedValue = record.equityHistory;
    valueToCompare = record.equityAndLiabilitiesHistory;
    serieName = t('EQUITY');
    serieNameToCompare = t('EQUITY & LIABILITIES');
  } else if (chart === charts.CASH_FLOW) {
    comparedValue = record.currentAssetsHistory;
    valueToCompare = record.currentLiabilitiesHistory;
    serieName = t('CURRENT ASSETS');
    serieNameToCompare = t('CURRENT LIABILITIES');
  }

  const feeLabels = record.timelineHistory;

  const feeSerie = [{
    name: serieName,
    data: comparedValue,
  }, {
    name: serieNameToCompare,
    data: valueToCompare,
  }];

  if (lossValues.length > 0) {
    feeSerie.push({
      name: t('LOSSES'),
      data: lossValues,
    });
  }

  return (
    <ColumnsChart
      title=""
      colours={['#204A89', '#74C6AD', '#F95738']}
      labels={feeLabels}
      serie={feeSerie}
      currency={record.currencyHistory}
    />
  );
};

const CreditSafeCharts = () => {
  const [chart, setChart] = useState(charts.PROFITS);
  const value = useMemo(
    () => ({ chart, setChart }),
    [chart],
  );

  return (
    <Box marginTop="2rem" paddingTop="3.125rem">
      <CreditSafeChartContext.Provider value={value}>
        <ChartSelection />
        <CreditSafeHistoryCharts />
      </CreditSafeChartContext.Provider>
    </Box>
  );
};

export default CreditSafeCharts;
