import React from 'react';
import {
  TextField,
  SelectField,
  ReferenceField,
  FunctionField,
  DateField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';
import renderReference from '../../utils/renderReference';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import renderLabelChip from '../utils/renderLabelChip';
import { getEntityNameFromToken } from '../../utils/getValueFromToken';

import { useConstantContext } from '../ConstantsContext';

import useCanEdit from './useCanEdit';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const OrderLabels = () => {
  const canEdit = useCanEdit();
  const theme = useTheme();
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  if (isMerchant) return null;

  return canEdit ? (
    <ReferenceArrayInput
      source="labelIds"
      reference="label"
    >
      <AutocompleteArrayInput
        variant="standard"
        label={t('Labels')}
        inputText={(label) => label.name}
        matchSuggestion={(filter, label) => (
          label.name.includes(filter)
        )}
        optionText={(
          <FunctionField
            label={t('Type')}
            render={renderLabelChip({ theme })}
          />
      )}
      />
    </ReferenceArrayInput>
  ) : (
    <SimpleShowLabeled>
      <ReferenceArrayField
        label={t('Labels')}
        source="labelIds"
        reference="label"
      >
        <SingleFieldList linkType={false} style={{ marginTop: '0.1rem' }}>
          <FunctionField
            render={renderLabelChip({ theme })}
          />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLabeled>
  );
};

const MerchantReference = () => {
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();
  if (isMerchant) {
    return (
      <SimpleShowLabeled>
        <FunctionField label={t('Merchant')} render={getEntityNameFromToken} />
      </SimpleShowLabeled>
    );
  }

  return (
    <SimpleShowLabeled>
      <ReferenceField
        label={t('Merchant')}
        source="sellerId"
        reference="entity-v2/reference"
        link={false}
      >
        <ReferenceField
          source="entityDetailsId"
          reference="entity-v2/merchant"
          link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
        >
          <FunctionField render={renderReference} />
        </ReferenceField>
      </ReferenceField>
    </SimpleShowLabeled>
  );
};

const InvoiceDetailsGroup = () => {
  const { choices } = useConstantContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Invoice details')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <MerchantReference />
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Customer')}
              source="debtorId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/customer"
                link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
              >
                <FunctionField render={renderReference} />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField label={t('Country')} source="debtorId" reference="entity-v2/core" link={false}>
              <ReferenceField
                label={t('Country')}
                source="country"
                reference="countries"
                link={false}
              >
                <FunctionField render={(country) => (country.name ? `(${country.id}) ${country.name}` : '')} />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="orderReferenceNumber" label={t('Order reference number')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="orderAmount" currencySource="currency" label={t('Order amount')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField
              source="currency"
              choices={choices.currencies}
            />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="paymentReference" label={t('Payment reference')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="balance" currencySource="currency" label={t('Order balance')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="activationDate" label={t('Activation date')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="id" label={t('System ID')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="creationSource" label={t('Creation source')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <OrderLabels />
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default InvoiceDetailsGroup;
