import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

const SaveOnlyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export default SaveOnlyToolbar;
