import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import {
  FunctionField,
  TextField,
  useRecordContext,
} from 'react-admin';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import { getUserLanguageFromToken } from '../../utils/getValueFromToken';
import { getLanguageCodeFromLanguageString } from '../../utils/setLanguage';

const CreditDataInfo = () => {
  const { t } = useTranslation();
  const creditRecord = useRecordContext();

  const {
    registrationDate,
    accountPeriodEndDate: accountPeriodEndDateString,
  } = creditRecord;

  const registrationYear = registrationDate ? new Date(registrationDate).getFullYear() : '-';
  const accountPeriodEndDate = accountPeriodEndDateString
    && new Date(accountPeriodEndDateString);
  const selectedLanguage = getUserLanguageFromToken();
  const languageCode = getLanguageCodeFromLanguageString(selectedLanguage);
  const endOfAccountingPeriod = accountPeriodEndDate ? accountPeriodEndDate.getFullYear() : '-';

  return (
    <Grid container>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('External rating')}
            render={
                (record = {}) => (`${record.rating} (${t('outlook')}: ${record.outlook})`)
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="externalLimit" currencySource="currency" label={t('External limit')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="kfDebt" currencySource="currency" label={t('Debt KFM')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={`${t('Payment injunctions')} (${endOfAccountingPeriod})`}
            render={
                (record = {}) => (
                  record.numberOfPaymentInjuctions ? (
                    <>
                      {`${record.numberOfPaymentInjuctions} (`}
                      <CurrencyField source="sumOfPaymentInjuctions" currencySource="currency" emptyText="-" />
                      )
                    </>
                  ) : '-'
                )
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="revenue" currencySource="currency" label={`${t('Revenue')} (${endOfAccountingPeriod})`} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="profit" currencySource="currency" label={`${t('Profit')} (${endOfAccountingPeriod})`} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('Registration year')}
            render={
                () => (`${registrationYear}`)
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <TextField label={t('Number of employees')} source="numberOfEmployees" emptyText="-" sx={{ overflowX: 'auto' }} />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('Accounting end period')}
            render={
                () => (accountPeriodEndDate ? accountPeriodEndDate.toLocaleDateString(languageCode, { year: 'numeric', month: 'long' }) : '-')
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="taxRespiteAmount" currencySource="currency" label={`${t('Tax respite')}`} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={`${t('Registrations')}: ${t('F-tax')}/${t('VAT')}/${t('AB')}`}
            render={
                (record = {}) => (
                  `${record.fTax ? t('Yes') : t('No')}/${record.vat ? t('Yes') : t('No')}/${record.companyType === 'AB' ? t('Yes') : t('No')}`
                )
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('Parent company')}
            render={
                (record = {}) => (`${record.parentCompany} (${record.parentCompanyOrgNr})`)
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('Board changes: 3/6 months')}
            render={
                (record = {}) => (
                  `${record.boardChanges3Months ? t('Yes') : t('No')}/${record.boardChanges6Months ? t('Yes') : t('No')}`
                )
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('Representative check')}
            render={
                (record = {}) => (
                  record.representativeCheck ? t('Negative') : t('Nothing negative')
                )
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('Property')}
            render={
                (record = {}) => (
                  record.numberOfProperties && record.numberOfProperties > 0
                    ? t('Yes') : t('No')
                )
              }
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <FunctionField
            label={t('SNI/NACE-code')}
            render={
                (record = {}) => (
                  `${record.branchDescription} (${record.branchCode})`
                )
              }
          />
        </SimpleShowLabeled>
      </Grid>
    </Grid>
  );
};

export default CreditDataInfo;
