import { styled } from '@mui/material';
import { SimpleShowLayout as RaSimpleShowLayout } from 'react-admin';

const StyledSimpleShowLayout = styled(RaSimpleShowLayout)`
  ${({ theme }) => `background-color: ${theme.palette.background.default};`}
`;

const SimpleShowLayout = StyledSimpleShowLayout;

export default SimpleShowLayout;
