module.exports = {
  apiBase: 'http://localhost:3001',
  apiKey: 'FNQsHE@Jef39hE2w%jU*GGUX',
  sentryDsn: 'https://78f500addb5f477d8cf2e15d21964fc9@o452401.ingest.sentry.io/6228437',
  sentryEnabled: false,
  sentryEnvironment: 'local',
  canEditFeatureFlags: true,
  allowHotjar: false,
  fimentoClientId: '64707e166ef9de0d8dfbb5c7',
};
