import React,
{
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useStatistics } from '../StatisticsContext';

import roundNumber from '../../../utils/roundNumber';

import AreaChart from '../../charts/AreaChart';
import ColumnsChart from '../../charts/ColumnsChart';

import { OverviewKpi } from '../KpiContainer';

import TitleH1 from '../../designSystem/TitleH1';

const periods = {
  THIS_WEEK: 'thisWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  THIS_YEAR: 'thisYear',
  LAST_YEAR: 'lastYear',
};

const useGetPeriodsLabel = () => {
  const { t } = useTranslation();
  return ({
    [periods.THIS_WEEK]: t('1 week'),
    [periods.THIS_MONTH]: t('This month'),
    [periods.LAST_MONTH]: t('Last month'),
    [periods.THIS_YEAR]: t('This year'),
    [periods.LAST_YEAR]: t('Last year'),
  });
};

const PeriodContext = createContext({
  period: '',
  setPeriod: () => {},
});

const PeriodSelection = () => {
  const periodsLabel = useGetPeriodsLabel();
  const { period, setPeriod } = useContext(PeriodContext);
  const handleClick = useCallback((e) => {
    setPeriod(e.target.id);
  }, [setPeriod]);

  return (
    <Box display="flex" flexDirection="row" gap="0.5rem" flexWrap="wrap">
      {Object.entries(periodsLabel).map(([id, label]) => (
        <Button
          id={id}
          key={id}
          variant={(period === id) ? 'contained' : 'outlined'}
          onClick={handleClick}
          color={(period === id) ? 'secondary' : 'primary'}
          sx={{ maxHeight: '1.25rem', alignSelf: 'center' }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

const PrimaryKpis = () => {
  const { t } = useTranslation();
  const { statisticsData } = useStatistics();
  const { period } = useContext(PeriodContext);

  const {
    currency,
    kpis: {
      invoicedAmountCurrentPeriod,
      financedAmountCurrentPeriod,
      totalNumberOfOrders,
      accumulatedFees,
      averageFeePercentage,
    },
  } = statisticsData[period];

  return (
    <Grid container marginBottom="2rem">
      <Grid item lg={4} md={6}>
        <OverviewKpi
          value={invoicedAmountCurrentPeriod}
          label={t('Invoiced amount')}
          currency={currency}
          subValue={totalNumberOfOrders}
          subLabel={t('Invoices sent')}
        />
      </Grid>
      <Grid item lg={4} md={6}>
        <OverviewKpi
          value={financedAmountCurrentPeriod}
          label={t('Financed amount')}
          currency={currency}
          subValue={totalNumberOfOrders}
          subLabel={t('Financed invoices')}
        />
      </Grid>
      <Grid item lg={4} md={6}>
        <OverviewKpi
          value={accumulatedFees}
          label={t('Accumulated fees')}
          currency={currency}
          subValue={averageFeePercentage}
          subLabel={t('Average fee')}
          isRatio
        />
      </Grid>
    </Grid>
  );
};
const PrimaryKpiCharts = () => {
  const { t } = useTranslation();
  const { statisticsData } = useStatistics();
  const { period } = useContext(PeriodContext);

  const {
    currency,
    kpis: {
      periodKpis,
    },
  } = statisticsData[period];

  const amountLabels = periodKpis.map((elem) => (elem.month ? elem.month.slice(0, 3) : elem.day));
  const amountSerie = [{
    name: t('Invoiced'),
    data: periodKpis.map((elem) => roundNumber(elem.invoicedAmountPerPeriod)),
  }, {
    name: t('Financed'),
    data: periodKpis.map((elem) => roundNumber(elem.financedAmountPerPeriod)),
  }];

  const feeLabels = periodKpis.map((elem) => (elem.month ? elem.month.slice(0, 3) : elem.day));
  const feeSerie = [{
    name: t('Fixed'),
    data: periodKpis.map((elem) => roundNumber(elem.fixedFeeAmountPerPeriod)),
  }, {
    name: t('Variable'),
    data: periodKpis.map((elem) => roundNumber(elem.variableFeeAmountPerPeriod)),
  }];

  return (
    <Grid container marginBottom="2rem" spacing={4}>
      <Grid item lg={6} md={6}>
        <AreaChart
          title={t('Invoiced & financed')}
          colours={['#5988FF', '#00FF19']}
          labels={amountLabels}
          serie={amountSerie}
          currency={currency}
        />
      </Grid>
      <Grid item lg={6} md={6}>
        <ColumnsChart
          title={t('Fees')}
          colours={['#204A89', '#74C6AD']}
          labels={feeLabels}
          serie={feeSerie}
          stacked
          currency={currency}
        />
      </Grid>
    </Grid>
  );
};

const SecondaryKpis = () => {
  const { t } = useTranslation();
  const { statisticsData } = useStatistics();
  const { period } = useContext(PeriodContext);

  const {
    currency,
    kpis: {
      applicationsGranted,
      applicationsReferred,
      applicationsDenied,
      averageInvoiceSize,
      averageFinancingSize,
      applicationsTotal,
    },
  } = statisticsData[period];

  return (
    <Grid container spacing={2}>
      <Grid container item lg={4}>
        <Grid item md={6}><OverviewKpi value={averageInvoiceSize} label={t('Average invoice size')} currency={currency} /></Grid>
        <Grid item md={6}><OverviewKpi value={averageFinancingSize} label={t('Average financing size')} currency={currency} /></Grid>
      </Grid>
      <Grid container item lg={8}>
        <Grid item lg={6} md={6}><OverviewKpi value={applicationsTotal} label={t('Total applications')} /></Grid>
        <Grid item lg={6} md={6}><OverviewKpi value={applicationsGranted} label={t('Granted applications')} /></Grid>
        <Grid item lg={6} md={6}><OverviewKpi value={applicationsReferred} label={t('Referred applications')} /></Grid>
        <Grid item lg={6} md={6}><OverviewKpi value={applicationsDenied} label={t('Denied applications')} /></Grid>
      </Grid>
    </Grid>
  );
};

const OverviewKpis = () => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState(periods.THIS_YEAR);
  const value = useMemo(
    () => ({ period, setPeriod }),
    [period],
  );

  return (
    <Card>
      <Box paddingX="1.25rem" paddingY="1.875rem">
        <CardHeader title={<TitleH1 title={t('Overview')} />} sx={{ padding: '0' }} />
        <CardContent sx={{ paddingX: '0' }}>
          <PeriodContext.Provider value={value}>
            <PeriodSelection />
            <PrimaryKpis />
            <PrimaryKpiCharts />
            <SecondaryKpis />
          </PeriodContext.Provider>
        </CardContent>
      </Box>
    </Card>
  );
};

export default OverviewKpis;
