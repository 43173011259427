import React from 'react';
import {
  ArrayInput,
  required,
  useRecordContext,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../../ConstantsContext';

import InputsGroup from '../../designSystem/InputsGroup';
import SelectInput from '../../designSystem/react-admin/inputs/SelectInput';
import NumberInput from '../../designSystem/react-admin/inputs/NumberInput';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import PercentageInput from '../../designSystem/react-admin/inputs/PercentageInput';
import SimpleFormIterator from '../../designSystem/react-admin/SimpleFormIterator';
import BooleanInput from '../../designSystem/react-admin/inputs/BooleanInput';

import {
  isTodayOrFutureDate,
  positiveNumberValidation,
  strictlyPositiveNumberValidation,
} from '../../../utils/validationErrors';

import validatePayoutPlan from '../../utils/validatePayoutPlan';

const LoanApplicationTermsForm = () => {
  const validateRequired = [required()];

  const record = useRecordContext();
  const { choices, constants } = useConstantContext();
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const {
    currencies,
    repaymentTypes,
  } = choices;
  const {
    repaymentTypes: repaymentTypesConstants,
  } = constants;

  const handleRepaymentTypeChange = (e) => {
    setValue('payoutPlan', undefined);
    setValue('fixedAmortisation', undefined);
    if (e.target.value === repaymentTypesConstants.INTEREST_ONLY) {
      setValue('payoutPlan', record.payoutPlan || []);
    } else if (e.target.value === repaymentTypesConstants.FIXED_AMORTISATION) {
      setValue('fixedAmortisation', record.fixedAmortisation || undefined);
    }
  };

  const repaymentTypeInput = useWatch({ name: 'repaymentType' });
  const isFixedAmortisation = repaymentTypeInput === repaymentTypesConstants.FIXED_AMORTISATION;
  const shouldShowPayoutPlan = repaymentTypeInput === repaymentTypesConstants.INTEREST_ONLY;
  const showAmortisationSetting = repaymentTypeInput === repaymentTypesConstants.ANNUITY;

  return (
    <>
      <InputsGroup layout="column">
        <SelectInput
          label={t('Repayment type')}
          source="repaymentType"
          choices={repaymentTypes}
          validate={validateRequired}
          onChange={handleRepaymentTypeChange}
        />
        <NumberInput
          label={t('Loan amount')}
          source="financeAmount"
          validate={[strictlyPositiveNumberValidation, ...validateRequired]}
        />
        <SelectInput
          label={t('Currency')}
          source="financingCurrency"
          choices={currencies}
          validate={validateRequired}
        />
        <PercentageInput
          label={t('Nominal rate (%)')}
          source="nominalRate"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <NumberInput
          label={t('Startup fee')}
          source="startupFee"
          validate={[positiveNumberValidation]}
        />
        <NumberInput
          label={t('Repayment period (months)')}
          source="repaymentTime"
          validate={[strictlyPositiveNumberValidation, ...validateRequired]}
        />
        <DateInput
          label={t('Payout date')}
          source="payoutDate"
          validate={[isTodayOrFutureDate, ...validateRequired]}
        />
        {isFixedAmortisation && (
          <NumberInput
            label={t('Fixed amortisation')}
            source="fixedAmortisation"
            validate={[positiveNumberValidation, ...validateRequired]}
          />
        )}
      </InputsGroup>
      {shouldShowPayoutPlan && (
        <InputsGroup title={t('Payout plan')}>
          <ArrayInput
            label=""
            source="payoutPlan"
            validate={validatePayoutPlan(
              'financeAmount',
              'payoutDate',
            )}
          >
            <SimpleFormIterator itemLabel={t('Payout plan')} addButtonLabel={t('Add new payout')}>
              <InputsGroup layout="column">
                <NumberInput
                  source="amount"
                  label={t('Amount')}
                  validate={[...validateRequired, strictlyPositiveNumberValidation]}
                />
                <DateInput
                  label={t('Payout date')}
                  source="date"
                  validate={validateRequired}
                />
              </InputsGroup>
            </SimpleFormIterator>
          </ArrayInput>
        </InputsGroup>
      )}
      {showAmortisationSetting && (
        <InputsGroup title={t('Amortisation')}>
          <BooleanInput
            source="amortisementFree"
            label={t('Skip amortisation on the next invoice')}
            helperText={t('This is only applied once and will reset after')}
          />
        </InputsGroup>
      )}
    </>
  );
};

export default LoanApplicationTermsForm;
