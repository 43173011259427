import { t } from 'i18next';

const validateAssignedCreditLimits = (assignedCreditLimits) => {
  let error;
  const merchantIds = new Set();

  if (!assignedCreditLimits || !Array.isArray(assignedCreditLimits)) return error;

  assignedCreditLimits.forEach((item) => {
    merchantIds.add(item.merchantId);
  });

  if (merchantIds.size !== assignedCreditLimits.length) {
    error = t('Duplicated merchants in assigned credit limits');
  }

  return error;
};

export default validateAssignedCreditLimits;
