import React from 'react';

import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import FieldsGroup from '../utils/OldFieldsGroup';

import useActionsAvailableToModuleAndRoleType from './useActionsAvailableToModuleAndRoleType';
import UserActionSubSection from './UserActionSubSection';

const UserActionSection = ({
  roleType,
  subSections,
  title,
}) => {
  const sectionActions = [];
  const SubSections = [];
  for (let i = 0; i < subSections.length; i += 1) {
    const { actions, title: subSectionTitle } = subSections[i];
    for (let j = 0; j < actions.length; j += 1) {
      const { action } = actions[j];
      sectionActions.push(action);
    }
    SubSections.push(
      <UserActionSubSection
        title={subSectionTitle}
        roleType={roleType}
        actions={actions}
        key={i}
      />,
    );
  }

  const shouldShow = useActionsAvailableToModuleAndRoleType({
    roleType,
    actions: sectionActions,
  });
  if (!shouldShow) return null;

  return (
    <FieldsGroup
      fillAvailable
      sx={{
        border: '1px solid',
        borderRadius: '4px',
        marginTop: '2rem',
        borderColor: '#D3D3D3',
        padding: '1rem',
      }}
    >
      <Typography sx={{ marginBottom: '2rem', color: '#2B7DF0' }}> {title} </Typography>
      {SubSections}
    </FieldsGroup>
  );
};

UserActionSection.propTypes = {
  roleType: PropTypes.string,
  subSections: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
      action: PropTypes.string,
      actionsToDisable: PropTypes.arrayOf(PropTypes.string),
      actionsToEnable: PropTypes.arrayOf(PropTypes.string),
      isPeerReviewable: PropTypes.bool,
      canBeLimited: PropTypes.bool,
    })),
  })),
  title: PropTypes.string,
};

UserActionSection.defaultProps = {
  roleType: '',
  subSections: [{
    title: '',
    actions: [{
      action: '',
      actionsToDisable: [],
      actionsToEnable: [],
      isPeerReviewable: false,
      canBeLimited: false,
    }],
  }],
  title: '',
};

export default UserActionSection;
