import React from 'react';

import { usePermissions, useRecordContext } from 'react-admin';

import { useConstantContext } from '../ConstantsContext';

import hasUserPermissions from '../../utils/hasUserPermissions';
import DropDownMenu from '../../utils/DropDownMenu';

import useFeatureFlags from '../hooks/useFeatureFlags';

import ConfirmDeliveryConfimrationButton from './ConfirmDeliveryConfimrationButton';
import CancelDeliveryConfirmationButton from './CancelDeliveryConfirmationButton';
import { OpenDeliveryConfirmationFileButton } from '../utils/OpenFileButton';

const DeliveryConfirmationSubActions = () => {
  const recordContext = useRecordContext();
  const { status, documents } = recordContext;
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();

  const [featureFlags] = useFeatureFlags();

  const { userActions, deliveryConfirmationStatuses } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};
  const hasPdf = documents?.length > 0;

  const canEditDeliveryConfirmation = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_DELIVERY_CONFIRMATION,
  );

  const { id } = recordContext;
  const isPending = status === deliveryConfirmationStatuses.PENDING;
  const isConfirmed = status === deliveryConfirmationStatuses.CONFIRMED;

  if (!canEditDeliveryConfirmation || !featureFlags.enableDeliveryConfirmation) return null;

  const subActions = [
    isPending && <ConfirmDeliveryConfimrationButton key="ConfirmButton" />,
    isPending && <CancelDeliveryConfirmationButton key="CancelButton" />,
    isConfirmed && hasPdf && (
      <OpenDeliveryConfirmationFileButton key={`OpenDeliveryConfirmationFileButton_${id}`} />
    ),
  ].filter((subAction) => !!subAction);

  if (subActions.length === 0) return null;

  return (
    <DropDownMenu key={`DropDownMenu_${id}`}>
      {subActions}
    </DropDownMenu>
  );
};

export default DeliveryConfirmationSubActions;
