import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecordContext } from 'react-admin';

import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';

import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';

const UpdateCompanySettings = () => {
  const { entitySettings } = useRecordContext();
  const { t } = useTranslation();

  const {
    mutate: updateSettings,
    isLoading,
  } = useCustomRpc({
    path: 'my-company/update-settings',
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the entity settings'),
  });

  const handleClick = useCallback(({ alwaysCreateDrafts }) => {
    updateSettings({
      entitySettings: {
        alwaysCreateDrafts,
      },
    });
    return true;
  }, [updateSettings]);

  return (
    <ModalButton
      modalTitle={t('Update settings')}
      actionLabel={t('Save')}
      openButtonLabel={t('Update settings')}
      onClick={handleClick}
      disabled={isLoading}
      formDefaultValue={{ alwaysCreateDrafts: entitySettings.alwaysCreateDrafts }}
    >
      <BooleanInput
        source="alwaysCreateDrafts"
        label={t('Always create drafts')}
        helperText={t('When active, the orders will not be processed automatically by the system but saved as drafts instead')}
      />
    </ModalButton>
  );
};

export default UpdateCompanySettings;
