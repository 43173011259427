import React from 'react';
import PropTypes from 'prop-types';
import ApexChart from 'react-apexcharts';

import convertToHumanReadable from '../../utils/convertToHumanReadable';

const totalFormatter = (w) => {
  const total = w.globals.seriesTotals
    .reduce((a, b) => a + b, 0);
  return convertToHumanReadable(total, 6);
};

const DonutChart = ({
  serie,
  labels,
  totalLabel,
  colours,
  title,
  subtitle,
}) => {
  const styleDocument = window.document.createElement('style');
  styleDocument.id = 'fimento-donut-chart-style';
  styleDocument.textContent = `
    .apexcharts-legend-marker {
      cursor: default !important;
    }
  `;
  const alreadyDefinedStyle = !!window.document.getElementById('fimento-donut-chart-style');
  if (!alreadyDefinedStyle) window.document.getElementsByTagName('head')[0].appendChild(styleDocument);

  const options = {
    chart: { type: 'donut' },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: '20px',
              fontWeight: '600',
              color: '#1A3154',
              offsetY: 0,
              formatter: (value) => convertToHumanReadable(value, 6),
            },
            total: {
              show: true,
              showAlways: true,
              label: totalLabel,
              fontSize: '12px',
              fontWeight: '500',
              color: 'rgba(0, 0, 0, 0.55)',
              offsetY: 0,
              formatter: totalFormatter,
            },
          },
        },
      },
    },
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '15px',
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.55)',
      },
    },
    ...(subtitle
      ? { subtitle: { text: subtitle } }
      : {}),
    labels,
    colors: colours,
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
      fontWeight: 400,
      labels: {
        colors: 'rgba(0, 0, 0, 0.6)',
        useSeriesColors: false,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    stroke: {
      width: 0,
    },
  };

  return (
    <ApexChart
      options={options}
      series={serie}
      type="donut"
      height="400"
    />
  );
};

DonutChart.propTypes = {
  serie: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  colours: PropTypes.arrayOf(PropTypes.string),
  subtitle: PropTypes.string,
};

DonutChart.defaultProps = {
  colours: undefined,
  subtitle: undefined,
};

export default DonutChart;
