import React from 'react';
import { t } from 'i18next';

import {
  ArrayField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { Box, Grid } from '@mui/material';

import Typography from '@mui/material/Typography';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';
import { percentageFormat } from '../utils/PercentageField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import EmptiableReferenceField from '../utils/EmptiableReferenceField';

import currencyFormat from '../../utils/currencyFormat';

import UpdateCreditSettingsButton from './UpdateCreditSettingsButton';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';
import { isNumber } from '../../utils/validators';

import Datagrid from '../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';

const renderInsuranceWarning = (record = {}) => (record.isLimitFullyInsured
  ? <Typography variant="caption" color="error">{t('The customer credit limit is not fully insured')}</Typography>
  : '');

const renderNotAllocatedLimitWarning = (record = {}) => (record.notReservedCreditLimit > 0
  ? <Typography variant="caption" color="error">{`${t('There is')} ${currencyFormat(record.notReservedCreditLimit, record.creditLimitCurrency)} ${t('credit limit not reserved for factoring')}`}</Typography>
  : '');

const CreditInfoGroup = () => {
  const { hasFactoring, isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { id } = useRecordContext();

  const canViewCreditInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isFinancer;

  if (!hasFactoring || !canViewCreditInfo) return null;

  return (
    <EmptiableReferenceField
      label={false}
      source="id"
      reference="entity-v2/credit-info"
      link={false}
    >
      <FieldsGroup
        title={t('Credit settings')}
        actions={<UpdateCreditSettingsButton startingEntityDetailsId={id} />}
      >
        <FunctionField render={renderInsuranceWarning} />
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="creditLimit" currencySource="creditLimitCurrency" label={t('Credit limit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="creditInsuranceLimit" currencySource="creditInsuranceLimitCurrency" label={t('Insurance limit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="creditLimitAvailable" currencySource="creditLimitCurrency" label={t('Available credit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField label={`${t('Utilised credit')} (%)`} render={(record = {}) => (isNumber(record.creditLimitUtilisedPercentage) ? percentageFormat(record.creditLimitUtilisedPercentage) : '-')} />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="creditLimitUtilised" currencySource="creditLimitCurrency" label={t('Utilised credit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              {/* This amount is in the credit limit currency */}
              <CurrencyField source="availableInsurance" currencySource="creditLimitCurrency" label={t('Available insurance')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              {/* This amount is in the credit limit currency */}
              <CurrencyField source="utilisedInsurance" currencySource="creditLimitCurrency" label={t('Utilised insurance')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField label={`${t('Utilised insurance')} (%)`} render={(record = {}) => (isNumber(record.utilisedInsurancePercentage) ? percentageFormat(record.utilisedInsurancePercentage) : '-')} />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="column" marginTop="4.5rem">
          <FunctionField render={renderNotAllocatedLimitWarning} />
          <SimpleShowLabeled>
            <ArrayField label={t('Assigned credit limit')} source="assignedCreditLimitInfo">
              <Datagrid bulkActionButtons={false} rightAlignedColumns={['assignedCreditLimit', 'usedCreditLimit', 'availableReservedCreditLimit', 'utilisedAssignedCreditLimit']}>
                <EntityReferenceField
                  label={t('Merchant')}
                  source="creditorId"
                  reference="entity-v2/merchant"
                  link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
                />
                <CurrencyField source="assignedCreditLimit" currencySource="creditLimitCurrency" label={t('Reserved')} />
                <CurrencyField source="usedCreditLimit" currencySource="creditLimitCurrency" label={t('Utilised')} />
                <FunctionField
                  label={`${t('Utilised assigned credit limit')} (%)`}
                  render={(record = {}) => percentageFormat(
                    record.utilisedAssignedCreditLimitPercentage,
                  )}
                />
                <CurrencyField source="availableReservedCreditLimit" currencySource="creditLimitCurrency" label={t('Available')} />
              </Datagrid>
            </ArrayField>
          </SimpleShowLabeled>
        </Box>
      </FieldsGroup>
    </EmptiableReferenceField>
  );
};

export default CreditInfoGroup;
