import React from 'react';
import { Typography } from '@mui/material';

const renderRoles = (record) => {
  if (!record) return '';
  if (!record.roles) return '';

  const roleNames = record.roles.map((role) => role.name);
  return <Typography> {roleNames.join(', ')} </Typography>;
};

export default renderRoles;
