import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DateField,
  NumberField,
  TextField,
} from 'react-admin';
import FieldsGroup from '../utils/OldFieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import CurrencyField from '../utils/CurrencyField';

const LoanDecisionPayload = () => {
  const { t } = useTranslation();
  return (
    <FieldsGroup column={2}>
      <SimpleShowLabeled>
        <CurrencyField source="payload.terms.financeAmount" label={t('Loan amount')} currencySource="payload.terms.financingCurrency" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <TextField source="payload.terms.repaymentType" label={t('Repayment type')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <NumberField source="payload.terms.startupFee" label={t('Startup fee')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <NumberField source="payload.terms.nominalRate" label={t('Nominal rate')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <DateField source="payload.terms.payoutDate" label={t('Payout date')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <NumberField source="payload.terms.repaymentTime" label={t('Repayment period')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <NumberField source="payload.terms.fixedAmortisation" label={t('Fixed amortisation')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <TextField label={t('Decision')} source="payload.decision" />
      </SimpleShowLabeled>
    </FieldsGroup>
  );
};

export default LoanDecisionPayload;
