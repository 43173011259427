import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button } from '@mui/material';

import { useConstantContext } from '../ConstantsContext';
import { ProcessableFileUploadModalButton } from '../designSystem/ModalButton';
import useDownloadDocument from '../hooks/useDownloadDocument';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const DownloadOrderFileTemplate = (props) => {
  const { trigger } = useFormContext();
  const { t } = useTranslation();

  const {
    mutate: downloadOrderFileTemplate,
  } = useDownloadDocument({
    path: 'order/order-file-template',
    errorMessage: t('Could not retrieve order file template'),
  });

  const templateType = useWatch({ name: 'templateType' });

  const downloadTemplate = useCallback(async (e) => {
    e.preventDefault();
    const isValid = await trigger('templateType');
    if (isValid) {
      downloadOrderFileTemplate({ paramId: templateType });
    }
  }, [downloadOrderFileTemplate, templateType, trigger]);

  return (
    <Button
      onClick={downloadTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {t('Download order file template')}
    </Button>
  );
};

const ImportOrderButton = () => {
  const {
    constants,
    choices: {
      orderFileTemplates,
      downloadableOrderTemplateTypes,
    },
  } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canCreate = useHasUserPermissions(constants, userActions.CREATE_ORDERS);
  if (!canCreate || !orderFileTemplates[0]) return null;

  return (
    <ProcessableFileUploadModalButton
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      uploadPath="upload/order-file"
      ModalButtonProps={{
        modalTitle: t('Upload order file'),
        openButtonLabel: t('Upload order file'),
        actionLabel: t('Upload'),
        formDefaultValue: {
          templateType: orderFileTemplates[0].id,
        },
      }}
      templateDownloadButton={<DownloadOrderFileTemplate />}
      choices={orderFileTemplates}
      downloadableTemplateTypes={downloadableOrderTemplateTypes}
    />
  );
};

export default ImportOrderButton;
