import { useMutation } from 'react-query';
import {
  useDataProvider,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';

const useCustomRpc = ({
  httpMethod,
  path,
  successMessage,
  errorMessage,
  shouldRefresh,
  shouldUnselectAll,
  ...queryOptions
}) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(shouldUnselectAll);
  const notify = useNotify();
  const mutation = useMutation(async (data) => {
    const { overridePath, ...body } = data ?? {};
    return dataProvider.customRpc({
      httpMethod,
      path: overridePath ?? path,
      data: httpMethod !== 'GET' ? body : undefined,
    });
  }, {
    ...queryOptions,
    onSuccess: async (...args) => {
      if (successMessage) notify(successMessage, { type: 'info' });
      if (shouldRefresh) refresh();
      if (shouldUnselectAll) unselectAll();
      if (queryOptions.onSuccess) return queryOptions.onSuccess(...args);
      return undefined;
    },
    onError: async (e, ...args) => {
      const notification = e.message ?? errorMessage;
      if (notification) notify(notification, { type: 'error' });
      if (queryOptions.onError) return queryOptions.onError(e, ...args);
      return undefined;
    },
  });

  return mutation;
};

export default useCustomRpc;
