import React from 'react';
import { useTranslation } from 'react-i18next';
import FileList from './FileList';
import TitleH1 from '../designSystem/TitleH1';

export const getUploadedFileListTitle = (t) => t('Uploaded files');

const UploadedFileList = () => {
  const { t } = useTranslation();
  return (
    <FileList
      title={<TitleH1 title={getUploadedFileListTitle(t)} />}
    />
  );
};

export default UploadedFileList;
