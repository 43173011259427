import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { useConstantContext } from '../ConstantsContext';

import UserActionSection from './UserActionSection';

import useFeatureFlags from '../hooks/useFeatureFlags';

import TitleH2 from '../designSystem/TitleH2';

const UserActionsGroup = ({ roleType }) => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const [featureFlags] = useFeatureFlags();

  const {
    userActions,
  } = constants;

  return (
    <>
      <TitleH2 title={t('Role permission settings')} marginTop="2rem" />
      <UserActionSection
        title={t('accounts management').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Billing accounts'),
            actions: [
              {
                action: userActions.VIEW_BILLING_ACCOUNTS,
                actionsToDisable: [userActions.MANAGE_BILLING_ACCOUNTS],
              },
              {
                action: userActions.MANAGE_BILLING_ACCOUNTS,
                actionsToEnable: [userActions.VIEW_BILLING_ACCOUNTS],
              },
            ],
          },
          {
            title: t('Settlement accounts'),
            actions: [
              {
                action: userActions.VIEW_SETTLEMENT_ACCOUNTS,
                actionsToDisable: [userActions.MANAGE_SETTLEMENT_ACCOUNTS],
              },
              {
                action: userActions.MANAGE_SETTLEMENT_ACCOUNTS,
                actionsToEnable: [userActions.VIEW_SETTLEMENT_ACCOUNTS],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('orders').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Orders/Financing management'),
            actions: [
              {
                action: userActions.VIEW_ORDERS,
                actionsToDisable: [
                  userActions.CREATE_ORDERS,
                  userActions.MAKE_RETURNS,
                  userActions.ADD_COMMENTS_ON_ORDERS,
                ],
              },
              {
                action: userActions.CREATE_ORDERS,
                actionsToEnable: [userActions.VIEW_ORDERS],
              },
              {
                action: userActions.MAKE_RETURNS,
                actionsToEnable: [userActions.VIEW_ORDERS],
              },
              {
                action: userActions.ADD_COMMENTS_ON_ORDERS,
                actionsToEnable: [userActions.VIEW_ORDERS],
              },
              ...featureFlags.enableDeliveryConfirmation ? [{
                action: userActions.VIEW_DELIVERY_CONFIRMATION,
                actionsToDisable: [userActions.MANAGE_DELIVERY_CONFIRMATION],
              }, {
                action: userActions.MANAGE_DELIVERY_CONFIRMATION,
                actionsToEnable: [userActions.VIEW_DELIVERY_CONFIRMATION],
              }] : [],
            ],
          },
          {
            title: t('Order drafts'),
            actions: [
              {
                action: userActions.VIEW_ORDER_DRAFTS,
                actionsToDisable: [userActions.MANAGE_ORDER_DRAFTS],
              },
              {
                action: userActions.MANAGE_ORDER_DRAFTS,
                actionsToEnable: [userActions.VIEW_ORDER_DRAFTS],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('loans').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Loans'),
            actions: [
              {
                action: userActions.VIEW_LOANS,
                actionsToDisable: [
                  userActions.EDIT_LOANS,
                ],
              },
              {
                action: userActions.EDIT_LOANS,
                actionsToEnable: [userActions.VIEW_LOANS],
              },
            ],
          },
          {
            title: t('Loan applications'),
            actions: [
              {
                action: userActions.VIEW_LOAN_APPLICATIONS,
                actionsToDisable: [userActions.EDIT_LOAN_APPLICATIONS],
              },
              {
                action: userActions.EDIT_LOAN_APPLICATIONS,
                actionsToEnable: [userActions.VIEW_LOAN_APPLICATIONS],
              },
              {
                action: userActions.UPDATE_LOAN_APPLICATIONS_TERMS,
                actionsToEnable: [userActions.VIEW_LOAN_APPLICATIONS],
              },
              {
                action: userActions.TAKE_LOAN_DECISION,
                actionsToEnable: [userActions.VIEW_LOAN_APPLICATIONS],
                isPeerReviewable: true,
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('applications').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Customer applications'),
            actions: [
              {
                action: userActions.VIEW_CUSTOMER_APPLICATIONS,
                actionsToDisable: [
                  userActions.EDIT_CUSTOMER_APPLICATIONS,
                  userActions.UPDATE_CUSTOMER_APPLICATIONS_TERMS,
                  userActions.TAKE_DECISION_ON_CUSTOMER_APPLICATIONS,
                ],
              },
              {
                action: userActions.EDIT_CUSTOMER_APPLICATIONS,
                actionsToEnable: [userActions.VIEW_CUSTOMER_APPLICATIONS],
              },
              {
                action: userActions.UPDATE_CUSTOMER_APPLICATIONS_TERMS,
                actionsToEnable: [userActions.VIEW_CUSTOMER_APPLICATIONS],
              },
              {
                action: userActions.TAKE_DECISION_ON_CUSTOMER_APPLICATIONS,
                actionsToEnable: [userActions.VIEW_CUSTOMER_APPLICATIONS],
                isPeerReviewable: true,
              },
            ],
          },
          {
            title: t('Financing applications'),
            actions: [
              {
                action: userActions.VIEW_FINANCING_APPLICATIONS,
                actionsToDisable: [
                  userActions.EDIT_FINANCING_APPLICATIONS,
                  userActions.UPDATE_FINANCING_APPLICATIONS_TERMS,
                  userActions.TAKE_FINANCING_DECISION,
                ],
              },
              {
                action: userActions.EDIT_FINANCING_APPLICATIONS,
                actionsToEnable: [userActions.VIEW_FINANCING_APPLICATIONS],
              },
              {
                action: userActions.UPDATE_FINANCING_APPLICATIONS_TERMS,
                actionsToEnable: [userActions.VIEW_FINANCING_APPLICATIONS],
              },
              {
                action: userActions.TAKE_FINANCING_DECISION,
                actionsToEnable: [userActions.VIEW_FINANCING_APPLICATIONS],
                isPeerReviewable: true,
                canBeLimited: true,
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('entities').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Entity groups'),
            actions: [
              {
                action: userActions.VIEW_COMPANY_GROUPS,
                actionsToDisable: [userActions.CREATE_COMPANY_GROUPS],
              },
              {
                action: userActions.CREATE_COMPANY_GROUPS,
                actionsToEnable: [userActions.VIEW_COMPANY_GROUPS],
              },
            ],
          },
          {
            title: t('Merchants'),
            actions: [
              {
                action: userActions.VIEW_MERCHANTS,
                actionsToDisable: [
                  userActions.CREATE_MERCHANTS,
                  userActions.UPDATE_MERCHANT_FINANCIAL_SETTINGS,
                  userActions.SET_MERCHANT_KYC,
                  userActions.ADD_COMMENTS_ON_MERCHANTS,
                  userActions.VIEW_FINANCIAL_SETTINGS,
                ],
              },
              {
                action: userActions.CREATE_MERCHANTS,
                actionsToEnable: [userActions.VIEW_MERCHANTS],
              },
              {
                action: userActions.ADD_COMMENTS_ON_MERCHANTS,
                actionsToEnable: [userActions.VIEW_MERCHANTS],
              },
              {
                action: userActions.VIEW_FINANCIAL_SETTINGS,
                actionsToDisable: [userActions.UPDATE_MERCHANT_FINANCIAL_SETTINGS],
                actionsToEnable: [userActions.VIEW_MERCHANTS],
              },
              {
                action: userActions.UPDATE_MERCHANT_FINANCIAL_SETTINGS,
                actionsToEnable: [
                  userActions.VIEW_MERCHANTS,
                  userActions.VIEW_FINANCIAL_SETTINGS,
                ],
              },
              {
                action: userActions.SET_MERCHANT_KYC,
                actionsToEnable: [userActions.VIEW_MERCHANTS],
              },
            ],
          },
          {
            title: t('Customers'),
            actions: [
              {
                action: userActions.VIEW_CUSTOMERS,
                actionsToDisable: [
                  userActions.ADD_COMMENTS_ON_CUSTOMERS,
                  userActions.CREATE_CUSTOMERS,
                  userActions.SET_CUSTOMER_KYC,
                  userActions.MANAGE_CUSTOMER_CREDIT_LIMIT_FOR_MERCHANTS,
                  userActions.MERGE_CUSTOMERS,
                ],
              },
              {
                action: userActions.ADD_COMMENTS_ON_CUSTOMERS,
                actionsToEnable: [userActions.VIEW_CUSTOMERS],
              },
              {
                action: userActions.CREATE_CUSTOMERS,
                actionsToEnable: [userActions.VIEW_CUSTOMERS],
              },
              {
                action: userActions.MERGE_CUSTOMERS,
                actionsToEnable: [userActions.VIEW_CUSTOMERS],
              },
              {
                action: userActions.SET_CUSTOMER_KYC,
                actionsToEnable: [userActions.VIEW_CUSTOMERS],
              },
              {
                action: userActions.MANAGE_CUSTOMER_CREDIT_LIMIT_FOR_MERCHANTS,
                actionsToEnable: [userActions.VIEW_CUSTOMERS],
              },
            ],
          },
          {
            title: t('External data'),
            actions: [
              {
                action: userActions.VIEW_CREDIT_DATA,
                actionsToDisable: [userActions.PURCHASE_CREDIT_DATA],
              },
              {
                action: userActions.PURCHASE_CREDIT_DATA,
                actionsToEnable: [userActions.VIEW_CREDIT_DATA],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('permissions').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('My company'),
            actions: [
              {
                action: userActions.VIEW_MY_COMPANY_INFORMATION,
                actionsToDisable: [
                  userActions.EDIT_MY_COMPANY_INFORMATION,
                  userActions.VIEW_LABELS,
                  userActions.MANAGE_LABELS,
                  userActions.EDIT_CREDIT_RULES,
                ],
              },
              {
                action: userActions.EDIT_MY_COMPANY_INFORMATION,
                actionsToEnable: [userActions.VIEW_MY_COMPANY_INFORMATION],
              },
              {
                action: userActions.VIEW_LABELS,
                actionsToDisable: [userActions.MANAGE_LABELS],
                actionsToEnable: [userActions.VIEW_MY_COMPANY_INFORMATION],
              },
              {
                action: userActions.MANAGE_LABELS,
                actionsToEnable: [
                  userActions.VIEW_MY_COMPANY_INFORMATION,
                  userActions.VIEW_LABELS,
                ],
              },
              {
                action: userActions.EDIT_CREDIT_RULES,
                actionsToEnable: [userActions.VIEW_MY_COMPANY_INFORMATION],
                isPeerReviewable: false,
              },
            ],
          },
          {
            title: t('Users'),
            actions: [
              {
                action: userActions.VIEW_USERS,
                actionsToDisable: [
                  userActions.CREATE_USERS,
                  userActions.MANAGE_USER_PERMISSIONS,
                ],
              },
              {
                action: userActions.CREATE_USERS,
                actionsToDisable: [userActions.MANAGE_USER_PERMISSIONS],
                actionsToEnable: [userActions.VIEW_USERS],
              },
              {
                action: userActions.MANAGE_USER_PERMISSIONS,
                actionsToEnable: [
                  userActions.VIEW_USERS,
                  userActions.CREATE_USERS,
                ],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('payments').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Payments'),
            actions: [
              {
                action: userActions.VIEW_PAYMENTS,
                actionsToDisable: [userActions.MANAGE_PAYMENTS],
              },
              {
                action: userActions.MANAGE_PAYMENTS,
                actionsToEnable: [userActions.VIEW_PAYMENTS],
              },
            ],
          },
          {
            title: t('Unidentified payments'),
            actions: [
              {
                action: userActions.VIEW_UNIDENTIFIED_PAYMENTS,
                actionsToDisable: [userActions.MANAGE_UNIDENTIFIED_PAYMENTS],
              },
              {
                action: userActions.MANAGE_UNIDENTIFIED_PAYMENTS,
                actionsToEnable: [userActions.VIEW_UNIDENTIFIED_PAYMENTS],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('accounting').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Loss reservation'),
            actions: [
              {
                action: userActions.VIEW_LOSS_RESERVATION,
                actionsToDisable: [userActions.EDIT_LOSS_RESERVATION],
              },
              {
                action: userActions.EDIT_LOSS_RESERVATION,
                actionsToEnable: [userActions.VIEW_LOSS_RESERVATION],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('reports').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Reports'),
            actions: [
              {
                action: userActions.VIEW_REPORTS,
                actionsToDisable: [userActions.GENERATE_REPORTS],
              },
              {
                action: userActions.GENERATE_REPORTS,
                actionsToEnable: [userActions.VIEW_REPORTS],
              },
            ],
          },
        ]}
      />
      <UserActionSection
        title={t('other').toUpperCase()}
        roleType={roleType}
        subSections={[
          {
            title: t('Other'),
            actions: [
              {
                action: userActions.VIEW_EXCHANGE_RATES,
              },
              {
                action: userActions.VIEW_DASHBOARD,
              },
              {
                action: userActions.VIEW_ACTIVITY_HISTORY,
              },
              {
                action: userActions.VIEW_NOTIFICATIONS,
              },
              {
                action: userActions.UPDATE_COMPANY_CORE_INFORMATION,
                actionsToEnable: [
                  userActions.VIEW_CUSTOMERS,
                  userActions.VIEW_MERCHANTS,
                ],
              },
              {
                action: userActions.VIEW_UPLOADED_FILES,
              },
              {
                action: userActions.VIEW_UPLOADED_FILES,
              },
            ],
          },
        ]}
      />
    </>
  );
};

UserActionsGroup.propTypes = {
  roleType: PropTypes.string,
};

UserActionsGroup.defaultProps = {
  roleType: '',
};

export default UserActionsGroup;
