import React from 'react';
import PropTypes from 'prop-types';

import { usePermissions, useRecordContext } from 'react-admin';
import { useConstantContext } from '../ConstantsContext';

import hasUserPermissions from '../../utils/hasUserPermissions';
import DropDownMenu from '../../utils/DropDownMenu';
import { OpenInvoiceFileButton } from '../utils/OpenFileButton';
import CreateCreditNote from '../utils/CreateCreditNote';
import { getEntityEnableCreditNotesToken } from '../../utils/getValueFromToken';
import ResendButton from '../utils/ResendButton';

import RemindInvoiceButton from '../order/RemindInvoiceButton';

import { MarkInvoiceAsPaid } from '../entityv2/MarkAsPaidButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const InvoiceSubActions = ({
  accountId,
  isCreditor,
  accountRecord,
  isReminderOrCreditNote,
}) => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();

  const { isOwner, isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const enableCreditNotes = getEntityEnableCreditNotesToken();

  const { userAllowedPermissions } = authenticationData.permissions || {};

  const { invoiceStatuses, accountTypes } = constants;

  const canEditInvoices = hasUserPermissions(
    userAllowedPermissions,
    constants.userActions.MANAGE_INVOICES,
  );
  if (!canEditInvoices) return null;

  const hasPdf = !!record.pdfGeneratedAt;

  const isMarkInvoiceAsPaidAvailable = record.status !== invoiceStatuses.PAID
  && ((isMerchant && isCreditor) || isFinancer);

  const isRemindInvoiceAvailable = record.status === invoiceStatuses.LATE
  && isFinancer
  && isCreditor;

  const {
    balance,
    accountType,
    invoicingSettings,
  } = accountRecord;

  const shouldSendInvoices = !!(invoicingSettings && invoicingSettings.shouldSendInvoices);
  const isBilling = accountType === accountTypes.BILLING;

  const isCreditNoteAvailable = (isOwner || enableCreditNotes)
  && ((isCreditor && isMerchant) || isFinancer)
  && balance > 0
  && (accountType === accountTypes.ORDER
    || accountType === accountTypes.LOAN);

  const subActionsRelatedInvoice = [
    hasPdf && <OpenInvoiceFileButton key="OpenInvoiceFileButton" />,
    hasPdf && <ResendButton isCreditor={isCreditor} key="ResendButton" />,
  ].filter((subAction) => !!subAction);

  let subActionsInvoice;
  if (isBilling) {
    subActionsInvoice = [hasPdf && (
      <OpenInvoiceFileButton key="OpenInvoiceFileButton" />
    )].filter((subAction) => !!subAction);
  } else {
    subActionsInvoice = [
      hasPdf && (
        <OpenInvoiceFileButton key="OpenInvoiceFileButton" />
      ),
      hasPdf && <ResendButton isCreditor={isCreditor} key="ResendButton" />,
      isMarkInvoiceAsPaidAvailable && <MarkInvoiceAsPaid isCreditor={isCreditor} accountId={accountId} invoiceId={record.id} accountType={record.accountType} key="MarkInvoiceAsPaid" />,
      isRemindInvoiceAvailable && <RemindInvoiceButton isCreditor={isCreditor} accountId={accountId} shouldSendInvoices={shouldSendInvoices} key="RemindInvoiceButton" />,
      isCreditNoteAvailable && <CreateCreditNote accountId={accountId} accountRecord={accountRecord} key="CreateCreditNote" />,
    ].filter((subAction) => !!subAction);
  }

  let subActions = subActionsInvoice;

  if (isReminderOrCreditNote) {
    subActions = subActionsRelatedInvoice;
  }

  if (subActions.length === 0) return null;

  return (
    <DropDownMenu>
      {subActions}
    </DropDownMenu>
  );
};

InvoiceSubActions.propTypes = {
  accountId: PropTypes.string,
  isCreditor: PropTypes.bool.isRequired,
  accountRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape({})),
    invoicingSettings: PropTypes.shape({
      shouldSendInvoices: PropTypes.bool,
    }),
  }),
  isReminderOrCreditNote: PropTypes.bool,
};

InvoiceSubActions.defaultProps = {
  accountId: undefined,
  accountRecord: {},
  isReminderOrCreditNote: false,
};

export default InvoiceSubActions;
