import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import {
  TextField,
  useRecordContext,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import { useConstantContext } from '../ConstantsContext';

const renderAddress = (record) => {
  if (!record) return '-';
  const { mainAddress } = record;

  const addressParts = [
    mainAddress.streetAddress,
    mainAddress.town,
  ].filter((part) => part).join(', ');

  return addressParts || '-';
};

const OverviewGroup = ({ route }) => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const coreTypeInput = useWatch({ name: 'type' });

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? record.coreType === constants.coreTypes.BUSINESS
      : undefined;
  }

  const infos = [
    <SimpleShowLabeled sx={{ paddingRight: '0.9rem' }}>
      <ReferenceField
        label={t('Name')}
        source="entityCoreId"
        reference="entity-v2/core"
        link={({ id }) => `/entity-v2/${route}/${id}`}
      >
        <TextField source="name" emptyText="-" sx={{ overflowX: 'auto' }} />
      </ReferenceField>
    </SimpleShowLabeled>,
    <SimpleShowLabeled>
      <ReferenceField
        label={isBusiness ? t('Org. number') : t('Social security number')}
        source="entityCoreId"
        reference="entity-v2/core"
        link={false}
      >
        <TextField source="nationalIdentifier" />
      </ReferenceField>
    </SimpleShowLabeled>,
    <SimpleShowLabeled sx={{ paddingRight: '0.9rem' }}>
      <FunctionField render={renderAddress} label={t('Company address')} />
    </SimpleShowLabeled>,
    <SimpleShowLabeled sx={{ paddingRight: '0.9rem' }}>
      <TextField source="phone" label={t('Phone')} emptyText="-" sx={{ overflowX: 'auto' }} />
    </SimpleShowLabeled>,
    <SimpleShowLabeled sx={{ paddingRight: '0.9rem' }}>
      <TextField source="contactEmail" label={t('Email')} emptyText="-" sx={{ overflowX: 'auto' }} />
    </SimpleShowLabeled>,
  ];

  return (
    <FieldsGroup
      title={t('Overview')}
    >
      <Grid container>
        {React.Children.map(infos, (info) => (
          <Grid item lg={4} md={6}>
            {info}
          </Grid>
        ))}
      </Grid>
    </FieldsGroup>
  );
};

OverviewGroup.propTypes = {
  route: PropTypes.string.isRequired,
};

export default OverviewGroup;
