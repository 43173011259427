// Deprecated enableAnalyticsAndRedesign
import React from 'react';

import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';

import ApplicationDetailsGroup from '../ApplicationDetailsGroup';
import CustomerDetailsInApplication from '../CustomerDetailsInApplication';
import FinancingApplicationTermsGroup from '../FinancingApplicationTermsGroup';
import MerchantDetailsInApplication from '../MerchantDetailsInApplication';
import FinancingApplicationRulesResultsGroup from '../FinancingApplicationRulesResultsGroup';
import DeliveryConfirmationGroup from '../../../order/DeliveryConfirmationGroup';

const SummaryTab = (props) => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <ApplicationDetailsGroup />
      <CustomerDetailsInApplication />
      {isFinancer && <MerchantDetailsInApplication />}
      <FinancingApplicationTermsGroup />
      <FinancingApplicationRulesResultsGroup />
      <DeliveryConfirmationGroup orderIdSource="orderId" />
    </FormTab>
  );
};

export default SummaryTab;
