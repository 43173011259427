import { usePermissions } from 'react-admin';

import hasPermissions from '../../utils/hasPermissions';

const useHasUserPermissions = (
  constants,
  userAction,
) => {
  const authenticationData = usePermissions();
  const { userAllowedPermissions, modules } = authenticationData.permissions || {};
  const modulesActions = constants.modulesActions || {};

  if (!modulesActions.mapActionToModules
    || !userAllowedPermissions
    || !modules
  ) return false;

  const isModuleCompatible = hasPermissions(
    modules,
    modulesActions.mapActionToModules[userAction],
  );

  const isAllowedAction = userAllowedPermissions.get(userAction)?.hasPermission;

  return isModuleCompatible && isAllowedAction;
};

export default useHasUserPermissions;
