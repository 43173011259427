import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { useConstantContext } from '../ConstantsContext';
import ListChip, { colors } from '../designSystem/ListChip';

const StyledDecisionChip = styled(ListChip)`
  padding: 0 0.25rem;
  width: auto;
`;

const DecisionChip = ({
  id,
  ruleName,
  decision,
  reason,
}) => {
  const { constants: { decisionStatuses, ruleLabelMap } } = useConstantContext();

  return (
    <Tooltip title={reason} key={id}>
      <StyledDecisionChip
        label={ruleLabelMap[ruleName]}
        chipColor={decision === decisionStatuses.REFERRED ? colors.orange : colors.red}
      />
    </Tooltip>
  );
};

DecisionChip.propTypes = {
  id: PropTypes.string.isRequired,
  ruleName: PropTypes.string.isRequired,
  decision: PropTypes.string.isRequired,
  reason: PropTypes.string,
};

DecisionChip.defaultProps = {
  reason: '',
};

export default DecisionChip;
