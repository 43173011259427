import React from 'react';
import {
  SaveButton,
  Toolbar,
  DeleteButton,
} from 'react-admin';
import ActivateDraftButton from './ActivateDraftButton';

const OrderDraftToolbar = () => (
  <Toolbar>
    <SaveButton />
    <ActivateDraftButton />
    <DeleteButton mutationMode="undoable" variant="text" />
  </Toolbar>
);

export default OrderDraftToolbar;
