import React, { useCallback } from 'react';

import {
  required,
  ReferenceField,
  useRecordContext,
  ReferenceInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import InputsGroup from '../designSystem/InputsGroup';

import { vatNumberValidation } from '../../utils/validationErrors';

import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const validateRequired = [required()];

const UpdateCoreInfoButton = () => {
  const {
    id: entityCoreId,
    nationalIdentifier,
    vatNumber,
    country,
    type,
  } = useRecordContext();
  const { isOwner } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const {
    mutate: updateCoreInfo,
    isLoading,
  } = useCustomRpc({
    path: `entity-v2/${entityCoreId}/core`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the core info'),
  });

  const onSubmit = useCallback((formData) => {
    updateCoreInfo({
      nationalIdentifier: formData.nationalIdentifier,
      vatNumber: formData.vatNumber,
      country: formData.country,
      alias: formData.marketplaceReferenceId,
    });

    return true;
  }, [updateCoreInfo]);

  const canEdit = useHasUserPermissions(
    constants,
    userActions.UPDATE_COMPANY_CORE_INFORMATION,
  );

  if (!isOwner || !canEdit) return null;

  const isBusiness = type === constants.coreTypes.BUSINESS;
  return (
    <EditModalButton
      modalTitle={t('Update core information')}
      onClick={onSubmit}
      disabled={isLoading}
      width="25rem"
      formDefaultValue={{
        id: entityCoreId,
        nationalIdentifier,
        vatNumber,
        country,
      }}
    >
      <InputsGroup>
        <TextInput source="nationalIdentifier" label={isBusiness ? t('Org. number') : t('Social security number')} validate={validateRequired} />
        <ReferenceField
          label={t('Alias')}
          source="id"
          reference="entity-v2/reference"
          link={false}
        >
          <TextInput source="marketplaceReferenceId" label={t('Alias')} validate={validateRequired} />
        </ReferenceField>
        {isBusiness && (
          <TextInput source="vatNumber" label={t('VAT number')} validate={[vatNumberValidation]} />
        )}
        <ReferenceInput
          source="country"
          reference="countries"
          resource="/countries"
          link={false}
        >
          <AutocompleteInput
            optionText={(record) => (record && record.name ? `(${record.id}) ${record.name}` : '')}
            label={t('Country')}
            placeholder={t('Country')}
            validate={validateRequired}
          />
        </ReferenceInput>
      </InputsGroup>
    </EditModalButton>
  );
};

export default UpdateCoreInfoButton;
