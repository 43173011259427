import React from 'react';
import {
  FormTab,
  ReferenceManyField,

} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../../../utils/FieldsGroup';
import SafePagination from '../../../utils/SafePagination';

import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../../ConstantsContext';

import {
  OpenDatagrid,
  ClosedDatagrid,
  InvoicedDatagrid,
  ReadyToChargeDatagrid,
} from '../../../billingAccount/BillingDatagrid';

const EntityBillingsTab = (props) => {
  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants, clientInfo } = useConstantContext();
  const { accountStatuses, userActions } = constants;
  const { t } = useTranslation();

  const canView = useHasUserPermissions(
    constants,
    userActions.VIEW_BILLING_ACCOUNTS,
  );

  const showFinancerOnTab = true; // Always show financer on tab
  const showPartnerOnTab = !clientInfo.defaultPartnerFinancer;

  if (!hasFactoring || !canView) return null;

  return (
    <FormTab
      label={t('Billings')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FieldsGroup title={t('Open')}>
        <ReferenceManyField
          source="entityCoreId"
          reference="billing-account"
          target="debtorId"
          label=""
          filter={{ accountStatus: accountStatuses.ACTIVE }}
          pagination={<SafePagination />}
          perPage={5}
        >
          <OpenDatagrid showFinancerName={showFinancerOnTab} showPartnerName={showPartnerOnTab} />
        </ReferenceManyField>
      </FieldsGroup>

      <FieldsGroup title={t('Ready to charge')}>
        <ReferenceManyField
          source="entityCoreId"
          reference="billing-account"
          target="debtorId"
          label=""
          filter={{ accountStatus: accountStatuses.PARKED }}
          pagination={<SafePagination />}
          perPage={5}
        >
          <ReadyToChargeDatagrid showFinancerName={showFinancerOnTab} showPartnerName={showPartnerOnTab} />
        </ReferenceManyField>
      </FieldsGroup>

      <FieldsGroup title={t('Invoiced')} withBorder column={1} fullWidth>
        <ReferenceManyField
          source="entityCoreId"
          reference="billing-account"
          target="debtorId"
          label=""
          filter={{ accountStatus: accountStatuses.INVOICED }}
          pagination={<SafePagination />}
          perPage={5}
        >
          <InvoicedDatagrid showFinancerName={showFinancerOnTab} showPartnerName={showPartnerOnTab} />
        </ReferenceManyField>
      </FieldsGroup>

      <FieldsGroup title={t('Closed')}>
        <ReferenceManyField
          source="entityCoreId"
          reference="billing-account"
          target="debtorId"
          label=""
          filter={{ accountStatus: accountStatuses.CLOSED }}
          sort={{ field: 'closedAt', order: 'DESC' }}
          pagination={<SafePagination />}
          perPage={5}
        >
          <ClosedDatagrid showFinancerName={showFinancerOnTab} showPartnerName={showPartnerOnTab} />
        </ReferenceManyField>
      </FieldsGroup>
    </FormTab>
  );
};

export default EntityBillingsTab;
