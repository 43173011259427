import React from 'react';
import PropTypes from 'prop-types';
import { Popper, IconButton, alpha } from '@mui/material';

const ScrollButtonBase = ({
  isDisplayed,
  onClick,
  parentElement,
  placement,
  color,
  backgroundColor,
  icon,
  popperRef,
  zIndex,
  extraPadding,
}) => (
  <Popper
    open={isDisplayed}
    anchorEl={() => parentElement}
    sx={{ zIndex: zIndex ?? 1 }}
    placement={placement}
    popperRef={popperRef}
    modifiers={[{
      name: 'offset',
      options: {
        offset: [0, -48], // button width + 8 = 48
      },
    }, {
      name: 'preventOverflow',
      enabled: true,
      options: {
        altBoundary: true,
        rootBoundary: 'viewport',
        padding: {
          top: (extraPadding?.top ?? 0) + 8,
          bottom: (extraPadding?.bottom ?? 0) + 8,
          right: (extraPadding?.right ?? 0) + 8,
          left: (extraPadding?.left ?? 0) + 8,
        },
      },
    }]}
  >
    <IconButton
      sx={{
        color,
        backgroundColor: alpha(backgroundColor, 0.8),
      }}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  </Popper>
);

ScrollButtonBase.propTypes = {
  parentElement: PropTypes.instanceOf(window.Element),
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
  extraPadding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }).isRequired,
  popperRef: PropTypes.shape(),
  zIndex: PropTypes.number,
};

ScrollButtonBase.defaultProps = {
  parentElement: null,
  popperRef: undefined,
  zIndex: undefined,
};

export default ScrollButtonBase;
