import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const summaryKeys = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

const styles = {
  accordionDetails: {
    maxHeight: '10rem',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  colors: {
    [summaryKeys.SUCCESS]: { color: '#479F4C' },
    [summaryKeys.INFO]: { color: '#3460c7' },
    [summaryKeys.WARNING]: { color: '#ffb347' },
    [summaryKeys.ERROR]: { color: '#BD371A' },
  },
};

const UploadedFileSummary = ({
  summary,
  successLabel,
  infoLabel,
  warningLabel,
  errorLabel,
  record,
}) => {
  const summaryToRender = record.summary || summary;
  if (!summaryToRender) return null;

  const labels = {
    [summaryKeys.SUCCESS]: successLabel,
    [summaryKeys.INFO]: infoLabel,
    [summaryKeys.WARNING]: warningLabel,
    [summaryKeys.ERROR]: errorLabel,
  };

  return (
    <Box sx={{ width: '100%' }}>
      {Object.values(summaryKeys)
        .filter((key) => labels[key] && summaryToRender[key])
        .map((key) => (
          <Accordion key={key} disabled={summaryToRender[key].length === 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={styles.colors[key]}
            >
              <Typography>{labels[key]}: {summaryToRender[key].length}</Typography>
            </AccordionSummary>
            <AccordionDetails style={styles.accordionDetails}>
              {summaryToRender[key].map((item) => (
                <Typography key={`${key}${item.row}`} whiteSpace="normal !important"> {item.row ? `Row ${item.row}` : ''} {item.sheet ? `in ${item.sheet}.` : ''} {item.comment ? `Comment: ${item.comment}` : ''}</Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

const summaryItemPropType = PropTypes.arrayOf(PropTypes.shape({
  comment: PropTypes.string,
  row: PropTypes.number,
  sheet: PropTypes.string,
}));

UploadedFileSummary.propTypes = {
  summary: PropTypes.shape({
    success: summaryItemPropType,
    info: summaryItemPropType,
    warning: summaryItemPropType,
    error: summaryItemPropType,
  }),
  successLabel: PropTypes.string,
  infoLabel: PropTypes.string,
  warningLabel: PropTypes.string,
  errorLabel: PropTypes.string,
  record: PropTypes.shape({
    summary: PropTypes.shape({
      success: summaryItemPropType,
      info: summaryItemPropType,
      warning: summaryItemPropType,
      error: summaryItemPropType,
    }),
  }),
};

UploadedFileSummary.defaultProps = {
  summary: {},
  successLabel: undefined,
  infoLabel: undefined,
  warningLabel: undefined,
  errorLabel: undefined,
  record: {},
};

export default UploadedFileSummary;
