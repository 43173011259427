import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useRecordContext, useResourceContext } from 'react-admin';
import { useTranslation } from 'react-i18next';

import useCustomRpc from '../hooks/useCustomRpc';
import { useConstantContext } from '../ConstantsContext';

const MarkAsSignedButton = () => {
  const {
    id,
    applicationStep,
  } = useRecordContext();
  const resource = useResourceContext();
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const { applicationSteps } = constants;

  const {
    mutate: markAsSigned,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resource}/${id}/mark-as-signed`,
    shouldRefresh: true,
    errorMessage: t('Could not mark this application as signed'),
  });

  const handleClick = useCallback(() => {
    markAsSigned();
  }, [markAsSigned]);

  if (applicationStep !== applicationSteps.ACCEPTED) return null;

  return (
    <Box margin="0.5rem 0.3rem" sx={{ float: 'right' }}>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        size="small"
        disabled={isLoading}
      >
        {t('Mark as signed')}
      </Button>
    </Box>
  );
};

export default MarkAsSignedButton;
