import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import CoreInfoGroup from '../../CoreInfoGroup';
import CompanyDetails from '../../company-details/CompanyDetails';
import SystemInfoGroup from '../../SystemInfoGroup';
import AdditionalInfoGroup from '../../AdditionalInfoGroup';
import KeyPeople from '../../KeyPeople';

import { useConstantContext } from '../../../ConstantsContext';

import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';

const DetailsTab = (props) => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Details')} {...props}>
      <CoreInfoGroup />
      <CompanyDetails
        viewUserAction={userActions.VIEW_CUSTOMERS}
        createUserAction={userActions.CREATE_CUSTOMERS}
        enableAliasInput={isMerchant}
        detailsSource="customerDetails"
      />
      <KeyPeople userAction={userActions.CREATE_CUSTOMERS} />
      <AdditionalInfoGroup userAction={userActions.CREATE_CUSTOMERS} route="customer" />
      <SystemInfoGroup />
    </FormTab>
  );
};

export default DetailsTab;
