import React from 'react';
import PropTypes from 'prop-types';
import {
  FormTab,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../../ConstantsContext';

import OrderList from '../../order/OrderList';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const EntityOrdersTab = (props) => {
  const {
    queryTarget,
    isMerchantView,
    isCustomerView,
    ...rest
  } = props;

  const record = useRecordContext();
  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewOrder = useHasUserPermissions(
    constants,
    userActions.VIEW_ORDERS,
  );

  if (!hasFactoring || !canViewOrder) return null;

  return (
    <FormTab
      label={t('Orders')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <OrderList filter={{ [queryTarget]: record.entityCoreId }} />
    </FormTab>
  );
};

EntityOrdersTab.propTypes = {
  queryTarget: PropTypes.string.isRequired,
  isMerchantView: PropTypes.bool,
  isCustomerView: PropTypes.bool,
};

EntityOrdersTab.defaultProps = {
  isMerchantView: false,
  isCustomerView: false,
};

export default EntityOrdersTab;
