import { useTranslation } from 'react-i18next';
import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import {
  UnauthorizedPageWrapper,
  StyledTextInput,
  StyledTextInputLabel,
} from '../utils/Login';
import { required } from '../../utils/validationErrors';

import useCustomRpc from '../hooks/useCustomRpc';

import Button from '../designSystem/Button';

const visibilityIconStyle = {
  marginRight: '0.3rem',
  ':hover': { cursor: 'pointer' },
};

const PasswordCriteria = ({ criteriaCheck, criteriaMessage }) => (
  <Box
    display="flex"
  >
    {criteriaCheck ? <CheckIcon sx={{ color: '#5DB54D' }} /> : <CloseIcon sx={{ color: '#F95738' }} />}
    <Typography fontSize="15px" fontWeight="500" marginLeft="0.5rem" lineHeight="22.5px">{criteriaMessage}</Typography>
  </Box>
);

PasswordCriteria.propTypes = {
  criteriaCheck: PropTypes.bool.isRequired,
  criteriaMessage: PropTypes.string.isRequired,
};

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasMinimumCharacters, setHasMinimumCharacters] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    mutate: resetPassword,
  } = useCustomRpc({
    path: 'authorization/reset-password',
    httpMethod: 'POST',
    errorMessage: t('Could not reset password'),
    onSuccess: () => {
      setIsSubmitted(true);
    },
  });

  const handleChange = useCallback((e) => {
    setPassword(e.target.value);
    const containsUpperCase = /[A-Z]/.test(e.target.value);
    const containsNumber = /[0-9]/.test(e.target.value);
    const containsMinimumCharacters = e.target.value.length >= 8;

    setHasUpperCase(containsUpperCase);
    setHasNumber(containsNumber);
    setHasMinimumCharacters(containsMinimumCharacters);
  }, [setPassword]);

  const onClick = useCallback(() => {
    setIsVisible(!isVisible);
  }, [setIsVisible, isVisible]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const newError = required(password);
    if (newError) return;

    resetPassword({
      resetToken: token,
      password,
    });
  }, [password, resetPassword, token]);

  if (!isSubmitted) {
    return (
      <UnauthorizedPageWrapper title={t('Set new password')}>
        <form onSubmit={handleSubmit} noValidate>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            gap="1.5rem"
          >
            <FormControl variant="filled" focused>
              <StyledTextInputLabel htmlFor="email">{t('Password')}</StyledTextInputLabel>
              <StyledTextInput
                id="password"
                name="password"
                label={t('New password')}
                type={isVisible ? 'text' : 'password'}
                value={password}
                onChange={handleChange}
                endAdornment={isVisible
                  ? <VisibilityOutlinedIcon sx={visibilityIconStyle} onClick={onClick} />
                  : <VisibilityOffOutlinedIcon sx={visibilityIconStyle} onClick={onClick} />}
              />
            </FormControl>
            <Box display="flex" gap="0.5rem" flexDirection="column">
              <PasswordCriteria criteriaCheck={hasUpperCase} criteriaMessage={t('At least 1 uppercase')} />
              <PasswordCriteria criteriaCheck={hasNumber} criteriaMessage={t('At least 1 number')} />
              <PasswordCriteria criteriaCheck={hasMinimumCharacters} criteriaMessage={t('At least 8 character')} />
            </Box>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!hasUpperCase || !hasNumber || !hasMinimumCharacters}
              disabledTooltip={!hasUpperCase || !hasNumber || !hasMinimumCharacters ? t('The password does not meet the requirements') : undefined}
              sx={{
                padding: '0.5rem',
                height: '2.5rem',
                marginTop: '0.8rem',
                width: '100%',
              }}
            >
              {t('Change password')}
            </Button>
          </Box>
        </form>
      </UnauthorizedPageWrapper>
    );
  }

  return (
    <UnauthorizedPageWrapper title={t('Password changed')}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        gap="1.5rem"
      >
        <Typography fontSize="15px" lineHeight="22.5px">{t('You have successfully updated your password!')}</Typography>
        <Button
          color="secondary"
          variant="contained"
          href="/#/login"
          sx={{
            padding: '0.5rem',
            height: '2.5rem',
            marginTop: '0.8rem',
          }}
        >
          {t('Go to login')}
        </Button>
      </Box>
    </UnauthorizedPageWrapper>
  );
};

export default ResetPasswordPage;
