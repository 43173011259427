// Deprecated
import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
} from 'react-admin';
import MUTextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

export const MUTextInputWithLens = (props) => (
  <MUTextField
    variant="standard"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />
);

const TextInputWithLens = (props) => (
  <TextInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />
);

TextInputWithLens.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  alwaysOn: PropTypes.bool,
};

TextInputWithLens.defaultProps = {
  alwaysOn: undefined,
};

export default TextInputWithLens;
