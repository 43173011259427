import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useConstantContext } from '../ConstantsContext';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';

const useNotificationListFilters = () => {
  const { choices } = useConstantContext();
  const { t } = useTranslation();
  const { resourceTypes } = choices;

  if (isEmpty(resourceTypes)) return null;

  return [
    <SelectInput
      alwaysOn
      placeholder={t('Status')}
      source="isRead"
      choices={[
        { id: true, name: t('Read') },
        { id: false, name: t('Not read') },
      ]}
    />,
    <SelectInput
      alwaysOn
      placeholder={t('Event type')}
      source="resource.resourceType"
      choices={resourceTypes}
    />,
  ];
};

export default useNotificationListFilters;
