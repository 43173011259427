import React from 'react';
import {
  TextField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import EntityListActions from '../EntityListActions';

import CurrencyField from '../../utils/CurrencyField';
import SafePagination from '../../utils/SafePagination';
import UtilisationField from '../../utils/UtilisationField';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../ConstantsContext';

import List from '../../designSystem/react-admin/list/List';
import ReferenceField from '../../designSystem/react-admin/ReferenceField';
import DateField from '../../designSystem/react-admin/DateField';
import TextInputWithLens from '../../designSystem/react-admin/inputs/TextInputWithLens';
import { DatagridConfigurable } from '../../designSystem/react-admin/Datagrid';
import TitleH1 from '../../designSystem/TitleH1';

export const getMerchantListTitle = (t) => t('Merchants');

const MerchantList = () => {
  const { constants, clientInfo } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewFinancingInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_FINANCIAL_SETTINGS,
  );
  const { hasFactoring } = useUserPermissionsAndRoles();

  return (
    <List
      actions={<EntityListActions />}
      empty={false}
      pagination={<SafePagination />}
      mainFilterInputs={[<TextInputWithLens placeholder={t('Search name, alias, VAT/Org')} source="custom_q" />]}
      sort={{ field: 'mainAddress.name', order: 'ASC' }}
      title={<TitleH1 title={getMerchantListTitle(t)} />}
      configurable
      appLocation="home.entity-v2/merchant"
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick="edit"
        omit={['marketplaceReferenceId', 'vatNumber']}
        rightAlignedColumns={[
          'kpis\\.outstandingFinancedAmount',
          'kpis\\.overdueAmount',
          'financingLimit',
          'financingLimitUtilised',
          'financingLimitUtilisedPercentage',
        ]}
      >
        {[
          hasFactoring && <TextField source="marketplaceReferenceId" label={t('Alias')} />,
          <TextField source="mainAddress.name" label={t('Name')} />,
          <DateField source="createdAt" label={t('Creation date')} />,
          <ReferenceField
            label={t('VAT number')}
            referenceSource="entityCoreId"
            source="vatNumber"
            reference="entity-v2/core"
            resource="entity-v2/core"
            FieldComponent={TextField}
          />,
          hasFactoring && canViewFinancingInfo && (
            <ReferenceField
              label={t('Financing limit')}
              referenceSource="entityCoreId"
              source="financingLimit"
              reference="entity-v2/financing-info"
              resource="entity-v2/financing-info"
              FieldComponent={CurrencyField}
              FieldComponentProps={{ currencySource: 'financingLimitCurrency' }}
            />
          ),
          hasFactoring && canViewFinancingInfo && (
            <ReferenceField
              label={t('Exposure')}
              referenceSource="entityCoreId"
              source="financingLimitUtilised"
              reference="entity-v2/financing-info"
              resource="entity-v2/financing-info"
              FieldComponent={CurrencyField}
              FieldComponentProps={{ currencySource: 'financingLimitCurrency' }}
            />
          ),
          <CurrencyField source="kpis.outstandingFinancedAmountInClientCurrency" label={`${t('Exposure')} ${clientInfo.referenceCurrency}`} currency={clientInfo.referenceCurrency} />,
          <CurrencyField source="kpis.overdueAmountInClientCurrency" label={`${t('Overdue amount')} ${clientInfo.referenceCurrency}`} currency={clientInfo.referenceCurrency} />,
          hasFactoring && canViewFinancingInfo && (
            <ReferenceField
              label={`${t('Exposure')} (%)`}
              referenceSource="entityCoreId"
              source="financingLimitUtilisedPercentage"
              reference="entity-v2/financing-info"
              resource="entity-v2/financing-info"
              FieldComponent={UtilisationField}
            />
          ),
        ].filter((col) => !!col).map((col) => React.cloneElement(col, { key: col.props.source }))}
      </DatagridConfigurable>
    </List>
  );
};

export default MerchantList;
