import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrayField,
  RecordContextProvider,
  TextField,
  useGetOne,
  useRecordContext,
  useNotify,
} from 'react-admin';
import FieldsGroup from '../utils/OldFieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import CurrencyField from '../utils/CurrencyField';
import PercentageField from '../utils/PercentageField';
import Datagrid from '../designSystem/react-admin/Datagrid';

const ExpandedFeeValues = () => {
  const { t } = useTranslation();
  return (
    <FieldsGroup>
      <SimpleShowLabeled>
        <TextField source="feeReferenceAmount" label={t('Reference Amount')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <TextField source="dayCountConvention" label={t('Day Count Convention')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <CurrencyField source="cap.value" currencySource="cap.currency" label={t('Fee Cap')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <PercentageField source="vatPercentage" label={`${t('VAT')} (%)`} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <TextField source="description" label={t('Description')} emptyText="-" />
      </SimpleShowLabeled>
    </FieldsGroup>
  );
};

const FinancingDecisionPayload = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { payload } = record;

  const { data, error } = useGetOne('financing-application', { id: record.resourceId });
  const notify = useNotify();

  useEffect(() => {
    if (error) notify(error, { type: 'warning' });
  }, [error, notify]);

  return (
    <FieldsGroup>
      <SimpleShowLabeled>
        <CurrencyField label={t('Financing Amount')} source="payload.terms.financeAmount" currencySource="payload.terms.financingCurrency" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <RecordContextProvider
          label={t('Financing Percentage')}
          value={{
            financeAmountPercentage: (payload.terms.financeAmount / data.orderAmount) * 100,
          }}
        >
          <PercentageField emptyText="-" source="financeAmountPercentage" />
        </RecordContextProvider>
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <ArrayField label={t('Fees')} source="payload.terms.feeSettings">
          <Datagrid bulkActionButtons={false} expand={<ExpandedFeeValues />} rightAlignedColumns={['percentageValue']}>
            <TextField source="rateType" label={t('Rate')} emptyText="-" />
            <TextField source="feeTrigger" label={t('Trigger')} emptyText="-" />
            <TextField source="billingMethod" label={t('Billing Method')} emptyText="-" />
            <PercentageField source="percentageValue" label={`${t('Fee')} (%)`} emptyText="-" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <TextField label={t('Decision')} source="payload.decision" />
      </SimpleShowLabeled>
    </FieldsGroup>
  );
};

export default FinancingDecisionPayload;
