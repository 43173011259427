import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import { useConstantContext } from '../../ConstantsContext';

import ReferenceField, { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const CustomerInfo = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { userActions } = constants;

  const canViewCreditInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isFinancer;

  return (
    <FieldsGroup
      title={t('Customer information')}
    >
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <EntityReferenceField
              label={t('Customer')}
              source="customerId"
              reference="entity-v2/customer"
              link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
            />
          </SimpleShowLabeled>
        </Grid>
        {canViewCreditInfo && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Credit limit')}
                referenceSource="customerId"
                reference="entity-v2/reference"
                source="entityDetailsId"
                link={false}
                FieldComponent={ReferenceField}
                FieldComponentProps={{
                  referenceSource: 'entityDetailsId',
                  reference: 'entity-v2/credit-info',
                  link: false,
                  source: '',
                  FieldComponent: CurrencyField,
                  FieldComponentProps: {
                    source: 'creditLimit',
                    currencySource: 'creditLimitCurrency',
                  },
                }}
              />
            </SimpleShowLabeled>
          </Grid>
        )}
        {canViewCreditInfo && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Available credit limit')}
                referenceSource="customerId"
                reference="entity-v2/reference"
                source="entityDetailsId"
                link={false}
                FieldComponent={ReferenceField}
                FieldComponentProps={{
                  referenceSource: 'entityDetailsId',
                  reference: 'entity-v2/credit-info',
                  link: false,
                  source: '',
                  FieldComponent: CurrencyField,
                  FieldComponentProps: {
                    source: 'notReservedCreditLimit',
                    currencySource: 'creditLimitCurrency',
                  },
                }}
              />
            </SimpleShowLabeled>
          </Grid>
        )}
      </Grid>
    </FieldsGroup>
  );
};

export default CustomerInfo;
