import mergeWith from 'lodash/mergeWith';

const fileFields = {
  order: {
    create: ['invoice'],
    update: ['invoice'],
  },
  entity: {
    create: ['uploadedLogo'],
    update: ['uploadedLogo'],
  },
  application: {
    update: ['documents'],
    create: ['documents'],
  },
  merchant: {
    update: ['documents'],
    create: ['documents'],
  },
  customer: {
    update: ['documents'],
    create: ['documents'],
  },
  'entity-v2/merchant': {
    update: ['documents'],
    create: ['documents'],
  },
  'entity-v2/customer': {
    update: ['documents'],
    create: ['documents'],
  },
};

const actions = Object.values(fileFields).reduce((acc, resourceActions) => {
  const newActions = Object.keys(resourceActions);
  return [
    ...acc,
    ...newActions,
  ];
}, []);

const concatArrayCustomizer = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) return a.concat(b);
  return undefined;
};

const getActionHandler = (dataProvider, action) => async (resource, params) => {
  if (!Object.keys(fileFields).includes(resource)) return dataProvider[action](resource, params);
  const fields = fileFields[resource][action];

  const fieldsExists = fields.filter((field) => params.data[field] !== undefined);
  if (fieldsExists.length === 0) return dataProvider[action](resource, params);

  const existingFiles = fieldsExists.reduce((data, field) => ({
    ...data,
    [field]: Array.isArray(params.data[field])
      ? params.data[field].filter((fileData) => !(fileData.rawFile instanceof window.File))
      : [params.data[field]].filter((fileData) => !(fileData.rawFile instanceof window.File)),
  }), {});

  const filesToUpload = fieldsExists
    .flatMap((field) => (
      Array.isArray(params.data[field])
        ? params.data[field].map((item) => ({ file: item.rawFile, fileName: field }))
        : [params.data[field]].map((item) => ({ file: item.rawFile, fileName: field }))
    ))
    .filter((fileData) => fileData.file instanceof window.File);

  const uploadedFiles = filesToUpload.length > 0
    ? (await dataProvider.uploadDocument(filesToUpload)).data
    : [];

  const filesData = mergeWith(existingFiles, uploadedFiles, concatArrayCustomizer);

  return dataProvider[action](resource, {
    ...params,
    data: {
      ...params.data,
      ...filesData,
    },
  });
};

const addUploadFeature = (dataProvider) => ({
  ...dataProvider,
  ...actions.reduce((acc, action) => ({
    ...acc,
    [action]: getActionHandler(dataProvider, action),
  }), {}),
});

export default addUploadFeature;
