import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TitleH2 from './TitleH2';
import { ChildrenProp } from '../../utils/helpers/propTypes';

export const COLUMN_SPACING = 5;

const InputsGroup = ({
  title,
  children,
  layout,
  source,
  ...rest
}) => (
  <Box
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    display="flex"
    gap="1.5rem"
    flexDirection="column"
    width="100%"
  >
    {title && <TitleH2 title={title} marginTop="1.5rem" />}
    <Grid container spacing={0}>
      <Grid container item columnSpacing={layout === 'column' ? COLUMN_SPACING : undefined}>
        {React.Children.map(children, (child) => child && (
          <Grid item lg={layout === 'column' ? 6 : 12}>
            {React.cloneElement(child, {
              ...child.props,
              source: source ? `${source}.${child.props.source}` : child.props.source,
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Box>
);

InputsGroup.propTypes = {
  title: PropTypes.string,
  children: ChildrenProp.isRequired,
  layout: PropTypes.oneOf(['row', 'column']),
  source: PropTypes.string,
};

InputsGroup.defaultProps = {
  title: undefined,
  layout: 'row',
  source: undefined,
};

export default InputsGroup;
