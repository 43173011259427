import React from 'react';
import PropTypes from 'prop-types';

import {
  useInput,
  SelectArrayInput as RaSelectArrayInput,
} from 'react-admin';

import { Chip, Box, styled } from '@mui/material';

import InputWrapper from './InputWrapper';

import { baseSelectProps, getStyledInputStyle } from './SelectInput';

const StyledSelectArrayInput = styled(RaSelectArrayInput)(getStyledInputStyle);

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: '0.25rem 0.25rem 0 0',
  backgroundColor: theme.palette.secondary.main,
  color: 'white',
  fontWeight: 'bold',
  padding: 0,
}));

const SelectArrayInput = (props) => {
  const {
    label,
    InputLabelProps,
    placeholder,
    handleHide,
    ...rest
  } = props;
  const {
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(rest);

  const inputId = `${rest.source}-fimento-select-input`;
  return (
    <InputWrapper
      inputId={inputId}
      error={invalid}
      label={label}
      isRequired={isRequired}
      InputLabelProps={InputLabelProps}
      fimentoVariant={rest.fimentoVariant}
      handleHide={handleHide(rest.source)}
    >
      <StyledSelectArrayInput
        id={inputId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error?.message : ''} // Override react admin that reserves space
        options={{
          ...rest,
          ...baseSelectProps(rest.fimentoVariant),
          renderValue: (selected) => (
            <Box display="flex" flexWrap="wrap">
              {(Array.isArray(selected) ? selected : [])
                .map((item) => (props.choices || []).find((choice) => choice.id === item))
                .filter((item) => !!item)
                .map((item) => (
                  <StyledChip
                    key={item.id}
                    label={item.name}
                    size="small"
                  />
                ))}
            </Box>
          ),
          variant: 'outlined',
        }}
        label={label || placeholder}
        margin="none"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </InputWrapper>
  );
};

SelectArrayInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  InputLabelProps: PropTypes.shape(),
  placeholder: PropTypes.string,
  handleHide: PropTypes.func,
};

SelectArrayInput.defaultProps = {
  InputLabelProps: {},
  placeholder: '',
  label: undefined,
  handleHide: () => undefined,
};

export default SelectArrayInput;
