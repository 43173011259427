import compose from 'lodash/fp/compose';

const getJWTByPart = (index) => compose(
  JSON.parse,
  window.atob,
  (token) => token.split('.')[index],
);

export const getJWTHeader = getJWTByPart(0);
export const getJWTPayload = getJWTByPart(1);
export const getJWTSignature = getJWTByPart(2);
