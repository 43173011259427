import React from 'react';

import {
  TextField,
  required,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import TitleH5 from '../../designSystem/TitleH5';
import BooleanField from '../../designSystem/react-admin/BooleanField';

const validateRequired = [required()];

const CompanyDetailsBusinessContentCustomerApplication = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.marketplaceReferenceId" label={t('Alias')} validate={validateRequired} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.mainAddress.name" label={t('Name')} validate={validateRequired} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.mainAddress.town" label={t('Town')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.bic" label="BIC" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.mainAddress.streetAddress" label={t('Street address')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.mainAddress.postcode" label={t('Postcode')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.iban" label="IBAN" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.mainAddress.additionalStreetAddress" label={t('Additional street address')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.invoiceEmail" label={t('Invoice email')} type="email" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.bankgiro" label={t('Bankgiro')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.phone" type="tel" label={t('Phone')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.contactEmail" label={t('Contact email')} type="email" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="debtorInfo.naceCode" label={t('NACE code')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="column" marginTop="3.5rem">
        <TitleH5 title={t('Additional information')} />
        <BooleanField label={t('Is a small or medium enterprise')} source="debtorInfo.isSME" />
        <BooleanField label={t('Is related or same entity group')} source="debtorInfo.isRelated" />
      </Box>
    </>
  );
};

export default CompanyDetailsBusinessContentCustomerApplication;
