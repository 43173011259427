import React from 'react';
import PropTypes from 'prop-types';
import ApexChart from 'react-apexcharts';

import convertToHumanReadable from '../../utils/convertToHumanReadable';

const ColumnsChart = ({
  serie,
  title,
  subtitle,
  colours,
  labels,
  xTitle,
  stacked,
  currency,
}) => {
  const styleDocument = window.document.createElement('style');
  styleDocument.id = 'fimento-column-chart-style';
  styleDocument.textContent = `
    .xaxis-labels {
      letter-spacing: 0.028rem;
      opacity: 0.88;
    }

    .yaxis-labels {
      letter-spacing: 0.031rem;
      opacity: 0.88;
    }

    .apexcharts-legend-text {
      letter-spacing: 0.083rem;
      opacity: 0.60;
    }
  `;
  const alreadyDefinedStyle = !!window.document.getElementById('fimento-column-chart-style');
  if (!alreadyDefinedStyle) window.document.getElementsByTagName('head')[0].appendChild(styleDocument);

  const options = {
    chart: {
      stacked,
      toolbar: { show: false },
    },
    colors: colours,
    title: {
      text: title,
    },
    ...(subtitle
      ? { subtitle: { text: subtitle } }
      : {}),
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: labels,
      axisBorder: { show: true },
      axisTicks: { show: false },
      labels: {
        show: true,
        offsetY: -2,
      },
      title: { text: xTitle },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: (x) => (x === 0 ? 0 : convertToHumanReadable(x, 5, currency)),
        style: {
          cssClass: 'yaxis-labels',
        },
      },
    },
    markers: { size: 0 },
    grid: {
      borderColor: 'lightgrey',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 2,
      },
    },
    dataLabels: { enabled: false },
  };

  return (
    <ApexChart
      options={options}
      series={serie}
      type="bar"
      height="370"
    />
  );
};

ColumnsChart.propTypes = {
  serie: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  colours: PropTypes.arrayOf(PropTypes.string),
  subtitle: PropTypes.string,
  xTitle: PropTypes.string,
  stacked: PropTypes.bool,
  currency: PropTypes.string,
};

ColumnsChart.defaultProps = {
  colours: undefined,
  subtitle: undefined,
  xTitle: undefined,
  stacked: false,
  currency: undefined,
};

export default ColumnsChart;
