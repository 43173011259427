const production = require('./production');
const dev = require('./dev');
const stage = require('./stage');
const local = require('./local');

const config = {};
const env = process.env.TARGET_ENV;

if (env === 'production') {
  Object.assign(config, production);
} else if (env === 'dev') {
  Object.assign(config, dev);
} else if (env === 'stage') {
  Object.assign(config, stage);
} else {
  Object.assign(config, local);
}

module.exports = config;
