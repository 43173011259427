import { useTranslation } from 'react-i18next';
import { useConstantContext } from '../ConstantsContext';

const useRulesNames = () => {
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { decisionRules } = constants;
  return ({
    [decisionRules.FINANCIAL_PRODUCT]: t('Order has financiable product'),
    [decisionRules.REQUIRE_MANUAL_DECISION]: t('Applications require manual decision'),
    [decisionRules.CREDIT_LIMIT]: t('Customer has enough credit limit'),
    [decisionRules.FINANCIAL_LIMIT]: t('Merchant has enough financial limit'),
    [decisionRules.KYC]: t('Customer and merchant have completed KYC'),
    [decisionRules.HAS_OVERDUE_INVOICES]: t('Customer has overdue invoices'),
    [decisionRules.ORDER_HAS_NOT_EXPIRED]: t('Order invoice due date expired'),
    [decisionRules.RESERVED_CREDIT_LIMIT]: t('Customer has enough reserved credit limit'),
    [decisionRules.FINANCING_TERMS]: t('Order has valid financing terms'),
    [decisionRules.ALWAYS_GRANT]: t('Application always granted'),
    [decisionRules.LARGE_REPAYMENT_PERIOD]: t('Order repayment time above'),
    [decisionRules.INVOIER_DEBTOR]: t('Customer from Invoier'),
    [decisionRules.INVOIER_FINANCING]: t('Order from Invoier'),
    [decisionRules.RIVR_DEBTOR]: t('Customer from Rivr'),
    [decisionRules.RIVR_FINANCING]: t('Order from Rivr'),
    [decisionRules.OTHER_CURRENCY_FINANCING]: t('Requested financing in different currency'),
    [decisionRules.IS_BUSINESS_CUSTOMER]: t('Customer is a company'),
    [decisionRules.CREDIT_LIMIT_THRESHOLD]: t('Customer requested credit limit above threshold'),
  });
};

export default useRulesNames;
