import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';

import { percentageValidation } from '../../../../utils/validationErrors';

import NumberInput from './NumberInput';

const PercentageInput = ({
  validate,
  ...rest
}) => (
  <NumberInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    min={0}
    max={100}
    validate={[percentageValidation, ...validate]}
    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
  />
);

PercentageInput.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.func),
};

PercentageInput.defaultProps = {
  validate: [],
};

export default PercentageInput;
