import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { isNumber } from '../../utils/validators';

const CustomSuffixTextField = ({
  source,
  label,
  customSuffix,
  emptyText,
}) => {
  const record = useRecordContext();
  const value = get(record, source);
  return (
    <Typography
      component="span"
      variant="body2"
      label={label}
    >
      {!isNumber(value) ? emptyText : `${value} ${customSuffix}`}
    </Typography>
  );
};

CustomSuffixTextField.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]).isRequired,
  customSuffix: PropTypes.string.isRequired,
  emptyText: PropTypes.string,
};

CustomSuffixTextField.defaultProps = {
  emptyText: '-',
};

export default CustomSuffixTextField;
