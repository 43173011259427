import React from 'react';
import {
  FormTab,
  ArrayField,
  TextField,
  FunctionField,
  DateField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Datagrid from '../../designSystem/react-admin/Datagrid';

import renderTransactionChip from '../../utils/renderTransactionChip';
import CurrencyField from '../../utils/CurrencyField';
import FieldsGroup from '../../utils/FieldsGroup';
import MarkAsUnidentifiedButton from '../../utils/MarkAsUnidentifiedButton';

const TransactionTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Transactions')} {...props}>
      <FieldsGroup>
        <ArrayField source="transactions" fullWidth>
          <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount', 'actions']}>
            <FunctionField
              label={t('Type')}
              render={renderTransactionChip}
            />
            <TextField source="description" />
            <DateField source="date" label={t('Date')} emptyText="-" />
            <TextField source="bankGiro" label={t('Bankgiro')} emptyText="-" />
            <TextField source="ocr" label={t('OCR')} emptyText="-" />
            <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
            <WrapperField label={false} source="actions">
              <MarkAsUnidentifiedButton />
            </WrapperField>
          </Datagrid>
        </ArrayField>
      </FieldsGroup>
    </FormTab>
  );
};

export default TransactionTab;
