import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecordContext, useResourceContext } from 'react-admin';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';

import useCustomRpc from '../hooks/useCustomRpc';

import { useConstantContext } from '../ConstantsContext';

import { isValidArray } from '../../utils/validators';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const OnboardingStage = () => {
  const {
    constants,
  } = useConstantContext();
  const { userActions } = constants;
  const resource = useResourceContext();
  const record = useRecordContext();
  const { t } = useTranslation();
  const canViewCustomers = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  );

  const { id } = record;

  const {
    mutate: getOnboardingSteps,
    data,
  } = useCustomRpc({
    path: `${resource}/${id}/onboarding-steps`,
    httpMethod: 'GET',
    errorMessage: t('Could not fetch onboarding steps'),
  });
  const onboardingSteps = data?.data.onboardingSteps;

  useEffect(() => {
    getOnboardingSteps();
  }, [getOnboardingSteps]);

  if (!canViewCustomers) return null;
  if (!isValidArray(onboardingSteps)) return null;

  const activeStep = onboardingSteps
    .filter(({ done }) => done)
    .length;

  return (
    <Box width="100%" marginBottom="1.75rem">
      <Stepper activeStep={activeStep}>
        {onboardingSteps.map((item) => (
          <Step key={item.label}>
            <StepLabel>{item.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default OnboardingStage;
