import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../theme';
import useCustomRpc from '../hooks/useCustomRpc';

const GradientTypography = styled(Typography)(({ expanded }) => ({
  fontSize: '15px',
  color: theme.palette.switch,
  fontWeight: '400',
  whiteSpace: 'pre-wrap',
  maskImage: expanded ? 'none' : 'linear-gradient(to bottom, black 140px, transparent 182px)',
}));

const EmailTemplate = ({ orderId }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState('');

  const {
    mutate: fetchEmailTemplate,
  } = useCustomRpc({
    path: `order/${orderId}/delivery-confirmation/email-template`,
    httpMethod: 'GET',
    shouldRefresh: false,
    errorMessage: t('Fail to fetch email template'),
    onSuccess: (res) => {
      setEmailTemplate(res.data.email);
    },
  });

  useEffect(() => {
    fetchEmailTemplate();
  }, [fetchEmailTemplate]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography
        fontSize="12px"
        color={theme.palette.headers}
        fontWeight={400}
      >
        {t('Message preview')}
      </Typography>
      <Box
        border="1px solid #ccc"
        padding="16px"
        borderRadius="4px"
      >
        <Collapse
          in={expanded}
          collapsedSize={182}
          timeout={200}
        >
          <GradientTypography expanded={expanded}>
            {emailTemplate.trim()}
          </GradientTypography>
        </Collapse>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="8px"
        >
          <Button onClick={handleExpandClick} variant="text" startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
            {expanded ? t('Read less') : t('Read more')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

EmailTemplate.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default EmailTemplate;
