import { Labeled } from 'react-admin';
import { styled } from '@mui/material';
import { titleH3Style } from '../../theme';

const SimpleShowLabeledList = styled(Labeled)(() => ({
  marginBottom: '1rem',
  '&:last-child': {
    marginBottom: 0,
  },
  '& > .RaLabeled-label': titleH3Style,
}));

export default SimpleShowLabeledList;
