// Deprecated enableAnalyticsAndRedesign
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormTab } from 'react-admin';

import OnboardingStage from '../../OnboardingStage';
import CurrentExposureItems from '../../CurrentExposureItems';

import EntityCommentsGroup from '../../EntityCommentsGroup';
import KycGroup from '../../KycGroup';
import FinancingInfoGroup from '../../FinancingInfoGroup';
import CoreInfoGroup from '../../CoreInfoGroup';
import CreditDataGroup from '../../CreditDataGroup';
import { CreditDataContextProvider } from '../../../CreditDataContext';

const SummaryTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <OnboardingStage />
      <CurrentExposureItems />
      <CoreInfoGroup />
      <FinancingInfoGroup />
      <KycGroup />
      <CreditDataContextProvider>
        <CreditDataGroup />
      </CreditDataContextProvider>
      <EntityCommentsGroup />
    </FormTab>
  );
};

export default SummaryTab;
