import React from 'react';
import PropTypes from 'prop-types';
// import ScrollButtonTop from './ScrollButtonTop';
import ScrollButtonBottom from './ScrollButtonBottom';

const ScrollVerticalButtons = ({
  isDisplayed,
  scrollableElement,
  color,
  backgroundColor,
  extraPadding,
  dependencies,
  // popperRefTop,
  popperRefBottom,
  zIndex,
}) => (
  <>
    {/* <ScrollButtonTop
      isDisplayed={isDisplayed}
      scrollableElement={scrollableElement}
      color={color}
      backgroundColor={backgroundColor}
      extraPadding={extraPadding}
      dependencies={dependencies}
      popperRef={popperRefTop}
      zIndex={zIndex}
    /> */}
    <ScrollButtonBottom
      isDisplayed={isDisplayed}
      scrollableElement={scrollableElement}
      color={color}
      backgroundColor={backgroundColor}
      extraPadding={extraPadding}
      dependencies={dependencies}
      popperRef={popperRefBottom}
      zIndex={zIndex}
    />
  </>
);

ScrollVerticalButtons.propTypes = {
  scrollableElement: PropTypes.instanceOf(window.Element),
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool,
  extraPadding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
  dependencies: PropTypes.arrayOf(PropTypes.any),
  // popperRefTop: PropTypes.shape(),
  popperRefBottom: PropTypes.shape(),
  zIndex: PropTypes.number,
};

ScrollVerticalButtons.defaultProps = {
  scrollableElement: null,
  isDisplayed: true,
  extraPadding: {},
  dependencies: [],
  // popperRefTop: undefined,
  popperRefBottom: undefined,
  zIndex: undefined,
};

export default ScrollVerticalButtons;
