import React from 'react';
import Chip from '@mui/material/Chip';

const styles = {
  default: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#cccccc',
  },
  financing: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#479F4C',
    color: '#f2f2f2',
  },
  discount: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#479F4C',
    color: '#f2f2f2',
  },
  payIn: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#479F4C',
    color: '#f2f2f2',
  },
  reminder: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#BD371A',
    color: '#f2f2f2',
  },
  item: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#2196f3',
    color: '#f2f2f2',
  },
  administration: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#FEC93E',
  },
  fee: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#F37C22',
    color: '#f2f2f2',
  },
  vat: {
    width: '7.4rem',
    marginRight: '5px',
    backgroundColor: '#FEC93E',
  },
};

const renderTransactionChip = (transaction = {}) => {
  const {
    feeType,
    adjustmentType,
    type,
  } = transaction;
  const key = feeType || adjustmentType || type;

  if (!key) return null;

  const style = styles[key] || styles.default;
  return (
    <Chip style={style} label={key} />
  );
};

export default renderTransactionChip;
