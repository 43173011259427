import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Loading,
  TextField,
  RecordContextProvider,
  useGetList,
  usePermissions,
  useRecordContext,
  ArrayField,
  WrapperField,
  FunctionField,
  ReferenceField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { startCase } from 'lodash';
import useFeatureFlags from '../hooks/useFeatureFlags';

import FieldsGroup from '../utils/FieldsGroup';
import hasUserPermissions from '../../utils/hasUserPermissions';

import Datagrid from '../designSystem/react-admin/Datagrid';
import DateField from '../designSystem/react-admin/DateField';

import EmptyData from '../EmptyData';
import CreateDeliveryConfirmationButton from './CreateDeliveryConfirmationButton';
import DeliveryConfirmationSubActions from './DeliveryConfirmationSubActions';

import { useConstantContext } from '../ConstantsContext';
import DeliveryConfirmationStatusChip from '../utils/DeliveryConfirmationStatusChip';

const DeliveryConfirmationDataGrid = ({ deliveryConfirmations }) => {
  const { t } = useTranslation();

  return (
    <RecordContextProvider value={{ list: deliveryConfirmations }}>
      <ArrayField source="list">
        <Datagrid bulkActionButtons={false} rightAlignedColumns={['status', 'subActions']}>
          <TextField label={t('Name')} source="data.name" emptyText="-" />
          <TextField label={t('Email')} source="data.email" emptyText="-" />
          <TextField label={t('Authentication')} source="type" emptyText="-" />
          <ReferenceField
            label={t('Created by')}
            source="createdBy"
            reference="user"
            link={false}
          >
            <FunctionField render={(user) => startCase(`${user.firstName} ${user.lastName}`)} />
          </ReferenceField>
          <DateField label={t('Creation date')} source="createdAt" />
          <WrapperField label={t('Status')} source="status">
            <DeliveryConfirmationStatusChip />
          </WrapperField>
          <WrapperField label={false} source="subActions">
            <DeliveryConfirmationSubActions />
          </WrapperField>
        </Datagrid>
      </ArrayField>
    </RecordContextProvider>
  );
};

const DeliveryConfirmationGroup = ({ orderIdSource }) => {
  const { t } = useTranslation();
  const [deliveryConfirmationList, setDeliveryConfirmationList] = useState([]);
  const [hasNoDCAvailable, setHasNoDCAvailable] = useState(false);
  const [featureFlags] = useFeatureFlags();

  const record = useRecordContext();
  const orderId = record[orderIdSource];

  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const { userAllowedPermissions } = authenticationData.permissions || {};
  const { userActions } = constants;
  const canViewDeliveryConfirmation = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_DELIVERY_CONFIRMATION,
  );
  const canEditDeliveryConfirmation = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_DELIVERY_CONFIRMATION,
  );

  const {
    isLoading: loadingGetDeliveryConfirmation,
  } = useGetList(
    `order/${orderId}/delivery-confirmation`,
    {},
    {
      onSuccess: (response) => {
        if (response.total === 0) setHasNoDCAvailable(true);
        else {
          setDeliveryConfirmationList(response.data);
          setHasNoDCAvailable(false);
        }
      },
      onError: (response) => {
        if (response.status === 404) setHasNoDCAvailable(true);
      },
      enabled: !!(featureFlags.enableDeliveryConfirmation && canViewDeliveryConfirmation),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  let componentToRender;
  if (loadingGetDeliveryConfirmation) componentToRender = <Loading />;
  else if (hasNoDCAvailable) componentToRender = <EmptyData />;
  else componentToRender = <DeliveryConfirmationDataGrid deliveryConfirmations={deliveryConfirmationList} />;

  if (!orderId) return null;
  if (!canViewDeliveryConfirmation || !featureFlags.enableDeliveryConfirmation) return null;
  return (
    <FieldsGroup title={t('Delivery confirmations')} actions={canEditDeliveryConfirmation && <CreateDeliveryConfirmationButton orderId={orderId} />}>
      {componentToRender}
    </FieldsGroup>
  );
};

DeliveryConfirmationGroup.propTypes = {
  orderIdSource: PropTypes.string.isRequired,
};
DeliveryConfirmationDataGrid.propTypes = {
  deliveryConfirmations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DeliveryConfirmationGroup;
