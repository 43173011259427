import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import PercentageField from '../utils/PercentageField';
import CustomSuffixTextField from '../utils/CustomSuffixTextField';

const LoanFinancingGroup = () => {
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Financing')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="remainingCapitalAmount" currencySource="currency" label={t('Remaining capital amount')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CustomSuffixTextField source="remainingRepaymentTime" label={t('Remaining repayment period')} customSuffix="months" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="overdueAmount" currencySource="currency" label={t('Overdue amount')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <PercentageField source="terms.nominalRate" label={t('Nominal rate')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="accumulatedFeesAndInterest" currencySource="currency" label={t('Accumulated fees and interest')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="accumulatedAmortisation" currencySource="currency" label={t('Accumulated amortisation')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default LoanFinancingGroup;
