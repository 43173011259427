import { t } from 'i18next';

const validatePaymentReference = ({
  withOriginalInvoice,
}) => (fieldValue, orderData) => (
  withOriginalInvoice.includes(orderData.financialProduct) && !fieldValue
    ? t('The payment reference is required for this type of financing product with a pre-existing invoice')
    : ''
);

export default validatePaymentReference;
