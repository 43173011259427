import React from 'react';
import {
  List,
  TextField,
  DateField,
  ReferenceField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import EntityListActions from '../EntityListActions';

import SafePagination from '../../utils/SafePagination';

import { DatagridConfigurable } from '../../designSystem/react-admin/Datagrid';

import { useConstantContext } from '../../ConstantsContext';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import TitleH1 from '../../designSystem/TitleH1';

const FinancerList = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewFinancer = useHasUserPermissions(
    constants,
    userActions.VIEW_FINANCERS,
  );

  if (!canViewFinancer) return null;

  return (
    <List
      actions={<EntityListActions />}
      empty={false}
      pagination={<SafePagination />}
      sort={{ field: 'mainAddress.name', order: 'ASC' }}
      title={<TitleH1 title={t('Financers')} />}
      exporter={false}
      perPage={25}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="mainAddress.name" label={t('Name')} />
        <TextField source="contactEmail" label={t('Contact Email')} />
        <ReferenceField
          label={t('VAT Number')}
          source="entityCoreId"
          reference="entity-v2/core"
          resource="entity-v2/core"
          link={false}
        >
          <TextField source="vatNumber" />
        </ReferenceField>
        <ReferenceField
          label={t('Organisation number')}
          source="entityCoreId"
          reference="entity-v2/core"
          // resource="entity-v2/core"
          link={false}
        >
          <TextField source="nationalIdentifier" />
        </ReferenceField>
        <DateField source="phone" label={t('Phone')} />
      </DatagridConfigurable>
    </List>
  );
};

export default FinancerList;
