import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import {
  required,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../../designSystem/ModalButton';
import BooleanInput from '../../designSystem/react-admin/inputs/BooleanInput';
import TextInput from '../../designSystem/react-admin/inputs/TextInput';
import AutocompleteInput from '../../designSystem/react-admin/inputs/AutocompleteInput';
import InputsGroup from '../../designSystem/InputsGroup';

import {
  bicValidation,
  emailValidation,
  ibanValidation,
  phoneValidation,
} from '../../../utils/validationErrors';

import useCustomRpc from '../../hooks/useCustomRpc';

import { useConstantContext } from '../../ConstantsContext';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const validateRequired = [required()];

const UpdateCustomerDetailsButton = ({
  userAction,
  pendingReview,
  applicationRecord,
  enableAliasInput,
}) => {
  const {
    id,
    debtorInfo: {
      contactEmail,
      invoiceEmail,
      phone,
      mainAddress,
      iban,
      bic,
      bankgiro,
      naceCode,
      isSME,
      isRelated,
      marketplaceReferenceId,
    },
    applicationStep,
    currentDecision,

  } = applicationRecord;

  const { choices, constants } = useConstantContext();
  const { t } = useTranslation();

  const {
    applicationSteps,
    decisionStatuses,
  } = constants;

  const isCreated = applicationSteps.CREATED === applicationStep;
  const isDenied = decisionStatuses.DENIED === currentDecision;

  const record = useRecordContext();

  const choiceStates = choices;

  const canEdit = useHasUserPermissions(
    constants,
    userAction,
  );

  const coreTypeInput = useWatch({ name: 'type' });
  const path = `customer-application/${id}`;

  const {
    mutate: updateCompanyDetails,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the customer details'),
  });

  const onSubmit = useCallback((formData) => {
    updateCompanyDetails({ debtorInfo: formData });
    return true;
  }, [updateCompanyDetails]);

  if (!record && !coreTypeInput) return null;
  if (record && coreTypeInput) throw new Error('Cannot render entity details. One source of truth is required for the entity core type');

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? record.type === constants.coreTypes.BUSINESS
      : undefined;
  }

  if (!canEdit || pendingReview) return null;

  return (
    <EditModalButton
      modalTitle={isBusiness ? t('Update company details') : t('Update consumer details')}
      onClick={onSubmit}
      disabled={isLoading || !isCreated || isDenied}
      formDefaultValue={{
        contactEmail,
        invoiceEmail,
        phone,
        mainAddress,
        iban,
        bic,
        bankgiro,
        naceCode,
        isSME,
        isRelated,
        marketplaceReferenceId,
      }}
    >
      <InputsGroup layout="column">
        {enableAliasInput && <TextInput source="marketplaceReferenceId" label={t('Alias')} validate={validateRequired} />}
        <TextInput source="mainAddress.name" label={t('Name')} validate={validateRequired} />
        <TextInput source="iban" label="IBAN" validate={[ibanValidation]} />
        <TextInput source="mainAddress.streetAddress" label={t('Street address')} />
        <TextInput source="bic" label="BIC" validate={[bicValidation]} />
        <TextInput source="mainAddress.additionalStreetAddress" label={t('Additional street address')} />
        <TextInput source="bankgiro" label={t('Bankgiro')} />
        <TextInput source="mainAddress.town" label={t('Town')} />
        <TextInput source="phone" type="tel" label={t('Phone')} validate={[phoneValidation]} />
        <TextInput source="mainAddress.postcode" label={t('Postcode')} />
        <TextInput source="invoiceEmail" label={t('Invoice email')} type="email" validate={[emailValidation]} />
        <TextInput source="contactEmail" label={t('Contact email')} type="email" validate={[emailValidation]} />
        {isBusiness && (
          <AutocompleteInput source="naceCode" label={t('NACE code')} choices={choiceStates.naceCodes} />
        )}
      </InputsGroup>
      {isBusiness && (
        <InputsGroup title={t('Additional information')}>
          <BooleanInput
            label={t('Is a small or medium enterprise')}
            source="isSME"
          />
          <BooleanInput source="isRelated" label={t('Is related or same entity group')} />
        </InputsGroup>
      )}
    </EditModalButton>
  );
};

UpdateCustomerDetailsButton.propTypes = {
  userAction: PropTypes.string.isRequired,
  pendingReview: PropTypes.bool,
  enableAliasInput: PropTypes.bool,
  applicationRecord: PropTypes.shape({
    id: PropTypes.string,
    applicationStep: PropTypes.string,
    currentDecision: PropTypes.string,
    debtorInfo: PropTypes.shape({
      contactEmail: PropTypes.string,
      invoiceEmail: PropTypes.string,
      mainAddress: PropTypes.shape({
        name: PropTypes.string,
        streetAddress: PropTypes.string,
        additionalStreetAddress: PropTypes.string,
        town: PropTypes.string,
      }),
      phone: PropTypes.string,
      iban: PropTypes.string,
      bic: PropTypes.string,
      bankgiro: PropTypes.string,
      naceCode: PropTypes.string,
      isSME: PropTypes.bool,
      isRelated: PropTypes.bool,
      marketplaceReferenceId: PropTypes.string,
    }),
  }).isRequired,
};

UpdateCustomerDetailsButton.defaultProps = {
  pendingReview: false,
  enableAliasInput: false,
};

export default UpdateCustomerDetailsButton;
