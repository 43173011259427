import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import {
  RecordContext,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../../utils/FieldsGroup';

import { useConstantContext } from '../../ConstantsContext';
import UpdateCompanyDetailsButton from '../UpdateCompanyDetailsButton';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import CompanyDetailsForMerchantDetails from './CompanyDetailsForMerchantDetails';
import CompanyDetailsForCustomerDetails from './CompanyDetailsForCustomerDetails';
import CompanyDetailsForMyCompany from './CompanyDetailsForMyCompany';
import CompanyDetailsForCustomerApplication from './CompanyDetailsForCustomerApplication';
import UpdateCustomerDetailsButton from './UpdateCustomerDetailsButton';

const CompanyDetails = ({
  viewUserAction,
  createUserAction,
  enableAliasInput,
  detailsSource,
  pendingReview,
  applicationRecord,
}) => {
  const { constants } = useConstantContext();
  const record = useRecordContext();
  const canView = useHasUserPermissions(
    constants,
    viewUserAction,
  );
  const { t } = useTranslation();

  const coreTypeInput = useWatch({ name: 'type' });

  const coreType = detailsSource === 'customerApplication' ? record.type : record.coreType;

  if (!canView || (!record && !coreTypeInput)) return null;
  if (record && coreTypeInput) throw new Error('Cannot render entity details. One source of truth is required for the entity core type');

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? coreType === constants.coreTypes.BUSINESS
      : undefined;
  }

  const mapSourceToDetails = {
    merchantDetails: <CompanyDetailsForMerchantDetails />,
    customerDetails: <CompanyDetailsForCustomerDetails isBusiness={isBusiness} enableAliasInput={enableAliasInput} />,
    myCompany: <CompanyDetailsForMyCompany />,
    customerApplication: (
      <RecordContext.Provider value={applicationRecord}>
        <CompanyDetailsForCustomerApplication isBusiness={isBusiness} applicationRecord={applicationRecord} />
      </RecordContext.Provider>
    ),
  };

  const detailsContent = mapSourceToDetails[detailsSource];
  if (!detailsContent) throw new Error('Cannot find company details content');

  let actions;
  if (detailsSource === 'customerApplication') {
    actions = (
      <UpdateCustomerDetailsButton
        userAction={createUserAction}
        pendingReview={pendingReview}
        applicationRecord={applicationRecord}
        enableAliasInput={enableAliasInput}
      />
    );
  } else {
    actions = (
      <UpdateCompanyDetailsButton
        userAction={createUserAction}
        enableAliasInput={enableAliasInput}
        isMyCompanyPage={detailsSource === 'myCompany'}
        isCustomerApplication={detailsSource === 'customerApplication'}
        pendingReview={pendingReview}
      />
    );
  }
  return (
    <FieldsGroup
      title={isBusiness ? t('Company details') : t('Consumer details')}
      actions={actions}
    >
      {detailsContent}
    </FieldsGroup>
  );
};

CompanyDetails.propTypes = {
  viewUserAction: PropTypes.string.isRequired,
  createUserAction: PropTypes.string.isRequired,
  detailsSource: PropTypes.string.isRequired,
  enableAliasInput: PropTypes.bool,
  pendingReview: PropTypes.bool,
  applicationRecord: PropTypes.shape({}),
};

CompanyDetails.defaultProps = {
  enableAliasInput: false,
  pendingReview: false,
  applicationRecord: {},
};

export default CompanyDetails;
