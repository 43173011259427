import { useRecordContext } from 'react-admin';
import { getEntityIdFromToken } from '../../utils/getValueFromToken';

const useIsCreditor = () => {
  const record = useRecordContext();
  const entityId = getEntityIdFromToken();

  return record.creditorId === entityId;
};

export default useIsCreditor;
