import React from 'react';

import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import InputAction from './InputAction';
import useActionsAvailableToModuleAndRoleType from './useActionsAvailableToModuleAndRoleType';

const UserActionSubSection = ({
  roleType,
  actions,
  title,
}) => {
  const subSectionActions = [];
  const InputActions = [];
  for (let i = 0; i < actions.length; i += 1) {
    const {
      action,
      actionsToDisable,
      actionsToEnable,
      isPeerReviewable,
      canBeLimited,
    } = actions[i];
    subSectionActions.push(action);
    InputActions.push(
      <InputAction
        action={action}
        actionsToEnable={actionsToEnable}
        actionsToDisable={actionsToDisable}
        isPeerReviewable={isPeerReviewable}
        canBeLimited={canBeLimited}
        key={i}
      />,
    );
  }
  const shouldShow = useActionsAvailableToModuleAndRoleType({
    roleType,
    actions: subSectionActions,
  });
  if (!shouldShow) return null;

  return (
    <Accordion variant="outlined" sx={{ backgroundColor: '#eeeeee', marginBottom: '0.5rem', borderRadius: '1px' }}>
      <AccordionSummary
        expandIcon={(
          <ExpandMoreIcon
            sx={{
              color: '#00897B',
              border: '1px solid',
              borderRadius: '20px',
            }}
          />
        )}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ fontWeight: 'bold' }}> {title} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        { InputActions }
      </AccordionDetails>
    </Accordion>
  );
};

UserActionSubSection.propTypes = {
  roleType: PropTypes.string,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.string,
    actionsToDisable: PropTypes.arrayOf(PropTypes.string),
    actionsToEnable: PropTypes.arrayOf(PropTypes.string),
    isPeerReviewable: PropTypes.bool,
    canBeLimited: PropTypes.bool,
  })),
};

UserActionSubSection.defaultProps = {
  roleType: '',
  title: '',
  actions: [{
    action: '',
    actionsToDisable: [],
    actionsToEnable: [],
    isPeerReviewable: false,
    canBeLimited: false,
  }],
};

export default UserActionSubSection;
