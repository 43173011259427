import React from 'react';
import {
  List,
  TextField,
  EditButton,
  ShowButton,
  ReferenceField,
  FunctionField,
  BooleanInput,
  ArrayField,
  TopToolbar,
  CreateButton,
  WrapperField,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import renderReference from '../../utils/renderReference';
import TextInputWithLens from '../utils/TextInputWithLens';

import { useConstantContext } from '../ConstantsContext';

import renderRoles from './renderRoles';
import AccordionFilter from '../utils/AccordionFilter';
import { useDefineLocation } from '../AppLocationContext';

import Datagrid from '../designSystem/react-admin/Datagrid';
import TitleH1 from '../designSystem/TitleH1';

export const getUserListTitle = (t) => t('Manage users');

const useUserListFilters = () => {
  const { constants } = useConstantContext();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { entityRoles } = constants;
  const { t } = useTranslation();

  return [
    <TextInputWithLens alwaysOn label={t('Email')} source="email" />,
    <TextInputWithLens alwaysOn label={t('First Name')} source="firstName" />,
    <TextInputWithLens alwaysOn label={t('Last Name')} source="lastName" />,
    ...(isFinancer ? [(
      <ReferenceInput
        source="entityId"
        reference="entity-v2/reference"
        filter={{ entityRoles: [entityRoles.MERCHANT] }}
        alwaysOn
      >
        <AutocompleteInput
          label={t('Merchant')}
          placeholder={t('Select merchant')}
          optionText={renderReference}
          suggestionLimit={20}
        />
      </ReferenceInput>
    )] : []
    ),
    <BooleanInput alwaysOn source="active" label={t('Show only active users')} />,
  ];
};

const UserActions = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const userListFilters = useUserListFilters();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_USERS,
  );

  return (
    <TopToolbar>
      <AccordionFilter filters={userListFilters}>
        {canEdit && <CreateButton />}
      </AccordionFilter>
    </TopToolbar>
  );
};

const UserList = () => {
  const { t } = useTranslation();
  const { isOwner } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  useDefineLocation('home.manage-users');

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_USERS,
  );

  return (
    <List
      title={<TitleH1 title={getUserListTitle(t)} />}
      resource="user"
      exporter={false}
      actions={<UserActions />}
      disableSyncWithLocation
      filterDefaultValues={{ active: true }}
      sx={{ width: '100%', padding: '0.9rem' }}
    >
      <Datagrid bulkActionButtons={false} rightAlignedColumns={['action']}>
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="firstName" label={t('First name')} />
        <TextField source="lastName" label={t('Last name')} />
        <ArrayField
          label={t('Roles')}
          source="roles"
        >
          <FunctionField render={renderRoles} />
        </ArrayField>
        {isOwner && (
          <ReferenceField
            label={t('Company')}
            source="entityId"
            reference="entity-v2/reference"
            link={false}
          >
            <FunctionField render={renderReference} />
          </ReferenceField>
        )}
        {canEdit
          ? <WrapperField label={false} source="action"><EditButton /></WrapperField>
          : <WrapperField label={false} source="action"><ShowButton /></WrapperField>}
      </Datagrid>
    </List>
  );
};

export default UserList;
