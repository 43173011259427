import { Labeled } from 'react-admin';
import { styled } from '@mui/material';
import { titleH4Style } from '../../theme';

const SimpleShowLabeled = styled(Labeled)(() => ({
  marginBottom: '1rem',
  '&:last-child': {
    marginBottom: 0,
  },
  '& > .RaLabeled-label': titleH4Style,
}));

export default SimpleShowLabeled;
