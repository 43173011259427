const hasUserPermissions = (
  permissions,
  userAction,
) => permissions && userAction
&& permissions.get(userAction)?.hasPermission;

export const hasMultipleUserPermissions = (
  permissions,
  userActions,
) => permissions && userActions
&& userActions.some((userAction) => permissions.get(userAction)?.hasPermission);

export default hasUserPermissions;
