import React from 'react';
import {
  ArrayField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import FieldsGroup from '../utils/OldFieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import CurrencyField from '../utils/CurrencyField';

import Datagrid from '../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';

const CustomerDecisionPayload = () => {
  const { t } = useTranslation();

  return (
    <FieldsGroup>
      <SimpleShowLabeled>
        <CurrencyField source="payload.terms.creditLimit" currencySource="payload.terms.creditLimitCurrency" label={t('Credit Limit')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <CurrencyField source="payload.terms.creditInsuranceLimit" currencySource="payload.terms.creditInsuranceLimitCurrency" label={t('Insurance Limit')} emptyText="-" />
      </SimpleShowLabeled>
      <SimpleShowLabeled>
        <ArrayField label={t('Assigned Credit Limit')} source="payload.terms.assignedCreditLimits">
          <Datagrid bulkActionButtons={false} rightAlignedColumns={['assignedCreditLimit']}>
            <EntityReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2/merchant"
              link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
            />
            <CurrencyField source="assignedCreditLimit" currencySource="creditLimitCurrency" label={t('Assigned Credit Limit')} emptyText="-" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
    </FieldsGroup>
  );
};

export default CustomerDecisionPayload;
