import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import {
  Loading,
  RecordContextProvider,
  useGetMany,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import moment from 'moment';

import hasUserPermissions from '../../utils/hasUserPermissions';
import FieldsGroup from '../utils/FieldsGroup';
import mapErrorCodeToDescription from '../utils/mapErrorCodeToDescription';

import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';

import { useConstantContext } from '../ConstantsContext';
import { useCreditDataContext } from '../CreditDataContext';
import ConfirmCreditDataButton from './ConfirmCreditDataButton';
import CreditSafeCharts from './CreditSafeCharts';
import CreditDataInfo from './CreditDataInfo';

const mapProviderNameToTitle = {
  creditsafe: 'Creditsafe',
};

const LoadingData = () => <Loading />;

const EmptyData = () => {
  const { t } = useTranslation();

  return (
    <Typography>{t('No external data is fetched')}</Typography>
  );
};

const Error = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <Box>
        <WarningIcon />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight="600" fontSize="15px">{t('Oops! Something went wrong')}</Typography>
        <Typography fontSize="15px">{t('Please contact technical support at support@fimento.com')}</Typography>
      </Box>
    </Box>
  );
};

const ErrorCodeData = ({ errorCode }) => (
  <Box display="flex" flexDirection="row" gap={1}>
    <Box>
      <WarningIcon />
    </Box>
    <Box display="flex" flexDirection="column">
      <Typography fontWeight="600" fontSize="15px">{mapErrorCodeToDescription(errorCode)}</Typography>
    </Box>
  </Box>
);

ErrorCodeData.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

const DataGridWithData = ({ existingCreditReport }) => {
  const { t } = useTranslation();

  const providerName = mapProviderNameToTitle[existingCreditReport.providerName];
  const reportDate = moment(existingCreditReport.createdAt).format('YYYY-MM-DD');
  const description = existingCreditReport.changedAt ? t('Change detected in source, data will update for next application') : t('No change detected in source');

  return (
    <RecordContextProvider value={existingCreditReport}>
      <Box marginBottom="1rem" display="flex" gap={1}>
        <Typography fontWeight="600" fontSize="15px" component="span">{`${providerName} : ${reportDate}`}</Typography>
        <Typography fontSize="15px" component="span" fontStyle="italic">{`(${description})`}</Typography>
      </Box>
      <CreditDataInfo />
      <CreditSafeCharts />
    </RecordContextProvider>
  );
};

DataGridWithData.propTypes = {
  existingCreditReport: PropTypes.shape({
    providerName: PropTypes.string,
    registrationDate: PropTypes.string,
    createdAt: PropTypes.string,
    changedAt: PropTypes.string,
    accountPeriodEndDate: PropTypes.string,
  }).isRequired,
};

const CreditDataGroup = ({ entityId }) => {
  const { t } = useTranslation();
  const record = useRecordContext();
  const [isNoDataAvailable, setIsNoDataAvailable] = React.useState(false);

  const authenticationData = usePermissions();
  const { constants } = useConstantContext();
  const { creditReport, setCreditReport } = useCreditDataContext();

  const { userActions } = constants;

  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canViewCreditData = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_CREDIT_DATA,
  );

  const canPurchaseCreditData = hasUserPermissions(
    userAllowedPermissions,
    userActions.PURCHASE_CREDIT_DATA,
  );

  const coreId = record.entityCoreId || record[entityId];

  const {
    isLoading: loadingGetCreditReport,
  } = useGetMany(
    'entity-v2/credit-data',
    { ids: [coreId] },
    {
      onSuccess: (data) => {
        if (data.length === 0) setIsNoDataAvailable(true);
        else setCreditReport(data[0]);
      },
      enabled: !!coreId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  let componentToRender;
  if (loadingGetCreditReport) componentToRender = <LoadingData />;
  else if (creditReport && creditReport.error) componentToRender = <ErrorCodeData errorCode={creditReport.error.code} />;
  else if (creditReport) componentToRender = <DataGridWithData existingCreditReport={creditReport} />;
  else if (isNoDataAvailable) componentToRender = <EmptyData />;
  else componentToRender = <Error />;

  if (!canViewCreditData) return null;

  return (
    <FieldsGroup
      title={t('External data')}
      actions={(
        canPurchaseCreditData
        && (
          <ConfirmCreditDataButton entityId={entityId} />
        )
      )}
    >
      {componentToRender}
    </FieldsGroup>
  );
};

CreditDataGroup.propTypes = {
  entityId: PropTypes.string,
};

CreditDataGroup.defaultProps = {
  entityId: undefined,
};

export default CreditDataGroup;
