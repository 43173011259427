import config from '../../config';

const init = () => {
  if (!config.allowHotjar) return;

  /* eslint-disable */
  (function (h, o, t, j, a, r) {
    window.hj = window.hj || function () { (window.hj.q = window.hj.q || []).push(arguments); };
    window._hjSettings = { hjid: 3570006, hjsv: 6 };
    a = document.getElementsByTagName('head')[0];
    r = document.createElement('script'); r.async = 1;
    r.src = t + window._hjSettings.hjid + j + window._hjSettings.hjsv;
    a.appendChild(r);
  }(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='));
  /* eslint-enable */
};

export default {
  init,
};
