import React from 'react';

import {
  ArrayField,
  TextField,
  usePermissions,
  SelectField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import UpdateBillingInvoicingSettings from './UpdateBillingInvoicingSettings';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useFeatureFlags from '../hooks/useFeatureFlags';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import hasUserPermissions from '../../utils/hasUserPermissions';
import CustomSuffixTextField from '../utils/CustomSuffixTextField';

import TitleH3 from '../designSystem/TitleH3';
import Datagrid from '../designSystem/react-admin/Datagrid';
import TooltipBox from '../designSystem/TooltipBox';

import { useConstantContext } from '../ConstantsContext';

const BillingInvoicingSettings = () => {
  const { constants, choices } = useConstantContext();
  const entityRecord = useRecordContext();
  const authenticationData = usePermissions();
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const [featureFlags] = useFeatureFlags();

  const { userActions } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const {
    emailLayoutTypesInvoice,
    invoiceBillingLayoutTypes,
    currencies,
  } = choices;

  const canView = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_INVOICES,
  );

  if (!featureFlags.enableBillingInvoicing
    || !canView
    || !isFinancer
    || !entityRecord
  ) return null;

  return (
    <FieldsGroup
      title={t('Billing invoicing settings')}
      actions={(
        <Box display="flex" gap="0.5rem" flexDirection="row">
          <UpdateBillingInvoicingSettings />
        </Box>
      )}
    >
      <Box display="flex" flexDirection="column">
        <TooltipBox
          tooltip={t('Due date is calculated from invoice creation date')}
          margin="2rem 0rem 1rem"
        >
          <TitleH3 title={t('Payment terms')} />
        </TooltipBox>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CustomSuffixTextField source="billingInvoicingSettings.termsOfPayment" label={t('Terms of payment')} customSuffix={t('days')} />
          </SimpleShowLabeled>
        </Grid>
        <TooltipBox
          tooltip={t('Some merchants are financed in multiple currencies, which requires different currency and payment settings for billing. If this doesn\'t apply to your merchant(s), only one set of settings is needed')}
          margin="2rem 0rem 1rem"
        >
          <TitleH3 title={t('Currency & payment settings')} />
        </TooltipBox>
        <SimpleShowLabeled fullWidth>
          <ArrayField label={false} source="billingInvoicingSettings.currencySettings">
            <Datagrid bulkActionButtons={false}>
              <SelectField
                source="currency"
                label={t('Currency')}
                choices={currencies}
                emptyText="-"
              />
              <TextField source="iban" label="IBAN" emptyText="-" />
              <TextField source="bic" label="BIC" emptyText="-" />
              <TextField source="bankgiro" label={t('Bankgiro')} emptyText="-" />
              <TextField source="accountHolderName" label="Account holder name" emptyText="-" />
              <FunctionField
                source="invoiceText"
                render={(record) => {
                  const invoiceText = record?.invoiceText;
                  if (invoiceText) {
                    return invoiceText.length > 25
                      ? `${invoiceText.substring(0, 25)}...`
                      : invoiceText;
                  }
                  return '-';
                }}
                label={t('Additional text')}
              />
            </Datagrid>
          </ArrayField>
        </SimpleShowLabeled>
        <TitleH3 title={t('Invoice sender')} margin="2rem 0rem 1rem" />
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="billingInvoicingSettings.invoiceEmail" label={t('Invoice email')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="billingInvoicingSettings.invoiceAddress.streetAddress" label={t('Street address')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="billingInvoicingSettings.invoiceAddress.postcode" label={t('Postcode')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="billingInvoicingSettings.invoiceAddress.town" label={t('Town')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField
                source="billingInvoicingSettings.invoiceLayout"
                label={t('Invoice layout')}
                choices={invoiceBillingLayoutTypes}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField
                source="billingInvoicingSettings.emailLayout"
                label={t('Email layout')}
                choices={emailLayoutTypesInvoice}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
      </Box>
    </FieldsGroup>
  );
};

export default BillingInvoicingSettings;
