import React, { useCallback } from 'react';
import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { green, red } from '@mui/material/colors';
import useCustomRpc from '../hooks/useCustomRpc';

const UpdateUserStatusButton = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const newStatus = !record?.active;

  const {
    mutate: changeStatus,
    isLoading,
  } = useCustomRpc({
    path: `user/${record?.id}/change-status`,
    httpMethod: 'PUT',
    successMessage: newStatus ? t('User activated successfully') : t('User disabled successfully'),
    errorMessage: t('Could not change status'),
    shouldRefresh: true,
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    changeStatus({ newStatus });
  }, [changeStatus, newStatus]);

  return (
    <Button
      onClick={onClick}
      variant="contained"
      size="medium"
      disabled={isLoading}
      sx={record?.active ? {
        backgroundColor: red[500],
        color: 'white',
        '&:hover': {
          backgroundColor: red[200],
        },
      } : {
        backgroundColor: green[500],
        color: 'white',
        '&:hover': {
          backgroundColor: green[200],
        },
      }}
    >
      {record?.active ? t('Deactivate') : t('Activate')}
    </Button>
  );
};

export default UpdateUserStatusButton;
