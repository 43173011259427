import React, { useState } from 'react';
import {
  required,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import renderReference from '../../../utils/renderReference';

import { useConstantContext } from '../../ConstantsContext';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';
import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import ReferenceInput from '../../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../../designSystem/react-admin/inputs/AutocompleteInput';

const useDSOFilters = () => {
  const { constants } = useConstantContext();
  const { entityRoles } = constants;
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  return useState(isMerchant ? [
    <DateInput
      label={t('Snapshot date')}
      source="date"
    />,
  ] : [
    <DateInput
      label={t('Snapshot date')}
      source="date"
    />,
    <ReferenceInput
      source="filters.seller"
      label={t('Merchant')}
      reference="entity-v2/reference"
      resource="/entity-v2/reference"
      filter={{ entityRoles: entityRoles.MERCHANT }}
      alwaysOn
    >
      <AutocompleteInput
        optionText={renderReference}
        label={t('Merchant')}
        suggestionLimit={20}
        validate={[required()]}
      />
    </ReferenceInput>,
    <ReferenceArrayInput
      label={t('Labels')}
      source="filters.labelIds"
      reference="label"
    >
      <AutocompleteArrayInput
        label={t('Labels')}
        variant="filled"
        optionText="name"
      />
    </ReferenceArrayInput>,
  ]);
};

export default useDSOFilters;
