import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormTab } from 'react-admin';

import OnboardingStage from '../../OnboardingStage';

import EntityCommentsGroup from '../../EntityCommentsGroup';
import OverviewGroup from '../../OverviewGroup';
import CreditDataGroup from '../../CreditDataGroup';
import EntityExposureGroup from '../../EntityExposureGroup';
import { CreditDataContextProvider } from '../../../CreditDataContext';

const SummaryTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <OnboardingStage />
      <OverviewGroup route="merchant" />
      <EntityCommentsGroup />
      <EntityExposureGroup route="merchant" />
      <CreditDataContextProvider>
        <CreditDataGroup entityId="merchantId" />
      </CreditDataContextProvider>
    </FormTab>
  );
};

export default SummaryTab;
