import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import CoreInfoGroup from '../../CoreInfoGroup';
import CompanyDetails from '../../company-details/CompanyDetails';
import SystemInfoGroup from '../../SystemInfoGroup';
import AdditionalInfoGroup from '../../AdditionalInfoGroup';

import KeyPeople from '../../KeyPeople';
import EntityInvoiceSettings from '../../EntityInvoiceSettings';
import { useConstantContext } from '../../../ConstantsContext';

import resources from '../../../../constants/resources';

const DetailsTab = (props) => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Details')} {...props}>
      <CoreInfoGroup />
      <CompanyDetails viewUserAction={userActions.VIEW_MERCHANTS} createUserAction={userActions.CREATE_MERCHANTS} detailsSource="merchantDetails" />
      <KeyPeople userAction={userActions.CREATE_MERCHANTS} />
      <EntityInvoiceSettings resource={resources.MERCHANT} />
      <AdditionalInfoGroup userAction={userActions.CREATE_MERCHANTS} route="merchant" />
      <SystemInfoGroup />
    </FormTab>
  );
};

export default DetailsTab;
