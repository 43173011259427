import React from 'react';
import {
  CreateButton,
  TextField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';

import List from '../designSystem/react-admin/list/List';
import DateField from '../designSystem/react-admin/DateField';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import ReferenceField from '../designSystem/react-admin/ReferenceField';
import UtilisationField from '../utils/UtilisationField';
import TitleH1 from '../designSystem/TitleH1';

export const getEntityGroupListTitle = (t) => t('Entity groups');

const EntityGroupList = () => {
  const { t } = useTranslation();

  return (
    <List
      title={<TitleH1 title={getEntityGroupListTitle(t)} />}
      actions={<CreateButton label={t('Add entity group')} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      appLocation="home.entity-group"
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick="edit"
        rightAlignedColumns={[
          'creditLimit',
          'entitiesTotalCreditLimit',
          'entitiesUtilisedCreditLimit',
          'entitiesUtilisedCreditLimitPercentage',
        ]}
      >
        <TextField label={t('Group name')} source="groupName" />
        <DateField label={t('Creation date')} source="createdAt" />
        <CurrencyField label={t('Credit limit')} source="creditLimit" currencySource="currency" />
        <ReferenceField
          label={t('Total credit limit of entities')}
          referenceSource="id"
          reference="entity-group/credit-info"
          source="entitiesTotalCreditLimit"
          FieldComponent={CurrencyField}
          FieldComponentProps={{ currencySource: 'currency' }}
        />
        <ReferenceField
          label={t('Exposure')}
          referenceSource="id"
          reference="entity-group/credit-info"
          source="entitiesUtilisedCreditLimit"
          FieldComponent={CurrencyField}
          FieldComponentProps={{ currencySource: 'currency' }}
        />
        <ReferenceField
          label={`${t('Exposure')} (%)`}
          referenceSource="id"
          reference="entity-group/credit-info"
          source="entitiesUtilisedCreditLimitPercentage"
          FieldComponent={UtilisationField}
        />
      </DatagridConfigurable>
    </List>
  );
};

export default EntityGroupList;
