import React from 'react';
import {
  FormTab,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../../ConstantsContext';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import LogsList from '../../logs/LogsList';

const EntityHistoryTab = (props) => {
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const recordContext = useRecordContext();
  const { t } = useTranslation();

  const canViewLogs = useHasUserPermissions(
    constants,
    userActions.VIEW_ACTIVITY_HISTORY,
  );
  if (!canViewLogs) return null;

  return (
    <FormTab
      label={t('History')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <LogsList resourceId={[recordContext.id, recordContext.entityCoreId]} />
    </FormTab>
  );
};

export default EntityHistoryTab;
