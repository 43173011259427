import { useQuery } from 'react-query';
import { useDataProvider, useNotify } from 'react-admin';

const useCustomQuery = ({
  httpMethod,
  path,
  key,
  errorMessage,
  filter,
  ...queryOptions
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const query = useQuery(key, async () => dataProvider.customRpc({
    httpMethod,
    path,
  }), {
    ...queryOptions,
    onError: async (e, ...args) => {
      const notification = e.message ?? errorMessage;
      if (notification) notify(notification, { type: 'error' });
      if (queryOptions.onError) return queryOptions.onError(e, ...args);
      return undefined;
    },
  });

  return query;
};

export default useCustomQuery;
