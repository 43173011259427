import hasPermissions from './hasPermissions';

const isAvailableToModule = (actions, modules, modulesActions) => actions.some(
  (action) => hasPermissions(
    modules,
    modulesActions.mapActionToModules[action],
  ),
);

export default isAvailableToModule;
