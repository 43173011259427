import React from 'react';
import {
  EditButton,
  ShowButton,
  useRecordContext,
} from 'react-admin';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const OrderLink = () => {
  const { constants } = useConstantContext();
  const { origin } = useRecordContext();
  const { userActions } = constants;

  const canEditOrder = useHasUserPermissions(
    constants,
    userActions.CREATE_ORDERS,
  );

  const canViewOrder = useHasUserPermissions(
    constants,
    userActions.VIEW_ORDERS,
  );

  if (!origin.orderId || (!canEditOrder && !canViewOrder)) {
    return null;
  }

  return (
    canEditOrder
      ? (
        <EditButton
          resource="order"
          variant="blueBackgroundOnHover"
          record={{ id: origin.orderId }}
          label={origin.orderReferenceNumber}
          sx={{ '& .MuiButton-startIcon': { display: 'none' } }}
        />
      )
      : (
        <ShowButton
          resource="order"
          variant="blueBackgroundOnHover"
          record={{ id: origin.orderId }}
          label={origin.orderReferenceNumber}
          sx={{ '& .MuiButton-startIcon': { display: 'none' } }}
        />
      )
  );
};

export default OrderLink;
