import React from 'react';
import {
  DateField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { useConstantContext } from '../../ConstantsContext';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import PeerReviewModalButton from '../../peerReview/PeerReviewModalButton';
import ApplicationButtons from '../../application/ApplicationButtons';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const ApplicationDetails = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const { constants } = useConstantContext();
  const { userActions } = constants;
  const canEditCustomerApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_CUSTOMER_APPLICATIONS,
  );

  const pendingReview = !!record.pendingPeerReviewRequest;

  const applicationAction = canEditCustomerApplication && (
    pendingReview ? <PeerReviewModalButton /> : <ApplicationButtons />
  );

  return (
    <FieldsGroup title={t('Application details')} actions={applicationAction}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="createdAt" label={t('Creation date')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="applicationStep" label={t('Application step')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="currentDecision" label={t('Current decision')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="decisionReason" label={t('Current decision reason')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default ApplicationDetails;
