import React, { useCallback } from 'react';
import {
  SimpleForm,
  NumberInput,
  SelectInput,
  required,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  SaveButton,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import CoreInfoGroup from '../CoreInfoGroup';
import AssignedCreditInfoGroup from '../AssignedCreditInfoGroup';

import FieldsGroup from '../../utils/OldFieldsGroup';

import { positiveNumberValidation } from '../../../utils/validationErrors';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../ConstantsContext';
import Create from '../../designSystem/react-admin/Create';
import TitleH1 from '../../designSystem/TitleH1';

const validateRequired = [required()];

export const getAssignedCreditInfoCreateTitle = (t) => t('Create assigned credit info');

const AssignedCreditInfoCreate = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { state } = useLocation();
  const { record } = state || {};
  const { id: entityId } = record || {};
  const { t } = useTranslation();

  const { isMerchant } = useUserPermissionsAndRoles();
  const { constants, choices } = useConstantContext();
  const { currencies: currenciesChoices } = choices;
  const { entityRoles, userActions } = constants;

  const canCreateAssignedCreditInfo = useHasUserPermissions(
    constants,
    userActions.CREATE_CUSTOMERS,
  ) && isMerchant;

  const onSuccess = useCallback(() => {
    notify(t('Customer Application Sent'));
    redirect(`/entity-v2/customer/${entityId}`);
    refresh();
  }, [notify, redirect, refresh, entityId, t]);

  if (!canCreateAssignedCreditInfo || !entityId) return <Navigate to="/entity-v2/customer" />;

  return (
    <Create
      mutationOptions={{ onSuccess }}
      appLocation="home.entity-v2/customer.create-assigned-credit-info"
      title={<TitleH1 title={getAssignedCreditInfoCreateTitle(t)} />}
    >
      <SimpleForm
        toolbar={<Toolbar><SaveButton /></Toolbar>}
        sanitizeEmptyValues
      // warnWhenUnsavedChanges
      >
        <CoreInfoGroup />
        <AssignedCreditInfoGroup />
        <FieldsGroup
          name={t('Request New Credit Settings')}
          withBorder
          fillAvailable
        >
          <FieldsGroup column={4}>
            <ReferenceInput
              source="financerId"
              reference="entity-v2/reference"
              filter={{ entityRoles: entityRoles.FINANCER }}
            >
              <AutocompleteInput
                label={t('Financer')}
                validate={validateRequired}
                optionText="mainAddress.name"
                suggestionLimit={20}
                options={{
                  suggestionsContainerProps: {
                    style: { zIndex: 1500 },
                  },
                }}
              />
            </ReferenceInput>
            <NumberInput
              label={t('New Total Credit Limit')}
              source="creditLimit"
              validate={[positiveNumberValidation, ...validateRequired]}
            />
            <SelectInput
              margin="none"
              label={t('Requested Credit Currency')}
              source="creditLimitCurrency"
              choices={currenciesChoices}
              validate={validateRequired}
            />
          </FieldsGroup>
          <FieldsGroup fillAvailable>
            <TextInput source="note" multiline style={{ width: '100%' }} />
          </FieldsGroup>
        </FieldsGroup>
      </SimpleForm>
    </Create>
  );
};

export default AssignedCreditInfoCreate;
