import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';

import { useRecordContext } from 'react-admin';

import currencyFormat from '../../utils/currencyFormat';
import { isNumber } from '../../utils/validators';
import roundNumber from '../../utils/roundNumber';

const colours = {
  OWED: 'green',
  BALANCED: 'orange',
  PAYOUT: 'red',
};

const CurrencyField = ({
  source,
  currencySource,
  emptyText,
  coefficient,
  coloured,
  currency,
  isRounded,
  customRecord,
}) => {
  let record = useRecordContext();
  if (customRecord) record = customRecord;
  const currencyValue = currency ?? get(record, currencySource);
  if (!currencyValue) {
    return (
      <Typography
        component="span"
        variant="body2"
      >
        {emptyText}
      </Typography>
    );
  }

  const value = isRounded ? roundNumber(get(record, source), 4) : get(record, source);

  let colour;
  if (value > 0) colour = colours.OWED;
  else if (value === 0) colour = colours.BALANCED;
  else colour = colours.PAYOUT;

  return (
    <Typography
      component="span"
      variant="body2"
      sx={coloured ? { color: colour } : {}}
    >
      {!isNumber(value) ? emptyText : currencyFormat(
        value === 0 ? 0 : value * coefficient, // Do not transform 0 in -0
        currencyValue,
      )}
    </Typography>
  );
};

CurrencyField.propTypes = {
  source: PropTypes.string.isRequired,
  currencySource: PropTypes.string,
  currency: PropTypes.string,
  emptyText: PropTypes.string,
  coefficient: PropTypes.number,
  coloured: PropTypes.bool,
  isRounded: PropTypes.bool,
  customRecord: PropTypes.shape({}),
};

CurrencyField.defaultProps = {
  currency: undefined,
  currencySource: '',
  emptyText: '',
  coefficient: 1,
  coloured: false,
  isRounded: false,
  customRecord: undefined,
};

export default CurrencyField;
