import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import {
  FormTab,
  ReferenceManyField,
  TextField,
  DateField,
  useNotify,
  ReferenceField,
  EditButton,
  ShowButton,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import PropTypes from 'prop-types';

import CurrencyField from '../../../utils/CurrencyField';
import FieldsGroup from '../../../utils/FieldsGroup';
import SafePagination from '../../../utils/SafePagination';

import FactoringAccountStatusChip from '../../../utils/FactoringAccountStatusChip';

import DropDownMenu from '../../../../utils/DropDownMenu';

import Datagrid from '../../../designSystem/react-admin/Datagrid';
import TextInputWithLens from '../../../designSystem/react-admin/inputs/TextInputWithLens';

import SettlementAccountBreakdown from '../../SettlementAccountBreakdown';
import DownloadAccountBreakdownButton from '../../DownloadAccountBreakdownButton';
import PartialPayoutButton from '../../PartialPayoutButton';

import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../../hooks/useHasUserPermissions';

import DownloadOutboundPaymentButton from '../../../payment/DownloadOutboundPaymentButton';

import FinancerName from '../../../utils/FinancerName';

import { useConstantContext } from '../../../ConstantsContext';

const StyledEditButton = styled(EditButton)(() => ({
  '& .MuiButton-startIcon': { display: 'none' },
}));
const StyledShowButton = styled(ShowButton)(() => ({
  '& .MuiButton-startIcon': { display: 'none' },
}));

const EntitySettlementsTab = (props) => {
  const { isFinancer, hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const { userActions } = constants;

  const canViewSettlement = useHasUserPermissions(
    constants,
    userActions.VIEW_SETTLEMENT_ACCOUNTS,
  );
  const canEditOrder = useHasUserPermissions(
    constants,
    userActions.CREATE_ORDERS,
  );

  const notify = useNotify();

  const params = new URLSearchParams(useLocation().search);
  const error = params.get('error');

  useEffect(() => {
    if (error) notify(error, { type: 'warning' });
  }, [error, notify]);

  const payoutReference = useWatch({ name: 'query.payoutReference' });

  if (!hasFactoring || !canViewSettlement) return null;

  return (
    <FormTab
      label={t('Settlements')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FieldsGroup title={t('Factorings ready to be balanced')}>
        <ReferenceManyField
          source="entityCoreId"
          reference="factoring-account"
          target="debtorId"
          label=""
          filter={{ markedToBalanceAt: { $exists: true } }}
          sort={{ field: 'markedToBalanceAt', order: 'DESC' }}
          pagination={<SafePagination />}
          perPage={10}
        >
          <Datagrid bulkActionButtons={false} rightAlignedColumns={['subActions', 'balance', 'status']}>
            <FinancerName label={t('Financer')} />
            <ReferenceField
              label={t('Order')}
              source="factoredOrder"
              reference="order"
              link={canEditOrder ? 'edit' : 'show'}
            >
              <TextField source="orderReferenceNumber" />
            </ReferenceField>
            <CurrencyField source="balance" currencySource="currency" label={t('Balance')} />
            <WrapperField source="status">
              <FactoringAccountStatusChip />
            </WrapperField>
            <WrapperField source="subActions" label={false}>
              <DropDownMenu>
                <StyledEditButton label={t('View factoring')} variant="subAction" />
              </DropDownMenu>
            </WrapperField>
          </Datagrid>
        </ReferenceManyField>
      </FieldsGroup>

      <FieldsGroup title={t('Active settlements')}>
        <ReferenceManyField
          source="entityCoreId"
          reference="settlement-account"
          target="debtorId"
          label=""
          filter={{ closedAt: { $exists: false } }}
          pagination={<SafePagination />}
          perPage={10}
        >
          <Datagrid expand={<SettlementAccountBreakdown />} bulkActionButtons={false} rightAlignedColumns={['subActions', 'balance']}>
            <FinancerName label={t('Financer')} />
            <TextField source="paymentReference" label={t('Payment reference')} />
            <TextField source="currency" />
            <CurrencyField source="balance" currencySource="currency" label={t('Settlement balance')} />
            <WrapperField source="subActions" label={false}>
              <DropDownMenu>
                {isFinancer && (
                <StyledShowButton
                  label={t('View settlement')}
                  variant="subAction"
                />
                )}
                <DownloadOutboundPaymentButton variant="subAction" />
                <PartialPayoutButton variant="subAction" />
                <DownloadAccountBreakdownButton variant="subAction" />
              </DropDownMenu>
            </WrapperField>
          </Datagrid>
        </ReferenceManyField>
      </FieldsGroup>

      <FieldsGroup title={t('Closed settlements')}>
        <Box maxWidth="20rem" marginBottom="1.25rem">
          <TextInputWithLens placeholder={t('Payout reference')} source="query.payoutReference" borderRadius="0.3rem" fimentoVariant="filter" />
        </Box>
        <ReferenceManyField
          source="entityCoreId"
          reference="settlement-account"
          target="debtorId"
          label=""
          filter={{
            closedAt: { $exists: true },
            ...(payoutReference ? { 'transactions.paymentDetails.payoutReference': payoutReference } : {}),
          }}
          sort={{ field: 'closedAt', order: 'DESC' }}
          pagination={<SafePagination />}
          perPage={10}
        >
          <Datagrid expand={<SettlementAccountBreakdown />} bulkActionButtons={false} rightAlignedColumns={['subActions', 'lastTransaction\\.amount']}>
            <FinancerName label={t('Financer')} />
            <TextField
              source="lastTransaction.paymentDetails.payoutReference"
              label={t('Payout reference')}
              emptyText="-"
            />
            <TextField source="paymentReference" label={t('Payment reference')} />
            <DateField source="closedAt" label={t('Closed date')} />
            <TextField source="currency" label={t('Currency')} />
            <CurrencyField
              source="lastTransaction.amount"
              currencySource="currency"
              label={t('Payout amount')}
              emptyText="-"
              coefficient={-1}
            />
            <WrapperField source="subActions" label={false}>
              <DropDownMenu>
                {isFinancer && (
                <StyledShowButton
                  label={t('View settlement')}
                  variant="subAction"
                />
                )}
                <DownloadAccountBreakdownButton variant="subAction" />
              </DropDownMenu>
            </WrapperField>
          </Datagrid>
        </ReferenceManyField>
      </FieldsGroup>
    </FormTab>
  );
};

EntitySettlementsTab.propTypes = {
  value: PropTypes.string,
};

EntitySettlementsTab.defaultProps = {
  value: undefined,
};

export default EntitySettlementsTab;
