import React, { useState } from 'react';
import {
  ArrayField, useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';

import Datagrid from '../designSystem/react-admin/Datagrid';
import DateField from '../designSystem/react-admin/DateField';

import GetPaymentPlanButton from './GetPaymentPlanButton';

const PaymentPlanList = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const showButton = invoices.length === 0;

  return (
    <FieldsGroup title={t('Payment plan')}>
      {showButton
        && <GetPaymentPlanButton accountId={record.id} setInvoices={setInvoices} />}
      {!showButton
        && (
        <ArrayField source="invoices" fullWidth record={{ invoices }}>
          <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount']}>
            <DateField source="issueDate" label={t('Issue date')} />
            <DateField source="expiryDate" label={t('Expiry date')} />
            <CurrencyField source="amount" currency={record.currency} label={t('Amount')} />
          </Datagrid>
        </ArrayField>
        )}
    </FieldsGroup>
  );
};

export default PaymentPlanList;
