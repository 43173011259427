import { isNumber } from './validators';

const convertToHumanReadable = (inputValue, digits = 9, currency) => {
  if (!isNumber(inputValue)) return `${currency ?? ''} -`;

  let value;
  let unit;
  if (inputValue < 10 ** digits) {
    value = inputValue;
    unit = '';
  } else if (inputValue < 10 ** (digits + 3)) {
    value = inputValue / 1000;
    unit = 'K';
  } else if (inputValue < 10 ** (digits + 6)) {
    value = inputValue / 1000000;
    unit = 'M';
  } else {
    value = inputValue / 1000000000;
    unit = 'B';
  }

  return `${currency ?? ''} ${Math.round(value).toLocaleString()} ${unit}`;
};

export default convertToHumanReadable;
