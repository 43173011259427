import React, { useCallback } from 'react';
import {
  Toolbar,
  SaveButton,
  useRecordContext,
  DeleteWithConfirmButton,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { getEntityIdFromToken } from '../../utils/getValueFromToken';

const Spacer = () => <div style={{ width: '100%' }} />;

const LabelToolbar = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const entityId = getEntityIdFromToken();
  const { t } = useTranslation();

  const onSuccess = useCallback(() => {
    notify(t('Label deleted'));
    redirect(`/my-company/${entityId}/1`);
  }, [notify, redirect, entityId, t]);

  const record = useRecordContext();

  return (
    <Toolbar>
      <SaveButton alwaysEnable />
      <Spacer />
      <DeleteWithConfirmButton
        confirmTitle={`${t('Delete label with name')} "${record.name}"`}
        confirmContent={t('Are you sure you want to delete this label?')}
        size="medium"
        mutationOptions={{ onSuccess }}
        variant="text"
      />
    </Toolbar>
  );
};

export default LabelToolbar;
