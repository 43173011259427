import React, { useCallback, useState } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  useResourceContext,
  useGetRecordId,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material';

import { ChromePicker } from 'react-color';

import LabelToolbar from './LabelToolbar';

import useCustomRpc from '../hooks/useCustomRpc';

import Edit from '../designSystem/react-admin/Edit';
import TitleH1 from '../designSystem/TitleH1';

const LabelEditForm = () => {
  const resource = useResourceContext();
  const recordId = useGetRecordId();
  const { t } = useTranslation();
  const theme = useTheme();
  const { color: recordColor } = useRecordContext();
  const [labelColor, setLabelColor] = useState(recordColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const {
    mutate: updateLabel,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resource}/${recordId}`,
    shouldRefresh: true,
    successMessage: t('Label updated'),
    errorMessage: t('Could not update label'),
  });

  const onSubmit = useCallback((formValues) => {
    const labelDetails = formValues;
    labelDetails.color = labelColor;
    updateLabel(labelDetails);
    return true;
  }, [updateLabel, labelColor]);

  return (
    <SimpleForm
      onSubmit={onSubmit}
      disabled={isLoading}
      sx={{ position: 'relative' }}
      toolbar={<LabelToolbar />}
      sanitizeEmptyValues
    >
      <TextInput source="name" validate={[required()]} />
      <Chip
        onClick={() => setDisplayColorPicker(!displayColorPicker)}
        label={t('Pick a colour')}
        sx={{
          marginRight: '0.3rem',
          backgroundColor: labelColor,
          color: theme.palette.getContrastText(labelColor),
          borderRadius: 1,
          height: 40,
        }}
      />
      {displayColorPicker && (
        <ChromePicker
          color={labelColor}
          onChangeComplete={(color) => setLabelColor(color.hex)}
          disableAlpha
        />
      )}
    </SimpleForm>
  );
};

export const getLabelEditTitle = (t) => t('Edit label');

const LabelEdit = () => {
  const { t } = useTranslation();
  return (
    <Edit
      redirect={false}
      title={<TitleH1 title={getLabelEditTitle(t)} />}
      mutationMode="optimistic"
      appLocation="home.my-company.edit/label"
    >
      <LabelEditForm />
    </Edit>
  );
};

export default LabelEdit;
