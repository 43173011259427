import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationListDetails from './NotificationListDetails';
import NotificationActions from './NotificationActions';
import List from '../designSystem/react-admin/list/List';
import useNotificationListFilters from './useNotificationListFilters';

export const getNotificationListTitle = () => 'Notifications';

const NotificationList = () => {
  const notificationFilters = useNotificationListFilters();
  const { t } = useTranslation();
  return (
    <List
      mainFilterInputs={notificationFilters}
      actions={<NotificationActions />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      appLocation="home.notification"
      title={t(getNotificationListTitle())}
    >
      <NotificationListDetails />
    </List>
  );
};

export default NotificationList;
