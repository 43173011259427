import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrayInput,
  AutocompleteInput,
  SimpleForm,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
  TextInput,
  ReferenceInput,
  BooleanInput,
  required,
  DateInput,
} from 'react-admin';

import {
  strictlyPositiveNumberValidation,
  positiveNumberValidation,
  isTodayOrPastDate,
  isValidOrderReferenceNumber,
} from '../../utils/validationErrors';
import renderReference from '../../utils/renderReference';
import { useConstantContext } from '../ConstantsContext';
import validatePaymentReference from '../utils/validatePaymentReference';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import Create from '../designSystem/react-admin/Create';
import TitleH1 from '../designSystem/TitleH1';

const validateRequired = [required()];

export const getOrderCreateTitle = (t) => t('Create order');

const OrderCreate = () => {
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();
  const { constants, choices } = useConstantContext();
  const financialProduct = constants.financialProducts.UNDISCLOSED_FACTORING_NON_RECOURSING;
  const { entityRoles } = constants;
  const defaultValues = () => ({ financialProduct });
  const { withOriginalInvoice } = constants.productFeatures;
  const {
    financialProducts,
    currencies,
    financeableFees,
  } = choices;

  const validatePaymentReferenceField = validatePaymentReference({
    withOriginalInvoice,
  });

  if (isMerchant) return null;

  return (
    <Create appLocation="home.order.create" title={<TitleH1 title={getOrderCreateTitle(t)} />}>
      <SimpleForm
        sanitizeEmptyValues
        defaultValues={defaultValues}
      >
        <ReferenceInput
          source="sellerId"
          reference="entity-v2/reference"
          filter={{ entityRoles: entityRoles.MERCHANT }}
        >
          <AutocompleteInput
            label={t('Merchant')}
            validate={validateRequired}
            optionText={renderReference}
            suggestionLimit={20}
          />
        </ReferenceInput>
        <ReferenceInput
          source="debtorId"
          reference="entity-v2/reference"
          filter={{ entityRoles: entityRoles.CUSTOMER }}
        >
          <AutocompleteInput
            label={t('Customer')}
            validate={validateRequired}
            optionText={renderReference}
            suggestionLimit={20}
          />
        </ReferenceInput>
        <SelectInput
          margin="none"
          source="financialProduct"
          choices={financialProducts}
          validate={validateRequired}
        />
        <SelectInput
          margin="none"
          source="currency"
          choices={currencies}
          validate={validateRequired}
        />
        <DateInput
          label={t('Issue date')}
          source="issueDate"
          validate={[isTodayOrPastDate, ...validateRequired]}
        />
        <NumberInput
          source="termsOfPayment"
          label={t('Terms of payments (days)')}
          validate={[strictlyPositiveNumberValidation, ...validateRequired]}
        />
        <TextInput
          source="orderReferenceNumber"
          label={t('Order reference number')}
          validate={[isValidOrderReferenceNumber, ...validateRequired]}
        />
        <TextInput
          source="paymentReference"
          label={t('Payment reference')}
          validate={[validatePaymentReferenceField]}
        />
        <BooleanInput source="isForborne" label={t('Is Forborne')} />
        <BooleanInput source="isPrepaid" label={t('Is Prepaid')} />
        <ArrayInput
          source="items"
          validate={validateRequired}
        >
          <SimpleFormIterator>
            <NumberInput
              source="amount"
              label={t('Amount')}
              validate={[positiveNumberValidation, ...validateRequired]}
            />
            <NumberInput
              source="vatPercentage"
              label={`${t('VAT')} (%)`}
            />
            <TextInput
              source="description"
              label={t('Description')}
              validate={validateRequired}
            />
            <SelectInput
              margin="none"
              source="feeType"
              label={t('Type')}
              validate={validateRequired}
              choices={financeableFees}
            />
            <NumberInput
              source="transactionDetails.deliveredQuantity"
              label={t('Delivered quantity')}
              validate={[positiveNumberValidation]}
            />
            <TextInput
              source="transactionDetails.unit"
              label={t('Unit')}
            />
            <TextInput
              source="transactionDetails.articleNumber"
              label={t('Article Number')}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default OrderCreate;
