import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  required,
  useRecordContext,
} from 'react-admin';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';

import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

const validateRequired = [required()];

const AddUserRoleButton = () => {
  const { id: userRoleId, roleType } = useRecordContext();
  const { t } = useTranslation();

  const [userIdState, setUserIdState] = useState();

  const {
    mutate: addUserRole,
  } = useCustomRpc({
    path: `user/${userIdState}/add-user-role`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not add user role'),
  });

  const handleInputChange = useCallback((event) => {
    setUserIdState(event);
  }, [setUserIdState]);

  const onSubmit = useCallback(() => {
    addUserRole({ userRoleId });
    return true;
  }, [addUserRole, userRoleId]);

  return (
    <ModalButton
      openButtonLabel={t('Add user')}
      modalTitle={t('Add user')}
      actionLabel={t('Add')}
      onClick={onSubmit}
      formDefaultValue={{}}
      width="25rem"
    >
      <ReferenceInput
        source="id"
        reference="user"
        resource="/user"
        filter={{
          active: true,
          custom_roleType: roleType,
          custom_roleId: userRoleId,
        }}
      >
        <AutocompleteInput
          label={t('Choose user')}
          onChange={handleInputChange}
          validate={validateRequired}
          optionText={(user) => `${user.firstName} ${user.lastName} (${user.email})`}
          suggestionLimit={20}
        />
      </ReferenceInput>
    </ModalButton>
  );
};

export default AddUserRoleButton;
