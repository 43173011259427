import React from 'react';
import PropTypes from 'prop-types';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

import ScrollButtonBase from './ScrollButtonBase';
import { useScrollToBottom } from '../../hooks/useScroll';

const ScrollButtonBottom = ({
  isDisplayed,
  scrollableElement,
  color,
  backgroundColor,
  extraPadding,
  dependencies,
  popperRef,
  zIndex,
}) => {
  const {
    scrollTo,
    canBeScrolled,
  } = useScrollToBottom(scrollableElement, dependencies);

  return (
    <ScrollButtonBase
      isDisplayed={isDisplayed && canBeScrolled}
      onClick={scrollTo}
      parentElement={scrollableElement}
      popperRef={popperRef}
      zIndex={zIndex}
      color={color}
      backgroundColor={backgroundColor}
      extraPadding={extraPadding}
      placement="bottom"
      icon={<ArrowCircleRightRoundedIcon sx={{ transform: 'rotate(90deg)' }} />}
    />
  );
};

ScrollButtonBottom.propTypes = {
  scrollableElement: PropTypes.instanceOf(window.Element),
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool,
  extraPadding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
  dependencies: PropTypes.arrayOf(PropTypes.any),
  popperRef: PropTypes.shape(),
  zIndex: PropTypes.number,
};

ScrollButtonBottom.defaultProps = {
  scrollableElement: null,
  isDisplayed: true,
  extraPadding: {},
  dependencies: [],
  popperRef: undefined,
  zIndex: undefined,
};

export default ScrollButtonBottom;
