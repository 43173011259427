import React from 'react';
import { useTranslation } from 'react-i18next';

import renderReference from '../../utils/renderReference';

import { useConstantContext } from '../ConstantsContext';

import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const useFactoringAccountFilters = () => {
  const { constants } = useConstantContext();
  const { entityRoles, userActions } = constants;
  const { t } = useTranslation();

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );

  const filters = [];

  if (canViewMerchant) {
    filters.push((
      <ReferenceInput
        source="debtorId"
        reference="entity-v2/reference"
        filter={{ entityRoles: entityRoles.MERCHANT }}
        alwaysOn
      >
        <AutocompleteInput
          placeholder={t('Merchant')}
          optionText={renderReference}
          suggestionLimit={20}
        />
      </ReferenceInput>
    ));
  }

  return filters;
};

export default useFactoringAccountFilters;
