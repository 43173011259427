import React from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import TitleH1 from '../../designSystem/TitleH1';

export const getLoanApplicationTitle = (record) => (record ? record.paymentReference : '');

const LoanApplicationTitle = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Loan application')}: ${getLoanApplicationTitle(record)}`;
  return <TitleH1 title={title} />;
};

export default LoanApplicationTitle;
