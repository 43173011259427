import { styled } from '@mui/material';
import MuiBadge from '@mui/material/Badge';

const StyledBadge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontSize: '10px',
    border: `0.125rem solid ${theme.palette.background.paper}`,
    backgroundColor: '#FC6A6A',
    padding: '0 0.25rem',
  },
}));

const Badge = StyledBadge;

export default Badge;
