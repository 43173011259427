import React from 'react';
import {
  required,
  ArrayInput,
  ReferenceInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import renderReference from '../../utils/renderReference';

import { positiveNumberValidation } from '../../utils/validationErrors';
import { useConstantContext } from '../ConstantsContext';

import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';

const validateRequired = [required()];

const CustomerForm = () => {
  const { constants, choices } = useConstantContext();
  const { currencies } = choices;
  const { entityRoles } = constants;
  const { t } = useTranslation();

  return (
    <>
      <InputsGroup layout="column">
        <NumberInput
          label={t('Credit limit')}
          source="creditLimit"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <SelectInput
          label={t('Credit currency')}
          source="creditLimitCurrency"
          choices={currencies}
          validate={validateRequired}
        />
        <NumberInput
          label={t('Insurance limit')}
          source="creditInsuranceLimit"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <SelectInput
          label={t('Insurance currency')}
          source="creditInsuranceLimitCurrency"
          choices={currencies}
          validate={validateRequired}
        />
      </InputsGroup>
      <InputsGroup title={t('Reserved credit limit')}>
        <ArrayInput
          source="assignedCreditLimits"
          label={false}
        >
          <SimpleFormIterator addButtonLabel={t('Assigned new credit limit')} itemLabel={t('Assigned credit limit')}>
            <InputsGroup layout="column">
              <ReferenceInput
                source="merchantId"
                reference="entity-v2/reference"
                filter={{ entityRoles: entityRoles.MERCHANT }}
              >
                <AutocompleteInput
                  label={t('Merchant')}
                  validate={validateRequired}
                  optionText={renderReference}
                  suggestionLimit={20}
                />
              </ReferenceInput>
              <NumberInput
                label={t('Assigned credit limit')}
                source="assignedCreditLimit"
                validate={[positiveNumberValidation, ...validateRequired]}
              />
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
    </>
  );
};

export default CustomerForm;
