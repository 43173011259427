import React from 'react';
import Chip from '@mui/material/Chip';

const renderLabelChip = ({ theme }) => (label = {}) => (
  <Chip
    label={label.name}
    style={{ margin: '2px', backgroundColor: label.color, color: theme.palette.getContrastText(label.color) }}
  />
);

export default renderLabelChip;
