import React from 'react';

import {
  TextField,
  DateField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const SystemInfoGroup = () => {
  const { t } = useTranslation();
  return (
    <FieldsGroup title={t('System information')}>
      <Grid container>
        <Grid item lg={3} md={6}>
          <SimpleShowLabeled>
            <DateField source="createdAt" label={t('Creation date')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={3} md={6}>
          <SimpleShowLabeled>
            <TextField source="creationSource" label={t('Creation source')} />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default SystemInfoGroup;
