import React, { useState, useCallback } from 'react';
import {
  useLogin,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ChildrenProp } from '../../utils/helpers/propTypes';

import { ReactComponent as FimentoLogo } from '../../../assets/fimentoBlackLogo.svg';
import { ReactComponent as EmergencyHome } from '../../../assets/emergency_home.svg';
import videoBackground from '../../../assets/videos/login-vawe-particle-background.mp4';

import Button from '../designSystem/Button';

export const StyledTextInput = styled(Input)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '1.4rem',
  backgroundColor: theme.palette.grey.input,
  borderRadius: '0.625rem',
  padding: '0.3rem 0.5rem',
  border: '1px solid transparent',
  '&:focus': {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  '&:before': {
    borderBottom: '0!important', // remove hover behaviour
  },
  '&:after': {
    borderBottom: '0',
  },
}));

export const StyledTextInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '1.4rem',
  transform: 'none',
  top: '-0.5rem',
  color: theme.palette.switch,
}));

export const UnauthorizedPageWrapper = ({ title, children }) => (
  <Box
    display="flex"
    justifyContent="center"
    flexDirection="column"
    maxHeight="calc(90% - 2rem)"
    height="100%"
  >
    <video
      autoPlay
      loop
      muted
      width="100%"
      height="100%"
      style={{
        position: 'absolute',
        objectFit: 'cover',
        mixBlendMode: 'multiply',
      }}
    >
      <source src={videoBackground} type="video/mp4" />
    </video>
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="2rem"
      >
        <a href="https://www.fimento.com">
          <FimentoLogo />
        </a>
      </Box>
      <Box
        backgroundColor="boxes"
        padding="4rem"
        display="flex"
        flexDirection="column"
        borderRadius="1.25rem"
        gap="1.5rem"
        margin="3.5rem 0 2.8rem"
        maxWidth="33.6rem"
        width="100%"
        alignSelf="center"
      >
        <Typography
          variant="h6"
          component="h1"
          gutterBottom
          color="switch"
          fontWeight="600"
        >
          {title}
        </Typography>
        {children}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
      >
        <Typography variant="caption" color="textSecondary">&copy; {new Date().getFullYear()} <strong>Fimento</strong></Typography>
      </Box>
    </Box>
  </Box>
);

UnauthorizedPageWrapper.propTypes = {
  title: PropTypes.string,
  children: ChildrenProp.isRequired,

};

UnauthorizedPageWrapper.defaultProps = {
  title: '',
};

const visibilityIconStyle = {
  marginRight: '0.3rem',
  ':hover': { cursor: 'pointer' },
};

const Login = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const login = useLogin();
  const submit = useCallback((e) => {
    e.preventDefault();
    login({ username: email, password }).catch(() => {
      setError(t('Invalid email or password'));
    });
  }, [login, email, password, setError, t]);
  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
    setError('');
  }, []);
  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
    setError('');
  }, []);

  const disableButton = !email || !password;

  const onClick = useCallback(() => {
    setIsVisible(!isVisible);
  }, [setIsVisible, isVisible]);

  return (
    <UnauthorizedPageWrapper title={t('Login')}>
      <form onSubmit={submit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="stretch"
          gap="1.5rem"
        >
          <FormControl variant="filled" focused>
            <StyledTextInputLabel htmlFor="email">{t('Username')}</StyledTextInputLabel>
            <StyledTextInput
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </FormControl>
          <FormControl variant="filled" focused>
            <StyledTextInputLabel htmlFor="password">{t('Password')}</StyledTextInputLabel>
            <StyledTextInput
              id="password"
              name="password"
              type={isVisible ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              endAdornment={isVisible
                ? <VisibilityOutlinedIcon sx={visibilityIconStyle} onClick={onClick} />
                : <VisibilityOffOutlinedIcon sx={visibilityIconStyle} onClick={onClick} />}
            />
          </FormControl>
          {error && (
          <Box
            display="flex"
          >
            <EmergencyHome width="1rem" />
            <Typography fontSize="12px" marginLeft="0.5rem" lineHeight="18px">{error}</Typography>
          </Box>
          )}
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={disableButton}
            disabledTooltip={disableButton ? t('Please fill in all fields') : undefined}
            sx={{
              padding: '0.5rem',
              height: '2.5rem',
              marginTop: '0.8rem',
              width: '100%',
            }}
          >
            {t('Log in')}
          </Button>
        </Box>
      </form>
    </UnauthorizedPageWrapper>
  );
};

export default Login;
