import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import downloadDocumentOnSuccess from '../../utils/downloadDocumentOnSuccess';
import useCustomRpc from '../hooks/useCustomRpc';
import { ReactComponent as OpenInNew } from '../../../assets/open_in_new.svg';

const EnhancedFileField = (props) => {
  const {
    parentId,
    resource,
  } = props;

  const record = useRecordContext();
  const { t } = useTranslation();

  const {
    _id: documentId,
    originalName,
    title,
    rawFile,
  } = record;
  if (!rawFile && !parentId && !resource) throw new Error('Missing parentId or resource');
  const {
    mutate: downloadModelDocument,
  } = useCustomRpc({
    httpMethod: 'GET',
    path: `${resource}/${parentId}/download/${documentId}`,
    errorMessage: t('Could not download the file'),
    onSuccess: downloadDocumentOnSuccess,
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    if (rawFile) return;
    downloadModelDocument();
  }, [downloadModelDocument, rawFile]);

  return (
    <Box display="inline-block" alignItems="center" marginRight="2rem">
      <Box padding="0.5rem 0" borderBottom="1px solid #E8E8E8" marginRight="0.5rem">
        {rawFile
          ? <Typography fontWeight="600" fontSize="12px" padding="0.5rem">{title}</Typography>
          : (
            <Button
              variant="blueBackgroundOnHover"
              startIcon={<OpenInNew />}
              onClick={onClick}
            >
              {originalName}
            </Button>
          )}
      </Box>
    </Box>
  );
};

EnhancedFileField.propTypes = {
  parentId: PropTypes.string,
  resource: PropTypes.string,
};

EnhancedFileField.defaultProps = {
  parentId: undefined,
  resource: undefined,
};

export default EnhancedFileField;
