// Deprecated
import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  ArrayInput,
  DateInput,
  required,
  SimpleFormIterator,
  SelectInput,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import hasUserPermissions from '../../utils/hasUserPermissions';

import OldFieldsGroup from '../utils/OldFieldsGroup';

import { useConstantContext } from '../ConstantsContext';

const validateRequired = [required()];

const KeyPeopleInputs = ({ userAction }) => {
  const { choices, constants } = useConstantContext();
  const entityDetailsRecord = useRecordContext();
  const { t } = useTranslation();

  const authenticationData = usePermissions();
  const choiceStates = choices;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canEdit = hasUserPermissions(userAllowedPermissions, userAction);
  const coreTypeInput = useWatch({ name: 'type' });

  if (!entityDetailsRecord && !coreTypeInput) return null;
  if (entityDetailsRecord && coreTypeInput) throw new Error('Cannot render entity details. One source of truth is required for the entity core type');

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? entityDetailsRecord.coreType === constants.coreTypes.BUSINESS
      : undefined;
  }

  return (
    <OldFieldsGroup fillAvailable>
      {isBusiness && (
        <OldFieldsGroup withBorder fillAvailable>
          <ArrayInput
            source="keyPeople"
            label={t('Key People')}
            style={{ width: '100%' }}
            disabled={!canEdit}
          >
            <SimpleFormIterator disableReordering inline>
              <TextInput source="firstName" label={t('First Name')} validate={validateRequired} disabled={!canEdit} />
              <TextInput source="lastName" label={t('Last Name')} validate={validateRequired} disabled={!canEdit} />
              <ReferenceInput
                source="nationality"
                reference="countries"
                resource="/countries"
              >
                <AutocompleteInput
                  optionText={(record) => (record && record.name ? `(${record.id}) ${record.name}` : '')}
                  suggestionLimit={20}
                  disabled={!canEdit}
                  label={t('Nationality')}
                  validate={validateRequired}
                />
              </ReferenceInput>
              <DateInput source="dateBirth" label={t('Date of Birth')} validate={validateRequired} disabled={!canEdit} />
              <SelectInput
                margin="none"
                label={t('Role')}
                source="role"
                choices={choiceStates.keyPeopleRoles}
                validate={validateRequired}
              />
              <BooleanInput source="isPep" label={t('Is PEP')} disabled={!canEdit} />
            </SimpleFormIterator>
          </ArrayInput>
        </OldFieldsGroup>
      )}
    </OldFieldsGroup>
  );
};

KeyPeopleInputs.propTypes = {
  userAction: PropTypes.string.isRequired,
};

export default KeyPeopleInputs;
