import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import InputAdornment from '@mui/material/InputAdornment';

import { positiveNumberValidation } from '../../utils/validationErrors';

import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import InputsGroup from '../designSystem/InputsGroup';

import useRulesNames from './useRulesNames';

import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: '0.5rem',
  alignSelf: 'center',
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: 'white',
      '& +.MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'white',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '0.81rem',
    backgroundColor: theme.palette.switchTrack,
    opacity: 1,
  },
}));

const StyledCheckboxIconChecked = styled(RadioButtonCheckedOutlinedIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  'input:disabled ~ &': {
    opacity: 0.5,
  },
}));

const InputRule = ({
  ruleSet,
  rule,
  repaymentPeriodSetting,
  overdueDaysSetting,
  maxExpiringDaysSetting,
  inputGroupLayout,
  borderTop,
  disableStrictlyDeny,
}) => {
  const { t } = useTranslation();
  const rulesNames = useRulesNames();
  const { setValue } = useFormContext();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const onDisableRule = useCallback((event) => {
    const value = event.target.checked;
    if (!value) {
      setValue(`${ruleSet}.${rule}.isHard`, null);
      setValue(`${ruleSet}.${rule}.maxOverdueDays`, null);
      setValue(`${ruleSet}.${rule}.maxCreditLimit`, null);
      setValue(`${ruleSet}.${rule}.repaymentPeriod`, null);
      setValue(`${ruleSet}.${rule}.maxDaysBeforeExpiring`, null);
    }
  }, [rule, ruleSet, setValue]);

  const { field: activateRuleField } = useInput({
    source: `${ruleSet}.${rule}`,
    parse: (value) => (value ? {} : null),
    format: (value) => !!value,
    onChange: onDisableRule,
    type: 'checkbox',
    defaultValue: null,
  });

  const { field: isHardRuleField } = useInput({
    source: activateRuleField.value ? `${ruleSet}.${rule}.isHard` : '',
    type: 'checkbox',
  });

  const canEdit = useHasUserPermissions(
    constants,
    userActions.EDIT_CREDIT_RULES,
  );

  return (
    <Grid
      container
      marginTop="0.94rem"
      paddingBottom="0.94rem"
      borderBottom="0.06rem solid var(--stroke-and-lines-subtle-gray-10, rgba(0, 0, 0, 0.10))"
      borderTop={borderTop}
    >
      <Grid
        item
        lg={2}
        md={6}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignContent="center"
      >
        <StyledSwitch
          name={activateRuleField.name}
          onChange={activateRuleField.onChange}
          checked={activateRuleField.value || false}
          disabled={!canEdit}
        />
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignContent="center"
      >
        <Typography alignSelf="center"> {rulesNames[rule]} </Typography>
      </Grid>
      <Grid item lg={4} md={6}>
        <InputsGroup layout={inputGroupLayout}>
          {overdueDaysSetting && (
            <NumberInput
              label={t('Count as late after')}
              source={activateRuleField.value ? `${ruleSet}.${rule}.maxOverdueDays` : ''}
              validate={positiveNumberValidation}
              disabled={!activateRuleField.value || !canEdit}
              InputProps={{ endAdornment: <InputAdornment position="end">days</InputAdornment> }}
              placeholder={t('E.g. 500')}
            />
          )}
          {repaymentPeriodSetting && (
            <NumberInput
              label={t('Repayment period')}
              source={activateRuleField.value ? `${ruleSet}.${rule}.repaymentPeriod` : ''}
              validate={positiveNumberValidation}
              disabled={!activateRuleField.value || !canEdit}
              InputProps={{ endAdornment: <InputAdornment position="end">days</InputAdornment> }}
              placeholder={t('E.g. 500')}
            />
          )}

          {maxExpiringDaysSetting && (
            <NumberInput
              label={t('Expiring days threshold')}
              source={activateRuleField.value ? `${ruleSet}.${rule}.maxDaysBeforeExpiring` : ''}
              validate={positiveNumberValidation}
              disabled={!activateRuleField.value || !canEdit}
              InputProps={{ endAdornment: <InputAdornment position="end">days</InputAdornment> }}
              placeholder={t('E.g. 500')}
            />
          )}
        </InputsGroup>
      </Grid>
      <Grid
        item
        lg={2}
        md={6}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignContent="center"
      >
        <FormControlLabel
          control={(
            <Checkbox
              name={isHardRuleField.name}
              onChange={isHardRuleField.onChange}
              checked={isHardRuleField.value || false}
              disabled={!activateRuleField.value || !canEdit || disableStrictlyDeny}
              icon={<CircleOutlinedIcon />}
              checkedIcon={<StyledCheckboxIconChecked />}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

InputRule.propTypes = {
  rule: PropTypes.string.isRequired,
  ruleSet: PropTypes.string.isRequired,
  repaymentPeriodSetting: PropTypes.bool,
  overdueDaysSetting: PropTypes.bool,
  maxExpiringDaysSetting: PropTypes.bool,
  inputGroupLayout: PropTypes.string,
  borderTop: PropTypes.string,
  disableStrictlyDeny: PropTypes.bool,
};

InputRule.defaultProps = {
  repaymentPeriodSetting: false,
  overdueDaysSetting: false,
  maxExpiringDaysSetting: false,
  inputGroupLayout: 'row',
  borderTop: '',
  disableStrictlyDeny: false,
};

export default InputRule;
