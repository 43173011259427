import React, { useCallback } from 'react';
import { startCase } from 'lodash';

import {
  FunctionField,
  useRedirect,
  usePermissions,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import NotificationNotRead from '@mui/icons-material/NotificationsActive';
import NotificationRead from '@mui/icons-material/NotificationsNone';
import HelpIcon from '@mui/icons-material/Help';

import useCustomRpc from '../hooks/useCustomRpc';

import resources from '../../constants/resources';

import MarkAsReadBatchButton from './MarkAsReadBatchButton';

import { useConstantContext } from '../ConstantsContext';

import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import DateField from '../designSystem/react-admin/DateField';
import DescriptionField from '../designSystem/react-admin/DescriptionField';

import mapResourceAction, { resourceAction } from '../utils/mapResourceToAction';
import { hasMultipleUserPermissions } from '../../utils/hasUserPermissions';

const renderIsRead = (record) => {
  if (!record) return <HelpIcon htmlColor="grey" />;

  return record.isRead ? <NotificationRead htmlColor="grey" /> : <NotificationNotRead htmlColor="red" />;
};

const fromCamelCaseToCapitalised = (record) => {
  if (!record || !record.resourceType) return '';
  return startCase(record.resourceType);
};

const resourcesWithoutShow = [
  resources.CUSTOMER,
  resources.MERCHANT,
];

const NotificationListDetails = () => {
  const redirect = useRedirect();
  const { constants } = useConstantContext();
  const { resourceTypePaths, userActions } = constants;
  const { permissions: userPermissions } = usePermissions();
  const { t } = useTranslation();

  const {
    mutate: markAsRead,
  } = useCustomRpc({
    httpMethod: 'PUT',
    errorMessage: t('Could not mark notification as read'),
    shouldRefresh: true,
  });

  const onClick = useCallback((id, _, record) => {
    const { resourceType, resourceId } = record;
    markAsRead({ overridePath: `notification/${id}/mark-as-read` });

    const resourceMap = mapResourceAction(userActions);

    const canShow = hasMultipleUserPermissions(
      userPermissions.userAllowedPermissions,
      resourceMap[resourceType][resourceAction.SHOW],
    );
    const canEdit = hasMultipleUserPermissions(
      userPermissions.userAllowedPermissions,
      resourceMap[resourceType][resourceAction.EDIT],
    );

    if (canShow || canEdit) {
      let redirectLinkBase;
      if (resourceType === 'report') {
        redirectLinkBase = `/${resourceTypePaths[resourceType]}`;
      } else if (canEdit || resourcesWithoutShow.includes(resourceType)) {
        redirectLinkBase = `/${resourceTypePaths[resourceType]}/${resourceId}`;
      } else {
        redirectLinkBase = `/${resourceTypePaths[resourceType]}/${resourceId}/show`;
      }

      redirect(redirectLinkBase);
    }
  }, [
    markAsRead,
    redirect,
    resourceTypePaths,
    userActions,
    userPermissions.userAllowedPermissions,
  ]);

  return (
    <DatagridConfigurable rowClick={onClick} bulkActionButtons={<MarkAsReadBatchButton />} cellMaxWidth="unset">
      <FunctionField render={renderIsRead} label={t('Read')} />
      <DateField source="createdAt" label={t('Event date')} showTime />
      <FunctionField render={fromCamelCaseToCapitalised} label={t('Event type')} sortable={false} />
      <DescriptionField source="description" label={t('Description')} sortable={false} />
    </DatagridConfigurable>
  );
};

export default NotificationListDetails;
