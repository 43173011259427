const isRecourseProduct = (
  financialProducts,
  financialProductsConstants,
) => financialProducts.some(
  (product) => [
    financialProductsConstants.DISCLOSED_FACTORING_RECOURSING,
    financialProductsConstants.UNDISCLOSED_FACTORING_RECOURSING,
  ].includes(product),
);

export default isRecourseProduct;
