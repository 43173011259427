import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChildrenProp } from '../../utils/helpers/propTypes';
import useCustomQuery from '../hooks/useCustomQuery';

const StatisticsContext = React.createContext({});

export const StatisticsContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const statisticsResult = useCustomQuery({
    key: 'statistics',
    path: 'statistics',
    httpMethod: 'GET',
    errorMessage: t('Error when fetching statistics'),
  });

  return (
    <StatisticsContext.Provider value={statisticsResult ?? {}}>
      {children}
    </StatisticsContext.Provider>
  );
};

StatisticsContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

export const useStatistics = () => {
  const {
    data: statisticsData,
    isLoading: getStatisticsLoading,
  } = useContext(StatisticsContext);
  return {
    statisticsData: statisticsData?.data,
    getStatisticsLoading,
  };
};
