import React from 'react';
import {
  Toolbar,
  useRecordContext,
  DeleteWithConfirmButton,
} from 'react-admin';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CreditScore from '@mui/icons-material/CreditCardSharp';
import Button from '@mui/material/Button';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../../ConstantsContext';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const Spacer = () => <div style={{ width: '1rem' }} />;

const CustomerToolbar = () => {
  const { isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_CUSTOMERS,
  );
  const canEditCustomerApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_CUSTOMER_APPLICATIONS,
  );
  const canCreateCreditInfo = useHasUserPermissions(
    constants,
    userActions.UPDATE_CUSTOMER_APPLICATIONS_TERMS,
  );

  const record = useRecordContext();

  if (
    !canEdit
    && !canCreateCreditInfo
  ) return null;

  return (
    <Toolbar>
      {canEdit && canEditCustomerApplication && isMerchant && (
        <>
          <Button
            color="primary"
            variant="outlined"
            component={NavLink}
            startIcon={<CreditScore />}
            to={{
              pathname: '/entity-v2/assigned-credit-info/create',
            }}
            state={{
              record: {
                id: record.id,
                entityCoreId: record.entityCoreId,
                marketplaceReferenceId: record.marketplaceReferenceId,
              },
            }}
          >
            {t('Request Credit Limit')}
          </Button>
          <Spacer />
        </>
      )}
      {canEdit && record && (
        <DeleteWithConfirmButton
          confirmTitle={`${t('Delete')} ${record.marketplaceReferenceId ? `(${record.marketplaceReferenceId})` : ''} ${record.mainAddress.name}`}
          confirmContent={t('Are you sure you want to delete this customer?')}
          size="medium"
          variant="text"
        />
      )}
    </Toolbar>
  );
};

export default CustomerToolbar;
