import React, { useCallback } from 'react';
import {
  useNotify,
  useResourceContext,
  useListContext,
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';

const UnidentifiedListButtons = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const resource = useResourceContext();
  const { selectedIds } = useListContext();

  const showNotification = useCallback(({ data }) => {
    notify(
      `${data.message}${data.errors.length > 0 ? `\nErrors:\n${data.errors.join('\n')}` : ''}`, {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
  }, [notify]);

  const {
    mutate: bulkRevertPayment,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: 'transaction/revert',
    onSuccess: showNotification,
    shouldRefresh: true,
    shouldUnselectAll: resource,
    errorMessage: t('Could not revert unidentified payments'),
  });

  const handleBulkRevertClick = useCallback(() => {
    bulkRevertPayment({ selectedIds });
    return true;
  }, [bulkRevertPayment, selectedIds]);

  return (
    <Box display="flex" gap="0.5rem" flexDirection="right" margin="0.5rem 0.3rem">
      <ModalButton
        modalTitle={t('Revert incorrect payments')}
        openButtonLabel={t('Revert payment')}
        actionLabel={t('Confirm')}
        onClick={handleBulkRevertClick}
        disabled={isLoading}
        width="32rem"
      >
        <Typography>
          {t('The payments has been selected as incorrect. To remove them from the "Unidentified" section, proceed with this action. This action cannot be reversed')}
        </Typography>
      </ModalButton>

    </Box>
  );
};

export default UnidentifiedListButtons;
