import { styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {
  ListBase,
  ListView as RaListView,
} from 'react-admin';
import ListAction from './ListAction';
import { useDefineLocation } from '../../../AppLocationContext';

const StyledListView = styled(RaListView)(({ theme }) => ({
  backgroundColor: theme.palette.boxes,
  marginTop: '1rem',
  padding: '2rem 1.25rem',
  borderRadius: '0.625rem',
  width: '100%',
}));

const List = ({
  // ListBase props
  filter,
  filters,
  filterDefaultValues,
  perPage,
  sort,
  exporter,
  resource,
  disableSyncWithLocation,

  // ListAction props
  mainFilterInputs,
  subFilterInputs,
  actions,
  configurable,

  // Custom props
  appLocation,

  ...rest
}) => {
  useDefineLocation(appLocation);

  return (
    <ListBase
      filterDefaultValues={filterDefaultValues}
      resource={resource}
      disableSyncWithLocation={disableSyncWithLocation}
      filter={filter}
      filters={filters}
      perPage={perPage || 50}
      sort={sort}
      exporter={exporter}
    >
      {(actions
        || mainFilterInputs.length > 0
        || subFilterInputs.length > 0)
        && (
          <ListAction
            actions={actions}
            mainFilterInputs={mainFilterInputs}
            subFilterInputs={subFilterInputs}
            configurable={configurable}
          />
        )}
      <StyledListView
        actions={false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </ListBase>
  );
};

List.propTypes = {
  ...ListAction.propTypes,
  appLocation: PropTypes.string,
};

List.defaultProps = {
  ...ListAction.defaultProps,
  appLocation: undefined,
};

export default List;
