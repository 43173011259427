import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { ChildrenProp } from './helpers/propTypes';

const DropDownMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const open = !!anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="more"
      >
        <MoreVertIcon color="secondary" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box padding="1.5rem" display="flex" flexDirection="column">
          <Typography
            fontSize="10px"
            color="secondary"
            fontWeight="600"
            marginBottom="0.5rem"
          >
            {t('Actions')}
          </Typography>
          {children}
        </Box>
      </Popover>
    </>
  );
};

DropDownMenu.propTypes = {
  children: ChildrenProp.isRequired,
};

export default DropDownMenu;
