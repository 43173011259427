import React from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
} from 'react-admin';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useOrderFilters from './useOrderFilters';
import ImportOrderButtonNew from './ImportOrderButton';
import EmptyOrderList from './EmptyOrderList';
import OrderListFields from './OrderListFields';

import SafePagination from '../utils/SafePagination';

import resources from '../../constants/resources';

import { useConstantContext } from '../ConstantsContext';

import List from '../designSystem/react-admin/list/List';
import TextInputWithLens from '../designSystem/react-admin/inputs/TextInputWithLens';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import TitleH1 from '../designSystem/TitleH1';

export const getOrderListTitle = () => 'Orders';

export const OrderListDetails = ({ filter }) => {
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_ORDERS,
  );

  return <OrderListFields canEdit={canEdit} filter={filter} />;
};

OrderListDetails.propTypes = {
  filter: PropTypes.shape({}),
};

OrderListDetails.defaultProps = {
  filter: {},
};

const OrderList = ({ filter }) => {
  const { pathname } = useLocation();
  const isPureOrderList = pathname.includes(resources.ORDER);
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_ORDERS,
  );

  const shouldShowOrderActions = pathname.includes(resources.ORDER);
  const orderFilters = useOrderFilters(filter);

  return (
    <List
      appLocation={!isPureOrderList ? undefined : 'home.order'}
      disableSyncWithLocation
      resource="order"
      filter={filter}
      title={!isPureOrderList ? <></> : <TitleH1 title={t(getOrderListTitle())} />}
      empty={<EmptyOrderList />}
      actions={(
        <>
          {shouldShowOrderActions && <ImportOrderButtonNew />}
          {canEdit && shouldShowOrderActions && !isMerchant ? <CreateButton label={t('Add order')} /> : null}
        </>
      )}
      mainFilterInputs={[<TextInputWithLens source="q" placeholder={t('Search order or payment ref.')} />]}
      subFilterInputs={orderFilters}
      configurable={shouldShowOrderActions}
      pagination={<SafePagination />}
      sort={{ field: 'activationDate', order: 'DESC' }}
    >
      <OrderListDetails filter={filter} />
    </List>
  );
};

OrderList.propTypes = {
  filter: PropTypes.shape({}),
};

OrderList.defaultProps = {
  filter: {},
};

export default OrderList;
