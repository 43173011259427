import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

import TextInput from './TextInput';

const TextInputWithLens = (props) => (
  <TextInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
);

export default TextInputWithLens;
