import React, { useCallback } from 'react';

import {
  Box,
  SvgIcon,
  Typography,
  useTheme,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileInput as RaFileInput, FileInputClasses as RaFileInputClasses } from 'react-admin';

import { ReactComponent as CloudUpload } from '../../../../../assets/cloud_upload.svg';

const StyledRaFileInput = styled(RaFileInput)(({ theme }) => ({
  [`& .${RaFileInputClasses.removeButton}`]: {
    display: 'table',
    position: 'relative',
    '& button.RaFileInputPreview-removeButton': {
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
      opacity: 0,
    },
    '&:hover button.RaFileInputPreview-removeButton': {
      opacity: 1,
    },
    '& .RaFileInputPreview-removeIcon': {
      color: theme.palette.secondary.main,
    },
  },
  '& .RaFileInput-dropZone': {
    backgroundColor: theme.palette.hover,
    borderRadius: '10px',
  },
}));

const UploadedDocumentsList = ({ ...restProps }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const onChange = useCallback(() => {
    // TODO submit
  }, []);

  const {
    placeHolder,
    ...props
  } = restProps;

  return (
    <StyledRaFileInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={onChange}
      maxSize={5000000}
      placeholder={(
        <Box display="flex" flexDirection="column" alignItems="center" padding="1rem">
          <SvgIcon
            component={CloudUpload}
            inheritViewBox
            sx={{ width: 'auto', height: 'auto' }}
          />
          {
            placeHolder || <Typography fontSize="12px">{t('Drag and drop files here or you can')} <br /><span style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>{t('click to select a file.')}</span></Typography>
          }
        </Box>
      )}
    />
  );
};

export default UploadedDocumentsList;
