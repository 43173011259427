import React, { useCallback } from 'react';

import {
  useNotify,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { t } from 'i18next';

import Typography from '@mui/material/Typography';

import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';

import { useConstantContext } from '../ConstantsContext';

import hasUserPermissions from '../../utils/hasUserPermissions';

const CancelDeliveryConfirmationButton = () => {
  const notify = useNotify();
  const [featureFlags] = useFeatureFlags();

  const { id, status, orderId } = useRecordContext();
  const { constants } = useConstantContext();

  const { userActions, deliveryConfirmationStatuses } = constants;

  const authenticationData = usePermissions();
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canEditDeliveryConfirmation = hasUserPermissions(
    userAllowedPermissions,
    userActions.MANAGE_DELIVERY_CONFIRMATION,
  );

  const {
    mutate: markAsCancelled,
    isLoading,
  } = useCustomRpc({
    path: `order/${orderId}/delivery-confirmation/${id}/status`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not cancel this delivery confirmation'),
    onSuccess: () => {
      notify(t('You successfully cancelled a delivery confirmation'), { type: 'success' });
    },
  });

  const onClick = useCallback(() => {
    markAsCancelled({ status: deliveryConfirmationStatuses.CANCELLED });
  }, [markAsCancelled, deliveryConfirmationStatuses]);

  if (!canEditDeliveryConfirmation
    || !featureFlags.enableDeliveryConfirmation
    || status !== deliveryConfirmationStatuses.PENDING
  ) return null;

  return (
    <ModalButton
      modalTitle={t('Cancel delivery confirmation')}
      openButtonLabel={t('Cancel confirmation')}
      actionLabel={t('Update')}
      onClick={onClick}
      disabled={isLoading}
      variant="subAction"
      width="33rem"
    >
      <Typography paragraph>{t('Permanently mark this delivery confirmation as canceled. If you need to validate the order with the customer, please create a new request using your preferred method.')}</Typography>
    </ModalButton>
  );
};

export default CancelDeliveryConfirmationButton;
