import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading, useNotify } from 'react-admin';
import { isEmpty } from 'lodash';

import FileList from './FileList';
import { useConstantContext } from '../ConstantsContext';

const OutboundPaymentFileList = () => {
  const { constants } = useConstantContext();
  const { fileTypes } = constants;
  const query = new URLSearchParams(useLocation().search);
  const error = query.get('error');
  const notify = useNotify();

  useEffect(() => {
    if (error) notify(error, { type: 'warning' });
  }, [error, notify]);

  if (isEmpty(fileTypes)) return <Loading />;

  return (
    <FileList
      skipLayout
      resource="file/outbound-payments"
      disableSyncWithLocation
      fileType={fileTypes.OUTBOUND}
      title={<></>}
    />
  );
};

export default OutboundPaymentFileList;
