import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  ArrayInput,
  required,
  useRecordContext,
  ReferenceInput,
} from 'react-admin';
import { t } from 'i18next';

import { get } from 'lodash';

import useCustomRpc from '../hooks/useCustomRpc';
import useIsCreditor from '../hooks/useIsCreditor';

import renderReference from '../../utils/renderReference';

import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
import { EditModalButton } from '../designSystem/ModalButton';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

import {
  bicValidation,
  emailValidation,
  ibanValidation,
  percentageValidation,
  positiveNumberValidation,
  maxLengthValidation,
} from '../../utils/validationErrors';
import TextInput from '../designSystem/react-admin/inputs/TextInput';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import { invoicingSettingsResources } from './UpdateEntityInvoiceSettingsButton';

const validateRequired = [required()];

const mapResource = (
  id,
  creditorId,
  sellerId,
) => ({
  [invoicingSettingsResources.ORDER]: {
    path: `order/${id}/invoicing-settings`,
    errorMessage: t('Could not update the invoicing settings for the order'),
    invoiceSenderFilter: [creditorId, sellerId],
  },
  [invoicingSettingsResources.LOAN]: {
    path: `loan/${id}/invoicing-settings`,
    errorMessage: t('Could not update the invoicing settings for the loan'),
    invoiceSenderFilter: creditorId,
  },
});

const CustomInput = ({
  source,
  InputComponent,
  disabledCondition,
  ...props
}) => {
  const splitedSource = source.split('.');

  splitedSource.pop();

  const sourcePrefix = splitedSource.join('.');

  const record = useRecordContext();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <InputComponent source={source} {...props} disabled={disabledCondition(get(record, sourcePrefix))} />
  );
};

CustomInput.propTypes = {
  source: PropTypes.string.isRequired,
  InputComponent: PropTypes.elementType.isRequired,
  disabledCondition: PropTypes.func,
};

CustomInput.defaultProps = {
  disabledCondition: () => false,
};

const UpdateAccountInvoiceSettingsButton = ({ resource }) => {
  const record = useRecordContext();
  const {
    id,
    balance,
    creditorId,
    sellerId,
  } = record;

  const { constants, choices } = useConstantContext();
  const isCreditor = useIsCreditor();

  const {
    path,
    errorMessage,
    invoiceSenderFilter,
  } = mapResource(
    id,
    creditorId,
    sellerId,
  )[resource];

  const {
    emailLayoutTypesInvoice,
    emailLayoutTypesReminder,
    invoiceDefaultLayoutTypes,
    reminderLayoutTypes,
  } = choices;

  const {
    mutate: updateInvoicingSettings,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage,
  });

  const onSubmit = useCallback((formData) => {
    updateInvoicingSettings(formData);
    return true;
  }, [
    updateInvoicingSettings,
  ]);

  const canEdit = useHasUserPermissions(
    constants,
    constants.userActions.MANAGE_INVOICES,
  );

  if (!canEdit
    || balance === 0
    || (resource === invoicingSettingsResources.ORDER && !isCreditor)
  ) return null;

  const disabledCondition = (recordContext) => recordContext && !!recordContext.reminderId;

  return (
    <EditModalButton
      modalTitle={t('Update invoicing settings')}
      onClick={onSubmit}
      disabled={isLoading}
      fullWidth
      withForm
      formDefaultValue={record}
      width="47.5rem"
    >
      <BooleanInput
        source="invoicingSettings.shouldSendInvoices"
        label={t('Should send invoices')}
        helperText={t('Automatically send invoice to the customer. Invoices will be sent by email to the invoice email address in the customer details. For factoring, the invoice will be sent after the financing application has been approved, reminders and credit notes are send based on the schedule or manual decisions.')}
      />
      <BooleanInput
        source="invoicingSettings.enableAutomaticDunning"
        label={t('Enable dunning flow')}
        helperText={t('Automatically send reminders with fees and late interest for overdue invoices. Deactivating this setting will temporarily pause the dunning flow. Once reactivated, it will send all reminders and fees that accumulated during the pause.')}
      />
      <InputsGroup layout="column">
        <ReferenceInput
          source="invoicingSettings.displaySenderId"
          reference="entity-v2/reference"
          filter={{ id: invoiceSenderFilter }}
        >
          <AutocompleteInput
            label={t('Invoice sender')}
            validate={validateRequired}
            optionText={renderReference}
            suggestionLimit={20}
          />
        </ReferenceInput>
        <NumberInput
          label={t('Invoice fee')}
          source="invoicingSettings.invoiceFee"
          validate={[positiveNumberValidation]}
        />
        <TextInput source="invoicingSettings.invoiceAddress.streetAddress" label={t('Street address')} />
        <TextInput source="invoicingSettings.iban" label="IBAN" validate={[ibanValidation]} />
        <TextInput source="invoicingSettings.invoiceAddress.additionalStreetAddress" label={t('Additional street address')} />
        <TextInput source="invoicingSettings.bic" label="BIC" validate={[bicValidation]} />
        <TextInput source="invoicingSettings.invoiceAddress.town" label={t('Town')} />
        <TextInput source="invoicingSettings.bankgiro" label={t('Bankgiro')} />
        <TextInput source="invoicingSettings.invoiceAddress.postcode" label={t('Postcode')} />
        <TextInput source="invoicingSettings.invoiceEmail" label={t('Invoice email')} type="email" validate={[emailValidation]} />
        <SelectInput
          label={t('Email layout')}
          source="invoicingSettings.emailLayout"
          choices={emailLayoutTypesInvoice}
          defaultValue={emailLayoutTypesInvoice[0].id}
          validate={validateRequired}
        />
        <SelectInput
          label={t('Invoice layout')}
          source="invoicingSettings.invoiceLayout"
          choices={invoiceDefaultLayoutTypes}
          defaultValue={invoiceDefaultLayoutTypes[0].id}
          validate={validateRequired}
        />
      </InputsGroup>
      <TextInput
        source="invoicingSettings.denunciationText"
        label={t('Denunciation text')}
        placeholder={t('Write your denunciation text here')}
        validate={[maxLengthValidation(1350)]}
        multiline
        emptyText="-"
      />
      <InputsGroup title={t('Dunning')}>
        <InputsGroup layout="column">
          <PercentageInput source="invoicingSettings.lateInterestRate" label={`${t('Late interest')} (%)`} validate={[percentageValidation]} />
        </InputsGroup>
        <ArrayInput
          source="invoicingSettings.dunningSettings"
          label={false}
          disabled={!canEdit}
        >
          <SimpleFormIterator addButtonLabel={t('Add new dunning settings')} itemLabel={t('Dunning settings')} disabledCondition={disabledCondition}>
            <InputsGroup layout="column">
              <CustomInput
                label={t('Fee amount')}
                source="feeAmount"
                validate={[positiveNumberValidation, ...validateRequired]}
                disabledCondition={disabledCondition}
                InputComponent={NumberInput}
              />
              <CustomInput
                label={t('Issue day')}
                source="issueDay"
                validate={[positiveNumberValidation, ...validateRequired]}
                disabledCondition={disabledCondition}
                InputComponent={NumberInput}
              />
              <CustomInput
                label={t('Description')}
                source="description"
                validate={validateRequired}
                disabledCondition={disabledCondition}
                InputComponent={TextInput}
              />
              <CustomInput
                label={t('Email layout')}
                source="emailLayout"
                choices={emailLayoutTypesReminder}
                disabledCondition={disabledCondition}
                InputComponent={SelectInput}
                validate={validateRequired}
              />
              <CustomInput
                label={t('Reminder layout')}
                source="reminderLayout"
                choices={reminderLayoutTypes}
                disabledCondition={disabledCondition}
                InputComponent={SelectInput}
                validate={validateRequired}
              />
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
    </EditModalButton>
  );
};

UpdateAccountInvoiceSettingsButton.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default UpdateAccountInvoiceSettingsButton;
