import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceInput as RaReferenceInput } from 'react-admin';
import { ChildrenProp } from '../../../../utils/helpers/propTypes';

const ReferenceInput = ({
  InputLabelProps,
  handleHide,
  fimentoVariant,
  children,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RaReferenceInput {...rest}>
    {React.cloneElement(
      children,
      { InputLabelProps, fimentoVariant, handleHide },
    )}
  </RaReferenceInput>
);

ReferenceInput.propTypes = {
  InputLabelProps: PropTypes.shape(),
  children: ChildrenProp.isRequired,
  fimentoVariant: PropTypes.string,
  handleHide: PropTypes.func,
};

ReferenceInput.defaultProps = {
  InputLabelProps: {},
  fimentoVariant: 'default',
  handleHide: undefined,
};

export default ReferenceInput;
