import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';

import { percentageValidation } from '../../utils/validationErrors';
import roundNumber from '../../utils/roundNumber';

const noop = (value) => value;

const PercentageInput = ({
  validate,
  format,
  parse,
  skipValidation,
  ...rest
}) => (
  <NumberInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    min={0}
    max={100}
    format={(value) => format(value && roundNumber(value, 10))}
    parse={parse}
    validate={[...(skipValidation ? [] : [percentageValidation]), ...validate]}
    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
    InputLabelProps={{ shrink: true }}
  />
);

PercentageInput.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.func),
  format: PropTypes.func,
  parse: PropTypes.func,
  skipValidation: PropTypes.bool,
};

PercentageInput.defaultProps = {
  validate: [],
  format: noop,
  parse: noop,
  skipValidation: false,
};

export default PercentageInput;
