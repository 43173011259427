import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useRecordContext, useRedirect } from 'react-admin';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import { isDefined } from '../../utils/validators';
import useCustomRpc from '../hooks/useCustomRpc';

const ActivateDraftButton = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { id } = record;
  const {
    formState: { dirtyFields },
    getValues,
  } = useFormContext();
  const redirect = useRedirect();

  const {
    mutate: activateDraft,
    isLoading,
  } = useCustomRpc({
    path: `order-draft/${id}/activate`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not convert the draft into a real order'),
    onSuccess: ({ data }) => {
      // TODO: Refresh notification
      const { id: activatedOrderId } = data;
      redirect(`/order/${activatedOrderId}/show`);
    },
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    // TODO: fix this
    const changedFieldKeys = Object.keys(dirtyFields);
    const values = getValues();
    const changeFields = changedFieldKeys.reduce((acc, key) => {
      const keyValue = values[key];
      return { ...acc, [key]: isDefined(keyValue) ? keyValue : null };
    }, {});
    activateDraft({ formData: changeFields });
  }, [dirtyFields, getValues, activateDraft]);

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      disabled={isLoading}
      sx={{
        margin: '0.5rem 0.3rem',
        float: 'right',
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[200],
        },
      }}
    >
      {t('Convert into order')}
    </Button>
  );
};

export default ActivateDraftButton;
