import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'react-hook-form';
import validateStartEndDateInSameYear from '../../utils/validateStartEndDateInSameYear';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';

const EndDateInput = (props) => {
  const { setValue } = useFormContext();

  const onChange = useCallback((e) => {
    setValue('date', e.target.value);
  }, [setValue]);

  return (
    <DateInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={onChange}
      validate={validateStartEndDateInSameYear()}
    />
  );
};

const useAccountingFilters = () => {
  const { t } = useTranslation();
  return useState([
    <DateInput
      alwaysOn
      source="filters.startDate"
      label={t('Start date')}
      validate={validateStartEndDateInSameYear()}
    />,
    <EndDateInput alwaysOn label={t('End date')} source="filters.endDate" />,
  ]);
};

export default useAccountingFilters;
