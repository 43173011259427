import React from 'react';

import {
  FormTab,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import LoanApplicationFields from '../LoanApplicationFields';
import LoanApplicationTerms from '../LoanApplicationTerms';
import CustomerInfo from '../CustomerInfo';
import DecisionResultsGroup from '../DecisionResultsGroup';

const SummaryTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <LoanApplicationFields />
      <CustomerInfo />
      <LoanApplicationTerms />
      <DecisionResultsGroup />
    </FormTab>
  );
};

export default SummaryTab;
