import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  styled,
  alpha,
} from '@mui/material';
import {
  sanitizeInputRestProps,
  useInput,
} from 'react-admin';
import InputWrapper, { getLabelStyle } from './InputWrapper';

const getFontStyle = (theme) => ({
  default: {
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.switch,
  },
  filter: {
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.switch,
  },
});

const getTextInputStyleDefaultVariant = ({ theme, error, multiline }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '0.3rem',
    backgroundColor: error ? alpha(theme.palette.error.main, 0.2) : theme.palette.listActive,
    ...multiline ? {
      minHeight: '7.5rem',
      alignItems: 'flex-start',
    } : {},
    'input::-webkit-input-placeholder': getLabelStyle(theme).default,
    'textarea::-webkit-input-placeholder': getLabelStyle(theme).default,
    lineHeight: 'unset',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    borderWidth: 0,
    ...getFontStyle(theme).default,
  },
  '& .MuiInputBase-formControl': {
    borderBottom: '0.125rem solid transparent',
  },
  '& .MuiInputBase-formControl.Mui-focused': {
    borderBottomColor: `${error ? theme.palette.error.main : theme.palette.secondary.main}`,
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0',
  },
  '& fieldset': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
  },
  '& .MuiInputLabel-shrink': {
    display: 'none', // Use label as a placeholder
  },
});

const getTextInputStyleFilterVariant = ({ theme, borderRadius }) => ({
  '& .MuiInputBase-root': {
    borderRadius: borderRadius || '2.75rem',
    backgroundColor: theme.palette.grey.input,
    'input::-webkit-input-placeholder': getLabelStyle(theme).filter,
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    borderWidth: 0,
    ...getFontStyle(theme).filter,
  },
  '& .MuiInputBase-formControl.Mui-focused': {
    backgroundColor: theme.palette.listActive,
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0',
  },
  '& fieldset': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
  },
  '& .MuiInputLabel-shrink': {
    display: 'none', // Use label as a placeholder
  },
});

export const mapFimentoVariantToStyle = {
  default: getTextInputStyleDefaultVariant,
  filter: getTextInputStyleFilterVariant,
};
const StyledTextInput = styled(TextField, {
  shouldForwardProp: (props) => !['borderRadius', 'fimentoVariant'].includes(props),
})(({ fimentoVariant, ...rest }) => mapFimentoVariantToStyle[fimentoVariant || 'default'](rest));

const convertNumberToString = (value) => (
  value == null || Number.isNaN(value) ? '' : value.toString());

const convertStringToNumber = (value) => {
  if (value == null || value === '') return null;
  const float = parseFloat(value);

  return Number.isNaN(float) ? 0 : float;
};

const TextInput = (props) => {
  const {
    label,
    placeholder,
    type,
    onChange,
    InputLabelProps,
    fimentoVariant,
    handleHide,
    ...rest
  } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(rest);

  const inputId = `${rest.source}-fimento-text-input`;

  const hasFocus = useRef(false);
  const [value, setValue] = useState(type === 'number' ? convertNumberToString(field.value) : field.value);
  useEffect(() => {
    if (!hasFocus.current) {
      const stringValue = convertNumberToString(field.value);
      setValue((val) => (val !== stringValue ? stringValue : val));
    }
  }, [field.value]);

  const handleChange = useCallback((e) => {
    if (onChange) onChange(e);
    setValue(e.target.value);

    const parsedValue = type === 'number' ? convertStringToNumber(e.target.value) : e.target.value;
    field.onChange(parsedValue);
  }, [field, onChange, type]);

  return (
    <InputWrapper
      inputId={inputId}
      error={invalid}
      label={label}
      isRequired={isRequired}
      InputLabelProps={InputLabelProps}
      fimentoVariant={fimentoVariant}
      handleHide={handleHide(rest.source)}
    >
      <StyledTextInput
        id={inputId}
        variant="outlined"
        placeholder={placeholder || label}
        fimentoVariant={fimentoVariant}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error?.message : <></>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sanitizeInputRestProps(rest)}
        onChange={handleChange}
        type={type}
        value={value}
      />
    </InputWrapper>
  );
};

TextInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  fimentoVariant: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  InputLabelProps: PropTypes.shape(),
  handleHide: PropTypes.func,
};

TextInput.defaultProps = {
  fimentoVariant: 'default',
  placeholder: undefined,
  label: undefined,
  type: undefined,
  onChange: undefined,
  InputLabelProps: {},
  handleHide: () => undefined,
};

export default TextInput;
