import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { isNumber } from '../../utils/validators';

const formattingOptions = {
  style: 'percent',
  minimumFractionDigits: 2,
};

export const percentageFormat = (
  amount,
  digitsPrecision,
) => (amount / 100).toLocaleString(
  undefined,
  {
    ...formattingOptions,
    maximumFractionDigits: digitsPrecision,
  },
);

export const percentageFormatOrigin = (
  amount,
  digitsPrecision,
) => (amount).toLocaleString(
  undefined,
  {
    ...formattingOptions,
    maximumFractionDigits: digitsPrecision,
  },
);

const PercentageField = ({
  source,
  digitsPrecision,
}) => {
  const record = useRecordContext();
  const value = get(record, source);
  return (
    <Typography
      component="span"
      variant="body2"
    >
      {!isNumber(value) ? '-' : percentageFormat(value, digitsPrecision)}
    </Typography>
  );
};

PercentageField.propTypes = {
  source: PropTypes.string.isRequired,
  digitsPrecision: PropTypes.number,
};

PercentageField.defaultProps = {
  digitsPrecision: 10,
};

export default PercentageField;
