import { t } from 'i18next';
import moment from 'moment';

const validatePayoutPlan = (
  financeAmountPath,
  payoutDatePath,
  termsPath,
) => (value, formValues) => {
  let error;
  if (!value || !value.length > 0) return error;

  const financeAmount = termsPath
    ? formValues[termsPath][financeAmountPath]
    : formValues[financeAmountPath];

  const payoutDate = termsPath
    ? formValues[termsPath][payoutDatePath]
    : formValues[payoutDatePath];

  if (
    value.reduce((sum, step) => sum + step.amount, 0) !== financeAmount
  ) {
    error = t('Financing amount must be equal to the sum of all payout plan steps');
  }

  if (moment(value[0].date).diff(payoutDate, 'd') !== 0) {
    error = t('First payout date must be the same as the payout date');
  }

  for (let i = 0; i < value.length; i += 1) {
    const step = value[i];
    if (value[i + 1] && moment(step.date).isSameOrAfter(value[i + 1].date)) {
      error = t('Payout dates must be sorted chronologically');
    }
  }

  return error;
};

export default validatePayoutPlan;
