const resources = {
  AUTHORIZATION: 'authorization',
  CHOICES: 'choices',
  CONSTANTS: 'constants',
  CREDIT_NOTE: 'creditNote',
  RETURN: 'returns',
  ENTITY: 'entity', // TODO: remove
  CUSTOMER: 'customer',
  MERCHANT: 'merchant',
  FINANCER: 'financer',
  KYC_INFO: 'kycInfo',
  CREDIT_INFO: 'creditInfo',
  ASSIGNED_CREDIT_INFO: 'assignedCreditInfo',
  FINANCING_INFO: 'financingInfo',
  ORDER: 'order',
  FINANCING_APPLICATION: 'financingApplication',
  CUSTOMER_APPLICATION: 'customerApplication',
  UNIDENTIFIED_ACCOUNT: 'unidentified-account',
  ACCOUNT: 'account',
  FACTORING_ACCOUNT: 'factoringAccount',
  SETTLEMENT_ACCOUNT: 'settlementAccount',
  BILLING_ACCOUNT: 'billingAccount',
  EXCHANGE_RATE: 'exchangeRate',
  MARKETPLACE: 'marketplace',
  ORDER_DRAFT: 'orderDraft',
  DOWNLOAD: 'download',
  REPORT: 'report',
  COMMENT: 'comment',
  INVOICE: 'invoice',
  UPLOAD: 'upload',
  STATISTICS: 'statistics',
  FILE: 'file',
  INBOUND_PAYMENT: 'inboundPayment',
  OUTBOUND_PAYMENT: 'outboundPayment',
  LOGS: 'logs',
  NOTIFICATION: 'notification',
  USER: 'user',
  ROLE: 'role',
  MY_COMPANY: 'myCompany',
  ENTITY_GROUP: 'entityGroup',
  LABEL: 'label',
  TRANSACTION: 'transaction',
  LOSS_RESERVATION: 'lossReservation',
  LOAN: 'loan',
  LOAN_APPLICATION: 'loanApplication',
};

export default resources;
