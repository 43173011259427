import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import useCustomRpc from '../hooks/useCustomRpc';

const SendResetPasswordLinkButton = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const {
    mutate: sendResetPasswordLink,
    isLoading,
  } = useCustomRpc({
    path: 'authorization/reset-password-link',
    httpMethod: 'POST',
    errorMessage: t('Could not send the reset password email'),
    successMessage: t('Successfully sent the reset password email'),
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    sendResetPasswordLink({ userId: record.id });
  }, [record.id, sendResetPasswordLink]);

  return (
    <Box
      sx={{
        margin: '0.5rem',
        position: 'absolute',
        right: 16,
      }}
    >
      <Button
        disabled={isLoading}
        onClick={onClick}
        variant="contained"
        color="primary"
        size="small"
      >
        {t('Send Reset Password Link')}
      </Button>
    </Box>
  );
};

export default SendResetPasswordLinkButton;
