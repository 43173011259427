/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Box, styled } from '@mui/material';
import { TabbedShowLayout as RaTabbedShowLayout } from 'react-admin';

export const StyledTabbedLayout = styled(Box)`
  & .MuiTab-root {
    text-transform: none;
  }

  ${({ theme }) => `background-color: ${theme.palette.background.default};`}

  & .MuiDivider-root + * {
    padding: 0!important;
    margin-top: 1.75rem;
  }

  & .ra-field {
    margin: 0!important;
  }
`;

const TabbedShowLayout = (props) => (
  <StyledTabbedLayout>
    <RaTabbedShowLayout {...props} />
  </StyledTabbedLayout>
);

export default TabbedShowLayout;
