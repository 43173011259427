import React from 'react';
import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import CurrencyField from '../../utils/CurrencyField';

import UpdateCustomerTermsButton from '../../application/UpdateCustomerTermsButton';

import AssignedCreditLimitsGrid from './AssignedCreditLimitsGrid';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

const CreditSettings = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const record = useRecordContext();
  const { t } = useTranslation();

  const pendingReview = !!record.pendingPeerReviewRequest;

  return (
    isFinancer && (
      <FieldsGroup
        title={t('New credit settings')}
        actions={!pendingReview && <UpdateCustomerTermsButton />}
      >
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="terms.creditLimit" currencySource="terms.creditLimitCurrency" label={t('Credit limit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="terms.creditInsuranceLimit" currencySource="terms.creditInsuranceLimitCurrency" label={t('Insurance limit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <AssignedCreditLimitsGrid />
      </FieldsGroup>
    )
  );
};

export default CreditSettings;
