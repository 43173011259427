module.exports = {
  apiBase: 'https://api.stage.fimento.com',
  apiKey: 'W*wZ7!*mH*9iu@^LKjVSgjeo',
  sentryDsn: 'https://78f500addb5f477d8cf2e15d21964fc9@o452401.ingest.sentry.io/6228437',
  sentryEnabled: true,
  sentryEnvironment: 'stage',
  canEditFeatureFlags: false,
  allowHotjar: false,
  fimentoClientId: '64707e166ef9de0d8dfbb5c7',
};
