import React, { useCallback } from 'react';
import {
  ArrayInput,
  required,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import useCustomRpc from '../hooks/useCustomRpc';
import { useConstantContext } from '../ConstantsContext';

import { EditModalButton } from '../designSystem/ModalButton';
import InputsGroup from '../designSystem/InputsGroup';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import DateInput from '../designSystem/react-admin/inputs/DateInput';
import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';

import {
  positiveNumberValidation,
  strictlyPositiveNumberValidation,
} from '../../utils/validationErrors';
import validatePayoutPlan from '../utils/validatePayoutPlan';

const UpdateLoanDetailsButton = () => {
  const validateRequired = [required()];

  const {
    id,
    terms,
  } = useRecordContext();
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const {
    resourceTypePaths,
    resourceTypes,
    repaymentTypes,
  } = constants;

  const shouldShowPayoutPlan = terms.repaymentType === repaymentTypes.INTEREST_ONLY;
  const shouldShowAmortisementFree = terms.repaymentType === repaymentTypes.ANNUITY;

  const {
    mutate: updateLoanTerms,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resourceTypePaths[resourceTypes.LOAN]}/${id}/terms`,
    shouldRefresh: true,
    errorMessage: t('Could not update the terms'),
  });

  const onSubmit = useCallback((newTerms) => {
    updateLoanTerms(newTerms);
    return true;
  }, [updateLoanTerms]);

  return (
    <EditModalButton
      modalTitle={t('Update terms')}
      onClick={onSubmit}
      disabled={isLoading}
      formDefaultValue={terms}
      width="47.5rem"
    >
      <InputsGroup layout="column">
        <PercentageInput
          label={`${t('Nominal rate')} (%)`}
          source="nominalRate"
          validate={[positiveNumberValidation, ...validateRequired]}
        />
        <NumberInput
          source="repaymentTime"
          label="Repayment period (months)"
          validate={[...validateRequired, strictlyPositiveNumberValidation]}
        />
      </InputsGroup>
      {shouldShowPayoutPlan && (
        <InputsGroup title={t('Payout plan')}>
          <ArrayInput
            label=""
            source="payoutPlan"
            validate={validatePayoutPlan(
              'amount',
              'payoutDate',
            )}
          >
            <SimpleFormIterator itemLabel={t('Payout plan')} addButtonLabel={t('Add new payout')}>
              <InputsGroup layout="column">
                <NumberInput
                  source="amount"
                  label={t('Amount')}
                  validate={[...validateRequired, strictlyPositiveNumberValidation]}
                />
                <DateInput
                  label={t('Payout date')}
                  source="date"
                  validate={validateRequired}
                />
              </InputsGroup>
            </SimpleFormIterator>
          </ArrayInput>
        </InputsGroup>
      )}
      {shouldShowAmortisementFree && (
        <InputsGroup title={t('Amortisation')}>
          <BooleanInput
            source="amortisementFree"
            label={t('Skip amortisation on the next invoice')}
            helperText={t('This is only applied once and will reset after')}
          />
        </InputsGroup>
      )}
    </EditModalButton>
  );
};

export default UpdateLoanDetailsButton;
