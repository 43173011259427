import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  useRecordContext,
  useNotify,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import resources from '../../constants/resources';
import { useConstantContext } from '../ConstantsContext';

import KycFields from '../utils/KycFields';

const UpdateKycButton = ({ entityResource }) => {
  const record = useRecordContext();
  const {
    entityDetailsId,
    kyc,
  } = record;
  const {
    creditScore,
    creditScoreProvider,
    paymentScore,
    paymentScoreProvider,
    financerScore,
    expectedVolume,
    insuranceStartDate,
    insuranceEndDate,
    ratingProvider,
    rating,
    creditQualityStep,
    nonInsurableCompany,
    financialInstitution,
  } = kyc;

  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();
  const notify = useNotify();

  const canEditCustomerKyc = useHasUserPermissions(
    constants,
    userActions.SET_CUSTOMER_KYC,
  );
  const canEditMerchantKyc = useHasUserPermissions(
    constants,
    userActions.SET_MERCHANT_KYC,
  );

  const showNotification = useCallback(({ data }) => {
    notify(
      `${data.message}${data.errors.length > 0 ? `\nErrors:\n${data.errors.join('\n')}` : ''}`,
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
  }, [notify]);

  const {
    mutate: updateKycInfo,
    isLoading,
  } = useCustomRpc({
    path: `entity-v2/${entityDetailsId}/kyc-info`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the kyc information'),
    onError: showNotification,
  });

  const onSubmit = useCallback((formData) => {
    const data = {
      kyc: {
        creditScore: formData.creditScore,
        creditScoreProvider: formData.creditScoreProvider,
        paymentScore: formData.paymentScore,
        paymentScoreProvider: formData.paymentScoreProvider,
        financerScore: formData.financerScore,
        expectedVolume: formData.expectedVolume,
        insuranceStartDate: formData.insuranceStartDate,
        insuranceEndDate: formData.insuranceEndDate,
        ratingProvider: formData.ratingProvider,
        rating: formData.rating,
        creditQualityStep: formData.creditQualityStep,
        nonInsurableCompany: formData.nonInsurableCompany,
        financialInstitution: formData.financialInstitution,
      },
    };
    updateKycInfo(data);

    return true;
  }, [updateKycInfo]);

  if (!canEditMerchantKyc && entityResource.endsWith(resources.MERCHANT)) return null;
  if (!canEditCustomerKyc && entityResource.endsWith(resources.CUSTOMER)) return null;
  if (!hasFactoring) return null;

  return (
    <EditModalButton
      modalTitle={t('Insurance and KYC')}
      onClick={onSubmit}
      disabled={isLoading}
      width="48rem"
      formDefaultValue={{
        creditScore,
        creditScoreProvider,
        paymentScore,
        paymentScoreProvider,
        financerScore,
        expectedVolume,
        insuranceStartDate,
        insuranceEndDate,
        ratingProvider,
        rating,
        creditQualityStep,
        nonInsurableCompany,
        financialInstitution,
      }}
    >
      <KycFields />
    </EditModalButton>
  );
};

UpdateKycButton.propTypes = {
  entityResource: PropTypes.string.isRequired,
};

export default UpdateKycButton;
