import React from 'react';
import {
  TextField,
  ArrayField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Datagrid from '../../designSystem/react-admin/Datagrid';
import DescriptionField from '../../designSystem/react-admin/DescriptionField';

import FieldsGroup from '../../utils/FieldsGroup';

const DecisionResultsGroup = () => {
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Decision results')}>
      <ArrayField source="decisionData.results" fullWidth selectedIds={[]}>
        <Datagrid bulkActionButtons={false} cellMaxWidth="unset">
          <TextField source="ruleName" label={t('Rule name')} />
          <TextField source="decision" />
          <DescriptionField source="reason" emptyText="-" />
        </Datagrid>
      </ArrayField>
    </FieldsGroup>
  );
};

export default DecisionResultsGroup;
