import React from 'react';
import {
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import renderReference from '../../utils/renderReference';

import { useConstantContext } from '../ConstantsContext';

import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import TextInputWithLens from '../designSystem/react-admin/inputs/TextInputWithLens';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';

const useApplicationFilters = () => {
  const resource = useResourceContext();
  const { constants, choices, clientInfo } = useConstantContext();
  const {
    decisionStatuses,
  } = choices;
  const {
    resourceTypes,
    resourceTypePaths,
    entityRoles,
    decisionStatuses: decisionStatusesConstants,
  } = constants;
  const { t } = useTranslation();

  const { isFinancer } = useUserPermissionsAndRoles();

  let placeholder;
  let isFinancingApplication = false;
  let isLoanApplication = false;
  let showSearchBar = true;
  let decisionChoices = decisionStatuses.filter((
    status,
  ) => status.id !== decisionStatusesConstants.PARTNER_PENDING
    && status.id !== decisionStatusesConstants.PARTNER_GRANTED);
  switch (resource) {
    case resourceTypePaths[resourceTypes.FINANCING_APPLICATION]:
      isFinancingApplication = true;
      placeholder = t('Search order id');
      if (clientInfo && clientInfo.defaultPartnerFinancer) {
        decisionChoices = decisionStatuses;
      }
      break;
    case resourceTypePaths[resourceTypes.LOAN_APPLICATION]:
      isLoanApplication = true;
      showSearchBar = false;
      break;
    case resourceTypePaths[resourceTypes.CUSTOMER_APPLICATION]:
      placeholder = t('Search alias or name');
      break;
    default:
      break;
  }

  if (!resourceTypes || !resourceTypePaths) return null;

  return [
    ...(showSearchBar ? [
      <TextInputWithLens
        placeholder={placeholder}
        source="q"
      />,
    ] : []),
    <SelectInput
      placeholder={t('Status')}
      source="currentDecision"
      choices={decisionChoices}
    />,
    ...(isFinancingApplication && isFinancer ? [
      <ReferenceInput
        source="merchantId"
        reference="entity-v2/reference"
        filter={{ entityRoles: entityRoles.MERCHANT }}
      >
        <AutocompleteInput
          placeholder={t('Merchant')}
          optionText={renderReference}
          suggestionLimit={10}
        />
      </ReferenceInput>,
    ] : []),
    ...(isLoanApplication && isFinancer ? [
      <ReferenceInput
        source="customerId"
        reference="entity-v2/reference"
        filter={{ entityRoles: entityRoles.CUSTOMER }}
      >
        <AutocompleteInput
          placeholder={t('Customer')}
          optionText={renderReference}
          suggestionLimit={10}
        />
      </ReferenceInput>,
    ] : []),
  ];
};

export default useApplicationFilters;
